import React from 'react'
import Phone from 'react-phone-number-input'
import Select from 'react-select';

const LabelFieldContainer = ({label, touched, error, warning, children}) => (
    <div className={`form-group ${ (touched && error) ? 'u-has-error-v1' : ''} row`}>
        <label
            className="g-min-width-150 col-form-label g-color-gray-dark-v2 g-font-weight-500 text text-right">
            {label}
        </label>

        <div id={label} className="col" style={{width: '100%'}}>

            {children}

            {touched && ((error &&
            <small className="form-control-feedback">{error}</small>) || (warning &&
            <span>{warning}</span>))}
        </div>
    </div>
);

const FieldContainer = ({touched, error, warning, children}) => (
    <div className={`${ (touched && error) ? 'u-has-error-v1' : ''}`}>

        {children}

        {touched && ((error &&
        <small className="form-control-feedback">{error}</small>) || (warning &&
        <span>{warning}</span>))}
    </div>

);


export const TextField = ({input, label, placeholder, disabled, type, meta: {touched, error, warning}}) => (
    <LabelFieldContainer label={label} touched={touched} error={error} warning={warning}>
        <input {...input}
               placeholder={label}
               type={type}
               disabled={disabled}
               className="form-control form-control-md rounded-0 g-py-6 pr-0"
        />
    </LabelFieldContainer>
);


export const TextFieldWithNoLabel = ({input, label, placeholder, disabled, type, meta: {touched, error, warning}}) => (
    <FieldContainer touched={touched} error={error} warning={warning}>
        <input {...input}
               placeholder={label}
               type={type}
               disabled={disabled}
               className="form-control form-control-md rounded-0 g-py-6 pr-0"
        />
    </FieldContainer>

);

export const Multiline = ({input, label, type, meta: {touched, error, valid, warning}}) => (
    <LabelFieldContainer label={label} touched={touched} error={error} warning={warning}>
        <textarea {...input}
                  placeholder={label}
                  type={type}
                  className="form-control form-control-md rounded-0 g-py-6 pr-0"
                  rows={5}
        />
    </LabelFieldContainer>
);

export const PhoneNumber = ({input, label, type, meta: {touched, error, valid, warning}}) => (
    <LabelFieldContainer label={label} touched={touched} error={error} warning={warning}>
        <Phone {...input}
               onFocus={() => {
                   let parent = document.getElementById(label);
                   let child = parent.getElementsByClassName("react-phone-number-input");

                   if (child[0]) {
                       child[0].classList.add("react-phone-number-input-focus");
                   }
               }}
               onBlur={() => {
                   let parent = document.getElementById(label);
                   let child = parent.getElementsByClassName("react-phone-number-input");
                   if (child[0]) {
                       child[0].classList.remove("react-phone-number-input-focus");
                   }
               }}
               country="BE"
               placeholder={label}
               className="form-control form-control-md rounded-0 g-py-6 pr-0"
               displayInitialValueAsLocalNumber={true}
        />
    </LabelFieldContainer>
);

export const Combo = ({input, label, type, options, meta: {touched, error, valid, warning}}) => (
    <LabelFieldContainer label={label} touched={touched} error={error} warning={warning}>
        <Select value={input.value}
                placeholder={label}
                onChange={(option) => input.onChange((option) ? option.value : '')}
                options={options}
        />
    </LabelFieldContainer>
);

export const CheckBox = ({input, label, type, placeholder, meta: {touched, error, warning}}) => (
    <div className="form-group">
        <label className="d-flex row g-ma-0 g-pa-0">
            <span
                className="col-10 g-min-width-250 col-form-label g-color-gray-dark-v2 g-font-weight-500 text text-right">{label}</span>

            <div className="col-2 g-pa-0 g-ma-0 col-form-label g-pt-4 ">
                <div className="form-check-inline u-check mb-0 justify-content-end align-content-end pull-right" >
                    <input {...input}
                           checked={input.value}
                           type="checkbox"
                           className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                    />
                    <div className="u-check-icon-radio-v7">
                        <i className="fa" data-check-icon="" data-uncheck-icon=""></i>
                    </div>
                </div>
            </div>
        </label>

        {/*<div className='row g-pa-0 g-ma-0'>*/}
            {/*{touched && ((error && <small className="form-control-feedback">{error}</small>) || (warning &&*/}
            {/*<span>{warning}</span>))}*/}
        {/*</div>*/}
    </div>
);




