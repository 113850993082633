export const basketTypes = {
    FETCH_REQUEST: 'BASKET_FETCH_REQUEST',
    FETCH_SUCCESS: 'BASKET_FETCH_SUCCESS',
    FETCH_FAILURE: 'BASKET_FETCH_FAILURE',
    FETCH_LAST5_REQUEST: 'BASKET_FETCH_LAST5_REQUEST',
    FETCH_LAST5_SUCCESS: 'BASKET_FETCH_LAST5_SUCCESS',
    FETCH_LAST5_FAILURE: 'BASKET_FETCH_LAST5_FAILURE',

    CLEANUP: 'BASKET_CLEANUP',
};
