import React from 'react';

export default class CircleWithLabel extends React.Component {

    render(){
        const {color, text, title, size, code} = this.props;
        return (
            <div className="g-ml-4 g-ml-20--sm pull-right" style={{float: 'left'}} onClick={() => this.props.onClick(code)}>

                <div style={{
                    border: `1px solid ${color}`,
                    width: size,
                    height: size,
                    borderRadius: size / 2,
                    position: 'relative',
                    textAlign: 'center',
                    marginBottom: 5
                }}>
                    <p style={{
                        position: 'absolute',
                        transform: "translateY(-50%)",
                        top: "50%",
                        left: 0,
                        right: 0
                    }}>{text}</p>

                </div>
                <p className="text-center g-pa-0 g-ma-0">{title}</p>

        </div>);
    }
}
