import React from 'react';
import {connect} from 'react-redux';
import {gettext} from '../../../translations';
import {colleaguesFetch, inviteColleague} from '../actions';
import * as selectors from '../selectors';
import {LoadingIndicator, validators} from '../../core/index';
import {pickBy, filter, orderBy} from 'lodash';
import ColleagueItem from './ColleagueItem';
import {Scrollbars} from 'react-custom-scrollbars';

const filters = [
    {code: "ACTIVE", label: gettext('Active')},
    {code: "LOCKED", label: gettext('Locked')},
    {code: "ALL", label: gettext('All')},
];

class Colleagues extends React.Component {
    constructor(props) {
        super(props);

        this.state = {email: '', validationError: null, filter: "ACTIVE"};

        this.handleInvite = this.handleInvite.bind(this);
    }

    componentDidMount() {
        this.props.colleaguesFetch();
    }

    handleInvite() {
        this.setState({validationError: validators.email(this.state.email)}, () => {
                if (this.state.email !== "" && this.state.validationError === undefined) {
                    this.props.inviteColleague(this.state.email);
                    this.setState({email: '', validationError: null});
                }
            }
        );
    }

    render() {
        const {colleagues, loading, error} = this.props;
        let showFilter = false;
        let filteredColleagues = colleagues;

        if (filter(colleagues, (colleague) => colleague.state === 'LOCKED_APPROVED').length > 0) {
            showFilter = true;
            let filterState = "";
            if (this.state.filter === "ACTIVE") {
                filterState = "APPROVED";
            } else if (this.state.filter === "LOCKED") {
                filterState = "LOCKED_APPROVED";
            }

            filteredColleagues = pickBy(colleagues, (colleague) => (filterState === "") ? true : colleague.state === filterState);
        }

        filteredColleagues = orderBy(filteredColleagues, 'first_name');


        if (error) {
            return <div>{error}</div>
        }

        return (

            <React.Fragment>

                <div className="row g-pa-0 g-ma-0 g-mb-10">

                    <p>{gettext("Invite your colleagues to work together in this company account. ")}</p>

                    <input
                        type='text'
                        value={ this.state.email }
                        className="col-9 form-control form-control-md rounded-0 g-py-6 pr-0"
                        placeholder={gettext('Colleague e-mail address')}
                        onChange={ (e) => {
                            this.setState({email: e.target.value});
                        } }
                    />


                    <button type="button"
                            onClick={this.handleInvite}
                            className="col-3 btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-5 g-py-5">
                        {gettext('Send')}
                        <i className="fa fa-chevron-right g-font-size-12 g-valign-middle g-ml-10"></i>
                    </button>

                    {this.state.validationError}
                </div>

                {error &&
                <div>{error}</div>
                }


                {showFilter &&
                <div className="btn-group justified-content text-center g-pb-0 g-color-white--hover g-mb-5">
                    {filters.map((filter) => {

                            if (filter.code === this.state.filter) {
                                return (<label key={filter.code}
                                    className="stats-item u-check g-pl-0 g-color-white g-bg-primary g-min-width-100"
                                    onClick={() => this.setState({filter: filter.code})}>
                                    <div
                                        className="btn btn-md btn-block u-btn-outline-lightgray rounded-0 g-color-white g-bg-primary">
                                        <div>{gettext(filter.label)}</div>
                                    </div>
                                </label>)
                            }
                            else {
                                return ( <label key={filter.code}
                                    className="stats-item u-check g-pl-0 g-color-white--hover g-bg-primary--hover g-min-width-100"
                                    onClick={() => this.setState({filter: filter.code})}>
                                    <div
                                        className="btn btn-md btn-block u-btn-outline-lightgray rounded-0 g-color-white--hover g-bg-primary--hover">
                                        <div>{gettext(filter.label)}</div>
                                    </div>
                                </label>)
                            }


                        }
                    )}
                </div>}

                {loading && <LoadingIndicator loading={loading}/>}

                {filteredColleagues.length > 0 &&
                <div>
                    <Scrollbars autoHide style={{height: 430}}>

                        {filteredColleagues.map((colleague) => <ColleagueItem key={colleague.id}
                                                                              colleague={colleague}/>)}
                    </Scrollbars>
                </div>}

            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        colleagues: selectors.getColleagues(state),
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
    };
};

export default connect(mapStateToProps, {colleaguesFetch, inviteColleague})(Colleagues);
