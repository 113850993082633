import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {validators} from '../../../components/core';
import {gettext} from '../../../translations';
import {Link} from 'react-router-dom';

export const registrationFields = {
    COMPANY_NAME: 'company_name',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    EMAIL: 'email',
    PASSWORD: 'password',
    PASSWORD_CONFIRM: 'password_confirm',
    TERMS: 'terms',
};



const TextField = ({input, label, placeholder, type, meta: {touched, error, warning}}) => (
    <div className={`form-group ${ (touched && error) ? 'u-has-error-v1' : ''} mb-4`}>
        <label className="g-color-gray-dark-v2 g-font-weight-600 g-font-size-13">
            {label}
        </label>

        <input {...input}
               placeholder={placeholder}
               type={type}
               className="form-control rounded g-py-15 g-px-15"
        />

        {touched && ((error && <small className="form-control-feedback">{error}</small>) || (warning &&
        <span>{warning}</span>))}

    </div>
);

const CheckBox = ({input, label, type, placeholder, meta: {touched, error, warning}}) => (
    <div className={`form-group ${ (touched && error) ? 'u-has-error-v1' : ''} mb-4`}>
        <label className="form-check-inline u-check g-color-gray-dark-v5 g-font-size-13 g-pl-25">
            <input {...input}
                   placeholder={placeholder}
                   type={type}
                   className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
            />
            <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                <i className="fa" data-check-icon=""></i>
            </div>
            {label}
        </label>

        <div className='row g-pa-0 g-ma-0'>
            {touched && ((error && <small className="form-control-feedback">{error}</small>) || (warning &&
            <span>{warning}</span>))}
        </div>
    </div>
);


const validate = values => {
    const errors = {};
    if (values.password !== values.password_confirm) {
        errors.password_confirm = 'Passwords are not equal';
    }
    return errors;
};

const RegistrationForm = (props) => {
    const {handleSubmit, submitting} = props;
    return (
        <form className="g-py-15" onSubmit={handleSubmit}>

            <Field name={registrationFields.COMPANY_NAME}
                   type="text"
                   component={TextField}
                   label={gettext("Company name")}
                   placeholder={gettext("Company name")}
                   validate={[validators.required]}
            />

            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <Field name={registrationFields.FIRST_NAME}
                           type="text"
                           label={gettext("Your firstname")}
                           component={TextField}
                           placeholder={gettext("First name")}
                           validate={[validators.required]}
                    />
                </div>

                <div className="col-xs-12 col-sm-6">
                    <Field name={registrationFields.LAST_NAME}
                           type="text"
                           label={gettext("Your lastname")}
                           component={TextField}
                           placeholder={gettext("Lastname")}
                           validate={[validators.required]}
                    />
                </div>
            </div>

            <Field name={registrationFields.EMAIL}
                   type="email"
                   component={TextField}
                   label={gettext("E-mail")}
                   placeholder={gettext("E-mail address")}
                   validate={[validators.required, validators.email]}
            />

            <div className="row">
                <div className="col-xs-12 col-sm-6 mb-4">
                    <Field name={registrationFields.PASSWORD}
                           type="password"
                           component={TextField}
                           label={gettext("Password")}
                           placeholder="********"
                           validate={[validators.required, validators.minLength6, validators.hasNumber,validators.hasLowerCase, validators.hasUpperCase]}
                    />
                </div>

                <div className="col-xs-12 col-sm-6 mb-4">
                    <Field name={registrationFields.PASSWORD_CONFIRM}
                           type="password"
                           component={TextField}
                           label={gettext("Password (confirm)")}
                           placeholder="********"
                           validate={[validators.required, validators.minValue6, validators.hasNumber, validators.hasLowerCase, validators.hasUpperCase]}
                    />
                </div>
            </div>

            <div className="row justify-content-between mb-5">
                <div className="col-12 col-sm-8 col-md-8 col-lg-8 align-self-center">
                    <Field name={registrationFields.TERMS}
                           type="checkbox"
                           component={CheckBox}
                           label={<div>{gettext('I accept the')}    <span className="g-text-underline g-color-primary">
                                <Link to="../terms-and-conditions" target="_blank">
                                     {gettext("general sales conditions")}.
                                </Link>
                            </span></div>}
                           validate={[validators.required]}
                    />
                </div>
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 align-self-center text-right">
                    <button type="submit"
                            disabled={submitting}
                            className="btn btn-block u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-25 g-py-15"
                    >
                        {gettext('Register')}
                    </button>
                </div>
            </div>
        </form>
    )
};

export default reduxForm({
    form: 'registration',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
})(RegistrationForm)



