import React from 'react';
import {connect} from 'react-redux';
import {getLocale} from '../selectors';
import {setLocale, sendLocale} from '../actions';
import {gettext} from '../../../translations';
import {ComboMenu} from '../../core';
import {isAuthenticated} from '../../session';

class LanguagePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {open: false}

        this.onSetLocale = this.onSetLocale.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
    }

    onSetLocale(locale) {
        const {setLocale, sendLocale, onChange, authenticated} = this.props;

        setLocale(locale).then(() => {

            if(authenticated) {
                sendLocale(locale);
            }

            setTimeout(() => onChange(locale), 50) ;
        });
    }

    renderTitle() {
        const {locale} = this.props;
        return (
            <span>
                {locale.toUpperCase()} <i className="fa fa-angle-down g-ml-5 g-hidden-sm-down"></i>
            </span>
        )
    }

    render() {
        return (
            <ComboMenu renderTitle={this.renderTitle}>
                <h5 className="color-highlight">{gettext('Language')}</h5>

                <ul className="list-inline mb-0 g-min-width-150 g-color-text ">
                    <li className="list-inline-item row g-ma-0 g-pa-0 clickable"
                        onClick={() => this.onSetLocale('en')}>
                        > English (EN)
                    </li>
                    <li className="list-inline-item row g-ma-0 g-pa-0 clickable"
                        onClick={() => this.onSetLocale('fr')}>
                        > Français (FR)
                    </li>
                    <li className="list-inline-item row g-ma-0 g-pa-0 clickable"
                        onClick={() => this.onSetLocale('de')}>
                        > German (DE)
                    </li>
                    <li className="list-inline-item row g-ma-0 g-pa-0 clickable"
                        onClick={() => this.onSetLocale('nl')}>
                        > Nederlands (NL)
                    </li>
                </ul>
            </ComboMenu>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: getLocale(state),
        authenticated: isAuthenticated(state),
    };
};


export default connect(mapStateToProps, {setLocale, sendLocale})(LanguagePicker);