import React, {Component} from 'react';
import {connect} from 'react-redux';
import {download} from '../../../helpers/functions';
import {getUserId} from '../../session';
import {getCriteria} from '../../listvehicles-filter/selectors';
import {gettext} from '../../../translations';
import {map} from 'lodash';
import moment from 'moment';

class ExcelDownloadByFilter extends Component {
    constructor(props){
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.stopPropagation();

        const {userId, filterCriteria} = this.props;

        let filters = [];

        if(filterCriteria && filterCriteria.states && filterCriteria.states.length > 0){
            filters.push(`state=${map(filterCriteria.states).join(',')}`);
        }

        if(filterCriteria && filterCriteria.expectationDt_min) {
            filters.push(`start=${moment(filterCriteria.expectationDt_min).format("YYYY-MM-DD")}`);
        }

        if(filterCriteria && filterCriteria.expectationDt_max){
            filters.push(`stop=${moment(filterCriteria.expectationDt_max).format("YYYY-MM-DD")}`);
        }

        const filter = (filters.length > 0) ? `?${map(filters).join('&')}` : "";

        download(`sales/portal/overviewListExcel2/${userId}${filter}`);
    }


    render() {
        return (
            <button onClick={this.handleClick}
                    className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-ml-10">
                <i className="fa fa-file-excel-o g-font-size-12 g-valign-middle g-mr-10"></i> {gettext('Download')}
            </button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userId: getUserId(state),
        filterCriteria: getCriteria(state, -1),
    }
};

export default connect(mapStateToProps)(ExcelDownloadByFilter)
