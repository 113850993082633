import React from 'react';
import {gettext} from '../../../translations';
import {withConfig} from '../../../config';

const NoLists = ({config}) =>
    <div className="g-pa-0 g-ma-0 justify-content-center g-min-height-250">

        <div className="row justify-content-center g-mt-50">
            <i className="fa fa-list g-font-size-180 g-color-primary"/>
        </div>

        <div className="row justify-content-center g-mt-20">
            {/*<h5 className="mb-2 color-highlight g-font-weight-800 g-pt-5">{gettext('Nothing yet...')}</h5>*/}
            <h5 className="mb-2 color-highlight g-font-weight-800 g-pt-5">{gettext('No lists currently available.')}</h5>
        </div>

        {/*<div className="row justify-content-center">*/}
            {/*{gettext('Currently no lists available for you')}*/}
        {/*</div>*/}

        {config.dealerships &&
        <div className="row justify-content-center g-pl-10 g-pr-10 g-pb-20 g-pt-5 text-center">
            {gettext('You can increase the number of cars offered by contacting multiple dealers.')} <br />
            {gettext('To do this, contact the desired dealers so that they can activate you within their network.')} <br />
            {gettext('After activation by the dealer, the cars offered will be available for you. ')}

        </div>}

    </div>

export default withConfig(NoLists);
