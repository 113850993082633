import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ListVehicleState} from '../../../listvehiclestate/index';
import {toggleVehicleState} from '../actions';
import {gettext} from '../../../../translations/index';
import {PrintButtons} from '../../../print';

class ListVehicleStateContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {closing: false};

        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        if (!this.state.closing) {
            // const {listVehicleId, listCleanupByListId, listVehiclesAndStateCleanUp} = this.props;
            // listCleanupByListId(getListIdFromListVehicleId(listVehicleId));
            // listVehiclesAndStateCleanUp();
        }
    }

    handleBack() {
        this.setState({closing: true}, () => this.props.toggleVehicleState());
    }


    render() {
        const {id} = this.props;

        return (
            <div className="bg-normal g-ma-0 g-mt-70 g-mb-200 g-pl-5 g-pr-5">
                <div
                    className="sticky sticky-md-down bg-white row g-pa-5 g-ma-0 justify-content-between align-items-center g-brd-bottom g-brd-10 brd-bg g-brd-primary  "
                    style={{zIndex: 50}}>

                    <div>
                        <button onClick={this.handleBack}
                                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-pt-5">
                            <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"/>
                            <span className="g-hidden-xs-down">
                            {gettext('Back to offer')}
                        </span>
                            <span className="g-hidden-sm-up">
                            {gettext('Back')}
                        </span>
                        </button>
                    </div>


                    <PrintButtons listVehicleId={id}/>
                </div>

                <ListVehicleState listVehicleId={id} stickyTop={124}/>
            </div>
        )
    }
}

export default connect(null, {toggleVehicleState})(ListVehicleStateContainer)

