import _ from 'lodash';

export const mapList = (data) => {
    return {
        id: _.get(data, 'id'),
        name: _.get(data, 'name'),
        start: _.get(data, 'start'),
        stop: _.get(data, 'stop'),
        first_auction_vehicle_stop_time: _.get(data, 'first_auction_vehicle_stop_time'),
        state: _.get(data, 'state'),
        type: _.get(data, 'publicationType'),
        vehicle_count: _.get(data, 'vehicle_count'),
        list_info: mapListInfo(_.get(data, 'make_models')),
        counters: _.filter(data.counters, (counter) => counter.count > 0)
    };
};

const mapListInfo = (data) => {
    let values = [];

    let temp = data;

    /* Make counter*/
    values =
        _(temp)
            .groupBy('make')
            .map((makeModels, make) => ({make: make, count: _.sumBy(makeModels, 'count')}))
            .value()
            .reduce((resultObject, value) => {
                resultObject[value.make] = value.count;
                return resultObject;
            }, {});

    /* Models counter if makes counter < 3 */
    if (Object.keys(values).length < 3) {
        values =
            _(temp)
                .groupBy('model')
                .map((makeModels, make) => ({make: make, count: _.sumBy(makeModels, 'count')}))
                .value()
                .reduce((resultObject, value) => {
                    resultObject[value.make] = value.count;
                    return resultObject;
                }, {})
    }

    values = _.orderBy(Object.keys(values).map(x => ({
        description: x,
        value: values[x]
    })), ['value', 'description'], ['desc', 'desc']);

    let result = _.take(values, 3);
    let other = _.sumBy(_.drop(values, 3), 'value');

    if (other > 0) {
        result.push({description: _.upperCase('Other'), value: other});
    }

    return result;
};