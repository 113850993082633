import React from 'react';
import {FormFields} from '../../../../core';
import {Field, reduxForm} from 'redux-form'
import {gettext} from '../../../../../translations';
import {compose} from "redux";
import {withConfig} from "../../../../../config";
import {includes} from 'lodash';

const {CheckBox} = FormFields;

const personalInfoFields = {
    SMS_ACTIVE: 'sms_active',
    CAR_BY_CAR_NOTIFICATION: 'car_by_car_notification',
    CAR_BY_CAR_MAIL: 'car_by_car_mail',
    DAILY_DIGEST_MAIL: 'daily_digest_mail',
    OFFERS_MAIL: 'offers_mail',
    WON_MAIL: 'won_mail',
};


const mapDataToFields = (data) => {
    return ({
        [personalInfoFields.SMS_ACTIVE]: data.sms_active,
        [personalInfoFields.CAR_BY_CAR_NOTIFICATION]: data.car_by_car_notification,
        [personalInfoFields.CAR_BY_CAR_MAIL]: data.car_by_car_mail,
        [personalInfoFields.DAILY_DIGEST_MAIL]: data.daily_digest_mail,
        [personalInfoFields.OFFERS_MAIL]: data.offers_mail,
        [personalInfoFields.WON_MAIL]: data.won_mail,
    })
};


const SettingsForm = ({initialValues, handleSubmit, config}) =>
    <form initialvalues={mapDataToFields(initialValues)}
          onSubmit={handleSubmit}>

        {/*<Field*/}
            {/*name={personalInfoFields.SMS_ACTIVE}*/}
            {/*component={CheckBox}*/}
            {/*label={gettext("New list / by SMS notifications")}*/}
        {/*/>*/}

        <Field
            name={personalInfoFields.CAR_BY_CAR_NOTIFICATION}
            component={CheckBox}
            label={gettext("Car by Car / by smartphone notification")}
        />

        <Field
            name={personalInfoFields.CAR_BY_CAR_MAIL}
            component={CheckBox}
            label={gettext("Car by Car / by e-mail")}
        />

        <Field
            name={personalInfoFields.DAILY_DIGEST_MAIL}
            component={CheckBox}
            label={gettext("Daily digest / by e-mail")}
        />

        {!includes(config.excluded_settings, personalInfoFields.OFFERS_MAIL) &&
        <Field
            name={personalInfoFields.OFFERS_MAIL}
            component={CheckBox}
            label={gettext("Bidding confirmation / by e-mail")}
        />}

        {!includes(config.excluded_settings, personalInfoFields.WON_MAIL) &&
        <Field
            name={personalInfoFields.WON_MAIL}
            component={CheckBox}
            label={gettext("Highest bid / by e-mail")}
        />}
    </form>;


export default compose(
    withConfig,
    reduxForm({
        form: 'Registration-Settings',
        destroyOnUnmount: true,
        forceUnregisterOnUnmount: true,
    })
)(SettingsForm);