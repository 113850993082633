import React, {Component} from 'react';

export class NotSupported extends Component {
    render() {
        return (
            <div className="g-flex-centered" style={{display: 'flex', height: '100vh', flexDirection: 'column'}}>
                {/*{this.props.name}*/}
                <div style={{width: 790}} className="bg-white u-shadow-v2 g-brd-around g-brd-gray-light-v4 g-line-height-2  g-brd-2 g-brd-highlight-top g-pa-40 g-mb-30">

                    <h3 className="h2 g-font-weight-300 g-mb-20">Helaas, je browser wordt niet ondersteund</h3>
                    <p>Het spijt ons, maar je surft met een verouderde browser of met een browser die niet wordt ondersteund.
                        Enkel de browsers Chrome, Firefox, Edge en Safari worden ondersteund <strong> Gebruik steeds de meest recente versie. <strong></strong></strong></p>

                    <div className="browser_options">

                        <a className="browser_button button--chrome" href="https://www.google.com/chrome/browser/" target="_blank" rel="noopener noreferrer">Chrome</a>


                        <a className="browser_button button--firefox" href="http://www.getfirefox.com" target="_blank" rel="noopener noreferrer">Firefox</a>


                        <a className="browser_button button--edge" href="https://www.microsoft.com/nl-be/windows/microsoft-edge" target="_blank" rel="noopener noreferrer">Microsoft Edge</a>


                        <a className="browser_button button--safari" href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer">Safari</a>

                    </div>


                </div>
            </div>
        )
    }
}

export default NotSupported;

