import React, {Component} from 'react';
import Header from './Header';
import Footer from './Footer';
import ErrorBoundary from './ErrorBoundary';
import {NewVersion} from "../../../../version";

export class Template extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };

        this.handleResize = this.handleResize.bind(this);
        window.addEventListener('resize', this.handleResize, true);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, true);
    }

    handleResize() {
        this.setState({
            width: window.innerWidth,
        })
    }

    render() {
        const {children, allowFluid, headerVisible, footerVisible} = this.props;

        let containerStyle;
        if (this.state.width <= 768) {
            containerStyle = 'content'
        } else if (this.state.width <= 1366) {
            containerStyle = 'content container-fluid';
        }
        else if (this.state.width > 1366 && !allowFluid) {
            containerStyle = 'content container-semiboxed'
        }
        else if (this.state.width > 1366 && this.state.width < 1600 && allowFluid) {
            containerStyle = 'content container-semiboxed'
        }
        else if (this.state.width > 1600 && allowFluid) {
            containerStyle = 'content container-fluid';
        }

        return (
            <ErrorBoundary>
                <div style={{display: 'flex', height: '100vh', flexDirection: 'column'}}>

                    {headerVisible &&
                    <Header containerStyle={containerStyle}/>}

                    {/*<div className="g-mt-70">*/}
                    {/*    <NewVersion/>*/}
                    {/*</div>*/}

                    <div className={containerStyle} style={{marginBottom: 10}}>
                        {children}
                    </div>

                    {footerVisible &&
                    <Footer/>}
                </div>
            </ErrorBoundary>
        )
    }
}

Template.defaultProps = {
    allowFluid: false,
    footerVisible: true,
    headerVisible: true,
};

export default Template;
