import {auth_types} from './types';
import {updateSession, clearSession} from '../session';


export const authenticationMiddleware = ({dispatch}) => next => action => {


    if (action.payload && action.payload.message === 'UNAUTHORIZED') {
        dispatch(clearSession());
        return next(action);
    }


    if (action.type === auth_types.FETCH_TOKEN_SUCCESS ||
        action.type === auth_types.FETCH_TOKEN_BY_CODE_SUCCESS ||
        action.type === auth_types.FETCH_REGISTRATION_SUCCESS ||
        action.type === auth_types.FETCH_PROFILE_SUCCESS) {

        dispatch(updateSession(action.payload));
    }

    if (action.type === auth_types.AUTH_BY_TOKEN) {
        dispatch(updateSession(action.payload));
    }

    if (action.type === auth_types.AUTH_REQUEST ||
        action.type === auth_types.AUTH_FAILURE) {
        dispatch(clearSession());
    }

    if (action.type === auth_types.AUTH_SUCCESS) {
        dispatch(updateSession({authenticated: true, last_login: Date.now()}));
    }

    return next(action);
};
