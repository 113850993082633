import React from 'react';
import {connect} from 'react-redux';
import * as selectors from '../selectors';
import style from '../../../styles';
import {toggleConfirm} from '../actions';

const ConfirmButtonMobile = ({count, toggleConfirm}) => (
    <div>
        <i className="fa fa-shopping-basket g-font-size-25 clickable" onClick={() => toggleConfirm(true)}
           style={{color: (count > 0) ? 'red' : style.defaultColor}}/>
    </div>
);

const mapStateToProps = (state, ownProps) => {
    return {
        count: selectors.getBiddingsToConfirmCount(state, ownProps.listId),
    }
};

export default connect(mapStateToProps, {toggleConfirm})(ConfirmButtonMobile);







