import {gettext} from './translations';

export const ListTypes = {
    TENDER: 'TENDER',
    FIXED: 'FIXED',
    LIVE_AUCTION: 'AUCTION',
};

export const ListStates = {
    INITIAL: 'INITIAL',
    IN_PREPARATION: 'IN_PREPARATION',
    PREPARED: 'PREPARED',
    OPEN: 'OPEN',
    FINISHED: 'FINISHED',
    CLOSED: 'CLOSED'
};

export const ListVehicleStates = {
    INITIAL: 'INITIAL',
    LOCKED: 'LOCKED',
    SOLD: 'SOLD',
    ACCEPTED: 'ACCEPTED',
    PAYED: 'PAYED',
    DELIVERED: 'DELIVERED',
    REMOVED: 'REMOVED',
};

export const BasketStates = {
    INITIAL: 'INITIAL',
    TRANSMITTED: 'TRANSMITTED',
    BOUGHT: 'BOUGHT',
};


export const BasketStatesGroups = {
    ALL: 'ALL',
    OFFERED: 'OFFERED',
    REFUSED: 'REFUSED',
    ACCEPTED: 'ACCEPTED',
    ARCHIVE: 'ARCHIVE',
    LATEST: 'LATEST',
};

export const BasketStatesGroupsTranslate = {
    OFFERED: gettext('Offered', true),
    REFUSED: gettext('Refused', true),
    ACCEPTED: gettext('Accepted', true),
    ARCHIVE: gettext('Archive', true),
    LATEST: gettext('Last 5', true),
};

export const BasketStatesGroupMapper = {
    INITIAL: BasketStatesGroups.OFFERED,
    TRANSMITTED: BasketStatesGroups.OFFERED,
    RETURNING: BasketStatesGroups.ARCHIVE,
    RETURNED: BasketStatesGroups.ARCHIVE,
    REFUSED: BasketStatesGroups.REFUSED,
    REFUSED_SENT: BasketStatesGroups.REFUSED,
    BOUGHT: BasketStatesGroups.ACCEPTED,
    ACCEPTED: BasketStatesGroups.ACCEPTED,
    ORDERING: BasketStatesGroups.ARCHIVE,
    ORDERED: BasketStatesGroups.ARCHIVE,
    PAYED: BasketStatesGroups.ARCHIVE,
    DELIVERED: BasketStatesGroups.ARCHIVE
};

export const BasketStatesTranslate = {
    INITIAL: gettext('In Basket', true),
    TRANSMITTED: gettext('Offered', true),
    RETURNING: gettext('Returning', true),
    RETURNED: gettext('Returned', true),
    REFUSED: gettext('Not retained', true),
    REFUSED_SENT: gettext('Not retained', true),
    BOUGHT: gettext('Bought', true),
    ACCEPTED: gettext('Accepted', true),
    ORDERING: gettext('Ordering', true),
    ORDERED: gettext('Ordered', true),
    PAYED: gettext('Payed', true),
    DELIVERED: gettext('Delivered', true),
};



