import React from 'react';
import {gettext} from '../../../translations';
import {keys, filter} from 'lodash';
import {settingsFields} from "./SettingsForm";

class Confirm extends React.Component {

    getItemText(item) {
        switch (item) {
            case settingsFields.WON_MAIL:
                return gettext("highest bid and delivery information");
            case settingsFields.CAR_BY_CAR_MAIL:
                return gettext('requests car by car ');
            case settingsFields.OFFERS_MAIL:
                return gettext('emails after offers');
            default:
                return '';

        }
    }

    render() {

        const {data} = this.props;

        const unsubscribe_all = data[settingsFields.NO_MAIL] === true;
        const unsubcribes = filter(keys(data), (key) => data[key] === false && key !== settingsFields.NO_MAIL);
        const subcribes = filter(keys(data), (key) => data[key] === true && key !== settingsFields.NO_MAIL);

        return (
            <div className="d-flex justify-content-end">
                <div style={{maxWidth: 450}}>
                    <h4 className='h4 g-color-primary'>{gettext("Are you sure?")}</h4>

                    {unsubscribe_all &&
                    <div>
                        <span>{gettext('Are you sure you want to unsubscribe entirely? ')}</span>
                    </div>}

                    {unsubcribes.length > 0 && !unsubscribe_all &&
                    <div>
                    <h5>
                        {gettext("You want to unsubscribe for:")}
                    </h5>
                        <ul>
                            {unsubcribes.map((item, key) =>
                                <li key={key}>{this.getItemText(item)}</li>
                            )}
                        </ul>
                    </div>}


                    {subcribes.length > 0 &&
                    <div>
                    <h5>
                       {gettext('You will still be receiving the following communication:')}
                    </h5>
                        <ul>
                            {subcribes.map((item, key) =>
                                <li key={key}>{this.getItemText(item)}</li>
                            )}
                        </ul>
                    </div>}
                </div>
            </div>
        )
    }
}

export default Confirm

