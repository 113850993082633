import {RSAA} from 'redux-api-middleware';
import {normalize} from 'normalizr';
import {listVehicleTypes} from './types';
import {mapKeys, map} from 'lodash';
import {listVehiclesInterestFetch} from '../listvehicles-no-interest/actions';
import {listVehiclesFavoriteFetch} from '../listvehicles-favorite/actions';
import {listScheme} from '../../helpers/normalizer';
import {mapListDetail, mapListBasket} from './mappers';
import {entityActions} from '../../entities/actions';
import {mapSortForRequest} from '../listvehicles-sort/mappers';
import {getOffset, getLoading, getMore} from './selectors';
import {getCriteria} from '../listvehicles-filter/selectors';
import {mapFilterForRequest} from '../listvehicles-filter/mappers';
import {getActiveSorter} from '../listvehicles-sort/selectors';
import {config} from '../../config'
import {fetchList} from "../lists/actions";
import {ListTypes} from "../../constants";
const LIMIT = 50;

const shouldFetchListVehicles = (state, listId, url) => {
    const data = state.listvehicles[listId];

    if (!data) {
        return true;
    }
    else if (data) {
        //if (data.requests && data.requests[url]) {
        //if (data.request === url) {
          // return false;
    }
    return true;
};

const getListType = async (dispatch, getState, listId) => {
        const listExistsInState = getState().entities.list[listId]
        if (!listExistsInState){
            await dispatch(fetchList(listId))
        }
        return getState().entities.list[listId].type
}

const fetchListVehicles = (listId, nextBatch = false) => {
    return async (dispatch, getState) => {
        const listType = await getListType(dispatch, getState, listId)
        const limit = LIMIT;
        const offset = (nextBatch) ? getOffset(getState(), listId) + limit : 0;
        const filter = getCriteria(getState(), listId);
        const sorter = getActiveSorter(getState(), listId);

        let url = `sales/api/v2/lists/${listId}/?limit=${limit}&offset=${offset}&filter=${mapFilterForRequest(filter)}&sort=${mapSortForRequest(sorter)}&unanswered=${filter.interestWithNoBid}&favourites=${filter.favorites}`;

        // Fetch all vehicles for live auction list
        const isLiveAuction = listType === ListTypes.LIVE_AUCTION
        if (isLiveAuction){
            url = `sales/api/v2/lists/${listId}/?filter=${mapFilterForRequest(filter)}&sort=${mapSortForRequest(sorter)}&unanswered=${filter.interestWithNoBid}&favourites=${filter.favorites}`;
        }

        if (shouldFetchListVehicles(getState(), listId, url)) {

            return dispatch({
                [RSAA]: {
                    endpoint: url,
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                    types: [
                        {
                            type: listVehicleTypes.FETCH_REQUEST,
                            payload: {
                                listId,
                                offset
                            }
                        },
                        {
                            type: listVehicleTypes.FETCH_SUCCESS,
                            payload: (action, state, res) => onListVehiclesFetchSuccess(dispatch, res, listId, offset, limit),
                        },
                        {
                            type: listVehicleTypes.FETCH_FAILURE,
                            payload: (action, state, res) => onFailure(dispatch, res, listId),
                        }
                    ]
                }
            });
        }
    };
};

export const listVehiclesBasketFetch = (listId) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/basket/?listId=${listId}`,
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: listVehicleTypes.FETCH_BASKET_REQUEST,
                        payload: {
                            listId,
                        }
                    },
                    {
                        type: listVehicleTypes.FETCH_BASKET_SUCCESS,
                        payload: (action, state, res) => onlistVehiclesBasketFetchSuccess(dispatch, res, listId),
                    },
                    {
                        type: listVehicleTypes.FETCH_BASKET_FAILURE,
                        payload: (action, state, res) => onFailure(dispatch, res, listId),

                    }
                ]
            }
        });
    };
};

const onListVehiclesFetchSuccess = (dispatch, res, listId, offset, limit) => {
    let totalCount = res.headers.get('RecordCount');

    return res.json().then(data => {

        data = normalize([mapListDetail(data)], [listScheme]);

        dispatch(entityActions.updateEntities({
            list: data.entities.list,
            vehicle: data.entities.vehicles,
        }));

        return {
            listId,
            limit,
            offset,
            totalCount,
            ids: (data.entities.vehicles) ? Object.keys(data.entities.vehicles) : [],
            receivedAt: Date.now(),
        };
    });
};

const onFailure = (dispatch, res, listId) => {
    return {
        listId,
        message: res.statusText,
    };
};

const onlistVehiclesBasketFetchSuccess = (dispatch, res, listId) => {
    return res.json().then(data => {

        let result = mapKeys(map(data, mapListBasket), function (value) {
            return value.list_vehicle_id;
        });

        dispatch(entityActions.updateEntities({
            vehicle: result,
        }));

        return {
            listId,
        }
    });
};

export const listVehiclesFetchAndBasket = (listId) => {
    const fetch_interest = config.vehicle_interest;
    const fetch_favorites = config.vehicle_favorite;

    return (dispatch) => {
        dispatch(fetchListVehicles(listId));
        dispatch(listVehiclesBasketFetch(listId));

        if(fetch_interest) {
            dispatch(listVehiclesInterestFetch(listId));
        }

        if(fetch_favorites) {
            dispatch(listVehiclesFavoriteFetch(listId));
        }

    }
};

const shouldFetchMoreListVehicles = (state, listId) => {
    const loading = getLoading(state, listId);
    const more = getMore(state, listId);

    if (loading || !more) {
        return false;
    }
    return true;
};


export const listVehiclesFetchMore = (listId) => {
    return (dispatch, getState) => {
        if (shouldFetchMoreListVehicles(getState(), listId)) {
            return dispatch(fetchListVehicles(listId, true));
        }
    }
};


export const listVehiclesClosed = () => {
    return {
        type: listVehicleTypes.CLOSED,
    }
};

export const updateVehicles = (data) => ({
    type: listVehicleTypes.UPDATE,
    data
});

export const selectListVehicle = (listVehicleId) => ({
    type: listVehicleTypes.SELECT_VEHICLE,
    payload: {
        listVehicleId
    }
});

export const listVehiclesCleanUp = (invalidListIds) => {
    return (dispatch) => {
        return dispatch({
            type: listVehicleTypes.CLEANUP,
            payload: {
                invalidListIds,
            }
        });
    }
};

