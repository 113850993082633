import React from 'react';


export default class ListInfo extends React.Component {
    render(){
        const {description, value} = this.props;
        return ( <div className="row">
            <div className="col g-min-width-120 g-font-size-12--md">{description}</div>
            <div className="col"><span className="u-label bg-highlight g-rounded-3 g-mb-5 text-center" style={{width: 40}}>{value}</span></div>
        </div>);
    }
}
