import React from 'react';
import {connect} from 'react-redux';
import {activate} from '../actions';
import {registration_wizard, login} from '../../../_web/navigation/routes';
import * as selectors from '../selectors';
import {loginByToken} from '../../../components/authentication';
import {LoadingIndicator, InfoContainer, CircleIcon} from '../../core';
import {gettext} from '../../../translations';

class Activation extends React.Component {

    componentDidMount() {
        const {activate, registrationId, uniqueId} = this.props;
        activate(registrationId, uniqueId);
    }

    render() {
        const {loading, token, activated, loginByToken} = this.props;

        if (activated && token === null) {
            return (
                <div className="g-mt-70">
                    <InfoContainer icon="fa fa-check"
                                   text={<h4>{gettext('Your e-mail address is allready confirmed!')}</h4>}
                                   container={true}/>

                    <p className="text-center">{gettext('You can immediately complete your account and start.')}</p>

                    <div className="text-center g-pt-10 g-pb-20 d-flex justify-content-center">
                        <button   onClick={() => login()}
                                  className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                            {gettext('Sign up')}
                        </button>
                    </div>
                </div>
            )
        }

         if (activated && token !== null) {
            return (
                <div className="g-bg-white d-flex flex-column justify-content-center g-pt-20 g-pb-20">
                    <InfoContainer icon="fa fa-check"
                                   text={<h4>{gettext('Your e-mail address is confirmed.')}</h4>}
                                   container={true}/>

                    <p className="text-center">{gettext('You can now complete the registration.')}</p>

                    <div className="text-center g-pt-10 g-pb-20 d-flex justify-content-center">
                        <button   onClick={() => loginByToken(token).then(() => registration_wizard())}
                                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                           {gettext('Complete registration')}
                        </button>
                    </div>
                </div>
            )
        }


        return (
            <div className="row g-mt-70 justify-content-center">
                <CircleIcon icon="fa fa-info" size={200}/>

                <div className="row justify-content-center g-width-100vw text-center g-mt-40">
                    <LoadingIndicator loading={loading}/>
                </div>

                <div className="g-width-100vw g-mt-40 text-center">{gettext('Activating your account')}</div>
                <div className="g-width-100vw text-center">{gettext('Please wait') + '...'}</div>
            </div>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        activated: selectors.isActivated(state),
        token: selectors.getToken(state),
    };
};

export default connect(mapStateToProps, {activate, loginByToken})(Activation);
