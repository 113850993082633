import {RSAA} from 'redux-api-middleware';
import {listVehicleBidTypes} from './types'
import _, {get} from 'lodash';
import {entityActions} from '../../entities/actions';
import {combineIds} from '../../helpers/functions';

export const listVehicleBid = (listId, vehicleId, bid, comment, quality = 0, maximumPrice = 0) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/basket/',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    list_id: listId,
                    vehicle_id: vehicleId,
                    price: bid,
                    comment: comment,
                    quality: quality,
                    maximum_price: maximumPrice
                }),
                types: [
                    {
                        type: listVehicleBidTypes.POST_REQUEST,
                    },
                    {
                        type: listVehicleBidTypes.POST_SUCCESS,
                        payload: (action, state, res) => onSuccess(dispatch, res, listId, vehicleId, comment),
                    },
                    {
                        type: listVehicleBidTypes.POST_FAILURE,
                    }
                ]
            }
        });
    }
};

export const listVehicleBidDelete = (listId, vehicleId) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/basket/',
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    list_id: listId,
                    vehicle_id: vehicleId,
                }),
                types: [
                    {
                        type: listVehicleBidTypes.POST_REQUEST,
                    },
                    {
                        type: listVehicleBidTypes.POST_SUCCESS,
                        payload: (action, state, res) => onSuccess(dispatch, res, listId, vehicleId),
                    },
                    {
                        type: listVehicleBidTypes.POST_FAILURE,
                    }
                ]
            }
        });
    }
};

const onSuccess = (dispatch, res, listId, vehicleId, comment) => {
    return res.json().then(data => {
            let result = {
                vehicle: {
                    [combineIds(listId, vehicleId)]: {
                        price: get(data.result, 'price'),
                        basket_price: get(data.result, 'basketPrice'),
                        basket_state: get(data.result, 'basketState'),
                        basket_maximum_price: get(data.result, 'basketMaximumPrice'),
                        //dealer_state: get(data.result, 'basketState'),
                        comment: comment,
                        last_server_update: Date.now(),
                    }
                }
            };

            if (data.result_data) {
                const id = combineIds(listId, vehicleId);
                result.vehicle[id] = {
                    ...result.vehicle[id],
                    vehicle_state: get(data.result_data, 'state'),
                    vehicle_stop: get(data.result_data, 'stop'),
                    last_server_update: Date.now(),
                    reserve_price_met: _.get(data.result_data, 'reserve_met') || null,
                    reserve_price_almost_met: _.get(data.result_data, 'reserve_almost_met') || null,
                };

                const publicationType = get(data.result_data, 'publicationType');
                if (publicationType && publicationType === "AUCTION") {
                    result.vehicle[id] = {
                        ...result.vehicle[id],
                        live_auction_hidden: false
                    }
                }
            }

            dispatch(entityActions.updateEntities(result));

            return {
                accepted: data.result_code === 'Ok' ? true : false,
                message: data.result_message,
            };
        }
    );
};
