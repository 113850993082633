import React from 'react';
import * as selectors from "../selectors";
import {connect} from "react-redux";

const NewVersion = ({swUpdated, swRegistration}) => {
    if (!swUpdated) {
        return null;
    }

    const handleInstall = () => {
        debugger;

console.log('KLIKKKKKKKK');
        const registrationWaiting = swRegistration.waiting;


        if (registrationWaiting) {
            debugger;
            console.log('KLIKKKKKKKK 11111 ' + registrationWaiting );
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
            registrationWaiting.postMessage({ type: 'skipWaiting' });

            console.log('KLIKKKKKKKK 22222');

            registrationWaiting.addEventListener('statechange', e => {
                console.log('KLIKKKKKKKK 3333');
                if (e.target.state === 'activated') {
                    console.log('KLIKKKKKKKK 4444');
                    debugger;
                    window.location.reload();
                }
            });
        }
    };

    return (
        <div>
            <div>
                New version available!! Please install to get the latest features!!
            </div>

            <div onClick={handleInstall} style={{backgroundColor: 'red', width: 250, height: 50}}>Install</div>

        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        swUpdated: selectors.isServiceWorkerUpdated(state),
        swRegistration: selectors.serviceWorkerRegistration(state),
    }
};

export default connect(mapStateToProps)(NewVersion);

