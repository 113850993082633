import React from 'react';
import _ from 'lodash';

const FilterSummaryTag = ({text, item, onClick}) =>
    <div className="filter-summary-tag">
        <span className="label">{_.capitalize(text)}</span>
        <span className="close" onClick={() => onClick()}>
            <i className="coll fa fa-close g-font-size-11"></i>
        </span>
    </div>


export default FilterSummaryTag;

