import React from 'react';
import _ from 'lodash';
import {gettext} from '../../../../../../translations/index';

const Damages = ({vehicle, onPictureClick}) => {

    const damages = _.groupBy(_.orderBy(vehicle.damages, 'location'), 'location');

    if (_.keys(damages).length === 0) return null;

    return (
        <div id="damages" className="g-bg-white g-pa-10 g-mb-10">
            <h5 className="h5 color-highlight g-mb- g-pb-5">
                {gettext('Damages')}
            </h5>

            <div className="row g-pa-0 g-ma-0 g-hidden-xs-down" >
                <div className="col-12 g-color-gray-dark-v4">
                    {Object.keys(damages).map((location, i_loc) =>
                        <div className="row" key={`damage-location-${i_loc}`}>
                            <div className="col-3 g-pa-2">
                                {location}
                            </div>
                            <div
                                className="col-9 g-brd-left g-brd-gray-light-v3 g-mb-20 g-pl-15">
                                {damages[location].map((damage, i_damage) =>
                                    <div className="g-pl-15" key={`damage-${i_loc}-${i_damage}`}>
                                        <div className="row g-pa-0 g-ma-0">
                                            {_.capitalize(damage.type)}
                                        </div>
                                        {damage.note &&
                                        <div className="font-weight-bold g-font-size-14">
                                            {damage.note}
                                        </div>}
                                        <div className="row g-pa-0 g-ma-0" >
                                            {damage.pictures && damage.pictures.length >= 1 && damage.pictures.map((picture, i_pic) =>
                                                <img className="g-pr-5 g-pt-5 damage-picture-thumbnail"
                                                     key={`damage-picture${i_loc}-${i_damage}-${i_pic}`}
                                                     src={picture.src}
                                                     alt={picture.description}
                                                     onClick={() => onPictureClick(picture)}/>

                                            )}

                                            {damage.pictures && damage.pictures.length <= 0 &&
                                            <img className="g-pr-5 g-pt-5 damage-picture-thumbnail"
                                                 key={`damage-picture${i_loc}-${i_damage}-0`}
                                                 src={require("../../../../../../img/nopic.jpeg")}
                                                 alt={damage.location}/>
                                            }
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row g-pa-0 g-ma-0 g-hidden-sm-up" >
                <div className="col-12 g-color-gray-dark-v4">
                    {Object.keys(damages).map((location, i_loc) =>
                        <div className="row" key={`damage-location-${i_loc}`}>
                            <div
                                className="col-12 g-pa-0 g-ma-0 g-mb-20">
                                {damages[location].map((damage, i_damage) =>
                                    <div key={`damage-${i_loc}-${i_damage}`}>
                                        <div className="row g-pa-0 g-ma-0">
                                            {location} - {_.capitalize(damage.type)}
                                        </div>
                                        <div className="row g-pa-0 g-ma-0" >
                                            {damage.pictures && damage.pictures.length >= 1 && damage.pictures.map((picture, i_pic) =>
                                                <img className="g-pr-5 g-pt-5 damage-picture-thumbnail-mobile"
                                                     key={`damage-picture${i_loc}-${i_damage}-${i_pic}`}
                                                     src={picture.src}
                                                     alt={picture.description}
                                                     onClick={() => onPictureClick(picture)}/>

                                            )}

                                            {damage.pictures && damage.pictures.length <= 0 &&
                                            <img className="g-pr-5 g-pt-5 damage-picture-thumbnail-mobile"
                                                 key={`damage-picture${i_loc}-${i_damage}-0`}
                                                 src={require("../../../../../../img/nopic.jpeg")}
                                                 alt={damage.location}/>
                                            }
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default Damages;
