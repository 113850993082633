import React from 'react';
import {keys} from 'lodash';
import {gettext} from '../../../translations';
import { formatPriceDecimal} from '../../../helpers/functions';

const CatalogPrices = ({catalog}) => {

    if(keys(catalog).length === 0) return null;

    return (
        <div className="row g-ma-0 g-pt-10" style={{justifyContent: 'flex-end'}}>
            <span className="g-mr-5 g-mr-20--sm">{`${gettext('catalogus')}  ${formatPriceDecimal(catalog.catalog)}`} </span>
            <span>{`${gettext('catalog+')}  ${formatPriceDecimal(catalog.catalog_options)}`} </span>
        </div>
    )
};


export default CatalogPrices;




