import React from 'react';
import {Template} from '../components/core/index';
import Activation from '../components/registration-activation/_web/Activation';

const Activate = ({match}) =>
    <Template>
        <div className="g-bg-white g-mt-20">
            <section className="container g-py-100 ">
                <Activation registrationId={match.params.registrationId}
                            uniqueId={match.params.uniqueId}
                />
            </section>

        </div>
    </Template>;

export default Activate ;



