import {connect} from 'react-redux';
import React, {Component} from 'react';
import {listVehiclesFetchAndBasket, listVehiclesFetchMore, listVehiclesClosed} from '../actions';
import * as selectors from '../selectors';
import * as listSelectors from '../../lists/selectors'
import ListVehicleItem from './ListVehicleItem';
import InfiniteScroll from 'react-infinite-scroller';
import {LoadingContainer, LoadingIndicator, WheelNotOutSide} from '../../core'
import {getCriteria} from '../../listvehicles-filter/selectors';
import {getActiveSorter} from '../../listvehicles-sort/selectors';
import {ListTypes} from "../../../constants";
import {ServerDate} from "../../../scripts/ServerDate";

class ListVehicles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedId: 0,
            auctionTimeSynced: false,
            syncTries: 0,
            intervalId: undefined,
        };

        this.loadMore = this.loadMore.bind(this);
        this.onListVehicleClick = this.onListVehicleClick.bind(this);
        this.handleActionSync = this.handleActionSync.bind(this);


    }

    fetchListVehicles() {

        const {listId, fetchData} = this.props;

        if (fetchData) {
            this.props.listVehiclesFetchAndBasket(listId);
        }
    }

    onListVehicleClick(listVehicleId, blockNavigation) {
        this.setState({selectedId: listVehicleId});
        this.props.onClick(listVehicleId, blockNavigation);

    }

    componentDidMount() {
        this.fetchListVehicles();

        this.handleActionSync(this.props);
    }

    checkAuctionSync() {
        let synced = ServerDate.isSynced();
        console.log('synced =' + synced);
        this.setState({syncTries: this.state.syncTries += 1});

        if (synced && !this.state.auctionTimeSynced) {
            clearInterval(this.state.intervalId);
            this.setState({auctionTimeSynced: true});
        }

        if (this.state.syncTries > 10) {
            console.log("20 = " + synced);
            clearInterval(this.state.intervalId);
            this.setState({auctionTimeSynced: true, syncTries: 0});
        }
    }


    handleActionSync(props) {
        if (props.listType !== undefined) {
            if (props.listType === ListTypes.LIVE_AUCTION) {
                if (this.state.intervalId === undefined) {
                    const intervalId = setInterval(this.checkAuctionSync.bind(this), 20);
                    this.setState({intervalId: intervalId})
                }

            } else if (props.listType) {
                if (!this.state.auctionTimeSynced) {
                    this.setState({auctionTimeSynced: true})
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {

        this.handleActionSync(nextProps);

        if (this.props.filter !== nextProps.filter || (this.props.sorter && this.props.sorter.id !== nextProps.sorter.id)) {
            window.scrollTo(0, 0)
        }

    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    loadMore() {
        const {listId} = this.props;
        this.props.listVehiclesFetchMore(listId);
    }


    render() {
        const {listVehicleIds, loading, offset} = this.props;
        const {selectedListVehicle: selectedFromProps, listType} = this.props;
        const {selectedId: selectedFromState} = this.state;

        const renderItems = (!listType && (listType === ListTypes.LIVE_AUCTION && !this.state.auctionTimeSynced)) ? false : true;
        const isLoading = ((loading && listVehicleIds.length > 0 && offset === 0) || (listType === ListTypes.LIVE_AUCTION && !this.state.auctionTimeSynced))

        return (
            <LoadingContainer loading={isLoading}>
                <WheelNotOutSide>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMore}
                        hasMore={this.props.more}
                        useWindow={true}
                        threshold={800}
                        loader={<LoadingIndicator key="loader" loading={(listVehicleIds.length > 0)}/>}
                    >
                        {renderItems &&
                        listVehicleIds.map((id) => {
                                return (
                                            <ListVehicleItem key={id}
                                                             listVehicleId={id}
                                                             loading={loading}
                                                             ref={id}
                                                             onClick={this.onListVehicleClick}
                                                             selected={(selectedFromProps !== 0) ? id === selectedFromProps : id === selectedFromState}
                                            />
                                )
                            }
                        )}
                    </InfiniteScroll>
                </WheelNotOutSide>
            </LoadingContainer>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const {listId} = ownProps;

    return {
        listId,
        listType: listSelectors.getListType(state, listId),
        listVehicleIds: selectors.getVehicleIds(state, listId),
        loading: selectors.getLoading(state, listId),
        error: selectors.getErrorMessage(state, listId),
        more: selectors.getMore(state, listId),
        offset: selectors.getOffset(state, listId),
        filter: getCriteria(state, listId),
        sorter: getActiveSorter(state),
    };
};

export default connect(mapStateToProps, {
    listVehiclesFetchAndBasket,
    listVehiclesClosed,
    listVehiclesFetchMore,
})(ListVehicles);

ListVehicles.defaultProps = {
    fetchData: true,
};
