export const listVehicleTypes = {
    FETCH_REQUEST: 'LISTVEHICLES_FETCH_REQUEST',
    FETCH_SUCCESS: 'LISTVEHICLES_FETCH_SUCCESS',
    FETCH_FAILURE: 'LISTVEHICLES_FETCH_FAILURE',
    FETCH_BASKET_REQUEST: 'LISTVEHICLES_FETCH_BASKET_REQUEST',
    FETCH_BASKET_SUCCESS: 'LISTVEHICLES_FETCH_BASKET_SUCCESS',
    FETCH_BASKET_FAILURE: 'LISTVEHICLES_FETCH_BASKET_FAILURE',
    CLOSED: 'LISTVEHICLES_CLOSED',

    UPDATE: 'LISTVEHICLES_UPDATE',
    SELECT_VEHICLE: 'LISTVEHICLES_SELECT_VEHICLE',

    CLEANUP: 'LISTVEHICLES_CLEANUP',

};


