import React from 'react';
import './ComboMenu.css';

class ComboMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };

        this.collapse = this.collapse.bind(this);
        this.expand = this.expand.bind(this);
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
    }

    collapse() {
        this.setState({expanded: false});
    }

    expand() {
        this.setState({expanded: true});
    }

    handleHeaderClick(){
        if(this.state.expanded){
            this.collapse();
        }
        else {
            this.expand();
        }
    }

    handleMenuItemClick(e){
        e.stopPropagation();
        this.collapse();
    }

    render() {
        return (
            <div onClick={this.handleHeaderClick} onBlur={this.collapse} tabIndex={0}
                 className="menu-item g-color-white g-bg-primary g-bg-primary-dark-v1--hover g-font-weight-500 g-px-15 g-py-6 clickable">
                {this.props.renderTitle()}

                {this.state.expanded &&
                <div className="menu-content-wrapper g-line-height-2 g-mb-30 g-pa-0 g-ma-0">
                    <div className="menu-content g-bg-white">
                        <div
                            className="menu-content-arrow u-triangle-inclusive-v1--top g-right-20" style={{top: 5}}>
                            <div className="u-triangle-inclusive-v1--top__back brd-highlight-bottom"></div>
                        </div>

                        <div onClick={this.handleMenuItemClick}>
                            {this.props.children}
                        </div>

                    </div>
                </div>}
            </div>
        )
    }
}

export default ComboMenu;
