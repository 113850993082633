import React, {Component} from 'react';
import {connect} from 'react-redux';
import {map} from 'lodash';
import Warning from './Warning';
import {getWarnings} from '../selectors';
import {fetchWarnings} from '../actions';

class WarningsBlock extends Component {
    componentDidMount() {
        const {fetchWarnings} = this.props;
        fetchWarnings()
    }

    render() {
        const {warnings} = this.props;
        if (warnings.length === 0) return null;

        return (
            <div style={{backgroundColor: 'white', margin: 5, width: '100%', marginTop:0}}>
                {map(warnings, (warning, i) =>
                    <Warning key={i} title={warning.title} content={warning.content}/>
                )}
            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        warnings: getWarnings(state),
    };
};

export default connect(mapStateToProps, {fetchWarnings})(WarningsBlock)
