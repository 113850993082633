import React, {Component} from 'react';
import {LoadingIndicator} from "../../core";
import Iframe from "react-iframe";


class CollapsePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true};
    }

    render() {
        const {loading} = this.state;
        const {video, locale} = this.props;

        return (
            <React.Fragment>
                <div className='row g-pa-0 g-ma-0 w-100 justify-content-center g-pt-10'>
                    <LoadingIndicator loading={loading}/>
                </div>
                <div className='w-100 justify-content-center'>

                    <Iframe url={(video.urls[locale]) ? video.urls[locale] : video.urls["en"]}
                            width={(window.innerWidth > 800) ? "640px" : "440px"}
                            height={(window.innerWidth > 800) ? "360px" : "260px"}
                            id={video.key}
                            display="initial"
                            position="relative"
                            allowFullScreen
                            onLoad={() => {
                                this.setState({loading: false})
                            }}/>
                </div>
            </React.Fragment>
        )
    }
}

export default CollapsePanel;


