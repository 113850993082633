import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchOffer, detailCleanup} from '../actions';
import * as selectors from '../selectors';
import {gettext} from '../../../../translations/index';
import {LoadingIndicator} from '../../../core';
import moment from 'moment';


class OfferInfo extends Component {

    componentDidMount() {
        const {offerId, fetchOffer} = this.props;
        fetchOffer(offerId);
    }

    componentWillUnmount(){
        const {detailCleanup} = this.props;
        detailCleanup();
    }

    render() {
        const {offer, loading, error} = this.props;

        if (loading) {
            return (
                <div className="g-bg-white">
                    <LoadingIndicator loading={loading}/>
                </div>
            )
        }

        if (error) {
            return (
                <div className="g-bg-white">
                    <div className="text-center">{error}</div>
                </div>
            )
        }

        if (offer === null) {
            return null;
        }

        let title = `NR-${offer.id}`;
        if( offer.date !== null){
            title = `NR-${offer.id} ${moment(offer.date).format('DD/MM/YYYY hh:mm')}`
        }

        return (
            <div>
                <h5 className="text-center text-uppercase color-highlight g-mb-5 g-pb-0">
                    {(offer.name !== null && offer.name !== '') ? offer.name : `<${gettext("Offer with no name")}>`}
                </h5>
                <div className="text-center">
                    {title}
                </div>
                <div className="text-center">
                    {offer.contact}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        offer: selectors.getData(state, ownProps.offerId),
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
    }
};


export default connect(mapStateToProps, {fetchOffer, detailCleanup})(OfferInfo)

