import React from 'react'
import Phone from 'react-phone-number-input'
import Select from 'react-select';
import ToolTipComponent from './ToolTipComponent';

const LabelFieldContainer = ({label, showLabel = true, touched, error, warning, children, additionalInfo = '', name, example}) => {
    const col = showLabel ? 8 : 12;

    return (
        <React.Fragment>
            <div className={`form-group ${ (touched && error) ? 'u-has-error-v1' : ''} row g-hidden-sm-up g-pl-5 g-pr-5`}>

                {showLabel &&
                <label
                    className="g-color-gray-dark-v2 g-font-weight-500 text">
                    {label}
                    {additionalInfo !== '' &&
                    <ToolTipComponent name={name + '_small'} text={additionalInfo} />}
                </label>}

                <div id={label} style={{width: '100%'}}>
                    {children}

                    {touched && ((error &&
                    <small className="form-control-feedback">{error}</small>) || (warning &&
                    <span>{warning}</span>))}
                </div>
            </div>

            <div className={`form-group ${ (touched && error) ? 'u-has-error-v1' : ''} row g-hidden-xs-down`}>

                {showLabel &&
                <label
                    className="col-4 g-min-width-130 col-form-label g-color-gray-dark-v2 g-font-weight-500 text text-right g-hidden-xs-down">
                    {label}
                    {additionalInfo !== '' &&
                    <ToolTipComponent name={name} text={additionalInfo} />}
                </label>}

                <div id={label} className={`col-${col}`} style={{width: '100%'}}>

                    {children}

                    {example &&
                    <small className="g-mr-10">{example}</small>
                    }

                    {touched && ((error &&
                    <small className="form-control-feedback">{error}</small>) || (warning &&
                    <span>{warning}</span>))}
                </div>
            </div>
        </React.Fragment>


    )
};

const FieldContainer = ({touched, error, warning, children}) => (
    <div className={`${ (touched && error) ? 'u-has-error-v1' : ''}`}>

        {children}

        {touched && ((error &&
        <small className="form-control-feedback">{error}</small>) || (warning &&
        <span>{warning}</span>))}
    </div>

);


export const TextField = ({input, label, placeholder, disabled, type, meta: {touched, error, warning}, additionalInfo, name, id}) => (
    <LabelFieldContainer label={label} touched={touched} error={error} warning={warning}
                         additionalInfo={additionalInfo} name={id}>
        <input {...input}
               placeholder={(placeholder && placeholder !== "") ? placeholder : label}
               type={type}
               disabled={disabled}
               className="form-control form-control-md rounded-0 g-py-6 pr-0"
        />
    </LabelFieldContainer>
);

export const TextFieldWithNoLabel = ({input, label, placeholder, disabled, type, meta: {touched, error, warning}}) => (
    <FieldContainer touched={touched} error={error} warning={warning}>
        <input {...input}
               placeholder={label}
               type={type}
               disabled={disabled}
               className="form-control form-control-md rounded-0 g-py-6 pr-0"
        />
    </FieldContainer>

);

export const Multiline = ({input, label, type, meta: {touched, error, valid, warning}, showLabel = true, rows = 5, placeholder}) => (
    <LabelFieldContainer label={label} showLabel={showLabel} touched={touched} error={error} warning={warning}>
        <textarea {...input}
                  placeholder={(placeholder && placeholder !== "") ? placeholder : label}
                  type={type}
                  className="form-control form-control-md rounded-0 g-py-6 pr-0"
                  rows={rows}
        />
    </LabelFieldContainer>
);


export const PhoneNumber = ({input, label, example, meta: {touched, error, valid, warning}}) => (
    <LabelFieldContainer label={label} touched={touched} error={error} warning={warning} example={example}>
        <Phone {...input}
               onFocus={() => {
                   let parent = document.getElementById(label);
                   let child = parent.getElementsByClassName("react-phone-number-input");

                   if (child[0]) {
                       child[0].classList.add("react-phone-number-input-focus");
                   }
               }}
               onBlur={() => {
                   let parent = document.getElementById(label);
                   let child = parent.getElementsByClassName("react-phone-number-input");
                   if (child[0]) {
                       child[0].classList.remove("react-phone-number-input-focus");
                   }
               }}
               country="BE"
               placeholder={label}
               className="form-control form-control-md rounded-0 g-py-6 pr-0"
               displayInitialValueAsLocalNumber={true}
        />

    </LabelFieldContainer>
);

export const Combo = ({input, label, type, options, meta: {touched, error, valid, warning}}) => (
    <LabelFieldContainer label={label} touched={touched} error={error} warning={warning}>
        <Select value={input.value}
                placeholder={label}
                onChange={(option) => input.onChange((option) ? option.value : '')}
                options={options}
        />
    </LabelFieldContainer>
);

export const CheckBox = ({input, label, type, placeholder, meta: {touched, error, warning}}) => (
    <div className="form-group">
        <label className="d-flex row g-ma-0 g-pa-0">
            <span
                className="g-min-width-300 col-form-label g-color-gray-dark-v2 g-font-weight-500 text text-right col">{label}</span>

            <div className="col" style={{width: '100%'}}>
                <div className="form-check-inline u-check mb-0" style={{float: 'right'}}>
                    <input {...input}
                           checked={input.value}
                           type="checkbox"
                           className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                    />
                    <div className="u-check-icon-radio-v7">
                        <i className="fa" data-check-icon="" data-uncheck-icon=""></i>
                    </div>
                </div>
            </div>
        </label>

        <div className='row g-pa-0 g-ma-0'>
            {touched && ((error && <small class="form-control-feedback">{error}</small>) || (warning &&
            <span>{warning}</span>))}
        </div>
    </div>
);




