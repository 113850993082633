import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form'
import {gettext} from '../../../translations';
import {compose} from "redux";

export const settingsFields = {
    CAR_BY_CAR_MAIL: 'car_by_car_mail',
    OFFERS_MAIL: 'offers_mail',
    WON_MAIL: 'won_mail',
    NO_MAIL: 'no_mail'
};

export const CheckBox = ({input, label, disabled}) => (
    <div className="form-group">
        <label className="d-flex row g-ma-0 g-pa-0">
            <span
                className="col-10 g-min-width-250 col-form-label g-color-gray-dark-v2 g-font-weight-500 text text-right">{label}</span>

            <div className="col-2 g-pa-0 g-ma-0 col-form-label g-pt-4 ">
                <div className="form-check-inline u-check mb-0 justify-content-end align-content-end pull-right">
                    <input {...input}
                           checked={input.value}
                           disabled={disabled}
                           type="checkbox"
                           className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                    />
                    <div className="u-check-icon-radio-v7">
                        <i className="fa" data-check-icon="" data-uncheck-icon=""/>
                    </div>
                </div>
            </div>
        </label>
    </div>
);


class SettingsForm extends Component {
    constructor(props){
        super(props);

        this.handleUnsubscribeAll = this.handleUnsubscribeAll.bind(this);
        this.state = {unsubscribe_all: props.initialValues.no_mail || false}
    }

    handleUnsubscribeAll(){
        const {change} = this.props;

        change(settingsFields.CAR_BY_CAR_MAIL, this.state.unsubscribe_all);
        change(settingsFields.OFFERS_MAIL, this.state.unsubscribe_all);
        change(settingsFields.WON_MAIL, this.state.unsubscribe_all);

        this.setState({unsubscribe_all: !this.state.unsubscribe_all})
    }

    render() {
        const {initialValues} = this.props;
        console.log(initialValues);

        return (
            <form initialvalues={initialValues}
                  onSubmit={this.props.handleSubmit}>

                <Field
                    name={settingsFields.CAR_BY_CAR_MAIL}
                    disabled={this.state.unsubscribe_all}
                    component={CheckBox}
                    label={gettext("I want to receive request emails car by car.")}
                />


                <Field
                    name={settingsFields.OFFERS_MAIL}
                    disabled={this.state.unsubscribe_all}
                    component={CheckBox}
                    label={gettext("I want to receive a confirmation email each time I make an offer.")}

                />

                <Field
                    name={settingsFields.WON_MAIL}
                    disabled={this.state.unsubscribe_all}
                    component={CheckBox}
                    label={gettext("I want to receive an email when I make the highest bid and the corresponding delivery information for this car.")}
                />

                <hr className="u-divider-dashed g-brd-gray-light-v2 g-my-30" />


                <Field
                    name={settingsFields.NO_MAIL}
                    onChange={this.handleUnsubscribeAll}
                    component={CheckBox}
                    label={gettext("I don’t want to receive any emails.")}
                />

            </form>

        );
    }
}

export default compose(
    reduxForm({
        form: 'unsubscribe-settings',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: false,
    })
)(SettingsForm);
