const Frontend = () => {
    if(process.env.NODE_ENV === "development"){
        window.location = '/dashboard';
    }else
    {
        window.location = '/frontend';
    }

    return null;
};

export default Frontend;
