import React, {Component} from 'react';
import {connect} from 'react-redux';
import {filtersFetch} from '../actions';
import * as selectors from '../selectors';
import {LoadingIndicator} from "../../core";
import {gettext} from "../../../translations";
import {filters as navFilters} from "../../../_web/navigation/routes";


class FiltersOverview extends Component {

    componentDidMount() {
        this.props.filtersFetch();
    }


    render() {
        const {filters, loading} = this.props;

        if (loading)
            return <LoadingIndicator loading={loading}/>

        return (
            <div className="">

                {Object.values(filters).map((filter, i) => {
                    return (
                        <figure key={i}
                                className="row g-brd-around g-brd-gray-light-v4 g-rounded-4 g-pa-0 g-ma-0 g-pa-5">
                            <div className="col-9 g-pa-0 g-ma-0 d-flex justify-content-start">

                                <div className="d-block">

                                    <h4 className="h5 g-mb-0">
                                        {filter.name}
                                    </h4>

                                </div>

                            </div>
                            <div className="col-3 g-pa-0 g-ma-0 text-right">
                                <a className="u-link-v5 g-brd-around g-brd-gray-light-v3 g-color-black g-color-primary--hover g-font-size-12 rounded g-px-10 g-py-5 clickable"
                                   onClick={() => navFilters()}>{gettext('Edit')}</a>
                            </div>

                        </figure>
                    )
                })}

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        filters: selectors.getFilters(state),
        loading: selectors.getLoading(state),
    };
};

export default connect(mapStateToProps, {
    filtersFetch,
})(FiltersOverview);

