import React from 'react';
import {SearchableList} from '../../../../components/core/index';
import WizardButtons from './WizardButtons';
import {includes} from 'lodash';
import _ from 'lodash';
import {connect} from 'react-redux';
import {gettext} from '../../../../translations';
import DealerMailForm from './forms/DealerPresentationForm';
import {submit} from 'redux-form'
import ContactInfo from './ContactInfo';

class WizardStepSix extends React.Component {
    constructor(props) {
        super(props);

        let selectedCompanies = null;
        if (props.filter !== null) {
            selectedCompanies = _.filter(props.filterCompanies.map((c) => c.id), (id) => !includes(props.filter.excluded_companies, id));
        } else {
            selectedCompanies = props.filterCompanies.map((company) => company.id);
        }

        this.state = {
            selectedCompanies,
            dealerPresentationTextVisible: true,
            dealerPresentationText: "",
        };

        this.renderItem = this.renderItem.bind(this);
        this.addCompanies = this.addCompanies.bind(this);
        this.removeCompanies = this.removeCompanies.bind(this);
    }

    handleCompany(id, event) {
        event.preventDefault();

        const checked = includes(this.state.selectedCompanies, id);

        if (checked) {
            this.removeCompanies([id]);
        } else {
            this.addCompanies([id]);
        }
    }

    removeCompanies(companies) {
        this.setState({
            selectedCompanies: this.state.selectedCompanies.filter(function (company_id) {
                return !includes(companies, company_id)
            })
        });
    }

    addCompanies(companies) {
        this.setState(prevState => ({
            selectedCompanies: [...prevState.selectedCompanies, ...companies]
        }));
    }

    renderItem = ({id, data}) => (
        <div key={`company_${id}`} className="col-lg-6 col-md-6 col-xl-6 g-mt-5 g-mb-5 clickable"
             onClick={this.handleCompany.bind(this, id)}
        >
            <figure className="g-brd-around g-brd-gray-light-v4 g-rounded-4">
                <div className="row g-pt-10 g-pl-10 g-pr-10">
                    <div className="col-9">
                        <h4 className="h5 g-mb-0">{data.name}</h4>
                        <ul className="list-unstyled">
                            <li className="g-color-gray-dark-v5 g-font-size-13">
                                <i className="align-middle mr-2 icon-hotel-restaurant-235 u-line-icon-pro"></i>
                                {data.region && data.region.name}
                            </li>
                        </ul>
                    </div>
                    <div className="col-3 g-ma-0 g-pa-0 g-pr-5 text-right">
                        <label>
                            <div className="form-check-inline u-check mb-0">
                                <input className="g-hidden-xs-up" name="radGroup1_1"
                                       type="checkbox"
                                       checked={includes(this.state.selectedCompanies, id)}
                                       onChange={this.handleCompany.bind(this, id)}
                                />
                                <div className="u-check-icon-radio-v7">
                                    <i className="fa" data-check-icon=""
                                       data-uncheck-icon=""></i>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </figure>
        </div>
    );

    renderDealers() {
        const {step, totalSteps, filterCompanies, onNext, onPrev} = this.props;

        const data = filterCompanies.map((company) => ({
            id: company.id,
            searchTerms: [company.name, (company.region) ? company.region.name : 'no-loc'],
            data: company
        }));

        return (
            <div className="g-pa-0 g-ma-0 g-mb-20">
                <div className="row g-bg-white g-pa-0 g-ma-0 g-pt-20 g-pb-0">

                    <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-lg-up g-pb-10 g-pa-0 g-ma-0 g-pl-5 gpl-10--sm g-pb-10">
                        <h5 className="g-color-primary g-mb-0 g-pb-0">{step}/{totalSteps} {gettext('Dealers')} 2/2</h5>
                        <h6 className="g-pt-2">{gettext('From which dealers do you want to receive price requests?')}</h6>
                        <p className="g-pt-8-5">{gettext('Select the dealers you want to receive requests from')}.</p>

                        <p className="g-pt-5 g-pb-0 g-mb-0"><span className="font-weight-bold">! {gettext("Please note")}:</span></p>
                        <p className="g-pt-2">{gettext("The dealers will receive an automatic e-mail in which we inform them of your registration. The dealers must activate your company within their network. If this does not happen after a while, please contact the dealer in person.")}</p>


                        <ContactInfo/>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-md-down">
                        <h5 className="g-color-primary">{gettext('From which dealers do you want to receive price requests?')}</h5>
                        <p className="g-pt-10 g-pb-10">{gettext('Select the dealers you want to receive requests from')}.</p>

                        <p className="g-pt-5 g-pb-0 g-mb-0"><span className="font-weight-bold">! {gettext("Please note")}:</span></p>
                        <p className="g-pt-2">{gettext("The dealers will receive an automatic e-mail in which we inform them of your registration. The dealers must activate your company within their network. If this does not happen after a while, please contact the dealer in person.")}</p>


                        <ContactInfo/>
                    </div>


                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0">

                        <div style={{height: 500, overflow: 'auto'}}>
                            <SearchableList data={data}
                                            renderItem={this.renderItem}
                                            onSelectAll={this.addCompanies}
                                            onDeSelectAll={this.removeCompanies}
                                            placeholder={gettext("Dealer or region") + ' ...'}
                            />
                        </div>

                        <div className="row g-pa-0 g-ma-0 g-bg-white">
                            <div className="col-12 g-pa-0 g-ma-0 box-shadow">
                                <WizardButtons currentStep={step}
                                               onNext={() => onNext(this.state.selectedCompanies, this.state.dealerPresentationText)}
                                               onPrevious={() => (!this.state.dealerPresentationTextVisible) ? this.setState({dealerPresentationTextVisible: true}) : onPrev()}
                                />
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        )
    }

    renderDealerPresentation() {
        const {required, step, totalSteps, submit, onPrev} = this.props;

        return (
            <div className="g-pa-0 g-ma-0 g-mb-20">
                <div className="row g-bg-white  g-pa-0 g-ma-0 g-pt-20 g-pb-0">


                    <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5 g-hidden-lg-up g-pb-10 g-pa-0 g-ma-0 g-pl-5 gpl-10--sm g-pb-10">
                        <h5 className="g-color-primary g-mb-0 g-pb-0">{step}/{totalSteps} {gettext('Dealers')} 1/2</h5>
                        <h6 className="g-pt-2">{gettext('How would you present yourself and your company?')}</h6>

                        <p className="g-pt-5">
                            {gettext('The first impression is important to win the trust.')}
                            <br/>
                            {gettext('Describe your company briefly. The description is meant for the dealers.')}
                        </p>


                        <div className="text-light-gr">
                            {gettext('Inspirational:')}
                            <ul>
                                <li>{gettext('What is the name of your company?')}</li>
                                <li>{gettext('Which vehicles do you sell?')}</li>
                                <li>{gettext('What can they do for you?')}</li>
                                <li>{gettext('What are your strengths?')}</li>
                            </ul>
                        </div>

                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-5 g-hidden-md-down">
                        <h5 className="g-color-primary">{gettext('How would you present yourself and your company?')}</h5>
                        <p className="g-pt-10">
                            {gettext('The first impression is important to win the trust.')}
                            <br/>
                            {gettext('Describe your company briefly. The description is meant for the dealers.')}
                        </p>


                        <div className="text-light-gr">
                            {gettext('Inspirational:')}
                            <ul>
                                <li>{gettext('What is the name of your company?')}</li>
                                <li>{gettext('Which vehicles do you sell?')}</li>
                                <li>{gettext('What can they do for you?')}</li>
                                <li>{gettext('What are your strengths?')}</li>
                            </ul>
                        </div>

                    </div>


                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 g-ma-0" style={{height: 500}}>
                        <DealerMailForm initialValues={{presentation_text: this.state.dealerPresentationText}}
                                        required={required}
                                        onSubmit={(values) => this.setState({
                                            dealerPresentationTextVisible: false,
                                            dealerPresentationText: values.presentation_text,
                                        })}
                        />

                    </div>
                </div>

                <div className="row g-pa-0 g-ma-0 g-bg-white">
                    <div className="col-12 g-pa-0 g-ma-0">
                        <WizardButtons currentStep={step}
                                       onNext={() => submit('Registration-DealerPresentation')}
                                       onPrevious={() => onPrev()}
                        />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.dealerPresentationTextVisible) {
            return this.renderDealerPresentation()
        }
        return this.renderDealers();
    }
}

export default connect(null, {submit})(WizardStepSix);

