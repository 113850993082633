import React from 'react';
import {connect} from 'react-redux';
import {VehicleVatRegimeBadge, Expectation, VehicleDescriptionLabel} from '../../core';
import {getVehicleById} from '../../listvehicles';

const VehicleInfo = ({vehicle}) => {
    let picture = vehicle.thumbnail_url;
    if(picture === undefined){
        if(vehicle.pictures[0]) {
            picture = vehicle.pictures[0].url;
        }
        else {
            picture = require("../../../img/nopic.jpeg")
        }
    }

    return (
        <div>
            <div className="row g-ma-0 g-pa-0">

                <div className="col-4 g-pa-0 g-ma-0" style={{maxWidth: 140, maxHeight: 150}}>
                    <img src={picture}
                         alt={vehicle.make + ' ' + vehicle.model}
                         style={{width: 140, height: 105}}
                         className="img-fluid"/>
                </div>

                <div className="col-8 g-pa-0 g-ma-0 g-pl-5 g-pl-15--md g-pl-15--lg g-pl-15--xl">
                    <h3 className="h5 d-inline-block text-uppercase color-highlight g-pa-0 g-ma-0 g-mr-10 text-wrap">
                        #{String('000' + vehicle.list_number).slice(-3)} {vehicle.make} {vehicle.model}
                    </h3>
                    <div className="g-color-gray-dark-v5 g-font-style-normal text-uppercase g-font-size-12 g-mb-5 text-wrap">
                        {vehicle.version}
                    </div>

                    <VehicleVatRegimeBadge vat_regime={vehicle.vat_regime}/>

                    <div className="g-pt-10">
                        <Expectation {...vehicle} />
                    </div>
                </div>
            </div>

            <div className="row g-ma-0 g-pa-0 g-mt-5 g-hidden-sm-down">
                <div className="col-12 g-pa-0 g-ma-0">
                    <VehicleDescriptionLabel description={vehicle.description_plus}/>
                </div>
            </div>
            <div className="row g-ma-0 g-pa-0 g-mt-5 g-hidden-sm-down">
                <div className="col-12 g-pa-0 g-ma-0">
                    <VehicleDescriptionLabel description={vehicle.description} important/>
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = (state, ownProps) => {
    const {listVehicleId} = ownProps;

    return {
        vehicle: getVehicleById(state, listVehicleId),
    };
};
export default connect(mapStateToProps)(VehicleInfo);


