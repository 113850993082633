import {types} from './types';
import {combineReducers} from 'redux';
import {keyBy} from 'lodash';

const profileData = (state = {byId: {}, loading: false, error: ''}, action) => {
    switch (action.type) {

        case types.PROFILE_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.PROFILE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                byId: keyBy(action.payload.profiles, 'id'),
                profile_id: action.payload.profiles[0].id,
            };
        case types.PROFILE_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state
    }
};

const countries = (state = {allCountries: [], error: '', loading: false}, action) => {
    switch (action.type) {
        case types.PROFILE_COUNTRIES_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.PROFILE_COUNTRIES_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                allCountries: action.payload.countries,
            };
        case types.PROFILE_COUNTRIES_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state
    }
};

const profile = (state = {step: 0, loading: false, error: ''}, action) => {
    switch (action.type) {
        case types.PROFILE_POST_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.PROFILE_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case types.PROFILE_POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        default:
            return state
    }
};

const data =  combineReducers({
    profile: profileData,
    countries,
});

export default combineReducers({
    data,
    profile,
});
