import {createErrorMessageSelector, createLoadingSelector} from "../ui";
import {APPROVAL_CHECK} from "./types";

const loadingSelector = createLoadingSelector([APPROVAL_CHECK]);
const errorSelector = createErrorMessageSelector([APPROVAL_CHECK]);

export const getLoading = (state) => {
    return loadingSelector(state)
};

export const getError = (state) => {
    return errorSelector(state)
};



export const getApprovalCount = (state) => state.approval_check.approvalCount