import React from 'react';
import {Template} from '../components/core/index';
import {AuthenticationForm} from '../components/authentication/index';
import {goToRegistration, goToForgotPassword} from './navigation/routes';
import {gettext} from '../translations/index';
import LoginByCode from './LoginByCode';
import {getParamValue} from '../helpers/functions';
import {withConfig} from '../config'
import TeaserBanner from "../components/core/components/web/TeaserBanner";

const Login = ({location, config}) => {
    let code = null;
    if (location.search.includes('code=')) {
        code = getParamValue(location.search, 'code');

        if (code !== null && code !== "") {
            return <LoginByCode code={code} location={location}/>
        }
    }
    const {login_headlines} = config;

    return (
        <Template>

            <TeaserBanner containerClass='g-pt-60' />

            <div className="g-bg-white">
                <div className="g-max-width-645 text-center g-pt-100 mx-auto g-mb-20">
                    <h1 className="mb-0">{gettext('Welcome back!')}</h1>
                </div>

                <div className="g-pb-30">
                    <div className="row justify-content-center g-pa-0 g-ma-0 g-mx-10 g-mx-0-md g-mx-0--lg g-mx-0--xl">
                        <div className="col-md-6 col-lg-4 g-mb-70 g-ma-0 g-pa-0">
                            <h2 className="h5 mb-4 text-center">{gettext('Sign in to your account')}</h2>

                            <AuthenticationForm/>

                            <div className="d-flex justify-content-between g-mb-10">
                                <a className="d-block g-mb-10 clickable g-color-primary"
                                   onClick={goToRegistration}>{gettext('Create an account')}</a>
                                <a className="d-block g-mb-10 clickable g-color-primary"
                                   onClick={goToForgotPassword}>{gettext('Forgot Password')}?</a>
                            </div>
                        </div>

                        {login_headlines &&
                        <div className="g-hidden-sm-down col-md-6 col-lg-5 ml-lg-5 g-mb-70">

                            <div className="media g-mb-30">
                                <div className="d-flex mr-3">
                                    <span className="u-icon-v1 u-icon-size--lg g-color-text">
                                      <i className="icon-medical-022 u-line-icon-pro"></i>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h3 className="h5">{gettext('Availability')}</h3>
                                    <p>{gettext('With this application you can deal with price requests whenever and wherever you want.')}</p>
                                </div>
                            </div>

                            <div className="media g-mb-30">
                                <div className="d-flex mr-3">
                                    <span className="u-icon-v1 u-icon-size--lg g-color-text">
                                     <i className="icon-real-estate-014 u-line-icon-pro"></i>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h3 className="h5">{gettext('Control and follow-up')}</h3>
                                    <p>{gettext('Manage the evolution of your quotations in one central location')}</p>
                                </div>
                            </div>

                            <div className="media">
                                <div className="d-flex mr-3">
                                    <span className="u-icon-v1 u-icon-size--lg g-color-text">
                                          <i className="icon-real-estate-083 u-line-icon-pro"></i>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h3 className="h5">{gettext('Flexible management of your profile')}</h3>
                                    <p>{gettext('You can always adjust your filters according to your needs')}</p>
                                </div>
                            </div>

                        </div>}
                    </div>
                </div>

            </div>



        </Template>
    )
};

export default withConfig(Login);
