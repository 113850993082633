import React, {Component} from 'react';
import {connect} from 'react-redux';
import {resetCriteria} from '../actions';
import {hasActiveCriteria} from '../functions/filter';
import {gettext} from '../../../translations';

class ClearFilters extends Component {

    render() {
        const {hasActiveCriteria, fontStyle} = this.props;

        return (<div>
            {hasActiveCriteria &&
            <p className="text-uppercase click-text g-mb-0 g-pl-20 color-highlight" style={fontStyle}
               onClick={() => {
                   this.props.resetCriteria(this.props.listId, true).then(() => this.props.onClearFilters());

                   if(this.props.onClick){
                       this.props.onClick();
                   }
               }}>{gettext("Clear filters")}</p>
            }
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        hasActiveCriteria: hasActiveCriteria(state, ownProps.listId),
    }
};

export default connect(mapStateToProps, {resetCriteria})(ClearFilters);

