import React, {Component} from 'react';
import {Template} from '../components/core/index';
import {ResetPasswordRequest} from '../components/reset-password-request';
import {ResetPassword} from '../components/reset-password';
import {gettext} from '../translations/index';

class ForgotPassword extends Component {

    renderResetPassword(){
        return (
            <Template>
                <div className="g-bg-white">
                    <section className="container g-py-100 ">
                        <div className="row justify-content-center">
                            <div className="col-sm-10 col-md-9 col-lg-6 ">
                                <h2 className="h5 mb-4">{gettext('Forgot your password') + '?'}</h2>
                                <p>{gettext('Please enter your email address below. You will receive a link to reset your password')}</p>
                                <ResetPasswordRequest/>
                            </div>
                        </div>
                    </section>

                </div>
            </Template>
        )
    }


    renderSetPassword(uniqueId){
        return (
            <Template>
                <div className="g-bg-white">
                    <section className="container g-py-100 ">
                        <div className="row justify-content-center">
                            <div className="col-sm-10 col-md-9 col-lg-6 ">
                                <h2 className="h5 mb-4">{gettext('Reset your password')}</h2>
                                <p>{gettext('Please enter your new password in the fields below.')}</p>
                               <ResetPassword uniqueId={uniqueId} />
                            </div>
                        </div>
                    </section>

                </div>
            </Template>
        )
    }


    render() {
        const {uniqueId} = this.props.match.params;

        if(uniqueId === undefined){
            return this.renderResetPassword();
        }
        else{
            return this.renderSetPassword(uniqueId);
        }

    }
}

export default ForgotPassword;

