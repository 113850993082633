import {createSelector} from 'reselect';
import {includes} from 'lodash';
import {ListVehicleStates, BasketStates} from '../../constants';


export const getLoading = (state) => state.listvehicles_no_interest.loading;
export const getError = (state) => state.listvehicles_no_interest.error;
export const getListVehicleId = (state, listVehicleId) => listVehicleId;
export const getNoInterstListVehicleIds = (state) => state.listvehicles_no_interest.vehicleIds;

export const makeGetListVehicleInterest = () => {
    return listVehicleInterest;
};

export const listVehicleInterest = createSelector(
    [getListVehicleId, getNoInterstListVehicleIds],
    (id, vehicleIds) => {

        if (includes(vehicleIds,id)) {
            return false;
        }
        else {
            return true;
        }

    }
);

export const makeGetListVehicleInterestVisible = () => {
    return listVehicleInterestVisible;
};


export const getVehicleById = (state, listVehicleId) => state.entities.vehicle[listVehicleId];

export const listVehicleInterestVisible = createSelector(
    [getVehicleById],
    (vehicle) => {
        if (vehicle === null || typeof vehicle === 'undefined') return false;

        let hasBid = (vehicle.basket_state !== null && typeof  vehicle.basket_state !== 'undefined') ? vehicle.basket_state !== BasketStates.INITIAL : false;

        return (vehicle.vehicle_state === ListVehicleStates.INITIAL && !hasBid);
    }
);


export const listVehicleDisabledByInterest = createSelector(
    [listVehicleInterest, getVehicleById],
    (interest, vehicle) => {
        if(!vehicle) return false;
        
        return (!interest && vehicle.vehicle_state === ListVehicleStates.INITIAL);
    }
);






