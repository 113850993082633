import React from 'react';
import {connect} from 'react-redux';
import {listVehicleInterest, listVehicleInterestDelete} from '../actions';
import * as selectors from '../selectors';
import {getListIdFromListVehicleId, getVehicleIdFromListVehicleId} from '../../../helpers/functions';
import {gettext} from '../../../translations';

const InterestLabelButton = ({listId, vehicleId, interest, listVehicleInterest, listVehicleInterestDelete, visible}) => {
    //const icon = (interest) ? 'fa fa-thumbs-down' : 'fa fa-thumbs-up';
    const icon = 'fa fa-thumbs-down';

    if(visible) {
        return (

            <div className="text-center g-valign-middle " onClick={(e) => {
                e.stopPropagation();
                (interest) ? listVehicleInterest(listId, vehicleId) : listVehicleInterestDelete(listId, vehicleId)
            }}>
                <i className={`${icon} g-font-size-20 ${(interest) ? 'color-btn-icon' : 'g-color-primary'} g-color-primary--hover g-mr-8 text-wrap` }/>
                {(interest) ? gettext('No Interest') : gettext('Interest') }
            </div>
        )
    }

    return null;
};

const makeMapStateToProps = () => {
    const getListVehicleInterest = selectors.makeGetListVehicleInterest();
    const getListVehicleInterestVisible = selectors.makeGetListVehicleInterestVisible();


    const mapStateToProps = (state, ownProps) => {
        let {listVehicleId} = ownProps;
        return {
            listId: getListIdFromListVehicleId(listVehicleId),
            vehicleId: getVehicleIdFromListVehicleId(listVehicleId),
            listVehicleId: listVehicleId,
            interest: getListVehicleInterest(state, listVehicleId),
            visible: getListVehicleInterestVisible(state, listVehicleId),
        }
    };
    return mapStateToProps
};

export default connect(makeMapStateToProps, {listVehicleInterest, listVehicleInterestDelete})(InterestLabelButton);




