import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateCriteria} from '../actions';
import * as listVehiclesFilterSelectors from '../../listvehicles-filter/selectors';
import * as criteriaHelpers from '../functions/criteria';
import {gettext} from '../../../translations';


class Favorite extends Component {
    render() {
        const {listId, criteria, updateCriteria, onFavoriteChanged} = this.props;

        return (
            <div className="g-px-10 g-py-5 clickable"
                 style={{display: 'flex', alignItems: 'center', minHeight: 28, backgroundColor: criteria.favorites ? '#96db79' : '#f2f2f2', color: criteria.favorites ? 'white' : 'black'}}
                 onClick={() => {
                     updateCriteria(listId, criteriaHelpers.toggleFavorites(criteria)).then(() => onFavoriteChanged());
                 }}>
                <i className="fa fa-heart g-font-size-13 clickable g-mr-5" style={{color: criteria.favorites ? 'white' : 'black'}}/> {gettext('Favorites')}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        criteria: listVehiclesFilterSelectors.getCriteriaCopy(state, ownProps.listId)
    }
};

export default connect(mapStateToProps, {updateCriteria})(Favorite);



