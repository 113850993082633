import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import {get} from 'lodash';
import {gettext} from '../../../../../translations/index';
import format from 'string-format';
import {config} from '../../../../../config';

const AllFields = {
    INDEX: 'INDEX',
    VIN: 'VIN',
    FIRST_REGISTRATION: 'FIRST_REGISTRATION',
    ENGINE_CC: 'ENGINE_CC',
    KM: 'KM',
    EMISSION_CO2: 'EMISSION_CO2',
    EMISSION_NORM: 'EMISSION_NORM',
    FUEL: 'FUEL',
    POWER_KW: 'POWER_KW',
    POWER_KW_PK: 'POWER_KW_PK',
    GEARBOX: 'GEARBOX',
    DOORS: 'DOORS',
    COLOR_EXTERIOR: 'COLOR_EXTERIOR',
    COLOR_INTERIOR: 'COLOR_INTERIOR',
    SEATS: 'SEATS',
    EXPECTED_DATE: 'EXPECTED_DATE',
    EXPECTED_FROM: 'EXPECTED_FROM',
    EXPECTED_KM: 'EXPECTED_KM',
    EXPECTED_KM_FROM_TO: 'EXPECTED_KM_FROM_TO',
    EMPTY: '',
};

export const getFieldData = (key, data) => {
    switch (key) {
        case AllFields.INDEX:
            const list_number = get(data, 'list_number');
            /* vehicle on active list */
            const list_index = get(data, 'list_index');
            /* historical vehicle */
            const list_short_name = get(data, 'list_short_name');
            /* historical vehicle */

            if (list_number && list_number > 0)
                return `${String('000' + list_number).slice(-3)}`;
            else if (list_index && list_index > 0)
                return `${String('000' + list_index).slice(-3)} (${list_short_name})`;
            else return gettext('n/a');
        case AllFields.VIN:
            return get(data, 'vin') || gettext('n/a');
        case AllFields.FIRST_REGISTRATION:
            return moment(get(data, 'first_registration')).format(config.vehicle_first_registration_format) || gettext('n/a');
        case AllFields.ENGINE_CC:
            return format(gettext('{0} cc'), get(data, 'engine_cc')) || gettext('n/a');
        case AllFields.KM:
            return format(gettext('{0} km'), get(data, 'kilometers')) || gettext('n/a');
        case AllFields.EMISSION_CO2:
            return format('{0} g CO2/km', get(data, 'emission_co2')) || gettext('n/a');
        case AllFields.EMISSION_NORM:
            return get(data, 'emission_norm') || gettext('n/a');
        case AllFields.FUEL:
            return get(data, 'fuel') || gettext('n/a');
        case AllFields.POWER_KW:
            return format(gettext('{0} kw'), get(data, 'power_kw')) || gettext('n/a');
        case AllFields.POWER_KW_PK:
            return format('{0} kW ({1} PK)', get(data, 'power_kw'), get(data, 'power_hp')) || gettext('n/a');
        case AllFields.GEARBOX:
            return get(data, 'gearbox') || gettext('n/a');
        case AllFields.DOORS:
            return get(data, 'doors') || gettext('n/a');
        case AllFields.COLOR_EXTERIOR:
            return get(data, 'color_exterior') || gettext('n/a');
        case AllFields.SEATS:
            return get(data, 'seats') || gettext('n/a');
        case AllFields.COLOR_INTERIOR:
            return get(data, 'color_interior') || gettext('n/a');
        case AllFields.EXPECTED_FROM:
            return format(gettext('from {0}'), moment(get(data, 'expected_date')).format('MM/YYYY'));
        case AllFields.EXPECTED_DATE:
            return moment(get(data, 'expected_date')).format('MM/YYYY');
        case AllFields.EXPECTED_KM:
            return format(gettext('{0} - {1} km'), numeral(get(data, 'expected_km')).format('0,0'), numeral(get(data, 'expected_km_to')).format('0,0'));
        case AllFields.EXPECTED_KM_FROM_TO:
            return format(gettext('{0} to {1} km'), numeral(get(data, 'expected_km')).format('0,0'), numeral(get(data, 'expected_km_to')).format('0,0'));
        case AllFields.EMPTY:
            return <br />;
        default:
            return gettext('n/a');
    }
};

export const getFieldLabel = (key) => {
    switch (key) {
        case AllFields.INDEX:
            return gettext('index');
        case AllFields.VIN:
            return gettext('vin');
        case AllFields.FIRST_REGISTRATION:
            return gettext('first registration');
        case AllFields.ENGINE_CC:
            return gettext('engine cc');
        case AllFields.KM:
            return gettext('kilometers');
        case AllFields.EMISSION_CO2:
            return gettext('emission co2');
        case AllFields.EMISSION_NORM:
            return gettext('emission norm');
        case AllFields.FUEL:
            return gettext('fuel');
        case AllFields.POWER_KW:
            return gettext('power_kw');
        case AllFields.GEARBOX:
            return gettext('gearbox');
        case AllFields.DOORS:
            return gettext('doors');
        case AllFields.COLOR_EXTERIOR:
            return gettext('color exterior');
        case AllFields.SEATS:
            return gettext('seats');
        case AllFields.COLOR_INTERIOR:
            return gettext('color interior');
        case AllFields.EXPECTED_DATE:
        case AllFields.EXPECTED_FROM:
            return gettext('from');
        case AllFields.EXPECTED_KM:
            return gettext('km');
        default:
            return gettext('n/a');
    }
};


const Specifications = ({vehicle, fields}) => (
    <div>
        <div className="row g-pt-3 ">
            <div className="col-6 col-md-4 col-lg-4 col-xl-4 ">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[0], vehicle)}
                </div>

            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-4 ">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[1], vehicle)}
                </div>
            </div>
            <div className="col-4 g-pl-0 g-hidden-sm-down ">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[2], vehicle)}
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-6 col-md-4 col-lg-4 col-xl-4 ">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[3], vehicle)}
                </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-4 ">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[4], vehicle)}
                </div>
            </div>
            <div className="col-4 g-pl-0 g-hidden-sm-down">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[5], vehicle)}
                </div>
            </div>
        </div>

        {fields.length === 9 &&
        <div className="row">
            <div className="col-6 col-md-4 col-lg-4 col-xl-4 ">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[6], vehicle)}
                </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-4 ">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[7], vehicle)}
                </div>
            </div>
            <div className="col-4 g-pl-0 g-hidden-sm-down">
                <div className="g-brd-bottom g-brd-gray-light-v3 text-wrap">
                    {getFieldData(fields[8], vehicle)}
                </div>
            </div>
        </div>}


    </div>
);

export default Specifications;

