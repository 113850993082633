import React from 'react';

const VehicleDescriptionLabel = ({description, important}) => {
    if (description === null || typeof description === 'undefined' || description.length <= 0) {
        return null;
    }

    return (
        <div>
            {!important &&
            <div className="row g-pa-0 g-ma-0 container-normal ">
                <div className="veh-description g-ma-0 g-pa-5">
                    <i className="fa fa-info-circle veh-description-icon-normal g-font-size-18" />
                </div>
                <div className="col g-ma-0 g-pa-5 g-font-weight-500">
                    {description}
                </div>
            </div>}


            {important &&
            <div className="row g-pa-0 g-ma-0 container-important ">
                <div className="veh-description-important g-ma-0 g-pa-5">
                    <i className="fa fa-warning veh-description-icon-important g-font-size-18" />
                </div>
                <div className="col g-ma-0 g-pa-5 g-font-weight-500">
                    {description}
                </div>
            </div>}
        </div>
    );
};

VehicleDescriptionLabel.defaultProps = {
    important: false,
}

export default VehicleDescriptionLabel;
