import {pictureSwiperTypes} from './types';

const INITIAL_STATE = {
    visible: false,
    pictures: [],
    index: 0,
};

export const swiper = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case pictureSwiperTypes.TOGGLE_VISIBILITY:
            return {
                ...state,
                visible: action.payload.visible,
                pictures: action.payload.pictures,
                index: action.payload.index,
            };
        default:
            return state;
    }
};