import React from 'react';
import {includes} from 'lodash';
import {addCompany, removeCompany, addCompanies, removeCompanies} from '../functions/companies';
import {SearchableList} from '../../../components/core/'
import {gettext} from '../../../translations';

class Companies extends React.Component {
    constructor(props) {
        super(props);

        this.renderItem = this.renderItem.bind(this);
        this.addCompanies = this.addCompanies.bind(this);
        this.removeCompanies = this.removeCompanies.bind(this);
    }

    handleCompany(companyId, event) {
        event.preventDefault();

        const checked = includes(this.props.filter.excluded_companies, companyId);

        if (checked) {
            this.props.onChange(addCompany(this.props.filter, companyId));
        }
        else {
            this.props.onChange(removeCompany(this.props.filter, companyId));
        }
    }

    removeCompanies(companies) {
        this.props.onChange(removeCompanies(this.props.filter, companies));
    }

    addCompanies(companies) {
        this.props.onChange(addCompanies(this.props.filter, companies));
    }

    renderItem = ({id, data}) => (
        <div key={`company_${id}`} className="col-lg-6 col-md-6 col-xl-6 g-mt-5 g-mb-5 clickable"
             onClick={this.handleCompany.bind(this, id)}
        >
            <figure className="g-brd-around g-brd-gray-light-v4 g-rounded-4">
                <div className="row g-pt-10 g-pl-10 g-pr-10">
                    <div className="col-9">
                        <h4 className="h5 g-mb-0">{data.name}</h4>
                        <ul className="list-unstyled">
                            <li className="g-color-gray-dark-v5 g-font-size-13">
                                <i className="align-middle mr-2 icon-hotel-restaurant-235 u-line-icon-pro"></i>
                                {data.region && data.region.name}
                            </li>
                        </ul>
                    </div>
                    <div className="col-3 g-ma-0 g-pa-0 g-pr-5 text-right">
                        <label>
                            <div className="form-check-inline u-check mb-0">
                                <input className="g-hidden-xs-up" name="radGroup1_1"
                                       type="checkbox"
                                       checked={!includes(this.props.filter.excluded_companies, id)}
                                       onChange={this.handleCompany.bind(this, id)}
                                />
                                <div className="u-check-icon-radio-v7">
                                    <i className="fa" data-check-icon=""
                                       data-uncheck-icon=""></i>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </figure>
        </div>
    );

    render() {
        const data = this.props.companies.map((company) => ({
            id: company.id,
            searchTerms: [company.name, (company.region) ? company.region.name : 'no-loc'],
            data: company
        }));


        return (
            <SearchableList data={data}
                            renderItem={this.renderItem}
                            onSelectAll={this.addCompanies}
                            onDeSelectAll={this.removeCompanies}
                            placeholder={gettext("Search dealer or region") + ' ...'}
            />
        )
    }
}

export default Companies;