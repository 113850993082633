import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

const FilterItem = ({onClick, item, showCounters = true}) => (
    <li className="g-mb-2">
        <div className="filter-item d-block u-link-v5 g-color-gray-dark-v4 g-color-primary--hover u-tags-v1 text-wrap"
           onClick={() => onClick(item)}>

            <div className="text-wrap" style={{float: 'left', width:'80%'}}>
            {(item.name !== '' && item.name !== 'undefined') ? _.capitalize(item.name) : 'n/a'}
            </div>

            {showCounters &&
            <span className={classNames({
                'g-font-size-12 float-right': true,
                'filter-item-counter': true,
                'filter-item-counter-active': item.active,
            })}>
               {item.count}
            </span>}

            {!showCounters && item.active &&
            <span className={classNames({
                'g-font-size-12 float-right': true,
                'filter-item-check': true,
                'filter-item-counter-active': item.active,
                'text-center': true
            })}>
              <i className='fa fa-check g-font-size-12 color-white'/>
            </span>
            }

        </div>



    </li>
);

export default FilterItem;

