import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Template} from '../components/core/index';
import {RegistrationWizard as Wizard} from '../components/registration-wizard/index';
import {updateSessionRegistrationStep} from '../components/session/index';
import {dashboard} from './navigation/routes';

class RegistrationWizard extends Component {

    onRegistrationComplete(){
        this.props.updateSessionRegistrationStep(100);

        // navigate to dashboard
        dashboard();
    }

    render() {
        return (
            <Template>
                <div className="g-mt-70">
                    <Wizard onWizardCompleted={() => this.onRegistrationComplete()} />
                </div>
            </Template>
        )
    }
}

export default connect(null, {updateSessionRegistrationStep})(RegistrationWizard);
