import React, {Component} from 'react';
import {connect} from 'react-redux';
import {listVehicleFavorite, listVehicleFavoriteDelete} from '../../listvehicles-favorite/actions';
import * as selectors from '../../listvehicles-favorite/selectors';
import {getListIdFromListVehicleId, getVehicleIdFromListVehicleId} from '../../../helpers/functions';

class FavoriteButton extends Component {

    constructor(props) {
        super(props);

        this.state = {hovered: false, clicked: false};

        this.handleFavorite = this.handleFavorite.bind(this);

    }

    handleFavorite(e) {
        const {listId, vehicleId, favorite, onClick, listVehicleFavorite, listVehicleFavoriteDelete} = this.props;

        this.setState({clicked: true});

        if (!favorite) {
            listVehicleFavorite(listId, vehicleId).then(() => {this.setState({clicked: false, hovered: false})})
        } else {
            listVehicleFavoriteDelete(listId, vehicleId).then(() => {this.setState({clicked: false, hovered: false})})
        }

        onClick(e);
    }


    render() {
        const {favorite} = this.props;
        const {hovered, clicked} = this.state;

        return (
            <div className="text-center g-valign-middle ">
                <i className={`${(hovered || clicked) ? (favorite) ? 'fa fa-heart-o' : 'fa fa-heart' : (favorite) ? 'fa fa-heart' : 'fa fa-heart-o'} g-font-size-22 g-color-red`}
                   onMouseOver={() => {
                       this.setState({hovered: true})
                   }}
                   onMouseLeave={() => {
                       if(!this.state.clicked) {
                           this.setState({hovered: false})
                       }
                   }}
                   onClick={this.handleFavorite}
                />
            </div>
        )
    }
}


const makeMapStateToProps = () => {
    const getListVehicleFavorite = selectors.makeGetListVehicleFavorite();

    const mapStateToProps = (state, ownProps) => {
        let {listVehicleId} = ownProps;
        return {
            listId: getListIdFromListVehicleId(listVehicleId),
            vehicleId: getVehicleIdFromListVehicleId(listVehicleId),
            listVehicleId: listVehicleId,
            favorite: getListVehicleFavorite(state, listVehicleId),

        }
    };
    return mapStateToProps
};

export default connect(makeMapStateToProps, {listVehicleFavorite, listVehicleFavoriteDelete})(FavoriteButton);




