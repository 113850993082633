import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {validators} from '../../core/index';
import {gettext} from '../../../translations';

export const restPasswordFields = {
    USERNAME: 'username',
};


const renderField = ({input, label, placeholder, type, meta: {touched, error, warning}}) => (
    <div className={`form-group ${ (touched && error) ? 'u-has-error-v1' : ''} mb-4`}>
        <input {...input}
               placeholder={placeholder}
               type={type}
               className="form-control rounded g-py-15 g-px-15"
        />
        {touched && ((error && <small class="form-control-feedback">{error}</small>) || (warning &&
            <span>{warning}</span>))}
    </div>
);


const ResetPasswordRequestForm = (props) => {
    const {handleSubmit, loading} = props;

    return (
        <form className="g-py-15" onSubmit={handleSubmit}>

            <Field name={restPasswordFields.USERNAME}
                   type="text"
                   component={renderField}
                   placeholder={gettext("e-mail")}
                   validate={[validators.required]}
            />

            <button type="submit"
                    disabled={loading}
                    className="btn btn-block u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 text-uppercase rounded-0 g-px-25 g-py-15 " style={{position: 'relative'}}>

                {loading &&
                <div className={`lds-ring lds-ring-white`}
                     style={{borderColor: 'white', position: 'absolute', marginLeft: -20, marginTop: 4}}>
                    <div></div>
                </div>
                }

                {gettext('Send')}
            </button>


        </form>
    )
};

export default reduxForm({
    form: 'password-reset',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
})(ResetPasswordRequestForm)

