import React, {Component} from 'react';
import ApprovalCheckModal from './ApprovalCheckModal';
import {connect} from 'react-redux';
import {gettext} from '../../../translations';
import calling from "../../../assets/svg/call.svg";
import {withConfig} from '../../../config';
import {compose} from "redux";
import {approvalCheck} from '../actions';
import {getApprovalCount} from "../selectors";


class ApprovalCheck extends Component {

    constructor(props) {
        super(props);

        this.state = {visible: false}
    }

    componentDidMount(){
        const {config, approvalCheck} = this.props;

        if(config.dealerships){
            approvalCheck()
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.approvalCount !== nextProps.approvalCount && nextProps.approvalCount === 0){
            this.setState({visible: true})
        }
    }

    render() {
        const {config, approvalCount, minApprovalsRequired} = this.props;

        if((!(config.dealerships && approvalCount < minApprovalsRequired)) || approvalCount === null) return null;

        return (
            <div style={{backgroundColor: 'white', margin: 5, width: '100%', marginTop: 0}}>
                <div className="row g-pa-10 g-font-size-10 g-font-size-20--md g-ma-0 clickable"
                     style={{
                         color: "#2680C2",
                         backgroundColor: "#DCEEFB",
                         borderBottomWidth: 2,
                         borderBottomStyle: 'solid',
                         borderBottomColor: "#2680C2"
                     }}
                     onClick={() => this.setState({visible: true})}>


                    <div className="d-flex justify-content-center align-items-center g-mr-5 g-mr-15--md"
                         style={{
                             alignItems: 'center',
                             justifyContent: 'center'
                         }}>
                        <img className="color-highlight" src={calling} alt="calling" style={{width: (window.innerWidth > 800) ? 35 : 12}}/>
                    </div>

                    {gettext('Contact the dealers so they can add you to their network.')}

                    <ApprovalCheckModal visible={this.state.visible}
                               onCancel={() => this.setState({visible: false})}/>

                </div>
            </div>
        )
    }
}

ApprovalCheck.defaultProps = {
    minApprovalsRequired: 1
};

const mapStateToProps = (state) => {
    return {
        approvalCount: getApprovalCount(state),
    };
};


export default compose(
    withConfig,
    connect(mapStateToProps, {approvalCheck})
)(ApprovalCheck)


