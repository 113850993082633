import {types} from './types';

const initial_state = {locale: ""};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case types.SET_LOCALE:
            return {
                ...state,
                locale: action.payload.locale,
            };
        default:
            return state;
    }
};

export default reducer;