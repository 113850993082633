
export {default as reducer} from './reducer';

export {localizationMiddleware} from './middlewares'

export {getLocale} from './selectors';
export {setLocale} from './actions';

export {getBrowserLocale} from './functions';

export {default as LocalizedContainer} from './_web/LocalizedContainer';
export {default as LanguagePicker} from './_web/LanguagePicker';