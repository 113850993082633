import {RSAA} from 'redux-api-middleware';
import {types} from './types';
import {keys, map, sortBy, get} from 'lodash';
import {
    getCreatedFilterId,
    getUpdateFilterSuccess,
    getCreateFilterSuccess,
    getUpdatedFilterId,
    getDocuments
} from './selectors';
import {gettext} from '../../translations';
import {config} from '../../config';

const stepMapper = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    99: 1,
};

export const fetchData = () => {
    return dispatch => {
        let actions = [
            dispatch(fetchRegistration()),
            dispatch(fetchCountries()),
        ];

        if (config.registration_steps.includes(6) && config.registration_steps.includes(7)) {
            actions.push(dispatch(fetchFilters()));
            actions.push(dispatch(fetchFilterMakes()));
            actions.push(dispatch(fetchFilterCompanies()));
        }

        return Promise.all(actions);
    }
};

export const refreshData = () => {
    return dispatch => {
        return dispatch(fetchRegistration(false));
    }
};

export const fetchRegistration = (initial = true) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/registrations/`,
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: types.REGISTRATION_FETCH_REQUEST,
                    },
                    {
                        type: types.REGISTRATION_FETCH_SUCCESS,
                        payload: (action, state, res) => onFetchRegistrationSuccess(dispatch, res, initial),
                    },
                    {
                        type: types.REGISTRATION_FETCH_FAILURE

                    }
                ]
            }
        });
    };
};
const onFetchRegistrationSuccess = (dispatch, res, initial) => {
    return res.json().then(data => {
        let registration = data[0];

        if (initial) {
            dispatch(currentStepUpdate(stepMapper[registration.step]));
        }

        return {
            id: get(registration, 'id'),
            email: get(registration, 'email'),
            email_validated: get(registration, 'email_validated'),
            culture_major: get(registration, 'culture_major'),
            culture_minor: get(registration, 'culture_minor'),
            unique_id: get(registration, 'unique_id'),
            company_name: get(registration, 'company_name'),
            first_name: get(registration, 'first_name'),
            last_name: get(registration, 'last_name'),
            step: get(registration, 'step'),
            title: get(registration, 'title'),
            telephone: get(registration, 'telephone'),
            mobile: get(registration, 'mobile'),
            vat_number: get(registration, 'vat_number'),
            country_code: get(registration, 'country_code'),
            address: get(registration, 'address'),
            post_code: get(registration, 'post_code'),
            city: get(registration, 'city'),
            logistics_email: get(registration, 'logistics_email'),
            sms_active: get(registration, 'sms_active'),
            car_by_car_notification: get(registration, 'car_by_car_notification'),
            car_by_car_mail: get(registration, 'car_by_car_mail'),
            daily_digest_mail: get(registration, 'daily_digest'),
            offers_mail:  get(registration, 'offers_mail') || false,
            won_mail:  get(registration, 'won_mail') || false,
            filter_id: get(registration, 'filter_id'),
            documents: get(registration, 'documents'),
            volume_year: get(registration, 'volume_year') || "",
            presentation_text: get(registration, 'presentation_text') || "",
            company_user_state: get(registration, 'company_user_state'),
        }
    });
};

export const fetchFilters = () => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/filters/',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: types.REGISTRATION_FILTERS_FETCH_REQUEST,
                    },
                    {
                        type: types.REGISTRATION_FILTERS_FETCH_SUCCESS,
                        payload: (action, state, res) => onFetchFilterSuccess(dispatch, res),
                    },
                    {
                        type: types.REGISTRATION_FILTERS_FETCH_FAILURE,
                    }
                ]
            }

        });
    };
};
const onFetchFilterSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return {
            filters: data.map((filter) => ({
                id: filter.id,
                name: filter.name,
                regions: map(filter.regions, 'id'),
                excluded_companies: map(filter.excluded_companies, 'id'),
                makes: map(filter.makes, 'id'),
                age_min: filter.age_min,
                age_max: filter.age_max,
                km_min: filter.km_min,
                km_max: filter.km_max,
            })),
        }
    });
};

export const fetchFilterMakes = () => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/filters-makes/',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: types.REGISTRATION_FILTER_MAKES_FETCH_REQUEST,
                    },
                    {
                        type: types.REGISTRATION_FILTER_MAKES_FETCH_SUCCESS,
                        payload: (action, state, res) => onFetchFilterMakesSuccess(dispatch, res),
                    },
                    {
                        type: types.REGISTRATION_FILTER_MAKES_FETCH_FAILURE,
                    }
                ]
            }
        });
    };
};
const onFetchFilterMakesSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return {
            makes: map(data, (make) => ({id: make.id, name: make.name}))
        };
    });
};

export const fetchFilterCompanies = () => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/filters-companies/',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: types.REGISTRATION_FILTER_COMPANIES_FETCH_REQUEST,
                    },
                    {
                        type: types.REGISTRATION_FILTER_COMPANIES_FETCH_SUCCESS,
                        payload: (action, state, res) => onFetchFilterCompaniesSuccess(dispatch, res),
                    },
                    {
                        type: types.REGISTRATION_FILTER_COMPANIES_FETCH_FAILURE,
                    }
                ]
            }
        });
    };
};
const onFetchFilterCompaniesSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return {
            companies: sortBy(map(data, (company) => ({
                id: company.id,
                region: company.region,
                name: company.name,
            })), 'name'),
        }
    });
};

export const fetchCountries = () => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/countries/`,
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: types.REGISTRATION_COUNTRIES_FETCH_REQUEST,
                    },
                    {
                        type: types.REGISTRATION_COUNTRIES_FETCH_SUCCESS,
                        payload: (action, state, res) => onFetchCountriesSuccess(dispatch, res),
                    },
                    {
                        type: types.REGISTRATION_COUNTRIES_FETCH_FAILURE

                    }
                ]
            }
        });
    };
};
const onFetchCountriesSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return {
            countries: data
        };
    });
};

export const createFilter = (filter) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/filters/',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filter),
                types: [
                    {
                        type: types.REGISTRATION_FILTER_CREATE_REQUEST,
                    },
                    {
                        type: types.REGISTRATION_FILTER_CREATE_SUCCESS,
                        payload: (action, state, res) => onCreateSuccess(dispatch, res),
                    },
                    {
                        type: types.REGISTRATION_FILTER_CREATE_FAILURE,
                        payload: (action, state, res) => onCreateFailure(dispatch, res),
                    }
                ]
            }
        });
    }
};
const onCreateSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return ({
            id: data.filterId
        })
    });
};

const onCreateFailure = (dispatch, res) => {
    return {
        message: gettext('Error creating filter'),
    };

};

export const updateFilter = (filter) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/filters/${filter.id}/`,
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filter),
                types: [
                    {
                        type: types.REGISTRATION_FILTER_UPDATE_REQUEST,
                    },
                    {
                        type: types.REGISTRATION_FILTER_UPDATE_SUCCESS,
                        payload: (action, state, res) => onUpdateSuccess(dispatch, res),
                    },
                    {
                        type: types.REGISTRATION_FILTER_UPDATE_FAILURE,
                    }
                ]
            }
        });
    }
};
const onUpdateSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return ({
            id: data.filterId
        })
    });
};

export const currentStepUpdate = (number) => {
    return {
        type: types.REGISTRATION_UPDATE_CURRENT_STEP,
        payload: {
            number,
        }
    };
};

export const submitPersonalInfo = (registrationId, data) => {
    return dispatch => {
        const body = {
            step: 3,
            title: data.title,
            first_name: data.first_name,
            last_name: data.last_name,
            telephone: data.telephone,
            mobile: data.mobile,
        };
        return dispatch(submitRegistration(registrationId, 2, body));
    }
};
export const submitCompanyInfo = (registrationId, data) => {
    return dispatch => {
        const body = {
            step: 4,
            company_name: data.company_name,
            vat_number: data.vat_number,
            address: data.address,
            country_code: data.country_code,
            post_code: data.post_code,
            city: data.city,
            logistics_email: data.logistics_email || '',
            volume_year: (data.volume_year !== "") ? data.volume_year : 0,
        };
        return dispatch(submitRegistration(registrationId, 3, body));
    }
};
export const submitLegalDocuments = (registrationId, documents) => {
    return dispatch => {

        let body = {step: 5};
        /* Format body to this structure */
        /*
         step: 5,
         document_typeA: 0,
         document_typeB: 2,
         ....
         */

        keys(documents).map((key) => {
                const documentKey = `document_${key}`;
                return body = {...body, [documentKey]: documents[key].document_id}
            }
        );

        return dispatch(submitRegistration(registrationId, 4, body));
    }
};
export const submitSettings = (registrationId, data) => {
    return dispatch => {
        const body = {
            step: 6,
            sms_active: data.sms_active,
            car_by_car_notification: data.car_by_car_notification,
            car_by_car_mail: data.car_by_car_mail,
            daily_digest: data.daily_digest_mail,
            won_mail: data.won_mail || false,
            offers_mail: data.offers_mail || false,
            no_mail: false,
        };

        return dispatch(submitRegistration(registrationId, 5, body));
    }
};
export const submitFilter = (registrationId, filterId) => {
    return dispatch => {
        const body = {
            step: 7,
            filter_id: filterId,
        };
        return dispatch(submitRegistration(registrationId, 6, body));
    }
};
export const submitFilterCompanies = (registrationId, presentation_text) => {
    return dispatch => {
        const body = {
            step: 100,
            presentation_text: presentation_text,
        };
        return dispatch(submitRegistration(registrationId, 7, body));
    }
};

const submitRegistration = (registrationId, step, body) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/registrations/${registrationId}/`,
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
                types: [
                    {
                        type: types.REGISTRATION_POST_REQUEST,
                        payload: {
                            id: step,
                        }
                    },
                    {
                        type: types.REGISTRATION_POST_SUCCESS,
                        payload: (action, state, res) => onSubmitRegistrationSuccess(dispatch, res, step, registrationId),

                    },
                    {
                        type: types.REGISTRATION_POST_FAILURE,
                        payload: (action, state, res) => {
                            return {
                                id: step,
                                message: `${res.status} - ${res.statusText}`,
                            }
                        }
                    }
                ]
            }
        });
    }
};
const onSubmitRegistrationSuccess = (dispatch, res, step, registrationId) => {
    return res.json().then(data => {
        if (data.result === 'Ok' && step === config.registration_steps.length && step !== 7) {
            //confirm everyting, all steps are complete only for vdfin ( no filter steps)
            const body = {
                step: 100,
            };
            dispatch(submitRegistration(registrationId, step, body));
        }
        if (data.result === 'Ok' && step <= 7) {
            dispatch(currentStepUpdate(step + 1));
        }
        return {
            id: step,
        }
    });
};

export const uploadDocumentsAndSubmit = (registrationId, documents) => {
    return (dispatch, getState) => {

        /* Dispatch action for each document */
        let promises = [];
        keys(documents).map((key) => promises.push(dispatch(uploadDocument(key, documents[key]))));

        return Promise.all(promises).then(() => {
            return dispatch(submitLegalDocuments(registrationId, getDocuments(getState())));
        });
    }
};
const uploadDocument = (key, document) => {
    return dispatch => {

        const frmData = new FormData();
        frmData.append("document", document, document.name);


        return dispatch({
            [RSAA]: {
                endpoint: 'manager/api/v1/sync/request/document/',
                //endpoint: 'https://qa.autralis.center/manager/api/v1/sync/request/document/',
                method: 'POST',
                headers: {
                    'Authorization': true,
                },
                body: frmData,
                types: [
                    {
                        type: types.REGISTRATION_DOCUMENT_UPLOAD_REQUEST,
                        payload: {
                            key,
                        }
                    },
                    {
                        type: types.REGISTRATION_DOCUMENT_UPLOAD_SUCCESS,
                        payload: (action, state, res) => onFileUploadSuccess(dispatch, res, key),

                    },
                    {
                        type: types.REGISTRATION_DOCUMENT_UPLOAD_FAILURE,
                        payload: (action, state, res) => onFail(dispatch, res, key),
                    }
                ]
            },
        });
    }
};
const onFileUploadSuccess = (dispatch, res, key) => {
    return res.json().then(data => {
        return {
            key,
            document_id: data.document_id,
        }
    });
};
const onFail = (dispatch, res, key) => {
    return res.json().then(data => {
        return {
            key,
        }
    });
};

export const sendActivationMail = (registrationId) => {
    return dispatch => {
        const body = {
            step: 99,
        };
        return dispatch(submitRegistration(registrationId, 99, body));
    }
};

export const createFilterAndSubmit = (registrationId, filter) => {
    return (dispatch, getState) => {
        return dispatch(createFilter(filter)).then(() => {
            const createSuccess = getCreateFilterSuccess(getState());
            if (createSuccess) {
                return Promise.all([
                    dispatch(submitFilter(registrationId, getCreatedFilterId(getState()))),
                    dispatch(fetchFilters()),
                ]);
            }
            //return dispatch(submitFilter(registrationId, getCreatedFilterId(getState())))
        })
    }
};

export const updateFilterAndSubmit = (registrationId, filter) => {
    return (dispatch, getState) => {
        return dispatch(updateFilter(filter)).then(() => {
            const updateSuccess = getUpdateFilterSuccess(getState());
            if (updateSuccess) {
                return Promise.all([
                    dispatch(submitFilter(registrationId, getUpdatedFilterId(getState()))),
                    dispatch(fetchFilters()),
                ]);
            }
            //return dispatch(submitFilter(registrationId, getUpdatedFilterId(getState())))
        })
    }
};

export const updateFilterCompaniesAndSubmit = (registrationId, presentation_text, filter) => {
    return (dispatch, getState) => {
        return dispatch(updateFilter(filter)).then(() => {
            const updateSuccess = getUpdateFilterSuccess(getState());
            if (updateSuccess) {
                return Promise.all([
                    dispatch(submitFilterCompanies(registrationId, presentation_text)),
                ]);
            }
            return dispatch(submitFilterCompanies(registrationId))
        })
    }
};







