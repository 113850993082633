export const listVehicleStateTypes = {
    FETCH_REQUEST: 'LISTVEHICLE_STATE_FETCH_REQUEST',
    FETCH_SUCCESS: 'LISTVEHICLE_STATE_FETCH_SUCCESS',
    FETCH_FAILURE: 'LISTVEHICLE_STATE_FETCH_FAILURE',

    FETCH_BASKET_REQUEST: 'LISTVEHICLE_FETCH_BASKET_REQUEST',
    FETCH_BASKET_SUCCESS: 'LISTVEHICLE_FETCH_BASKET_SUCCESS',
    FETCH_BASKET_FAILURE: 'LISTVEHICLE_FETCH_BASKET_FAILURE',

    CLOSED: 'LISTVEHICLE_STATE_CLOSED',
    CLEANUP: 'LISTVEHICLE_STATE_CLEANUP',
};


