import {RSAA} from 'redux-api-middleware';
import {types} from './types';
import {gettext} from '../../translations';

export const register = (data) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/registrations/',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': false,
                },
                body: JSON.stringify(data),
                types: [
                    {
                        type: types.POST_REQUEST,
                    },
                    {
                        type: types.POST_SUCCESS,
                        payload: (action, state, res) => onSuccess(dispatch, res),
                    },
                    {
                        type: types.POST_FAILURE,
                        payload: (action, state, res) => onError(dispatch, res),
                    }
                ]
            }
        });
    }
};

const onSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return {
            success: data.result === 'Ok',
            registration_id: data.id,
        };
    });
};


const onError = (dispatch, res) => {
    return res.json().then(data => {
        let message = gettext('An error occured');
        let code = 400;

        if (data[0] && data[0].email) {
            message = gettext('An account with this e-mail address already exists.');
            code = 409;
        }
        return {
            message,
            code,
        };
    });
};


