import React from 'react';
import {gettext} from '../../../translations';
import ConfirmHeader from './ConfirmHeader';
import {Content, LegalTypes} from '../../legal';

const CartTermsConditions = ({onCancel, onConfirm}) => (
    <div className="row bg-normal g-ma-0 g-mt-70 g-mb-200">

        <ConfirmHeader cancelBtnText={gettext('Cancel')}
                       nextBtnText={gettext('I accept & confim')}
                       title={`02. ${gettext('Terms & conditions.')}`}
                       onCancel={onCancel}
                       onNext={onConfirm}
        />

        <div className="g-bg-white content container-semiboxed">
            <Content id={LegalTypes.BIDDING_CONFIRM_CONDITIONS}/>
        </div>

    </div>
)

export default CartTermsConditions;








