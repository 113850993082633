import {types} from './types';

export const detailReducer = (state = {loading: false, error: '', data: null}, action) => {
    switch (action.type) {
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                data: action.payload,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        case types.CLEANUP:
            return{
                loading: false,
                error: '',
                data: null,
            };
        default:
            return state;
    }
};

