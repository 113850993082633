import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Template} from '../../core';
import {getUserState} from '../../../components/session/index';
import {InfoContainer} from '../../core';
import {logout} from '../../../components/authentication';
import {gettext} from '../../../translations';

class UserNotAllowed extends Component {
    constructor(props){
        super(props);

        if(props.message === "") {

            props.logout();
        }
    }
    render() {
        const {message, logout} = this.props;

        return (
            <Template>
                <div className="bg-normal text-center g-ma-0 g-pa-0 g-mt-70 g-mb-200">

                    <InfoContainer text={message} icon='fa fa-hourglass-half'/>
                    <button onClick={() => logout()}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-mt-15">
                        <span>{gettext('Logout')}</span>
                    </button>
                </div>
            </Template>
        )
    }
}

const mapStateToProps = (state) => {
    const userState = getUserState(state);

    return {
        message: (userState.message !== null && userState.message !== undefined) ? userState.message : 'Something went wrong'
    };
};

export default connect(mapStateToProps, {logout})(UserNotAllowed);

