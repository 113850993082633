import React from 'react';
import {gettext} from '../../../../translations';
import {InfoContainer} from '../../../core';
import {withConfig} from '../../../../config';
import ContactInfo from'./ContactInfo';

class WizardActivateMail extends React.Component {
    render() {
        const {onSendActivationMail, step, totalSteps, config} = this.props;
        const {registration_steps} = config;

        const text = (registration_steps.includes(6) && registration_steps.includes(7)) ? gettext('Then you can provide your personal and company data and set your preferences regarding dealers.') : gettext('Then you can provide your personal and company data.');

        return (
            <div className="g-pa-0 g-ma-0 g-mb-20">
                <div className="row g-bg-white  g-pa-0 g-ma-0 g-pt-20 g-pb-0 g-pb-20">

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-lg-up g-pb-10">
                        <h5 className="g-color-primary g-mb-0 g-pb-0">{step}/{totalSteps} {gettext('E-mail confirmation')}</h5>
                        <h6 className="g-pt-2">{gettext('Once you confirm your e-mail address, you can complete the registration.')}</h6>
                        <p className="g-pt-8-5">{gettext('Check your mailbox and follow the instructions in the confirmation e-mail.') + ' ' + text}</p>

                        <ContactInfo />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-md-down">
                        <h5 className="g-color-primary">{gettext('E-mail confirmation')}</h5>
                        <p className="g-pt-10">{gettext('Once you confirm your e-mail address, you can complete the registration.')}</p>
                        <p className="g-pt-10 g-pb-10">{gettext('Check your mailbox and follow the instructions in the confirmation e-mail.') + ' ' + text}</p>

                        <ContactInfo />
                    </div>


                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0 text-center">
                        <InfoContainer icon="fa fa-envelope-open"
                                       text={<h5>{gettext('No mail received?')}</h5>}
                                       container={true}/>

                        <button onClick={onSendActivationMail}
                                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-mt-10">
                            {gettext('Send again')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export
default

withConfig(WizardActivateMail);

