import {connect} from 'react-redux';
import React, {Component} from 'react';
import {compose} from "redux";
import * as selectors from '../selectors';
import {PropagateLoader} from 'react-spinners';
import style from '../../../styles';
import {gettext} from '../../../translations';
import {CircleIcon} from '../../core';
import {withConfig} from '../../../config';

class BidProcessing extends Component {

    constructor(props) {
        super(props);
        const {vehicle_bidding_cart} = props.config;

        this.state = {
            iconSize: (!vehicle_bidding_cart) ? 200 : 80,
            topMargin: (!vehicle_bidding_cart) ? 40 : 20
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.visible && !nextProps.visible) {
            this.props.onClose();
        }
    }

    renderProcessing() {
        return (
            <div className="row justify-content-center" style={{marginTop: this.state.topMargin}}>
                <CircleIcon icon="fa fa-gavel" size={this.state.iconSize}/>

                <div className="row justify-content-center g-width-100vw text-center g-mt-40">
                    <PropagateLoader loading={this.props.loading}
                                     color={style.highlightColor}
                    />
                </div>

                <div className="g-width-100vw g-mt-40 text-center">{gettext('Processing bid')}</div>
                <div className="g-width-100vw text-center">{gettext('Please wait') + '...'}</div>
            </div>
        );
    }

    renderResult() {
        const {message} = this.props;
        return (
            <div className="align-content-end g-bg-white">
                <div className="row justify-content-center" style={{marginTop: this.state.topMargin}}>
                    <CircleIcon icon="fa fa-warning" size={this.state.iconSize}/>
                    <div className="g-mt-40 text-center g-pa-20">{message}</div>
                </div>

                <div className="row justify-content-center g-pa-0 g-ma-0 g-mr-10 g-pt-10 g-mt-25">
                    <button onClick={this.props.onReview}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-500 g-font-size-14 rounded-0 g-pa-8">
                        {gettext('Review')}
                    </button>

                    <button className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-500 g-font-size-14 rounded-0 g-pa-8 g-ml-8"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.onClose();
                            }}>
                        {gettext('Cancel')}
                    </button>
                </div>
            </div>
        )
    }

    renderError() {
        const {error} = this.props;
        return (
            <div className="align-content-end">
                <div className="row justify-content-center" style={{marginTop: this.state.topMargin}}>
                    <CircleIcon icon="fa fa-bolt" size={this.state.iconSize}/>
                    <div className="g-width-100vw g-mt-40 text-center">{error.message}</div>
                </div>

                <div className="row justify-content-center g-pa-0 g-ma-0 g-mt-120 g-mr-10 g-mt-25">
                    <button onClick={this.props.onReview}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-500 g-font-size-14 rounded-0 g-pa-8">
                        {gettext('Try again')}
                    </button>

                    <button onClick={this.props.onClose}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-500 g-font-size-14 rounded-0 g-pa-8 g-ml-8">
                        {gettext('Cancel')}
                    </button>
                </div>
            </div>

        )
    }

    render() {
        const {visible, showProcessing, showResult, showError} = this.props;

        if (!visible) {
            return <div></div>
        }
        return (
            <div className="g-pos-abs g-bg-white g-top-0 g-bottom-0 g-left-0 g-right-0 g-brd-white"
                 style={{marginTop: (!showProcessing && !showResult && !showError) ? 40 : 0}}>
                {showProcessing && this.renderProcessing()}
                {showResult && this.renderResult()}
                {showError && this.renderError()}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const loading = selectors.getLoading(state);
    const accepted = selectors.getAccepted(state);
    const message = selectors.getMessage(state);
    const error = selectors.getError(state);

    return {
        visible: loading || message !== '' || error !== '',
        showProcessing: loading,
        showResult: (!accepted && message !== ''),
        accepted,
        message,
        showError: (error !== ''),
        error,

    };
};

export default compose(
    withConfig,
    connect(mapStateToProps)
)(BidProcessing)


