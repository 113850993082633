import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {validators} from '../../core/index';
import {gettext} from '../../../translations';
import {LoadingButton} from '../../core';

export const resetPasswordFields = {
    PASSWORD: 'password',
    PASSWORD_CONFIRM: 'password_confirm',
};


const renderField = ({input, label, placeholder, type, meta: {touched, error, warning}}) => (
    <div className={`form-group ${ (touched && error) ? 'u-has-error-v1' : ''} mb-4`}>
        <input {...input}
               placeholder={placeholder}
               type={type}
               className="form-control rounded g-py-15 g-px-15"
        />
        {touched && ((error && <small class="form-control-feedback">{error}</small>) || (warning &&
            <span>{warning}</span>))}
    </div>
);


const validate = values => {
    const errors = {};
    if (values.password !== values.password_confirm) {
        errors.password_confirm = 'Passwords are not equal';
    }
    return errors;
};



const ResetPasswordForm = (props) => {
    const {handleSubmit, loading} = props;
    return (
        <form className="g-py-15" onSubmit={handleSubmit}>

            <Field name={resetPasswordFields.PASSWORD}
                   type="password"
                   component={renderField}
                   label={gettext("Password") + ':'}
                   placeholder="********"
                   validate={[validators.required, validators.minLength6, validators.hasNumber,validators.hasLowerCase, validators.hasUpperCase]}
            />

            <Field name={resetPasswordFields.PASSWORD_CONFIRM}
                   type="password"
                   component={renderField}
                   label={gettext("Confirm password") + ':'}
                   placeholder="********"
                   validate={[validators.required, validators.minValue6, validators.hasNumber, validators.hasLowerCase, validators.hasUpperCase]}
            />

            <LoadingButton loading={loading}
                           text={gettext('Send')}
            />

        </form>
    )
};

export default reduxForm({
    form: 'password-set',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
})(ResetPasswordForm)

