import React from 'react';

export const LoadingContainer = ({loading, children}) =>
    <div className="g-ma-0 g-pa-0">
        {loading &&
        <div className="loadingContainerOverlay g-pos-abs"/>}

        <div>
            {children}
        </div>
    </div>


