import {find, orderBy} from 'lodash';
import {vehicleSorters, historySorters} from './sorters'
import {gettext} from '../../translations';

export const getSortById = (sorters, id) => {
   return find(sorters, {id});
};

export const getListVehicleSorters = () => orderBy(vehicleSorters.map((sorter) => ({
    ...sorter,
    label: gettext(sorter.label),
})), 'label');


export const getHistorySorters = () => orderBy(historySorters.map((sorter) => ({
    ...sorter,
    label: gettext(sorter.label),
})), 'label');
