import React from 'react';
import {connect} from 'react-redux';
import * as selectors from '../selectors';
import {fetchContent} from '../actions';
import {LoadingIndicator} from '../../core';

class Content extends React.Component {

    componentDidMount() {
        const {fetchContent, id} = this.props;
        fetchContent(id);
    }

    render() {
        const {loading, error, content} = this.props;

        if(loading){
            return <LoadingIndicator loading={loading}/>
        }

        if(error){
            return <div>{error.message}</div>
        }

        return <div className="g-pa-10  g-mb-20" dangerouslySetInnerHTML={{__html: content}}/>
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        content: selectors.getContent(state, ownProps.id),
        loading: selectors.getLoading(state, ownProps.id),
        error: selectors.getError(state, ownProps.id),
    }
};

export default connect(mapStateToProps, {fetchContent})(Content);
