import {RSAA} from 'redux-api-middleware';
import {types} from './types';

export const activate = (registrationId, uniqueId) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/registrations/${registrationId}/`,
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': false,
                },
                body: JSON.stringify({
                    step: 2,
                    unique_id: uniqueId,
                }),
                types: [
                    {
                        type: types.ACTIVATION_REQUEST,
                    },
                    {
                        type: types.ACTIVATION_SUCCESS,
                        payload: (action, state, res) => onActivateSuccess(dispatch, res),

                    },
                    {
                        type: types.ACTIVATION_FAILURE,
                    }
                ]
            }
        });
    }
};

const onActivateSuccess = (dispatch, res) => {
    return res.json().then(response => {
        if (response.result === 'Ok') {
            return {
                activated: true,
                token: response.data.token,
            }
        } else if (response.result === 'NOk') {
            return {
                activated: true,
                token: null,
            }
        }

    });
};
