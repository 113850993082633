import {approval_check_types} from './types';

const approval_check_reducer = (state = {approvalCount: null }, action) => {
    switch (action.type) {
        case approval_check_types.FETCH_REQUEST:
            return {
                ...state,
                approvalCount: null,
            };
        case approval_check_types.FETCH_SUCCESS:
            return {
                ...state,
                approvalCount: action.payload.approvalCount,
            };
        case approval_check_types.FETCH_FAILURE:
            return {
                ...state,
                approvalCount: null,
            };
        default:
            return state;
    }
};

export default approval_check_reducer;