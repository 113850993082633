import React from 'react';
import {config} from './index';

function withConfig(WrappedComponent) {
    return class extends React.Component {
        render() {
            return <WrappedComponent config={config} {...this.props} />;
        }
    };
}

export default withConfig;