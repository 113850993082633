import React from 'react';
import {Tooltip} from 'reactstrap';

class ToolTipComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
        };

        this.onClick = this.onClick.bind(this);
        this.hide = this.hide.bind(this);
        this.toggle = this.toggle.bind(this);

    }

    onClick(e) {
        e.stopPropagation();
        this.setState({
            tooltipOpen: true,
        });
    }

    hide() {
        if (this.state.tooltipOpen) {
            this.setState({
                tooltipOpen: false,
            });
        }
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });

    }

    render() {
        const {text, name} = this.props;
        const uniqueId = `Tooltip_${name}`;

        return (
            <React.Fragment>
                <i id={uniqueId}  className="fa fa-info-circle g-ml-10 g-font-size-20" />
                <Tooltip target={uniqueId}
                         isOpen={this.state.tooltipOpen}
                         placement={this.props.toolTipLocation}
                         toggle={this.toggle}
                         delay={{show: 50, hide: 0}}
                >
                    {text}
                </Tooltip>
            </React.Fragment>
        )
    }
}


export default ToolTipComponent;
