import React from 'react';
import {render} from 'react-dom';
import App from './_web/App';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {store, persistor} from './store';
import Raven from 'raven-js';
import {getParamValue} from './helpers/functions';
import NotSupported from './components/core/components/web/not-supported/NotSupported';
import {LoadingIndicator} from './components/core';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/vendor/icon-awesome/css/font-awesome.min.css";
import "./assets/vendor/autralis/icons.css";
import "./assets/vendor/icon-line/css/simple-line-icons.css";
import "./assets/vendor/icon-line-pro/style.css";
import "./assets/vendor/icon-line/css/simple-line-icons.css";
import "./assets/vendor/icon-hs/style.css";
import "./assets/vendor/animate.css";
import "./assets/vendor/hs-megamenu/src/hs.megamenu.css";
import "./assets/vendor/hamburgers/hamburgers.min.css";
import "./assets/vendor/jquery.filer/css/jquery.filer.css";
import "./assets/vendor/chosen/chosen.css";
import "./assets/css/unify-components.css";
import "./assets/css/unify.css";
import 'react-photoswipe/lib/photoswipe.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-select/dist/react-select.css';
import './components/core/components/web/not-supported/style.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "./assets/css/custom.css";
import "./components/statistics/custom.css";
import * as serviceWorker from './serviceWorker';
import {VersionTypes} from './components/version';
const {detect} = require('detect-browser');
const browser = detect();

class WebContainer extends React.Component {

    render() {
        let supported = false;

        switch (browser && browser.name) {
            case 'chrome':
            case 'firefox':
            case 'edge':
            case 'safari':
            case 'ios':
            case 'crios':
                supported = true;
                break;
            default:
                supported = false;
        }

        if (!supported) {
            return <NotSupported name={browser.name}/>
        }

        let locale = '';

        try {
            if (window !== undefined &&
                window.location !== undefined &&
                window.location.href !== undefined &&
                window.location.href.includes('culture=')) {
                locale = getParamValue(window.location.href, 'culture');
            }
        } catch (ex) {

        }

        return (
            <Provider store={store}>
                <PersistGate loading={<LoadingIndicator loading={true}/>}
                             persistor={persistor}>
                    <App locale={locale}/>
                </PersistGate>
            </Provider>
        )
    }
}

if (process.env.NODE_ENV !== "development") {
    Raven.config('https://2da9b081715e4d6387b3b4086405cba2@sentry.autralis.com/21').install()
}

render(<WebContainer style={{flex: 1}}/>, document.getElementById('app'));

// serviceWorker.register({
//     onSuccess: () => store.dispatch({type: VersionTypes.SW_INIT}),
//     onUpdate: (registration) => store.dispatch({type: VersionTypes.SW_UPDATE, payload: {bla:'bla', registration: registration}}),
//     onWaiting: (registration) => store.dispatch({type: VersionTypes.SW_UPDATE, payload: {bla:'bla', registration: registration}}),
// });
