import React from 'react';
import _ from 'lodash';

const Features = ({vehicle}) => {

    const featureGroups = _.sortBy(vehicle.featureGroups, 'name');
    const features = _.sortBy(vehicle.features, 'name');
    const featuresText = _.sortBy(vehicle.featuresText, 'key');

    return (
        <React.Fragment>
            <div className="row g-pa-0 g-ma-0">
                {featureGroups.map((group, i_feature_group) =>
                    <div className="col-12 g-pa-0 g-mb-10" key={`feature-group-${i_feature_group}`}>
                        <h5 className="h5 g-mb-0 g-pb-0">{group.name}</h5>
                        <div id="vehicleFeatures" className="multicolumn">
                            {_.map(_.filter(features, (feature) => feature.group === group.id), (feature, i_feature) =>
                                <div className=" g-color-gray-dark-v4"
                                     key={`feature-${i_feature}`}>
                                    <i className={`${feature.icon} g-pr-10 g-font-size-16`}/>
                                    {feature.name}
                                </div>)}
                        </div>
                    </div>
                )}
            </div>
            <div className="row g-pa-0 g-ma-0">
                <div className="col-12 g-pa-0 g-mb-10">
                    <div id="vehicleFeatures" className="multicolumn">
                        {_.map(featuresText, (feature, i_feature) =>
                            <div className=" g-color-gray-dark-v4 text-wrap"
                                 key={`feature-${i_feature}`}>
                                <span className="g-font-weight-600">{feature.key}:</span> {feature.description}
                            </div>)}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};


export default Features;




