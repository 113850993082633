import {reset_pw_types} from '../components/reset-password';
import {reset_pw_request_types} from '../components/reset-password-request';

import {login} from '../_web/navigation/routes';


export const navigationMiddleware = ({dispatch}) => next => action => {

    if (action.type === reset_pw_types.RESET_PASSWORD_SUCCESS ||
        action.type === reset_pw_request_types.RESET_PASSWORD_REQ_SUCCESS) {

        if (action.payload.success) {
            login();
        }

    }

    return next(action);


};