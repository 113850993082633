import {RSAA} from 'redux-api-middleware';
import {userDeviceInfoTypes} from './types';

export const userDeviceInfoSend = (data) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/device/',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                types: [
                    {
                        type: userDeviceInfoTypes.POST_REQUEST,
                    },
                    {
                        type: userDeviceInfoTypes.POST_SUCCESS,
                    },
                    {
                        type: userDeviceInfoTypes.POST_FAILURE,
                    }
                ]
            }
        });
    }
};
