import React, {Component} from 'react';
import './Rating.css';

export default class Rating extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rating: this.props.rating || null,
            temp_rating: null
        }

        this.rate = this.rate.bind(this);
        this.star_over = this.star_over.bind(this);
        this.star_out = this.star_out.bind(this);
    }

    rate(rating) {
        this.setState({
            rating: rating,
            temp_rating: rating
        });

        this.props.onChange(rating + 1);
    }

    star_over(rating) {
        this.setState({
            rating: rating,
            temp_rating: this.state.rating
        });

    }

    star_out() {
        this.setState({rating: this.state.temp_rating});
    }

    render() {
        let stars = [];

        for (let i = 0; i < 5; i++) {
            let rateClass = 'rating-star';

            if (this.state.rating >= i && this.state.rating != null) {
                rateClass += ' star-selected';
            }

            stars.push(
                <label key={i}
                       className={rateClass}
                       onClick={this.rate.bind(this, i)}
                       onMouseOver={this.star_over.bind(this, i)}
                       onMouseOut={this.star_out}>
                    ★
                </label>
            );
        }

        return (
            <div className="star-rating">
                {stars}
            </div>
        );
    }
}
