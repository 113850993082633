import React from 'react';
import {connect} from 'react-redux';
import * as selectors from '../selectors';
import {gettext} from '../../../translations';
import {toggleConfirm} from '../actions';

const ConfirmReminder = ({count, style}) => {
    if (count <= 0) return null;

    return (
        <div style={style}>
            <div className="row g-pa-0 g-ma-0 container-important">
                <div className="veh-description-important g-ma-0 g-pa-5">
                    <i className="fa fa-warning veh-description-icon-important g-font-size-18"/>
                </div>
                <div className="col g-ma-0 g-pa-5 g-font-weight-500">
                    {gettext('Don’t forget to check out.')}
                </div>
            </div>
        </div>

    );
};


const mapStateToProps = (state, ownProps) => {
    return {
        count: selectors.getBiddingsToConfirmCount(state, ownProps.listId),
    }
};

export default connect(mapStateToProps, {toggleConfirm})(ConfirmReminder);







