import React from 'react';

const CheckValueBoolean = ({value}) => {
    return (

            <i className={((value === 1) || (value === "1")) ? 'fa fa-check' : 'fa fa-close'}></i>

    )
};


export default CheckValueBoolean;








