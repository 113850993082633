import React, {Component} from 'react';
import {connect} from 'react-redux';
import style from '../../../styles';
import {updateCriteria} from '../actions';
import * as listVehiclesFilterSelectors from '../../listvehicles-filter/selectors';
import * as criteriaHelpers from '../functions/criteria';
import {Tooltip} from 'reactstrap';
import {gettext} from '../../../translations';

class InterestWithNoBid extends Component {
    constructor(props){
        super(props);

        this.state = {
            tooltipOpen: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render(){
        const {listId, criteria, updateCriteria, onInterestWithNoBidChanged} = this.props;
        return (
            <div id="TooltipEye" onClick={() => {
                updateCriteria(listId, criteriaHelpers.toggleInterestWithNoBid(criteria)).then(() => onInterestWithNoBidChanged());
            }}>
                <i className="fa fa-eye-slash g-font-size-25 clickable"
                   style={{color: criteria.interestWithNoBid ? '#86d863' : style.defaultColor}}/>

                <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target="TooltipEye"
                         toggle={this.toggle}>
                    {(criteria.interestWithNoBid) ? gettext('Check ALL the price requests') :  gettext('Check the OPEN price requests') }
                </Tooltip>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        criteria: listVehiclesFilterSelectors.getCriteriaCopy(state, ownProps.listId),
    }
};

export default connect(mapStateToProps, {updateCriteria})(InterestWithNoBid);



