import {WARNINGS} from './types';
import {createLoadingSelector, createErrorMessageSelector} from '../ui';

const loadingSelector = createLoadingSelector([WARNINGS]);
const errorSelector = createErrorMessageSelector([WARNINGS]);

export const getLoading = (state) => {
    return loadingSelector(state)
};

export const getError = (state) => {
    return errorSelector(state)
};

export const getWarnings = (state) =>  state.warnings.data;
