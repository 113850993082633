//Defaults
let locale = 'en';
let dataTranslate = require('./en.json');

export const gettext = (text, constant = false) => {
    if(constant) return text;

    if (dataTranslate && dataTranslate.hasOwnProperty(text)) {
        const temp = dataTranslate[text];
        const result = (temp === null || temp === undefined || temp === '') ? text : temp;

        const translation = result[1];

        return (typeof translation === 'string' && typeof translation !== undefined && translation !== "") ? translation : text;
    }
    return text;
};

export const setLocale = (value) => {
    locale = value;

    switch (locale) {
        case 'nl':
            dataTranslate = require('./nl.json');
            break;
        case 'fr':
            dataTranslate = require('./fr.json');
            break;
        case 'de':
            dataTranslate = require('./de.json');
            break;
        default:
            dataTranslate = require('./en.json');
            break;
    }
};
