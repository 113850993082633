import {historyAndStateTypes} from './types';
import {entityActions} from '../../entities/actions';
import * as basketSelectors from '../basket/selectors';
import {listVehiclesStateCleanUp} from '../listvehiclestate';
import {basketCleanUp} from '../basket/actions';
import {listVehiclesFilterCleanUp} from '../listvehicles-filter/actions'

export const historyVehicleSelect = (listVehicleId, stateFullScreen = false) => {
    return {
        type: historyAndStateTypes.VEHICLE_SELECT,
        listVehicleId,
        stateVisible: stateFullScreen
    }
};

export const toggleVehicleState = () => {
    return {
        type: historyAndStateTypes.TOGGLE_STATE,
    }
};


export const historyVehiclesAndStateCleanUp = () => {
    return (dispatch) => {
        return dispatch({
            type: historyAndStateTypes.CLEANUP,
        });
    }
};


export const historyCleanUp = () => {
    return (dispatch, getState) => {
        const state = getState();

        const vehicleIds = basketSelectors.getIds(state, 'ALL');

        return Promise.all([
            dispatch(basketCleanUp(["ALL"])),
            dispatch(historyVehiclesAndStateCleanUp()),
            dispatch(listVehiclesStateCleanUp(vehicleIds)),
            dispatch(listVehiclesFilterCleanUp([-1])),
            dispatch(entityActions.clearEntities([], vehicleIds)),
        ]);

    }
};