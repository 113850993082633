import React from 'react';
import {Template} from '../components/core/index';
import {Content, LegalTypes} from '../components/legal/index';
import {sessionTermsAndConditionsReviewedUpdate, clearSession} from "../components/session";
import {connect} from "react-redux";
import {gettext} from "../translations";

class TermsAndConditionsReview extends React.Component {

    constructor(props) {
        super(props);
        this.handleAccept = this.handleAccept.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleAccept() {
        const {sessionTermsAndConditionsReviewedUpdate} = this.props;
        sessionTermsAndConditionsReviewedUpdate(true);
    }

    handleCancel() {
        const {clearSession} = this.props;
        clearSession();
    }

    render() {

        return (
            <Template>
                <div className="g-bg-white g-pt-80">
                    <div className="sticky sticky-md-down col-12 g-pa-0 g-ma-0 bg-normal" style={{zIndex: 50}}>
                        <div  className="row g-pa-0 g-ma-0 justify-content-end g-bg-white g-pa-5 g-mb-10  bottom-shadow">
                            <div onClick={this.handleCancel}
                                 className="g-color-white g-bg-red g-bg-red--hover clickable g-font-weight-500 g-px-15 g-py-6 g-mr-10">
                                {gettext('Cancel')}
                            </div>
                            <div onClick={this.handleAccept}
                                 className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6">
                               {gettext('I accept & confim')}
                            </div>
                        </div>
                    </div>

                    <div className="g-bg-white content container-semiboxed">
                        <Content id={LegalTypes.CONDITIONS}/>
                    </div>
                </div>
            </Template>
        )
    }
}

export default connect(null, {sessionTermsAndConditionsReviewedUpdate, clearSession})(TermsAndConditionsReview);


