import React from 'react';
import {SearchableList} from '../../core'
import {addMakes, removeMakes} from '../functions/makes';
import {gettext} from '../../../translations';
import {includes} from 'lodash';

class Makes extends React.Component {
    constructor(props) {
        super(props);

        this.renderItem = this.renderItem.bind(this);
        this.addMakes = this.addMakes.bind(this);
        this.removeMakes = this.removeMakes.bind(this);
    }

    addRemoveMake(id) {
        if (includes(this.props.filter.makes, id)) {
            this.removeMakes([id]);
        }
        else {
            this.addMakes([id]);
        }
    }

    removeMakes(makes) {
        this.props.onChange(removeMakes(this.props.filter, makes));
    }

    addMakes(makes) {
        this.props.onChange(addMakes(this.props.filter, makes));
    }

    renderItem = ({id, data}) => (
        <div key={id} className="col-4 g-mt-5 g-mb-5">

            <label className="form-check-inline u-check g-pl-25">
                <input type="checkbox"
                       checked={includes(this.props.filter.makes, id)}
                       onChange={() => this.addRemoveMake(id)}
                       className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                />

                <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                    <i className="fa" data-check-icon=""></i>
                </div>
                {data.name}
            </label>
        </div>
    );

    render() {
        const data = this.props.makes.map((make) => ({id: make.id, searchTerms: [make.name], data: make}));
        return (
            <SearchableList data={data}
                            renderItem={this.renderItem}
                            onSelectAll={this.addMakes}
                            onDeSelectAll={this.removeMakes}
                            placeholder={gettext("Search make") + ' ...'}
            />
        )
    }
}


export default Makes;
