import {listVehiclesAndStateTypes,} from './types';
import {listVehicleTypes} from '../listvehicles/types';

export const listVehiclesAndState = (state = {selectedId: 0, vehicleStateVisible: false}, action) => {
    switch (action.type) {
        case listVehiclesAndStateTypes.VEHICLE_SELECT:
            return {
                ...state,
                selectedId: action.listVehicleId,
                vehicleStateVisible: action.stateVisible,
            };

        case listVehiclesAndStateTypes.TOGGLE_STATE:
            return {
                ...state,
                vehicleStateVisible: !state.vehicleStateVisible,
            };
        case listVehicleTypes.FETCH_SUCCESS:
            const {ids, offset} = action.payload;
            return {
                ...state,
                selectedId: (state.selectedId === 0) ?  ids.length > 0 ? offset === 0 ? ids[0] : state.selectedId : 0 : state.selectedId,
            };
        case listVehiclesAndStateTypes.CLEANUP:
            return {
                selectedId: 0,
                vehicleStateVisible: false,
            }
        default:
            return state;
    }
};

export default listVehiclesAndState;