import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form'
import {TextField, Multiline, TextFieldWithNoLabel, Combo, PhoneNumber, CheckBox} from './FormFields';
import {validators} from '../../../../../components/core';
import {capitalize, includes} from 'lodash';
import {gettext} from '../../../../../translations';
import {Colleagues} from '../../../../colleagues';

import {FiltersOverview} from '../../../../filters';
import {compose} from "redux";
import {withConfig} from "../../../../../config";

export const profileFields = {
    PERSONAL_TITLE: 'title',
    PERSONAL_FIRST_NAME: 'first_name',
    PERSONAL_LAST_NAME: 'last_name',
    PERSONAL_MAIL: 'email',
    PERSONAL_PHONE: 'telephone',
    PERSONAL_MOBILE: 'mobile',

    COMPANY_NAME: 'company_name',
    COMPANY_VAT: 'vat_number',
    COMPANY_ADDRESS: 'address',
    COMPANY_COUNTRY: 'country_code',
    COMPANY_POSTAL: 'post_code',
    COMPANY_CITY: 'city',
    COMPANY_MAIL: 'logistics_email',

    SETTINGS_CAR_BY_CAR_NOTIFICATION: 'car_by_car_notification',
    SETTINGS_CAR_BY_CAR_MAIL: 'car_by_car_mail',
    SETTINGS_DAILY_DIGEST_MAIL: 'daily_digest_mail',
    SETTINGS_OFFERS_MAIL: 'offers_mail',
    SETTINGS_WON_MAIL: 'won_mail',

};

const validate = (values, props) => {
    const {required} = props;
    const errors = {};

    if (!required)
        return errors;

    const phoneError = validators.required(values.telephone) || validators.minLength10(values.telephone);
    const mobileError = validators.required(values.mobile) || validators.minLength10(values.mobile);

    if (phoneError !== undefined && mobileError !== undefined) {

        errors.telephone = gettext('Telephone or mobile is required');
        errors.mobile = gettext('Telephone or mobile is required');
    }
    return errors;
};

class ProfileForm extends Component {
    render() {
        const {initialValues, countries, required, config} = this.props;

        return (
            <form initialvalues={initialValues}
                  onSubmit={this.props.handleSubmit}>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">

                        {/*PERSONAL*/}
                        <div className="g-pa-10 g-pl-20 g-bg-white g-mb-10">
                            <div className="u-heading-v3-1 g-mb-20">
                                <h2 className="h5 u-heading-v3__title color-highlight g-brd-primary">{gettext('Personal information')}</h2>
                            </div>

                            <Field name={profileFields.PERSONAL_TITLE}
                                   component={Combo}
                                   label={gettext("Title")}
                                   validate={(required) ? [validators.required] : []}
                                   placeholder={gettext("Title")}
                                   options={[
                                       {value: 'Mr', label: gettext('Mr')},
                                       {value: 'Mrs', label: gettext('Mrs')},
                                       {value: 'Miss', label: gettext('Miss')}
                                   ]}
                            />

                            <Field
                                name={profileFields.PERSONAL_LAST_NAME}
                                component={TextField}
                                type="text"
                                label={gettext("Lastname")}
                                validate={(required) ? [validators.required] : []}
                            />

                            <Field
                                name={profileFields.PERSONAL_FIRST_NAME}
                                component={TextField}
                                type="text"
                                label={gettext("Firstname")}
                                validate={(required) ? [validators.required] : []}
                            />

                            <Field
                                name={profileFields.PERSONAL_MAIL}
                                component={TextField}
                                type="email"
                                label={gettext("E-mail")}
                                disabled={true}
                                validate={(required) ? [validators.required] : []}
                            />

                            <Field
                                name={profileFields.PERSONAL_PHONE}
                                component={PhoneNumber}
                                type="text"
                                label={gettext("Telephone")}
                            />

                            <Field
                                name={profileFields.PERSONAL_MOBILE}
                                component={PhoneNumber}
                                type="text"
                                label={gettext("Mobile")}
                            />
                        </div>


                        {/*COMPANY*/}
                        <div className="g-pa-10 g-pl-20 g-bg-white g-mb-10">
                            <div className="u-heading-v3-1 g-mb-20">
                                <h2 className="h5 u-heading-v3__title color-highlight g-brd-primary">{gettext('Company information')}</h2>
                            </div>

                            <Field name={profileFields.COMPANY_VAT}
                                   component={TextField}
                                   type="text"
                                   label={gettext('VAT number')}
                                   validate={[validators.required]}
                            />

                            <Field name={profileFields.COMPANY_NAME}
                                   component={TextField}
                                   type="text"
                                   label={gettext("Company name")}
                                   validate={[validators.required]}
                            />

                            <Field name={profileFields.COMPANY_ADDRESS}
                                   component={Multiline}
                                   type="text"
                                   label={gettext("Address")}
                                   validate={[validators.required]}
                            />


                            <div className="form-group row g-hidden-sm-down">
                                <label
                                    className="g-min-width-150 col-form-label g-color-gray-dark-v2 g-font-weight-500 text text-right">
                                    {gettext('Postal')}/{gettext('City')}
                                </label>

                                <div className="col">
                                    <div className="row g-pa-0 g-ma-0">
                                        <div className="col-3 g-ma-0 g-pa-0 g-pr-20">
                                            <Field name={profileFields.COMPANY_POSTAL}
                                                   component={TextFieldWithNoLabel}
                                                   type="text"
                                                   label={gettext("Postal")}
                                                   validate={[validators.required]}
                                            />
                                        </div>
                                        <div className="col-9 g-ma-0 g-pa-0">
                                            <Field name={profileFields.COMPANY_CITY}
                                                   component={TextFieldWithNoLabel}
                                                   type="text"
                                                   label={gettext("City")}
                                                   validate={[validators.required]}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="g-hidden-sm-up">
                                <Field name={profileFields.COMPANY_POSTAL}
                                       component={TextField}
                                       type="text"
                                       label={gettext("Postal")}
                                       validate={[validators.required]}
                                />

                                <Field name={profileFields.COMPANY_CITY}
                                       component={TextField}
                                       type="text"
                                       label={gettext("City")}
                                       validate={[validators.required]}
                                />
                            </div>

                            <Field name={profileFields.COMPANY_COUNTRY}
                                   component={Combo}
                                   label={gettext("Country")}
                                   validate={[validators.required]}
                                   placeholder={gettext("Country")}
                                   options={countries.map((country) => ({
                                       value: country.code,
                                       label: capitalize(country.name)
                                   }))}
                            />

                            {config.registration_company_info_logistics_email &&
                            <Field name={profileFields.COMPANY_MAIL}
                                   component={TextField}
                                   type="text"
                                   label={gettext('Logistic e-mail')}
                                   validate={[]}
                            />}

                        </div>

                    </div>


                    {/*SETTINGS*/}
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">


                        {!config.app_excluded_routes.includes('/filters') &&
                        <div className="g-pa-10 g-pl-20 g-bg-white g-mb-10">
                            <div className="u-heading-v3-1 g-mb-20">
                                <h2 className="h5 u-heading-v3__title color-highlight g-brd-primary">{gettext('Filters')}</h2>
                            </div>

                            <FiltersOverview />

                        </div>}
                        <div className="g-pa-10 g-pl-20 g-bg-white g-mb-10">
                            <div className="u-heading-v3-1 g-mb-20">
                                <h2 className="h5 u-heading-v3__title color-highlight g-brd-primary">{gettext('Settings')}</h2>
                            </div>

                            {/*<Field*/}
                            {/*name={profileFields.SETTINGS_SMS_ACTIVE}*/}
                            {/*component={CheckBox}*/}
                            {/*label={gettext("SMS notifications")}*/}
                            {/*/>*/}

                            <Field
                                name={profileFields.SETTINGS_CAR_BY_CAR_NOTIFICATION}
                                component={CheckBox}
                                label={gettext("Car by Car / by smartphone notification")}
                            />


                            <Field
                                name={profileFields.SETTINGS_CAR_BY_CAR_MAIL}
                                component={CheckBox}
                                label={gettext("Car by Car / by e-mail")}

                            />

                            <Field
                                name={profileFields.SETTINGS_DAILY_DIGEST_MAIL}
                                component={CheckBox}
                                label={gettext("Daily digest / by e-mail")}
                            />

                            {!includes(config.excluded_settings, profileFields.SETTINGS_OFFERS_MAIL) &&
                            <Field
                                name={profileFields.SETTINGS_OFFERS_MAIL}
                                component={CheckBox}
                                label={gettext("Bidding confirmation / by e-mail")}
                            />}

                            {!includes(config.excluded_settings, profileFields.SETTINGS_WON_MAIL) &&
                            <Field
                                name={profileFields.SETTINGS_WON_MAIL}
                                component={CheckBox}
                                label={gettext("Highest bid / by e-mail")}
                            />}



                        </div>


                        <div style={{maxHeight: 595}}>

                                    <div className="g-pa-10 g-pl-20 g-bg-white g-mb-10">
                                        <div className="u-heading-v3-1 g-mb-20">
                                            <h2 className="h5 u-heading-v3__title color-highlight g-brd-primary">{gettext('Colleagues')}</h2>
                                        </div>
                                        <Colleagues />
                                    </div>
                        </div>


                    </div>


                </div>
            </form>

        );
    }
}

export default compose(
    withConfig,
    reduxForm({
        form: 'Profile',
        destroyOnUnmount: true,
        forceUnregisterOnUnmount: true,
        validate,
    })
)(ProfileForm);
