import React from 'react';
import {keys, filter, keyBy, capitalize} from 'lodash';

const Tyres = ({vehicle}) => {

    let tyres = filter(keys(vehicle.tyres), (key) => (vehicle.tyres[key].length > 0)).map((tyreGroup) => ({
        [tyreGroup]: keyBy(vehicle.tyres[tyreGroup], 'position_name'),
    }))[0];

    return (
        <div className="row g-pa-0 g-ma-0 g-color-gray-dark-v4">
            {keys(tyres).map((tyreGroup, i_tyre_group) =>
                <React.Fragment key={`group_${i_tyre_group}`}>
                    {keys(tyres[tyreGroup]).map((tyreLocation, i_tyre_group_location) =>

                        <div className="col-6 g-pa-0"
                             key={`group-${i_tyre_group}-location${i_tyre_group_location}`}>
                            <div className="row g-pa-0 g-ma-0">
                                <div className="col-5 g-pa-0">
                                    <span className="g-font-weight-500"> {capitalize(tyreGroup)} </span> <span className="g-hidden-sm-down">-</span> {tyreLocation}
                                </div>
                                <div className="col-7 g-brd-left g-brd-gray-light-v3 g-mb-20">
                                    <div>
                                        {tyres[tyreGroup][tyreLocation].brand}
                                    </div>
                                    <div>
                                        {tyres[tyreGroup][tyreLocation].size}
                                    </div>
                                    <div>
                                        {`${tyres[tyreGroup][tyreLocation].left}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </React.Fragment>
            )}
        </div>
    )
};

export default Tyres;
