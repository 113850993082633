import React from 'react';
import {Template} from '../components/core/index';
import {Unsubscribe} from '../components/unsubscribe';


const Invite = ({match}) =>
    <Template>
        <div className="g-bg-white g-mt-20">
            <section className="container g-py-100 ">
                <Unsubscribe unsubscribeId={match.params.unsubscribeId} />
            </section>
        </div>
    </Template>;

export default Invite ;



