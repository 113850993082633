import {filterTypes, filterCompanyTypes, filterMakeTypes} from './types';
import {combineReducers} from 'redux'

const all = (state = {selectedFilterId: 0, data: [], loading: false, error: '',}, action) => {
    switch (action.type) {
        case filterTypes.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                data: [],
            };
        case filterTypes.FETCH_SUCCESS:
            return  {
                ...state,
                loading: false,
                data: action.payload,
            };
        case filterTypes.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
                data: [],
            };
        default:
            return state;
    }
};

const filter = (state = {action: '', loading: false, error: '', data: null, search_companies: "", search_makes: ""}, action) => {
    switch (action.type) {
        case filterTypes.SELECT_FILTER:
        case filterTypes.UPDATE_FILTER_DATA:
         return {
                ...state,
                data: action.payload.filter,
            };
        case filterTypes.CREATE_REQUEST:
        case filterTypes.UPDATE_REQUEST:
        case filterTypes.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                action: action.payload.action,
            };
        case filterTypes.CREATE_SUCCESS:
        case filterTypes.UPDATE_SUCCESS:
        case filterTypes.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
            };
        case filterTypes.CREATE_FAILURE:
        case filterTypes.UPDATE_FAILURE:
        case filterTypes.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        case filterTypes.SEARCH_MAKES:
            return {
                ...state,
                search_makes: action.payload.searchTerm,
            };
        case filterTypes.SEARCH_COMPANIES:
            return {
                ...state,
                search_companies: action.payload.searchTerm,
            };
        case filterTypes.ERROR_CLEAR:
            return {
                ...state,
                error: '',
            };
        default:
            return state;
    }
};


const companies = (state = {loading: false, error: '', data: []}, action) => {
    switch (action.type) {
        case filterCompanyTypes.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case filterCompanyTypes.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.companies,
            };
        case filterCompanyTypes.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        default:
            return state;
    }
};

const makes = (state = {loading: false, error: '', data: []}, action) => {
    switch (action.type) {
        case filterMakeTypes.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case filterMakeTypes.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case filterMakeTypes.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };

        default:
            return state;
    }
};


export default combineReducers({
    all,
    selected: filter,
    companies,
    makes,
});




