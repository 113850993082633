import React from 'react';
import {connect} from 'react-redux';
import {SignOutButton} from '../../../../authentication';
import * as navigate from '../../../../../_web/navigation/routes';
import {
    isAuthenticated,
    isRegistrationPending,
    getUser,
    isMailActivationPending,
    getUserState
} from '../../../../session';
import {VehicleSearchControl as VehicleSearch, VehicleSearchPopUp} from '../../../../vehicle-search';
import {LanguagePicker} from '../../../../localization';
import {gettext} from '../../../../../translations';
import {setFunctionsLocale} from '../../../../../helpers/functions';
import {ComboMenu} from '../../../../core';
import {withConfig} from '../../../../../config';
import {compose} from "redux";

const Header = ({authenticated, registrationPending, user, userAllowed, locale, containerStyle, config}) =>
    <header className="u-header">
        <div className="stickyHeader"
             data-header-fix-moment-exclude="g-mt-0">

            <div className="g-bg-white g-brd-bottom g-brd-gray-light-v4" style={{minHeight: 60}}>
                <div className={containerStyle}>
                    <div className="row align-items-center justify-content-between g-ma-0 g-pa-0">
                        <div className="col g-ma-0 g-pa-0 d-flex flex-row justify-content-between align-items-center">

                            <div>
                                <a className="navbar-brand d-flex align-items-center g-color-main text-uppercase g-text-underline--none--hover clickable g-pa-5 g-pr-0 g-mr-0"
                                   onClick={() => (authenticated) ? navigate.dashboard() : window.location.assign(config.front_url)} style={{minHeight: 60}}>
                                    {/*<img src={require("../../../../../img/logo_web.png")} alt="Brand"*/}
                                    {/*style={{width: 36, height: 36}}*/}
                                    {/*className="img-fluid"*/}
                                    {/*/>*/}

                                    {/*<span*/}
                                    {/*className="d-block g-hidden-sm-down g-font-weight-600 g-line-height-1_4 g-ml-40"> D'Ieteren<span*/}
                                    {/*className="d-block g-color-text g-font-size-10 text-center">Used Cars</span></span>*/}
                                    {/**/}

                                    <img src={require("../../../../../img/logo_web.png")} alt="Brand" className="img-fluid g-mr-10--md"
                                    />
                                </a>
                            </div>

                            <div className="ml-auto g-pa-5 g-pl-0 g-ma-0 ">
                                <ul className="list-inline float-right mb-0 g-flex-centered  ">

                                    {authenticated && !registrationPending && userAllowed &&
                                    <li className="list-inline-item g-hidden-xs-down">
                                        <VehicleSearch />
                                    </li>
                                    }

                                    {authenticated && !registrationPending && userAllowed &&
                                    <li className="list-inline-item g-hidden-sm-up">
                                        <VehicleSearchPopUp />
                                    </li>
                                    }

                                    {!authenticated && config.dealerships &&
                                    <li className="list-inline-item">
                                        <div className="menu-item g-color-white g-bg-primary text-uppercase g-bg-primary-dark-v1--hover g-font-size-10  g-font-size-13--md  g-font-weight-500 g-px-5 g-px-10--md g-px-15--md g-py-8 g-py-6--md clickable"
                                        onClick={() => window.location.assign(config.admin_url)}>
                                            { gettext("I'm a dealer")}
                                        </div>
                                    </li>
                                    }

                                    <li className="list-inline-item">
                                        <LanguagePicker
                                            onChange={() => {
                                                setFunctionsLocale(locale);
                                                setTimeout(() => navigate.reloadCurrentPage(), 10)
                                            }}/>
                                    </li>

                                    {authenticated &&
                                    <li className="list-inline-item">

                                        <ComboMenu renderTitle={() => <span>
                                            <i className="fa fa-user-o"></i>
                                            <span className="g-ml-10 g-hidden-sm-down">{user.first_name}</span>
                                            <i className="fa fa-angle-down g-ml-5 g-hidden-sm-down"></i>
                                        </span>}>

                                            <h5 className="color-highlight">{user.first_name} {user.last_name}</h5>
                                            <ul className="list-inline mb-0 g-min-width-150 g-color-text">
                                                {!registrationPending &&
                                                <li className="list-inline-item row g-ma-0 g-pa-0 clickable"
                                                    onClick={() => navigate.dashboard()}>
                                                    <i className="fa fa-home g-mr-5 g-width-25 "
                                                       style={{alignSelf: 'center'}}/>
                                                    <span
                                                        className="g-color-primary--hover">{gettext('Home')}</span>
                                                </li>}


                                                {!registrationPending &&
                                                <li className="list-inline-item row g-ma-0 g-pa-0 clickable"
                                                    onClick={() => navigate.profile()}>
                                                    <i className="fa fa-gears g-mr-5 g-width-25 "
                                                       style={{alignSelf: 'center'}}/>
                                                    <span
                                                        className="g-color-primary--hover">{gettext('My profile')}</span>
                                                </li>}
                                                {!registrationPending && !config.app_excluded_routes.includes('/filters') &&
                                                <li className="list-inline-item row g-ma-0 g-pa-0 clickable"
                                                    onClick={() => navigate.filters()}>
                                                    <i className="fa fa-filter g-mr-5 g-width-25 "
                                                       style={{alignSelf: 'center'}}/>
                                                    <span
                                                        className="g-color-primary--hover">{gettext('My filters')}</span>
                                                </li>}
                                                <SignOutButton />
                                            </ul>

                                        </ComboMenu>


                                    </li>
                                    }
                                </ul>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-normal " style={{height: 10}}>

            </div>


        </div>
    </header>


const mapStateToProps = (state) => {
    const userState = getUserState(state);

    return {
        authenticated: isAuthenticated(state), //&& !isRegistrationPending(state) && !isMailActivationPending(state),
        registrationPending: isRegistrationPending(state) || isMailActivationPending(state),
        user: getUser(state),
        userAllowed: (userState !== null) ? !userState.error : false,
    };
};

export default compose(
    withConfig,
    connect(mapStateToProps)
)(Header)



