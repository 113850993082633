import React from 'react';
import {gettext} from '../../../../../translations/index'
import classNames from 'classnames';
import format from 'string-format';

const VehicleDamageBadge = ({damages}) => {
    const count = (typeof damages === "number") ? damages : damages.length;
    const accent = count > 0;
    const title = count > 1 ? gettext('{0} damages') : gettext('{0} damage');
    const text = (accent) ? format(gettext(title), count) : gettext('No damages');

    var spanClass = classNames({
        'u-label': true,
        'g-rounded-3': true,
        'badge': true,
        'badge-normal': !accent,
        'damage-highlight': accent,
        'g-mb-5': true,
    });

    return (
        <span className={spanClass}>
                {text}
        </span>

    )
};

export default VehicleDamageBadge;




