import React from 'react';
import {capitalize} from 'lodash';
import {SpecificationHelpers} from '../../../../index';

const Specifications = ({vehicle, fields}) => (
    <div className="row g-pa-0 g-ma-0">
        {fields.map((field, i_spec) => {
                const data = SpecificationHelpers.getFieldData(field, vehicle);
                const label = SpecificationHelpers.getFieldLabel(field, vehicle);

                return (
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6  g-color-gray-dark-v4"
                         key={`specification-${i_spec}`}>
                        <div className="row">
                            <div className="col-5 g-pl-5 g-pr-5 text-wrap">{capitalize(label)}</div>
                            <div
                                className="col-7 g-pl-5 g-pr-5 align-right g-font-weight-500 text-wrap">{data}</div>
                        </div>
                    </div>
                );
            }
        )}
    </div>
);
export default Specifications;











