import React, {Component} from 'react';


class CollapsePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {collapsed: props.collapsed};

        this.handleCollapse = this.handleCollapse.bind(this);
    }

    handleCollapse() {
        this.props.onClick();
        // this.setState({collapsed: !this.state.collapsed});
    }

    componentWillReceiveProps(nextProps, nextContext) {
       if(nextProps.collapsed !== this.state.collapsed){
           this.setState({collapsed: !this.state.collapsed});
       }
    }

    render() {
        return (
            <React.Fragment>
                <div className="w-100 g-ml-10 g-ml-50--md g-mr-10 g-mr-50--md g-mb-20">
                    <div className='row w-100 g-pa-0 g-ma-0 g-pa-5 g-pl-5 g-pl-25--md g-pr-5 g-pr-25--md text-light-gr g-font-size-14 g-font-size-16--md align-items-center clickable justify-content-between' style={{backgroundColor: '#edf8f5', height: '60px'}}
                         onClick={this.handleCollapse}>
                       {this.props.title}

                        <i className={`${(this.state.collapsed) ? 'fa fa-chevron-right' : 'fa fa-chevron-down'} g-font-size-18 g-font-size-24--md`}/>
                    </div>

                    {!this.state.collapsed &&
                    <div className='row g-pa-0 g-ma-0 w-100'>
                        {this.props.children}
                    </div>}
                </div>
            </React.Fragment>
        )
    }
}

export default CollapsePanel;


