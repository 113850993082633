import React from 'react';
import Modal from 'react-modal';
import FilterDetailsHeader from './FilterDetailsHeader';
import FilterForm from './FilterForm';
import {LoadingContainer, LoadingIndicator} from '../../core/';

export default class FilterModal extends React.Component {

    componentWillMount() {
        Modal.setAppElement('#app');
    }

    render() {
        const {filterLoading, selectedFilter, companies, makes, onChange, onSave, onDelete, onCancel } = this.props;

        return (
            <Modal
                isOpen={this.props.visible}
                onRequestClose={this.props.onCancel}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 500,
                    },
                    content: {
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        //  marginRight: '-50%',
                        paddingTop: 50,
                        paddingBottom: 50,
                        //transform: 'translate(-50%, -50%)',

                    }
                }}
            >

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                    <div className="sticky sticky-md-down bg-normal" style={{zIndex: 50, top: 0}}>
                        <FilterDetailsHeader onSave={onSave}
                                             onDelete={onDelete}
                                             onClose={onCancel}
                        />
                        <LoadingIndicator loading={filterLoading}/>
                    </div>

                    <div className="g-bg-white g-pa-0 g-ma-0  g-pa-10">
                        <LoadingContainer loading={filterLoading}>
                            <FilterForm filter={selectedFilter}
                                        allCompanies={companies}
                                        allMakes={makes}
                                        onChange={onChange}
                            />
                        </LoadingContainer>
                    </div>
                </div>

            </Modal>

        );
    }
}



