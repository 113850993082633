import _ from 'lodash';
import moment from 'moment';

export const getCriteria = (state) => state.basket_filter;
export const getCriteriaCopy = (state) => _.cloneDeep(state.basket_filter);


export const getCriteriaForUI = (state) => {
    const criteria = getCriteria(state);
    let startDate = moment();
    let endDate = moment();
    let dateRange = "";

    if(criteria.startDate && criteria.startDate !== ""){
        startDate = moment(criteria.startDate);
    }

    if(criteria.endDate && criteria.endDate !== ""){
        endDate = moment(criteria.endDate);
    }

    if (criteria.startDate && criteria.startDate !== "" && criteria.endDate && criteria.endDate !== "") {
        dateRange = moment(criteria.startDate).format('DD/MM/YYYY') + ' - ' + moment(criteria.endDate).format('DD/MM/YYYY');
        if (criteria.startDate === criteria.endDate) {
            dateRange = moment(criteria.startDate).format('DD/MM/YYYY');
        }
    }

    return {
        searchTerm: criteria.searchTerm,
        startDate: startDate,
        endDate: endDate,
        dateRange: dateRange,
        state: criteria.state,
    };
};

export const defaultRanges = {
    'This month': [moment().startOf('month'), moment().endOf('month')],
    'This month + 1': [moment().add(1, 'M').startOf('month'), moment().add(1, 'M').endOf('month')],
    'This month + 2': [moment().add(2, 'M').startOf('month'), moment().add(2, 'M').endOf('month')],
    'This month + 3': [moment().add(3, 'M').startOf('month'), moment().add(3, 'M').endOf('month')],
    'This month + 4': [moment().add(4, 'M').startOf('month'), moment().add(4, 'M').endOf('month')],
    'This month + 5': [moment().add(5, 'M').startOf('month'), moment().add(5, 'M').endOf('month')],
    'This month + 6': [moment().add(6, 'M').startOf('month'), moment().add(6, 'M').endOf('month')],
}
