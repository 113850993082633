import _ from 'lodash';

export const addCompany = (filter, id) => {
    // Remove from excluded list
    if (_.includes(filter.excluded_companies, id)) {
        filter.excluded_companies = _.pull(filter.excluded_companies, id);
    }
    return filter;
};

export const removeCompany = (filter, id) => {
    // Add to excluded list
    if (!_.includes(filter.excluded_companies, id)) {
        filter.excluded_companies.push(id);
    }
    return filter;
};

export const addCompanies = (filter, companies) => {
    // Remove all from excluded list
    _.each(companies, (id) => {
        if (_.includes(filter.excluded_companies, id)) {
            filter.excluded_companies = _.pull(filter.excluded_companies, id);
        }
    });

    return filter;
};

export const removeCompanies = (filter, companies) => {
    // Remove all from excluded list
    _.each(companies, (id) => {
        if (!_.includes(filter.excluded_companies, id)) {
            filter.excluded_companies.push(id);
        }
    });

    return filter;
};

export const filterCompanies = (companies, searchTerm) => {
    if(searchTerm === "") return companies;

    let result = [];
    const term = searchTerm.toLowerCase();

    _.each(companies, (company) => {
        if(company.name.toLowerCase().startsWith(term) || (company.region && company.region.name.toLowerCase().startsWith(term))){
            result.push(company);
        }
    });

    return result;
};
