import {listVehicleFavoriteTypes}     from './types';

export const listVehicleFavorites = (state = {vehicleIds: [], loading: false, error: ''}, action) => {
    switch (action.type) {
        case listVehicleFavoriteTypes.FETCH_SUCCESS:
            return {
                ...state,
                vehicleIds: action.payload.vehicleIds,
            };
        case listVehicleFavoriteTypes.ADD:
            return {
                ...state,
                vehicleIds: [...state.vehicleIds, action.payload.vehicleId],
            };
        case listVehicleFavoriteTypes.REMOVE:
            return {
                ...state,
                vehicleIds: state.vehicleIds.filter(id => id !== action.payload.vehicleId),
            };
        case listVehicleFavoriteTypes.POST_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case listVehicleFavoriteTypes.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                lastUpdate: action.receivedAt
            };
        case listVehicleFavoriteTypes.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case listVehicleFavoriteTypes.ERROR_CLEAR:
            return {
                ...state,
                error: '',
            };
        default:
            return state;
    }
}
