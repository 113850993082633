import {RSAA} from 'redux-api-middleware';
import {types} from './types';

export const setLocale = (locale) => {
    return {
        type: types.SET_LOCALE,
        payload: {
            locale,
        }
    };
};


export const sendLocale = (locale) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/profiles/1/set_language/?locale=${locale}`,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                types: [
                    {
                        type: types.SEND_LOCALE_REQUEST,
                    },
                    {
                        type: types.SEND_LOCALE_SUCCESS,
                        payload: (action, state, res) => {
                            return {
                            }
                        },

                    },
                    {
                        type: types.SEND_LOCALE_FAILURE,
                        payload: (action, state, res) => {
                            return {
                                message: `${res.status} - ${res.statusText}`,
                            }
                        }
                    }
                ]
            }
        });
    }
};
