import React from 'react';
import {connect} from 'react-redux';
import ResetPasswordRequestForm from './ResetPasswordRequestForm';
import {resetPasswordRequest} from '../actions';
import {getLoading, getError} from '../selectors';

class ResetPasswordRequest extends React.Component {

    onHandleSubmit(data) {
        const {resetPasswordRequest} = this.props;
        resetPasswordRequest(data.username);
    }

    render() {
        const {error, loading} = this.props;

        return (
            <div>

                <ResetPasswordRequestForm loading={loading}
                                          onSubmit={(data) => this.onHandleSubmit(data)}
                />

                {error !== null &&
                <div className="row g-pa-0 g-ma-0 container-important g-pa-5 g-mb-10 text-center">
                    {error.message}
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: getLoading(state),
        error: getError(state),
    };
};

export default connect(mapStateToProps, {resetPasswordRequest})(ResetPasswordRequest);
