import _ from 'lodash';
import {INITIAL_FILTERDATA, INITIAL_FILTERCRITERIA} from './state';
import moment from 'moment';

export const getCriteria = (state, id) =>  (state.listvehicles_filter[id]) ? state.listvehicles_filter[id].criteria : INITIAL_FILTERCRITERIA;
export const getCriteriaCopy = (state, id) => (state.listvehicles_filter[id]) ? _.cloneDeep(state.listvehicles_filter[id].criteria) : _.cloneDeep(INITIAL_FILTERCRITERIA);
export const getData = (state, id) => (state.listvehicles_filter[id]) ? state.listvehicles_filter[id].data : INITIAL_FILTERDATA;

export const getLoading = (state, id) => (state.listvehicles_filter[id]) ? state.listvehicles_filter[id].isFetching : false;
export const getError = (state, id) => (state.listvehicles_filter[id]) ? state.listvehicles_filter[id].error: '';



export const defaultRanges = {
    'This month': [moment().startOf('month'), moment().endOf('month')],
    'This month + 1': [moment().add(1, 'M').startOf('month'), moment().add(1, 'M').endOf('month')],
    'This month + 2': [moment().add(2, 'M').startOf('month'), moment().add(2, 'M').endOf('month')],
    'This month + 3': [moment().add(3, 'M').startOf('month'), moment().add(3, 'M').endOf('month')],
    'This month + 4': [moment().add(4, 'M').startOf('month'), moment().add(4, 'M').endOf('month')],
    'This month + 5': [moment().add(5, 'M').startOf('month'), moment().add(5, 'M').endOf('month')],
    'This month + 6': [moment().add(6, 'M').startOf('month'), moment().add(6, 'M').endOf('month')],
}
