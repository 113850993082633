import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {filter, includes} from 'lodash';
import * as selectors from '../selectors';
import {
    fetchData,
    refreshData,
    currentStepUpdate,
    submitPersonalInfo,
    submitCompanyInfo,
    submitSettings,
    createFilterAndSubmit,
    updateFilterAndSubmit,
    uploadDocumentsAndSubmit,
    updateFilterCompaniesAndSubmit,
    sendActivationMail,
} from '../actions';
import Wizard from './components/Wizard';
import WizardActivateMail from './components/WizardActivateMail';
import WizardStepOne from './components/WizardStep1';
import WizardStepTwo from './components/WizardStep2';
import WizardStepThree from './components/WizardStep3';
import WizardStepFour from './components/WizardStep4';
import WizardStepFive from './components/WizardStep5';
import WizardStepSix from './components/WizardStep6';
import WizardCompleted from './components/WizardCompleted';
import {gettext} from '../../../translations';
import {withConfig} from '../../../config';

const documentTypes = [
    {id: 'ID_CARD', description: gettext('Identity card', true), icon:'fa fa-id-card-o' },
    {id: 'EMPTY_INVOICE', description: gettext('Empty invoice', true), icon:'fa fa-file-pdf-o'},
    {id: 'STATUTES', description: gettext('Statutes', true), icon:'fa fa-file-o'},
    {id: 'DEALERSHIP_PICTURE', description: gettext('Dealership picture', true), icon:'fa fa-camera-retro'},
];


class RegistrationWizard extends Component {
    constructor(props) {
        super(props);

        this.onHandlePersonalInfo = this.onHandlePersonalInfo.bind(this);
        this.onHandleCompanyInfo = this.onHandleCompanyInfo.bind(this);
        this.onHandleLegalDocuments = this.onHandleLegalDocuments.bind(this);
        this.onHandlePreferences = this.onHandlePreferences.bind(this);
        this.onHandleFilters = this.onHandleFilters.bind(this);
        this.onHandleFilterCompanies = this.onHandleFilterCompanies.bind(this);
        this.onHandlePrev = this.onHandlePrev.bind(this);
        this.onSendActivationMail = this.onSendActivationMail.bind(this);
    }

    componentDidMount() {
        this.props.fetchData();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.step !== nextProps.step) {
            this.props.refreshData();
            window.scrollTo(0, this.scrollOffset);
        }
    }

    onHandlePrev() {
        const {step, currentStepUpdate} = this.props;
        currentStepUpdate(step - 1);

    }

    onSendActivationMail(){
        const {registration, sendActivationMail} = this.props;
        sendActivationMail(registration.id);
    }

    onHandlePersonalInfo(data) {
        const {registration, submitPersonalInfo} = this.props;
        submitPersonalInfo(registration.id, data);
    }

    onHandleCompanyInfo(data) {
        const {registration, submitCompanyInfo} = this.props;
        submitCompanyInfo(registration.id, data);
    }

    onHandleLegalDocuments(documents) {
        const {registration, uploadDocumentsAndSubmit} = this.props;
        uploadDocumentsAndSubmit(registration.id, documents);
    }

    onHandlePreferences(data) {
        const {registration, submitSettings} = this.props;
        submitSettings(registration.id, data);
    }

    onHandleFilters(makes) {
        const {registration, createFilterAndSubmit, updateFilterAndSubmit, filter} = this.props;

        if (registration.filter_id === 0 || filter === null) {
            const createFilter = {
                id: -1,
                name: 'Default',
                regions: [],
                excluded_companies: [],
                makes: makes,
                age_min: 0,
                age_max: 0,
                km_min: 0,
                km_max: 0,
            };

            createFilterAndSubmit(registration.id, createFilter);
        }
        else {
            const updateFilter = {...filter, makes: makes};
            updateFilterAndSubmit(registration.id, updateFilter);
        }
    }

    onHandleFilterCompanies(companies, presentation_text) {
        const {registration, updateFilterCompaniesAndSubmit, filterCompanies} = this.props;
        const excluded_companies = filter(filterCompanies.map((c) => c.id), (id) => !includes(companies, id));
        const updateFilter = {...this.props.filter, excluded_companies};

        updateFilterCompaniesAndSubmit(registration.id, presentation_text, updateFilter);
    }

    render() {
        const {loading, error, step, hasAllData, registration, countries, filter, filterMakes, filterCompanies, config} = this.props;
        const {registration_steps} = config;

        if (!hasAllData) {
            return <div> {gettext('loading data')} ....</div>;
        }

        if ((!loading && registration === null)) {
            return <div> {gettext('No registration found.')}</div>;
        }

        const complete = (step === registration_steps.length + 1 || registration.step === 100);

        if (complete) {
            return <WizardCompleted /*onPrev={this.onHandlePrev}*/ onComplete={() => this.props.onWizardCompleted()} />
        }

        return (
            <Wizard step={step} loading={loading} error={error} complete={complete}>

                {registration_steps.includes(1) &&
                <WizardActivateMail title={gettext("E-mail confirmation")}
                               required={true}
                               registration={registration}
                               onSendActivationMail={this.onSendActivationMail}
                />}


                {registration_steps.includes(2) &&
                <WizardStepOne title={gettext("Personal info")}
                               required={true}
                               registration={registration}
                               onNext={this.onHandlePersonalInfo}
                               onPrev={this.onHandlePrev}
                />}

                {registration_steps.includes(3) &&
                <WizardStepTwo title={gettext("Company info")}
                               required={true}
                               registration={registration}
                               countries={countries}
                               onNext={this.onHandleCompanyInfo}
                               onPrev={this.onHandlePrev}
                />}

                {registration_steps.includes(4) &&
                <WizardStepThree title={gettext("Legal documents")}
                                 required={false}
                                 registration={registration}
                                 documentTypes={documentTypes}
                                 onNext={this.onHandleLegalDocuments}
                                 onPrev={this.onHandlePrev}
                />}

                {registration_steps.includes(5) &&
                <WizardStepFour title={gettext("Preferences")}
                                required={true}
                                registration={registration}
                                onNext={this.onHandlePreferences}
                                onPrev={this.onHandlePrev}
                />}

                {registration_steps.includes(6) &&
                <WizardStepFive title={gettext("Filters")}
                                required={true}
                                filter={filter}
                                filterMakes={filterMakes}
                                onNext={this.onHandleFilters}
                                onPrev={this.onHandlePrev}
                />}

                {registration_steps.includes(7) &&
                <WizardStepSix title={gettext("Dealers")}
                               required={true}
                               filter={filter}
                               registration={registration}
                               filterCompanies={filterCompanies}
                               onNext={this.onHandleFilterCompanies}
                               onPrev={this.onHandlePrev}
                />}




            </Wizard>
        )
    }
}


const mapStateToProps = (state) => {
    const registration = selectors.getRegistration(state);

    return {
        registration,
        countries: selectors.getCountries(state),
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        step: selectors.getStep(state),
        hasAllData: selectors.hasAllData(state),
        filterMakes: selectors.getFilterMakes(state),
        filterCompanies: selectors.getFilterCompanies(state),
        filter: (registration) ? selectors.getFilterById(state, registration.filter_id) : 0,
    }
};

export default compose(
    withConfig,
    connect(mapStateToProps, {
        currentStepUpdate,
        fetchData,
        refreshData,
        submitPersonalInfo,
        submitCompanyInfo,
        submitSettings,
        updateFilterAndSubmit,
        createFilterAndSubmit,
        uploadDocumentsAndSubmit,
        updateFilterCompaniesAndSubmit,
        sendActivationMail,
    })
)(RegistrationWizard);

