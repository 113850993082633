import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import ListVehicles from '../../../components/listvehicles/_web/ListVehicles';
import {ListVehicleState} from '../../../components/listvehiclestate';
import * as listVehicleSelectors from '../../../components/listvehicles/selectors';
import * as listSelectors from '../../../components/lists/selectors';
import {withConfig} from '../../../config';
import {Scrollbars} from 'react-custom-scrollbars';
import {resetCriteria as resetFilterCriteria} from '../../../components/listvehicles-filter/actions';
import ListDetailInfo from '../../../components/lists/_web/ListDetailInfo';
import {WheelNotOutSide} from '../../../components/core'
import {listVehicleSelect, toggleListVehicleState,} from '../actions';
import * as selectors from '../selectors';
import {withRouter} from 'react-router-dom';
import {dashboard, goBack} from '../../../_web/navigation/routes'
import {gettext} from '../../../translations';
import {startsWith} from 'lodash';
import {listVehiclesFetchAndBasket} from '../../listvehicles/actions';
import {ConfirmButton,  ConfirmReminder} from '../../cart/';


import {
    Filter,
} from '../../listvehicles-filter/';
import {ListTypes} from "../../../constants";
import LiveAuctionListVehiclesHeader from "./LiveAuctionListVehiclesHeader";
import DefaultListVehiclesHeader from "./DefaultListVehiclesHeader";


const CORR = 70;
const xxl = 1600;
const small = 992;

class ListVehiclesContainer extends Component {
    constructor() {
        super();

        this.state = {
            height: window.innerHeight - CORR,
            width: window.innerWidth,
            detailsVisible: window.innerWidth >= xxl,
            small: window.innerWidth <= small,
            closing: false,
            clicked: false,
            filterModalVisible: false,
        };

        this.handleResize = this.handleResize.bind(this);
        this.onListVehicleClick = this.onListVehicleClick.bind(this);
        this.onShowState = this.onShowState.bind(this);
        this.gooBack = this.gooBack.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleResize() {
        this.setState({
            height: window.innerHeight - CORR,
            width: window.innerWidth,
            detailsVisible: window.innerWidth >= xxl,
            small: window.innerWidth <= small,
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.handleResize, true);
    }


    handleScroll() {
        if (this.state.detailsVisible) {
            const footerOffset = document.getElementById('footer').offsetTop;
            const currentOffset = window.pageYOffset + window.innerHeight;

            if (currentOffset > footerOffset) {
                this.setState({
                    height: ((window.innerHeight - CORR) - (currentOffset - footerOffset)),
                });
            } else {
                const height = window.innerHeight - CORR;
                if (this.state.height !== height) {
                    this.setState({
                        height: height,
                    });
                }
            }
        }
    }

    onListVehicleClick(listVehicleId, blockNavigation) {
        if (!this.state.detailsVisible) {
            this.setState({clicked: true}, () => this.props.listVehicleSelect(listVehicleId, !blockNavigation));

        } else {
            this.props.listVehicleSelect(listVehicleId, false);
        }

        if (this.props.onSelectedVehicleChanged) {
            this.props.onSelectedVehicleChanged(listVehicleId);
        }
    }

    onShowState(listVehicleId) {
        this.props.listVehicleSelect(listVehicleId, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.handleResize, true);


        // if (!this.state.closing && !this.state.clicked /*&& this.props.history.action === 'POP'*/) {
        //     const {listId, listCleanupByListId} = this.props;
        //     listCleanupByListId(listId);
        // }
    }


    gooBack() {
        const {listId, location,} = this.props;

        this.props.resetFilterCriteria(listId);

        this.setState({closing: true});

        if (location.state && location.state.routed) {
            goBack();
        } else {
            dashboard();
        }
    }


    render() {
        const {listId, listType, listVehicleId, config, sendListInformationByRequest} = this.props;
        const {vehicle_bidding_cart} = config;

        if (this.state.closing) return null;

        return (
            <div className="row bg-normal g-ma-0 g-mt-70 g-mb-200">

                <div className="g-hidden-sm-down col-md-3 col-lg-3 col-xl-3 col-xxl-2 g-ma-0 g-pl-5 g-pr-5">


                    <div className="sticky" style={{height: this.state.height}}>
                        <Scrollbars autoHide style={{height: this.state.height}}>
                            <WheelNotOutSide>
                                <React.Fragment>
                                    <div className="bg-white g-pt-10 g-pb-10 g-mb-10">

                                        <ListDetailInfo listId={listId}/>

                                        <div className="text-center g-mt-10">
                                            <button onClick={this.gooBack}
                                                    className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                                                <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"></i> {gettext('Back')}
                                            </button>
                                        </div>
                                    </div>

                                    {vehicle_bidding_cart && listType !== ListTypes.LIVE_AUCTION &&
                                    <div className="g-bg-white g-mb-10 g-pa-10">
                                        <ConfirmButton listId={listId}/>
                                        <ConfirmReminder listId={listId} style={{marginTop: 10}}/>
                                    </div>
                                    }


                                    <Filter listId={listId}
                                            onCriteriaChanged={() => this.props.listVehiclesFetchAndBasket(listId)}/>
                                </React.Fragment>
                            </WheelNotOutSide>
                        </Scrollbars>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-5 g-ma-0 g-pl-5 g-pr-5">

                    {listType !== ListTypes.LIVE_AUCTION && listType !== "" &&
                    <DefaultListVehiclesHeader listId={listId}
                                               loading={this.props.listVehiclesLoading}
                                               onBack={this.gooBack}
                                               cartConfirm={vehicle_bidding_cart}
                                               onFilterChanged={() => this.props.listVehiclesFetchAndBasket(listId)}/>}

                    {listType === ListTypes.LIVE_AUCTION  && listType !== "" &&
                    <LiveAuctionListVehiclesHeader listId={listId}
                                                   loading={this.props.listVehiclesLoading}
                                                   onBack={this.gooBack}
                                                   onFilterChanged={() => this.props.listVehiclesFetchAndBasket(listId)}/>}

                    <ListVehicles listId={listId}
                                  selectedListVehicle={listVehicleId}
                                  onClick={this.onListVehicleClick}
                                  onShowState={(this.state.detailsVisible) ? this.onShowState : null}
                                  fetchData={false}

                    />


                </div>

                <div className="hide-xl-down col-xxl-5 g-ma-0 g-pl-5 g-pr-5">

                    {this.state.detailsVisible &&
                    <div className="sticky g-mn-10"
                         style={{height: this.state.height}}>
                        <Scrollbars autoHide style={{height: this.state.height}}>
                            <WheelNotOutSide>
                                <ListVehicleState containerId={listVehicleId}
                                                  listVehicleId={listVehicleId}
                                                  showStickyHeader={false}
                                                  sendListInformationByRequest={sendListInformationByRequest}
                                />
                            </WheelNotOutSide>
                        </Scrollbars>
                    </div>
                    }
                </div>
            </div>
        )
    }


}


const mapStateToProps = (state, ownProps) => {
    const {listId} = ownProps;
    const selectedId = selectors.getSelectedListVehicleId(state).toString();
    const list = listSelectors.listById(state, listId);

    return {
        listId,
        listType: (list) ? list.type : "",
        listVehicleId: (startsWith(selectedId, listId.toString())) ? selectedId : 0,
        listVehiclesLoading: listVehicleSelectors.getInitialLoading(state, listId),
        count: listVehicleSelectors.getTotalCount(state, listId),
    };
};


export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps, {
        resetFilterCriteria,
        listVehicleSelect,
        toggleListVehicleState,
        listVehiclesFetchAndBasket,
    }),
)(ListVehiclesContainer);


