import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form'
import {FormFields, validators} from '../../../../core';
import {capitalize} from 'lodash';
import {gettext} from '../../../../../translations';
import {withConfig} from "../../../../../config";
import {compose} from 'redux';

var checkVat = require('validate-vat');
const {TextField, Multiline, TextFieldWithNoLabel, Combo} = FormFields;

const companyInfoFields = {
    COMPANY_NAME: 'company_name',
    VAT: 'vat_number',
    ADDRESS: 'address',
    COUNTRY: 'country_code',
    POSTAL: 'post_code',
    CITY: 'city',
    MAIL: 'logistics_email',
    YEARLY_VOLUME: 'volume_year'
};

const mapDataToFields = (data) => {
    return ({
        [companyInfoFields.COMPANY_NAME]: data.company_name,
        [companyInfoFields.VAT]: data.vat_number,
        [companyInfoFields.ADDRESS]: data.address,
        [companyInfoFields.COUNTRY]: data.country_code,
        [companyInfoFields.POSTAL]: data.post_code,
        [companyInfoFields.CITY]: data.city,
        [companyInfoFields.MAIL]: data.logistics_email,
        [companyInfoFields.YEARLY_VOLUME]: data.volume_year,
    })
};

class CompanyInfoForm extends Component {

    constructor(props) {
        super(props);

        this.checkVat = this.checkVat.bind(this);
        this.parseVat = this.parseVat.bind(this);
    }

    checkVat(value) {
        if (value.length === 12) {
            let country = value.substring(0, 2);
            let number = value.substring(2, value.length);

            checkVat(country, number, this.parseVat);
        }

        // BE0536650124
    }

    parseVat(err, info) {
        if (info && info.valid) {
            const {name, address} = info;
            const addressLine = address.split(",")[0];

            const postal = address.split(",")[1].split(" ")[1];
            const city = address.split(",")[1].split(" ")[2];


            this.props.change(companyInfoFields.COMPANY_NAME, name);
            this.props.change(companyInfoFields.ADDRESS, addressLine);
            this.props.change(companyInfoFields.POSTAL, postal);
            this.props.change(companyInfoFields.CITY, city);
        }
    }

    render() {
        const {initialValues, countries, config} = this.props;
        return (
            <form initialvalues={mapDataToFields(initialValues)}
                  onSubmit={this.props.handleSubmit}>


                <Field name={companyInfoFields.COMPANY_NAME}
                       component={TextField}
                       type="text"
                       label={gettext("Company name")}
                       validate={[validators.required]}
                />

                <Field name={companyInfoFields.VAT}
                       component={TextField}
                       type="text"
                       label={gettext('VAT number')}
                       onChange={(e) => {
                           this.checkVat(e.target.value)
                       }}
                       validate={[validators.required]}
                />

                <Field name={companyInfoFields.ADDRESS}
                       component={Multiline}
                       type="text"
                       label={gettext("Address")}
                       validate={[validators.required]}
                />


                <div className="form-group row g-hidden-sm-down">
                    <label
                        className="col-4 g-min-width-130 col-form-label g-color-gray-dark-v2 g-font-weight-500 text text-right">
                        {gettext('Postal')}/{gettext('City')}
                    </label>

                    <div className="col-8">
                        <div className="row g-pa-0 g-ma-0">
                            <div className="col-3 g-ma-0 g-pa-0 g-pr-20">
                                <Field name={companyInfoFields.POSTAL}
                                       component={TextFieldWithNoLabel}
                                       type="text"
                                       label={gettext("Postal")}
                                       validate={[validators.required]}
                                />
                            </div>
                            <div className="col-9 g-ma-0 g-pa-0">
                                <Field name={companyInfoFields.CITY}
                                       component={TextFieldWithNoLabel}
                                       type="text"
                                       label={gettext("City")}
                                       validate={[validators.required]}
                                />
                            </div>
                        </div>

                    </div>
                </div>


                <div className="g-hidden-sm-up">
                    <Field name={companyInfoFields.POSTAL}
                           component={TextField}
                           type="text"
                           label={gettext("Postal")}
                           validate={[validators.required]}
                    />

                    <Field name={companyInfoFields.CITY}
                           component={TextField}
                           type="text"
                           label={gettext("City")}
                           validate={[validators.required]}
                    />
                </div>


                <Field name={companyInfoFields.COUNTRY}
                       component={Combo}
                       label={gettext("Country")}
                       validate={[validators.required]}
                       placeholder={gettext("Country")}
                       options={countries.map((country) => ({value: country.code, label: capitalize(country.name)}))}
                />

                {config.registration_company_info_logistics_email &&
                <Field name={companyInfoFields.MAIL}
                       component={TextField}
                       type="text"
                       label={gettext('Logistic e-mail')}
                       validate={[]}
                       // additionalInfo={gettext("More info soon")}
                />}

                <Field name={companyInfoFields.YEARLY_VOLUME}
                       component={TextField}
                       label={gettext("Estimated yearly volume")}
                       type="number"
                       // validate={[validators.required]}
                       id={companyInfoFields.YEARLY_VOLUME}
                       additionalInfo={gettext("Estimation: how many vehicles will you process annualy via Autralis? Thanks to this assessment, we, as an independent service provider, can better tailor our support to your needs.")}
                />
            </form>

        );
    }
}

export default compose(
    withConfig,
    reduxForm({
        form: 'Registration-CompanyInfo',
        destroyOnUnmount: true,
        forceUnregisterOnUnmount: true,
        initialValues: {},
    })
)(CompanyInfoForm)