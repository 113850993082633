import React from 'react';
import {connect} from 'react-redux';
import {listsFetch} from '../actions';
import ListItem from './ListItem';
import * as selectors from '../selectors';
import {LoadingContainer} from '../../core'
import NoLists from './NoLists';
import {LoadingIndicator} from '../../../components/core';
import {gettext} from '../../../translations';
import {compose} from "redux";
import {withConfig} from "../../../config/index.native";
import TeaserBanner from "../../core/components/web/TeaserBanner";
class Lists extends React.Component {

    componentWillReceiveProps(nextProps, nextContext) {
        const {lists, loading, onNoData} = this.props;
        if (lists.length === 0 && !loading) {
            onNoData()
        }

    }

    componentDidMount() {
        this.props.dispatch(listsFetch());
    }

    render() {
        const {lists, loading, error, config} = this.props;

        return (
            <div>
                <div className="bg-normal" style={{zIndex: 50}}>
                    <div className="g-bg-white g-pa-10 g-mb-10  bottom-shadow">
                        <div className="justify-content-between align-items-center d-flex g-mb-0">
                            <div className="d-flex text-uppercase">
                                {gettext('Current sales')} : {this.props.count} {gettext('vehicles')}
                            </div>
                        </div>
                    </div>

                    <LoadingIndicator loading={loading}/>
                </div>

                <LoadingContainer loading={loading && lists.length > 0}>
                    {error && <span className="text-danger">ERROR: {error}</span>}

                    {lists.length === 0 && !loading &&
                    <NoLists/>
                    }

                    <TeaserBanner />

                    {lists &&
                    lists.map((id) => {
                            return (
                                <ListItem key={'list' + id}
                                          listId={id}
                                          onListSelect={this.props.onListSelect}
                                />)
                        }
                    )}
                </LoadingContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lists: selectors.currentListsIds(state),
        count: selectors.allListsVehicleCount(state),
        loading: selectors.currentListsLoading(state),
        error: selectors.currentListsError(state),
    }
};

export default compose(
    withConfig,
    connect(mapStateToProps)
)(Lists);
