import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Route, Redirect, withRouter} from 'react-router-dom';
import {
    isAuthenticated,
    isRegistrationPending,
    getAuthenticationCode,
    getUserState,
    getTermsAndConditionsReviewed
} from '../../components/session/index';
import {withConfig} from '../../config';
import TermsAndConditionsReview from "../TermsAndConditionsReview";

class PrivateRoute extends Component {

    hasAuthCode(props) {
        if (props.history.action === 'POP' && props.location.search && props.location.search.startsWith('?code=')) {
            return true;
        }
        return false;
    }

    authenticateByCode(props) {
        const code = props.location.search.replace("?code=", '');
        return <Redirect to={{
            pathname: '/login',
            search: `?code=${code}`,
            state: {
                from: {pathname: props.location.pathname}
            }
        }}/>
    }

    getCodeFromParams(props) {
        return props.location.search.replace("?code=", '');
    }


    render() {
        const {component: Component, authenticated, registrationPending, userAllowed, config, termsAndConditionsReviewed, ...rest} = this.props;
        const {app_excluded_routes,  terms_and_conditions_after_login} = config;


        return (
            <Route {...rest} render={props => {
                if ((authenticated && this.hasAuthCode(props) && this.props.authenticationCode !== this.getCodeFromParams(props)) ||
                    (!authenticated && this.hasAuthCode(props))) {
                    return this.authenticateByCode(props);
                } else if (authenticated) {
                    if (terms_and_conditions_after_login && !termsAndConditionsReviewed) {
                        return <TermsAndConditionsReview/>
                    } else if (app_excluded_routes.includes(props.match.path)) {
                        return <Redirect to={{pathname: '/dashboard'}}/>
                    } else if (registrationPending && props.match.path !== '/registration-wizard') {
                        return <Redirect to={{pathname: '/registration-wizard'}}/>
                    } else if (!registrationPending && !userAllowed && props.match.path !== '/403') {
                        return <Redirect to={{pathname: '/403'}}/>
                    } else if (props.location.state && props.location.state.from && props.location.state.from.pathname !== '/403') {
                        return <Redirect to={{pathname: props.location.state.from.pathname, state: {routed: false}}}/>
                    } else {
                        return <Component {...props} />
                    }
                } else {
                    return <Redirect to={{
                        pathname: '/login',
                        state: {
                            from: (props.location && props.location.state && props.location.state.routed) ? {
                                from: {
                                    pathname: '/',
                                    state: {routed: false, loginByCode: true}
                                }
                            } : props.location
                        }
                    }}/>
                }
            }}/>
        )
    }
}

function mapStateToProps(state) {

    return {
        authenticated: isAuthenticated(state),
        registrationPending: isRegistrationPending(state),
        authenticationCode: getAuthenticationCode(state),
        userAllowed: !getUserState(state).error,
        termsAndConditionsReviewed: getTermsAndConditionsReviewed(state),

    };
}

export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps)
)(PrivateRoute);



