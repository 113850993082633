import React from 'react';
import Modal from 'react-modal';
import Filter from './Filter';
import ClearFilters from '../_web/ClearFilters';

export default class FilterModal extends React.Component {

    componentWillMount() {
        Modal.setAppElement('#app');
    }

    render() {
        const {listId} = this.props;

        return (
            <Modal
                isOpen={this.props.visible}
                onRequestClose={this.props.onCancel}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 500,
                    },
                    content: {
                        top: 0,
                        left: 0,
                       width: '100%',
                        height: '100%',
                      //  marginRight: '-50%',
                        paddingTop: 50,
                        paddingBottom: 50,
                        //transform: 'translate(-50%, -50%)',

                    }
                }}
            >

                <div className="sticky sticky-md-down bg-highlight g-min-height-35 d-flex justify-content-end align-items-center">

                    <div className="g-mr-30">
                        <ClearFilters listId={listId}
                                      onClearFilters={this.props.onClearFilters}
                                      onClick={this.props.onCancel}
                                      fontStyle={{color: 'white'}}/>
                    </div>

                    <div className="clickable g-mr-10" onClick={this.props.onCancel}>
                        <i className="fa fa-close g-font-size-30 g-color-white"                           />
                    </div>

                </div>


                <div style={{height: '100vh', overflow: 'auto'}}>
                    <Filter listId={listId} onCriteriaChanged={this.props.onCriteriaChanged} />
                </div>

            </Modal>

        );
    }
}



