import React from 'react';
import {Field, reduxForm} from 'redux-form'
import {FormFields, validators} from '../../../../core';
import {gettext} from '../../../../../translations';

const {TextField, PhoneNumber, Combo} = FormFields;

const personalInfoFields = {
    TITLE: 'title',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    MAIL: 'email',
    PHONE: 'telephone',
    MOBILE: 'mobile',
};

const mapDataToFields = (data) => {
    return ({
        [personalInfoFields.TITLE]: data.title,
        [personalInfoFields.FIRST_NAME]: data.first_name,
        [personalInfoFields.LAST_NAME]: data.last_name,
        [personalInfoFields.MAIL]: data.email,
        [personalInfoFields.PHONE]: data.telephone,
        [personalInfoFields.MOBILE]: data.mobile,
    })
};

const validate = (values, props) => {
    const {required} = props;
    const errors = {};

    if (!required)
        return errors;

    const phoneError = validators.required(values.telephone) || validators.minLength10(values.telephone);
    const mobileError = validators.required(values.mobile) || validators.minLength10(values.mobile);

    if (phoneError !== undefined && mobileError !== undefined) {

        errors.telephone = gettext('Telephone or mobile is required');
        errors.mobile = gettext('Telephone or mobile is required');
    }
    return errors;
};


const PersonalInfoForm = ({initialValues, handleSubmit, required}) =>
    <form initialvalues={mapDataToFields(initialValues)}
          onSubmit={handleSubmit}>

        <Field name={personalInfoFields.TITLE}
               component={Combo}
               label={gettext("Salutation")}
               validate={(required) ? [validators.required] : []}
               placeholder={gettext("Salutation")}
               options={[
                   {value: 'Mr', label: gettext('Mr')},
                   {value: 'Mrs', label: gettext('Mrs')},
                   {value: 'Miss', label: gettext('Miss')}
               ]}
        />

        <Field
            name={personalInfoFields.FIRST_NAME}
            component={TextField}
            type="text"
            label={gettext("First name")}
            validate={(required) ? [validators.required] : []}
        />

        <Field
            name={personalInfoFields.LAST_NAME}
            component={TextField}
            type="text"
            label={gettext("Lastname")}
            validate={(required) ? [validators.required] : []}
        />

        <Field
            name={personalInfoFields.MAIL}
            component={TextField}
            type="email"
            label={gettext("E-mail address")}
            disabled={true}
            validate={(required) ? [validators.required] : []}
        />

        <Field
            name={personalInfoFields.PHONE}
            component={PhoneNumber}
            type="text"
            example={`${gettext("E.g.")} 09 360 00 00`}
            label={gettext("Telephone")}
        />

        <Field
            name={personalInfoFields.MOBILE}
            component={PhoneNumber}
            type="text"
            example={`${gettext("E.g.")} 0494 11 22 33`}
            label={gettext("Mobile")}
        />
    </form>;


export default reduxForm({
    form: 'Registration-PersonalInfo',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
})(PersonalInfoForm)
