import React from 'react';
import {gettext} from "../../../translations";
import NumberFormat from "react-number-format";
import {formatPrice} from "../../../helpers/functions";
import withData from "../withData";
import {PropagateLoader} from "react-spinners";
import style from "../../../styles";


class BiddingAuctionRowBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            currentBid: props.price,
            maximumPrice: props.maximumPrice,
            error: "",

        };

        this.handleBid = this.handleBid.bind(this);
        this.handleMaximumPrice = this.handleMaximumPrice.bind(this)

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.listVehicleId !== nextProps.listVehicleId || (this.props.listVehicleId === nextProps.listVehicleId && this.props.price !== nextProps.price)) {
            this.setState({currentBid: nextProps.price, maximumPrice: nextProps.maximumPrice});
        }


        // if (this.props.vehicleStop !== nextProps.vehicleStop) {
        //     this.setState({dateExtended: true})
        // }
    }

    handleBid(price) {
        // if (price !== null && price != this.props.basketPrice) {
        if (price !== null) {
            if (!this.state.loading) {
                this.setState({loading: true});

                this.props.listVehicleBid(this.props.listId, this.props.vehicleId, price, "", 0, 0).then((result) => {
                    if (!result.payload.accepted) {
                        this.setState({loading: false, error: result.payload.message})
                    } else {
                        this.setState({loading: false, error: ""})
                    }

                    if(this.priceInput) {
                        this.priceInput.blur()
                    }
                })


            }
        }

    }

    handleMaximumPrice(maximumPrice) {
        if (!this.state.loading) {
            this.setState({loading: true});

            this.props.listVehicleBid(this.props.listId, this.props.vehicleId, 0, "", 0, maximumPrice).then((result) => {
                if (!result.payload.accepted) {
                    this.setState({loading: false, error: result.payload.message})
                } else {
                    this.setState({loading: false, error: ""})
                }
            })


        }

    }

    render() {
        const {vehiclePrice, basketPrice, highestBidder, biddingAllowed, isAuction, vehicle, config} = this.props;
        const {vehicle_vat_regime} = config;
        const {loading, error} = this.state;


        const highestBid = vehiclePrice;

        const color = (highestBidder) ? '#48bb78' : ((basketPrice > 0) && !highestBidder) ? '#c53030' : 'lightgrey';
        const iconColor = (highestBidder) ? '#48bb78' : ((basketPrice > 0) && !highestBidder) ? '#c53030' : 'black';

        const maxBidColor = 'lightgrey';

        if (!biddingAllowed || !isAuction) {
            return null;
        }

        const placeholder = (vehicle_vat_regime) ? gettext("Incl VAT") : (vehicle.vat_regime) ? gettext("Incl VAT") : gettext("Excl VAT");


        return (
            <div className="row g-pa-0 g-ma-0 g-pa-5 g-pa-10--sm g-pa-10--md g-pa-10--lg g-pa-a-10--xl g-mb-5 g-pos-rel" style={{backgroundColor: '#f5f5f5', width: '100%'}}
                 onMouseEnter={() => {
                     if (this.props.onFocus) {
                         this.props.onFocus()
                     }
                 }}
                 onMouseLeave={() => {
                     if (this.props.onBlur) {
                         this.props.onBlur()
                     }
                 }}
                 onClick={() => {
                     this.setState({currentBid: null})
                 }}
            >

                {error && error !== "" &&
                <div className="row g-pa-0 g-ma-0 g-mb-10 g-pa-10" style={{width: '100%', backgroundColor: '#fed7d7', color: '#c53030'}}>
                    {error}
                </div>}

                <div className="row g-pa-0 g-ma-0" style={{display: 'flex', justifyContent: 'space-between', flex: 1}}>

                    <div className="">

                        <div className="g-mb-6">
                            <span className="g-font-weight-500 g-font-size-12">{gettext('Bid directly')}</span>
                        </div>

                        <div className="row g-pa-0 g-ma-0 g-brd-1 g-brd-style-solid g-bg-white" style={{height: 35, minWidth: 125, borderColor: color}}>

                            <div className={`col-9 row g-pa-0 g-ma-0 g-pl-5 g-valign-middle`}>
                                <NumberFormat value={(this.state.currentBid) ? this.state.currentBid : ""}
                                              disabled={false}
                                              getInputRef={(el) => this.priceInput = el}
                                              placeholder={placeholder}
                                              thousandSeparator={' '} prefix={'€ '}
                                              onValueChange={(values) => this.setState({currentBid: values.value})}
                                              allowNegative={false}
                                              onKeyPress={(e) => {
                                                  if (e.key === 'Enter') {
                                                      //this.setState({loading: true}, () => this.handleBid(this.state.currentBid));
                                                      this.handleBid(this.state.currentBid);
                                                  }
                                              }}
                                              className="slick-slide"
                                              onFocus={() => {
                                                  this.priceInput.setSelectionRange(0, this.priceInput.value.length);
                                                  if (this.props.onFocus) {
                                                      this.props.onFocus()
                                                  }
                                              }}
                                              autoFocus={false}
                                              style={{
                                                  border: 'none',
                                                  outline: 'none',
                                                  outlineOffset: 0,
                                                  paddingRight: 10,
                                                  width: 90,
                                                  color: (this.state.currentBid > 0 && color === 'lightgrey') ? 'black' : color
                                              }}
                                              onSelect={(e) => {
                                                  if (this.props.onFocus) {
                                                      this.props.onFocus()

                                                  }
                                              }}
                                              onBlur={() => {
                                                  /* fix when leaves the control, but not clicked to confirm the bid ==> reset to initial price */
                                                  if (this.state.currentBid !== this.props.basketPrice && !loading) {
                                                      setTimeout(() => {
                                                          this.setState({currentBid: basketPrice});
                                                      }, 200);
                                                  }

                                                  if (this.props.onBlur) {
                                                      this.props.onBlur()
                                                  }
                                              }}
                                />
                            </div>
                            <div ref={divElement => this.divElement = divElement}
                                 className="col-3 g-ma-0 g-pa-0 g-mt-3 g-mb-3 text-center clickable "
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     // this.setState({loading: true}, () => this.handleBid(this.state.currentBid));
                                     this.handleBid(this.state.currentBid);
                                 }
                                 }
                                 style={{
                                     backgroundColor: 'white',
                                     borderLeftColor: color,
                                     borderLeftWidth: 1,
                                     borderLeftStyle: 'solid',
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     color: color,
                                 }}>

                                <i className={`fa fa-check g-font-size-16 color-btn-icon`} style={{color: iconColor}}></i>
                            </div>
                        </div>
                    </div>

                    <div className="g-pa-0 g-ma-0 flex justify-content-center g-hidden-xs-down" onClick={(e) => e.stopPropagation()}>
                        <div>
                            <div className="g-mb-6">
                                <span className="g-font-weight-500 g-font-size-12">{gettext('Quick bid')}</span>
                            </div>
                            <div className="row g-pa-0 g-ma-0">
                                <div className="mr-2">
                                    <button
                                        className="btn u-btn-white g-color-primary g-bg-primary-dark-v1--hover g-color-white--hover g-brd-primary  g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5"
                                        // onClick={() => this.setState({loading: true}, () => this.handleBid(highestBid + 100))}>
                                        onClick={() => this.handleBid(highestBid + 100)}>
                                        {formatPrice(highestBid + 100)}
                                    </button>

                                </div>
                                <div className="mr-2">
                                    <button
                                        className="btn u-btn-white g-color-primary g-bg-primary-dark-v1--hover g-color-white--hover g-brd-primary  g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5"
                                        // onClick={() => this.setState({loading: true}, () => this.handleBid(highestBid + 200))}>
                                        onClick={() => this.handleBid(highestBid + 200)}>
                                        {formatPrice(highestBid + 200)}
                                    </button>
                                </div>
                                <div className="mr-2">
                                    <button
                                        className="btn u-btn-white g-color-primary g-bg-primary-dark-v1--hover g-color-white--hover g-brd-primary  g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5"
                                        // onClick={() => this.setState({loading: true}, () => this.handleBid(highestBid + 500))}>
                                        onClick={() => this.handleBid(highestBid + 500)}>
                                        {formatPrice(highestBid + 500)}
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="btn u-btn-white g-color-primary g-bg-primary-dark-v1--hover g-color-white--hover g-brd-primary  g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5"
                                        // onClick={() => this.setState({loading: true}, () => this.handleBid(highestBid + 800))}>
                                        onClick={() => this.handleBid(highestBid + 800)}>
                                        {formatPrice(highestBid + 800)}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="g-mb-6">
                            <span className="g-font-weight-500 g-font-size-12">{gettext('Place an automatic bid')}</span>
                        </div>
                        <div className="row g-pa-0 g-ma-0 g-brd-1 g-brd-style-solid g-bg-white" style={{height: 35, minWidth: 125, color: maxBidColor}}>

                            <div className={`col-9 row g-pa-0 g-ma-0 g-pl-5 g-valign-middle`}>
                                <NumberFormat value={(this.state.maximumPrice > 0) ? this.state.maximumPrice : ""}
                                              disabled={false}
                                              getInputRef={(el) => this.maximumPriceInput = el}
                                              placeholder={placeholder}
                                              thousandSeparator={' '} prefix={'€ '}
                                              onValueChange={(values) => this.setState({maximumPrice: values.value})}
                                              allowNegative={false}
                                              onKeyPress={(e) => {
                                                  if (e.key === 'Enter') {
                                                      // this.setState({loading: true}, () => this.handleMaximumPrice(this.state.maximumPrice));
                                                      this.handleMaximumPrice(this.state.maximumPrice);
                                                  }
                                              }}
                                              className="slick-slide"
                                              onFocus={() => {
                                                  if (this.maximumPriceInput) {
                                                      this.maximumPriceInput.setSelectionRange(0, this.maximumPriceInput.value.length)
                                                  };
                                                  if (this.props.onFocus) {
                                                      this.props.onFocus()
                                                  }
                                              }}
                                              autoFocus={false}
                                              style={{
                                                  border: 'none',
                                                  outline: 'none',
                                                  outlineOffset: 0,
                                                  paddingRight: 10,
                                                  width: 90,
                                                  color: (this.state.maximumPrice > 0 && maxBidColor === 'lightgrey') ? 'black' : maxBidColor
                                              }}
                                              onSelect={(e) => {
                                                  if (this.props.onFocus) {
                                                      this.props.onFocus()
                                                  }
                                              }}
                                              onBlur={() => {
                                                  if (this.props.onBlur) {
                                                      this.props.onBlur()
                                                  }
                                              }}
                                />
                            </div>
                            <div ref={divElement => this.divElement = divElement}
                                 className="col-3 g-ma-0 g-pa-0 g-mt-3 g-mb-3 text-center clickable "
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     // this.setState({loading: true}, () => this.handleMaximumPrice(this.state.maximumPrice));
                                     this.handleMaximumPrice(this.state.maximumPrice);
                                 }
                                 }
                                 style={{
                                     backgroundColor: 'white',
                                     borderLeftColor: maxBidColor,
                                     borderLeftWidth: 1,
                                     borderLeftStyle: 'solid',
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     color: (this.state.maximumPrice > 0 && maxBidColor === 'lightgrey') ? 'black' : maxBidColor
                                 }}>

                                <i className={`fa fa-check g-font-size-16 color-btn-icon`}
                                   style={{color: 'black'}}></i>
                            </div>
                        </div>


                    </div>
                </div>


                {loading &&
                <div className="row g-pa-0 g-ma-0 justify-content-center g-pos-abs g-width-100 loadingContainerOverlay " style={{alignItems: 'center', cursor: 'default'}}
                     onClick={(e) => e.stopPropagation()}>
                    <PropagateLoader loading={loading}
                                     color={style.highlightColor}
                    />
                </div>}

            </div>


        );
    }
}

export default withData(BiddingAuctionRowBlock);

