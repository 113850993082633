import React, {Component} from 'react';
import {Button, Popover, PopoverBody} from 'reactstrap';
import 'react-dropdown/style.css'
import VehicleSearchControl from './VehicleSearchControl';

class VehicleSearchPopUp extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {

        return (
            <div className="menu-item g-bg-primary g-bg-primary-dark-v1--hover g-font-weight-500 g-px-15 g-py-5 clickable" style={{maxHeight: 32}} onClick={this.toggle} >
                <Button id="vehicle_search_popover" color="transparent" className="g-pa-0 g-ma-0 align-items-center justify-content-center" >
                    <i className="fa fa-search g-color-white"/>
                </Button>
                <Popover placement="bottom" isOpen={this.state.popoverOpen} target="vehicle_search_popover"
                         toggle={this.toggle}>
                    <PopoverBody>
                        <VehicleSearchControl autoFocusInput={true}/>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}


export default VehicleSearchPopUp;


