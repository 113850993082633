import {types} from './types';

export const updateSession = (session) => {
    return {
        type: types.SESSION_UPDATE,
        payload: {
            session,
        }
    };
};

export const clearSession = () => {
    return {
        type: types.SESSION_CLEAR,
    };
};

export const updateSessionRegistrationStep = (step) => {
    return updateSession({
        registration_step: step,
        company_user_state: {
            error: false,
            message: ''
        }
    })
};


export const sessionTermsAndConditionsReviewedUpdate = (value) => {
    return updateSession({
        terms_and_conditions_reviewed: value,
    })
};
