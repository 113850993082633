import {unsubscribe_types} from "./types";

const unsubscribe = (state = {}, action) => {
    switch (action.type) {
        case unsubscribe_types.SETTINGS_SUCCESS:
            return {
                ...state,
               ...action.payload,
            };
        default:
            return state
    }
};

export default unsubscribe;