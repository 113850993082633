import {schema} from 'normalizr';

// Define a users schema
export const vehicleScheme = new schema.Entity('vehicles', undefined, {
    idAttribute: value => `${value.list_id}_${value.vehicle_id}`,
});

// Define your comments schema
export const listScheme = new schema.Entity('list', {
    vehicles: [vehicleScheme]
});

