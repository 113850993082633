import React from 'react';
import CheckValueBoolean from './CheckValueBoolean';
import CheckValueTrafficLight from './CheckValueTrafficLight';

const CheckValue = ({type, value}) => {
    switch (type) {
        case 'Boolean':
            return <CheckValueBoolean value={value} />;
        case 'TrafficLight':
            return <CheckValueTrafficLight value={value} />;
        case 'Numeric':
            return <div>{value}</div>;
        default:
            return <div />
    }
}

export default CheckValue;
