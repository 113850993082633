import React from 'react';
import classNames from 'classnames';

const TrafficLight = {
    RED: 'true',
    ORANGE: 'false',
    GREEN: 'na'
};

const CheckValueTrafficLight = ({value}) => (
    <div style={{flexDirection: 'row'}}>

        <i className={classNames({
            'fa fa-circle': (value === TrafficLight.RED),
            'fa fa-circle-o': (value !== TrafficLight.RED),
            'traffic-light': true,
            'traffic-light-red': (value === TrafficLight.RED),
        })} />

        <i className={classNames({
            'fa fa-circle': (value === TrafficLight.ORANGE),
            'fa fa-circle-o': (value !== TrafficLight.ORANGE),
            'traffic-light': true,
            'traffic-light-orange': (value === TrafficLight.ORANGE),
        })} />

        <i className={classNames({
            'fa fa-circle': (value === TrafficLight.GREEN),
            'fa fa-circle-o': (value !== TrafficLight.GREEN),
            'traffic-light': true,
            'traffic-light-green': (value === TrafficLight.GREEN),
        })} />


    </div>
);

export default CheckValueTrafficLight;

