import _ from 'lodash';

export const normalizeOfferVehicles = (data) => {
    let normalizedOfferVehicles = _.mapKeys(data, function (value) {
        return 'OFFER' + value.id + '_' + value.vehicle_id;
    });

    return {
        result: Object.keys(normalizedOfferVehicles),
        entities: {vehicle: normalizedOfferVehicles},
    };
};