import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateSort} from '../actions';
import {getSortById} from '../functions';
import * as selectors from '../selectors';
import {Button, Popover, PopoverBody} from 'reactstrap';
import 'react-dropdown/style.css'
import style from '../../../styles';


class SortDropDownSmallDevice extends Component {

    constructor(props) {
        super(props);

        this.onSort = this.onSort.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };

        const {listId, sorters, defaultSorterId} = props;
        this.props.updateSort(listId, getSortById(sorters, defaultSorterId));
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    onSort(id) {
        const {listId, sorters, onSortChanged} = this.props;

        this.props.updateSort(listId, getSortById(sorters, id));

        if(onSortChanged){
            onSortChanged();
        }
    }

    render() {
        const {sorters} = this.props;
        return (
            <div>
                <Button id="sort_popover" color="white" onClick={this.toggle} className="g-pa-0 g-ma-0">
                    <i className="fa fa-sort g-font-size-25 g-valign-middle"
                       style={{color: style.defaultColor}}/>
                </Button>
                <Popover placement="bottom" isOpen={this.state.popoverOpen} target="sort_popover" toggle={this.toggle}>

                    <PopoverBody>
                        {sorters.map((sorter) =>
                            <div key={sorter.id} className="clickable" style={{minHeight: 28}} onClick={() => {
                                this.toggle();
                                this.onSort(sorter.id)
                            }}>{sorter.label}</div>
                        )}
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        activeSorter: selectors.getActiveSorter(state, ownProps.listId),
    };
};


export default connect(mapStateToProps, {updateSort})(SortDropDownSmallDevice);


