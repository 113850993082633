import React from 'react';
import {connect} from 'react-redux';
import {fetchStatistics} from '../actions';
import {LoadingIndicator} from '../../core';
import LineChart from 'recharts/es6/chart/LineChart';
import Line from 'recharts/es6/cartesian/Line';
import XAxis from 'recharts/es6/cartesian/XAxis';
import YAxis from 'recharts/es6/cartesian/YAxis';
import CartesianGrid from 'recharts/es6/cartesian/CartesianGrid';
import Tooltip from 'recharts/es6/component/Tooltip';
import style from '../../../styles';
import {updateCriteriaStates, updateCriteriaRange} from '../../listvehicles-filter';
import {gettext} from '../../../translations';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactSpeedometer from "./SpeedoMeter";
import {formatPrice} from '../../../helpers/functions';
import NoStats from '../NoStats';
import CountUp from 'react-countup';
import format from 'string-format';
import {goToHistory} from '../../../_web/navigation/routes';
import moment from 'moment';
import {keys} from "lodash";

class CustomTooltip extends React.Component {
    render() {
        const {active} = this.props;
        if (active) {
            const {payload} = this.props;
            return (
                <div className="custom-tooltip g-bg-white">
                    <p className="label">{`${gettext('Period')}: ${payload[0].payload.period}`}</p>
                    <p className="label">{`${gettext('Number of cars')}: ${payload[0].payload.count}`}</p>
                    <p className="label">{`${gettext('Amount')}: ${formatPrice(payload[0].payload.amount, true)}`}</p>
                </div>
            );
        }
        return null;
    }
}


class Statistics extends React.Component {
    constructor(props) {
        super(props);

        this.onStateClick = this.onStateClick.bind(this);
        this.handleChartClick = this.handleChartClick.bind(this);
    }


    onStateClick(state) {
        const {updateCriteriaStates} = this.props;

        updateCriteriaStates(-1, [state]);
        goToHistory();
    }

    format(cell, row) {
        return cell + ' %';
    }

    getGraphWidth() {
        if (window.innerWidth <= 768) {
            return 350;
        } else if (window.innerWidth < 1600 && window.innerWidth > 768) {
            return 500;
        }
        return 600;
    }

    formatYAxis(tickItem) {
        return formatPrice(tickItem);
    }

    formatXAxis(tickItem) {
        const format = (window.innerWidth <= 768) ? 'MM/YY' : 'MM/YYYY';
        return moment(`${tickItem}/01`).format(format);
    }

    handleChartClick(dot) {
        const {month, year} = dot.payload;

        const {updateCriteriaStates, updateCriteriaRange} = this.props;

        updateCriteriaStates(-1, ["ACCEPTED"]);
        updateCriteriaRange(-1, `${year}/${month}/01`, moment(`${year}/${month}/01`).add(1, 'M'));
        goToHistory();
    }

    countersMoreThanOne() {
        return keys(this.props.data.states).length > 0;
    }

    render() {
        const {loading, error, data} = this.props;

        if (loading) {
            return <LoadingIndicator loading={loading}/>
        }

        if (error) {
            return <div>{error}</div>
        }

        if (data !== null) {
            const options = {
                sizePerPageList: [],
                sizePerPage: 5,
                pageStartIndex: 1,
                paginationSize: 3,
                paginationShowsTotal: false,
                paginationPosition: 'bottom',
                hideSizePerPage: true,
                alwaysShowAllBtns: true,
                withFirstAndLast: false,
            };

            function AverageFormatter(cell, row) {
                return cell + ' %';
            }

            const columns = [
                {
                    dataField: 'make',
                    text: gettext("Make"),
                    sort: true,

                },
                {
                    dataField: 'count',
                    text: gettext("# bids"),
                    sort: true,
                    align: 'right',
                    headerAlign: 'right',
                },
                {
                    dataField: 'average',
                    text: gettext("relative to highest bid"),
                    formatter: AverageFormatter,
                    sort: true,
                    align: 'right',
                    headerAlign: 'right',
                }];

            const defaultSorted = [{
                dataField: 'count',
                order: 'desc'
            }];

            return <div id="statistics-container" className="g-bg-white">

                {this.countersMoreThanOne() &&
                <div className="btn-group justified-content text-center g-pa-10 g-color-white--hover">
                    <label
                        className="stats-item u-check g-pl-0 g-color-white--hover g-bg-primary--hover g-min-width-150"
                        onClick={() => this.onStateClick('OFFERED')}>
                        <div
                            className="btn btn-md btn-block u-btn-outline-lightgray rounded-0 g-color-white--hover g-bg-primary--hover">
                            <div className="g-font-size-22 g-font-weight-700 g-color-primary ">
                                {(data.states["OFFERED"]) ? <CountUp start={0}
                                                                     end={data.states["OFFERED"].count}/> : 0}</div>
                            <div>{gettext("Waiting")}</div>
                        </div>
                    </label>

                    <label
                        className="stats-item u-check g-pl-0 g-color-white--hover g-bg-primary--hover g-min-width-150"
                        onClick={() => this.onStateClick("ACCEPTED")}>
                        <span
                            className="btn btn-md btn-block u-btn-outline-lightgray rounded-0 g-color-white--hover g-bg-primary--hover">
                           <div
                               className="g-font-size-22 g-font-weight-700 g-color-primary">{(data.states["ACCEPTED"]) ?
                               <CountUp start={0}
                                        end={data.states["ACCEPTED"].count}/> : 0}</div>
                            <div>{gettext("Accepted")}</div>
                        </span>
                    </label>

                    <label
                        className="stats-item u-check g-pl-0 g-color-white--hover g-bg-primary--hover g-min-width-150"
                        onClick={() => this.onStateClick("ENTERED")}>
                        <span
                            className="btn btn-md btn-block u-btn-outline-lightgray rounded-0 g-color-white--hover g-bg-primary--hover">
                           <div
                               className="g-font-size-22 g-font-weight-700 g-color-primary">{(data.states["ENTERED"]) ?
                               <CountUp start={0}
                                        end={data.states["ENTERED"].count}/> : 0}</div>
                            <div>{gettext("To pickup")}</div>
                        </span>
                    </label>

                    <label
                        className="stats-item u-check g-pl-0 g-color-white--hover g-bg-primary--hover g-min-width-150"
                        onClick={() => this.onStateClick("REFUSED")}>
                        <span
                            className="btn btn-md btn-block u-btn-outline-lightgray rounded-0 g-color-white--hover g-bg-primary--hover">
                           <div
                               className="g-font-size-22 g-font-weight-700 g-color-primary">{(data.states["REFUSED"]) ?
                               <CountUp start={0}
                                        end={data.states["REFUSED"].count}/> : 0}</div>
                            <div>{gettext('Without continuation')}</div>
                        </span>
                    </label>

                </div>}

                {this.countersMoreThanOne() &&
                <div className="row g-pa-0 g-ma-0">
                    {(data.states["ARCHIVED"] && data.states["ARCHIVED"].count > 0) &&
                    <div className="col-6 g-text-underline clickable g-color-primary"
                         onClick={() => this.onStateClick("ARCHIVED")}>
                        {format(gettext('{0} archived'), data.states["ARCHIVED"].count)}
                    </div>}
                </div>}

                {!data.average_all && data.accepted.length === 0 && data.average_make.length === 0 &&
                <div className={`${(this.countersMoreThanOne() ? 'bg-white' : 'bg-normal')}`}>
                    <NoStats />
                </div>}

                {data.average_all && data.average_all.count > 0 &&
                <div className="g-pt-20">

                    <div className="text-center">
                        <ReactSpeedometer
                            minValue={60}
                            maxValue={100}
                            value={data.average_all.average}
                            segments={4}
                            currentValueText=" "
                            width={300}
                            height={180}
                        />
                        <p className="g-font-weight-700"
                           style={{marginTop: -20}}>
                            {format(gettext('Your given bids are at {0}% of the highest bid.'), data.average_all.average)}
                        </p>
                    </div>

                </div>}

                {data.average_make && data.average_make.length >= 1 &&
                <div className="g-pt-20 g-pl-10 g-pr-10">

                    <BootstrapTable keyField='id'
                                    data={ data.average_make }
                                    defaultSorted={defaultSorted}
                                    columns={columns}
                                    pagination={ paginationFactory(options) }
                                    tableHeaderClass={"col-hidden"}
                                    hover
                                    condensed
                                    bordered={ false }
                    />
                </div>}


                {data.accepted && data.accepted.length >= 1 &&
                <div className="text-center g-pt-20">
                    <LineChart width={this.getGraphWidth()} height={300} data={data.accepted}
                               margin={{top: 5, right: 5, left: 5, bottom: 5}}


                    >
                        <XAxis dataKey="period" tickFormatter={this.formatXAxis} tickMargin={8} style={{fontSize: 12}}/>
                        <YAxis tick={{width: 500}} tickFormatter={this.formatYAxis} style={{fontSize: 12}}/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Line type="monotone" dataKey="amount" stroke={style.highlightColor}
                              activeDot={{r: 8, onClick: this.handleChartClick, style: {cursor: 'pointer'}}}/>
                    </LineChart>
                    <p className="g-font-weight-700">{gettext("Number of cars assigned to you")}</p>
                </div>}


            </div>
        }
        return null;


    }
}


export default connect(null, {fetchStatistics, updateCriteriaStates, updateCriteriaRange})(Statistics);
