import React from 'react';
import {connect} from 'react-redux';
import style from '../../../styles';
import {hasActiveCriteria} from '../functions/filter';
import FilterModal from './FilterModal';

class FilterButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {modalVisible: false}
    }

    render() {
        const {listId, hasActiveCriteria} = this.props;
        return (
            <div>
                <i className="fa fa-filter g-font-size-25 clickable" onClick={() => this.setState({modalVisible: true})}
                   style={{color: (hasActiveCriteria) ? '#86d863' : style.defaultColor}}/>

                {this.state.modalVisible &&
                <FilterModal visible={this.state.modalVisible}
                             listId={listId}
                             onCancel={() => {
                                 this.setState({modalVisible: false})
                             }}
                             onCriteriaChanged={this.props.onCriteriaChanged}
                             onClearFilters={this.props.onClearFilters}
                />}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {listId} = ownProps;
    return {
        hasActiveCriteria: hasActiveCriteria(state, listId),
    }
};

export default connect(mapStateToProps)(FilterButton);



