import React from 'react';
import classNames from 'classnames';
import {numberFormat} from '../../../helpers/functions';
import {gettext} from '../../../translations';

const FiltersList = ({filters, selectedFilter, onSelectFilter, allMakesCount, allCompaniesCount}) =>
    <div>
        {Object.values(filters).map((filter, i) =>

            <article key={filter.id}
                     className={classNames({
                         'item item--hover': true,
                         'item-active': (selectedFilter && selectedFilter.id === filter.id),
                         'clickable': true,
                     })} onClick={() => {
                onSelectFilter(filter)
            }}>

                <div className="g-pa-0 g-ma-0 g-pa-10 g-mt-5 clickable bg-white"

                >

                    <h3 className="g-font-weight-600 g-font-size-16">{filter.name}</h3>

                    <ul className="list-unstyled">
                        <li className="g-brd-bottom--dashed g-brd-gray-light-v3 pt-1 mb-3">
                            {gettext('Dealers')}: {allCompaniesCount - filter.excluded_companies.length} / {allCompaniesCount}
                        </li>

                        <li className="g-brd-bottom--dashed g-brd-gray-light-v3 pt-1 mb-3">
                            {gettext('Makes')}: {filter.makes.length} / {allMakesCount}
                        </li>


                        <li className="g-brd-bottom--dashed g-brd-gray-light-v3 pt-1 mb-3">
                            {filter.age_min === filter.age_max &&
                            <span>{gettext('All age')}</span>
                            }

                            {filter.age_min !== filter.age_max &&
                            <span>{filter.age_min} {gettext('to')} {filter.age_max} {gettext('months')}</span>
                            }

                        </li>
                        <li className="g-brd-bottom--dashed g-brd-gray-light-v3 pt-1 mb-3">
                            {filter.km_min === filter.km_max &&
                            <span>{gettext('All km')}</span>
                            }
                            {filter.km_min !== filter.km_max &&
                            <span>{numberFormat(filter.km_min)} {gettext('to')} {numberFormat(filter.km_max)} {gettext('km')}</span>
                            }
                        </li>

                    </ul>


                </div>
            </article>
        )}
    </div>

export default FiltersList;


