import {includes} from 'lodash';

export const getBrowserLocale = () => {
    let locale = 'en';
    const browserLocale = require('browser-locale')();
    console.log(browserLocale);
    if(browserLocale !== '' && includes(browserLocale, '-')){
        locale = browserLocale.split('-')[0];
    }
    else if(browserLocale !== '' && browserLocale.length === 2){
        locale = browserLocale;
    }
    return locale;
};

