import React from 'react';
import * as selectors from '../selectors';
import {connect} from 'react-redux';
import {gettext} from '../../../translations';
import {BidControl} from '../../bidding';
import ConfirmHeader from './ConfirmHeader';
import {filter, includes} from 'lodash';
import style from '../../../styles';
import {BasketStatesTranslate} from '../../../constants';

class CartVehicles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {excluded_vehicles: [], checked: false};

        this.handleVehicle = this.handleVehicle.bind(this);
        this.handleEndSelection = this.handleEndSelection.bind(this);
    }

    handleVehicle(listVehicleId, event) {
        event.preventDefault();

        if (!includes(this.state.excluded_vehicles, listVehicleId)) {
            this.setState({excluded_vehicles: [...this.state.excluded_vehicles, listVehicleId]});
        }
        else {
            this.setState({excluded_vehicles: filter(this.state.excluded_vehicles, (id) => id !== listVehicleId)});
        }
    }


    handleEndSelection(){
        const {vehicles} = this.props;
        const {excluded_vehicles} = this.state;
        this.props.onSelectionEnd(filter(vehicles, (veh) => !includes(excluded_vehicles, veh.list_vehicle_id)));
    }

    render() {
        const {vehicles} = this.props;
        const {excluded_vehicles} = this.state;

        return (
            <div className="row bg-normal g-ma-0 g-mt-70 g-mb-200">

                <ConfirmHeader cancelBtnText={gettext('Cancel')}
                               nextBtnText={gettext('Check out')}
                               title={`01. ${gettext('Please verify your basket content.')}`}
                               onCancel={this.props.onCancel}
                               onNext={this.handleEndSelection}
                />


                <div className="row g-pa-0 g-ma-0" style={{width: '100%'}}>


                    {vehicles.map((veh, x) => {
                            const {
                                list_vehicle_id,
                                picture_url,
                                make,
                                model,
                                version,
                                basket_state,
                                list_number,
                            } = veh;


                            return (
                                <div key={list_vehicle_id}
                                     className="col-xl-6 col-md-6 col-12 g-pl-0 g-pt-0 g-pb-5 clickable"
                                     style={{paddingRight: (x % 2) ? 5 : 5}}
                                     onClick={this.handleVehicle.bind(this, list_vehicle_id)}
                                >

                                    <div className="row g-bg-white g-pa-10 g-ma-0">

                                        <div className="col-4 g-pa-0 g-ma-0 text-center">

                                            <div className="col-12 g-pa-0 g-ma-0" style={{position: 'relative'}}>
                                                {picture_url &&
                                                <img src={picture_url}
                                                     alt={make + ' ' + model}
                                                     className="img-fluid"/>}

                                                {!picture_url &&
                                                <img src={require("../../../img/nopic.jpeg")}
                                                     alt={make + ' ' + model}
                                                     className="img-fluid"/>}
                                            </div>

                                            <div className="col-12 g-pa-0 g-ma-0 g-valign-middle" style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                backgroundColor: style.highlightColor,
                                                color: style.highlightInvertColor,
                                                height: 22,
                                            }}>{gettext(BasketStatesTranslate[basket_state])}
                                            </div>
                                        </div>

                                        <div className="col-8  g-pl-10">
                                            <div className="row g-pl-20">
                                                <div className="col-10 col-xs-8 g-pa-0 g-ma-0">

                                                    <h3 className="h5 text-uppercase color-highlight g-mb-0 text-wrap">
                                                        #{String('000' + list_number).slice(-3)} {make} {model}
                                                    </h3>

                                                    <div
                                                        className="g-color-gray-dark-v5 g-font-style-normal text-uppercase g-font-size-12 g-mb-5 text-wrap">
                                                        {version}
                                                    </div>

                                                    <div style={{width: 150, paddingTop: 10}}>
                                                        <BidControl listVehicleId={list_vehicle_id}/>
                                                    </div>

                                                </div>

                                                <div className="col-2 col-xs-4 g-pa-0 g-ma-0 text-right" style={{top: -8}}>
                                                    <label>
                                                        <div className="form-check-inline u-check mb-0">
                                                            <input className="g-hidden-xs-up"
                                                                   type="checkbox"
                                                                   checked={!includes(excluded_vehicles, list_vehicle_id)}
                                                                   onChange={this.handleVehicle.bind(this, list_vehicle_id)}
                                                            />
                                                            <div className="u-check-icon-radio-v7">
                                                                <i className="fa" data-check-icon=""
                                                                   data-uncheck-icon=""></i>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>


                                            </div>
                                        </div>


                                    </div>


                                </div>
                            );
                        }
                    )}

                </div>

            </div>
        );

    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        vehicles: selectors.getVehiclesToConfirm(state, ownProps.listId),
    };
};


export default connect(mapStateToProps)(CartVehicles);








