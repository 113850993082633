import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateSort} from '../actions';
import {getSortById} from '../functions';
import * as selectors from '../selectors';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

class SortDropDown extends Component {
    constructor(props) {
        super(props);

        this.onSort = this.onSort.bind(this);

        const {listId, sorters, defaultSorterId} = props;
        this.props.updateSort(listId, getSortById(sorters, defaultSorterId));

    };



    onSort(id) {
        const {listId, sorters, onSortChanged} = this.props;

        this.props.updateSort(listId, getSortById(sorters, id));

        if(onSortChanged){
            onSortChanged();
        }
    }

    render() {
        const {sorters, activeSorter} = this.props;

        return (
            <Dropdown options={sorters.map((sorter) => ({label: sorter.label, value: sorter.id}))}
                      onChange={(item) => this.onSort(item.value)}
                      value={activeSorter}
                      placeholder="Select an option"
                      className="dropdown-no-border"
            />
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        activeSorter: selectors.getActiveSorter(state, ownProps.listId),
    };
};

export default connect(mapStateToProps, {updateSort})(SortDropDown);


