import React, {Component} from 'react';
import moment from 'moment'
import {gettext} from '../../../translations';
import format from 'string-format';

export default class CountDownLabel extends Component {
    constructor(props) {
        super(props);

        this.state = {time: Date.now(),};
        this.getTimeText = this.getTimeText.bind(this);
    }

    componentDidMount() {
        this.setInterval(1000);
    }

    setInterval(interval) {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.setState({time: Date.now()}), interval);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getTimeText = (date) => {
        const timeDifference = moment(date).diff(moment());

        if (timeDifference > 0) {
            return format(gettext('{0} left'), moment.duration(timeDifference).humanize());
        } else if (this.props.showStarted) {
            return gettext('Started')
        } else {
            return gettext('Closed');
        }
    };

    render() {
        const lessOneDay = (Math.round(moment(this.props.date).diff(moment(), 'hours', true))) <= 24 ? true : false;
        const color = lessOneDay ? '#009acb' : '#009acb';

        const text = this.getTimeText(this.props.date);

        return (
            <div>
                <i className="fa fa-bell g-mr-7" style={{color: color, alignSelf: 'center'}}></i>
                <span style={{alignSelf: 'center', color:color}}>
                    {text}
                </span>
            </div>
        );
    }
}





