import numeral from 'numeral';
import "numeral/locales";
import moment from 'moment-timezone';
import mnt from 'moment';
import 'moment/min/locales.min';
import "moment/locale/fr";
import "moment/locale/nl";
import "moment/locale/en-gb";
import "moment/locale/de";
import {config} from '../config';
import {ServerDate} from "../scripts/ServerDate";
const server_timezone = "Europe/Brussels";

const browserLocale = require('browser-locale')();
numeral.locale('nl-be');
moment.locale(browserLocale);
mnt.locale(browserLocale);

export const setFunctionsLocale = (locale) => {
    numeral.locale('nl-be');
    moment.locale(locale);
    mnt.locale(locale);
};

export const urlBuilder = (location) => {
    return `${config.base_url}${location}`;
};

export const createGuid = () => {
    const uuidv4 = require('uuid/v4');
    return uuidv4();
};

export const formatDateString = (dateString, format) => {
    return moment(dateString).format(format)
};

export const parseDateTZ = (dateString) => {
    return moment.tz(dateString, server_timezone);
};

export const formatDateTZ = (dateString, format) => {
    const currentTimeZone = moment.tz.guess();
    return parseDateTZ(dateString).tz(currentTimeZone).format(format);
};


export const formatPrice = (price, currency = true) => {
    const format = (currency) ? '$0,0' : '0,0';
    return `${numeral(Math.round(price)).format(format)}`;
};

export const formatPriceDecimal = (price, currency = true) => {
    const format = (currency) ? '$0,00.00' : '0,00.00';
    return `${numeral(price).format(format)}`;
};

export const numberFormat = (number) => {
    return `${numeral(number).format('0,0')}`;
};

export const getVehicleIdFromListVehicleId = (listVehicleId) => {
    return listVehicleId.substr(listVehicleId.indexOf('_') + 1, listVehicleId.length);
};

export const getListIdFromListVehicleId = (listVehicleId) => {
    return listVehicleId.substr(0, listVehicleId.indexOf('_'));
};

export const combineIds = (id1, id2) => {
    return `${id1}_${id2}`
};

export const getParamValue = (url, name) => {
    let parts = url.split('?');
    let params = parts[1].split('&');
    let val = "";

    for (let i = 0; i < params.length; i++) {
        let paramNameVal = params[i].split('=');

        if (paramNameVal[0] === name) {
            val = paramNameVal[1];
            break;
        }
    }
    return val;
};

export const download = (file, newWindow = false) => {
    if (!newWindow) {
        window.location.assign(file);
    } else {
        window.open(`${config.base_url}/${file}`);
    }
};

export const openUrl = (url) => {
    window.open(`${config.base_url}/${url}`);
};

export const openAdminUrl = (url) => {
    window.open(`${config.admin_url}/${url}`);
};



export const isExpired = (list_stop, vehicle_stop) => {
    const now_tz = ServerDate.now();
    const list_stop_tz = (list_stop) ? parseDateTZ(list_stop) : null;
    let vehicle_stop_ts = (vehicle_stop) ? parseDateTZ(vehicle_stop) : null;
    if(vehicle_stop_ts) {
        vehicle_stop_ts.add(4, 'seconds');
    }
    return ((list_stop_tz !== null) && now_tz > list_stop_tz) || (vehicle_stop_ts !== null && now_tz > vehicle_stop_ts);
};


