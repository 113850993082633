import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import * as selectors from '../selectors';
import {gettext} from '../../../../translations/index';
import { formatPrice} from '../../../../helpers/functions';
import {DealerStateTranslate} from '../../../../helpers/utils';
import classNames from 'classnames';
import {withConfig} from '../../../../config/index';

import {VehicleVatRegimeBadge, Expectation, VehicleDescriptionLabel, Specifications, Top9FeatureText} from '../../../core/index';
import {PrintButtons} from '../../../print';

class VehicleItem extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const {onClick} = this.props;
        onClick(this.props.id);
    }

    render() {
        const {id, vehicle, selected, config} = this.props;
        const {
            picture_url,
            make,
            model,
            version,
            main_company_name,
            vat_regime,
            description,
            description_plus,
            top_features_text,
            features_count,
        } = vehicle;


        const {list_vehicle_summary_fields, list_vehicle_summary_provisional_fields, list_vehicle_top_feature_icons} = config;
        const summaryFields = (vehicle.provisional_sale && !vehicle.expected_disclose) ? list_vehicle_summary_provisional_fields : list_vehicle_summary_fields;

        return (
            <article className={classNames({
                'item item--hover': true,
                'item-active': selected,
            })} onClick={this.onClick}>

                <div className="row g-pa-0 g-pa-5 g-pa-10--sm g-pa-10--md g-pa-10--lg g-pa-a-10--xl g-pb-5">

                    <div className="col-md-12">

                        <div className="row g-pa-0 g-ma-0 g-pos-rel g-nowrap">

                            <div className="col-11 col-md-6 col-lg-6 col-xl-6 g-pa-0 g-ma-0 text-wrap">
                                <h3 className="h5 font-size- d-inline-block text-uppercase color-highlight g-pa-0 g-ma-0 g-mr-10 text-wrap">
                                    {make} {model}
                                </h3>
                            </div>

                            <div className="col-6 text-right g-pa-0 g-ma-0 g-pl-10 g-hidden-sm-down">

                                {main_company_name &&
                                <span className="g-pa-5 g-color-gray-dark-v4">
                                    <i className="icon-map-pin"/> {main_company_name}
                                </span>}


                            </div>
                        </div>
                        <div
                            className="g-color-gray-dark-v5 g-font-style-normal text-uppercase g-font-size-12 g-mb-5 text-wrap">
                            {version}
                        </div>

                    </div>

                    <div className="col-4 col-md-3 col-lg-3">

                        <div>
                            {picture_url &&
                            <img src={picture_url}
                                 alt={make + ' ' + model}
                                 className="img-fluid"/>}

                            {!picture_url &&
                            <img src={require("../../../../img/nopic.jpeg")}
                                 alt={make + ' ' + model}
                                 className="img-fluid"/>}
                        </div>

                        <div
                            className="bg-highlight color-highlight-invert g-font-size-14 g-font-weight-600 text-center">
                            {formatPrice(vehicle.price)}
                        </div>

                        <PrintButtons listVehicleId={id}/>

                    </div>

                    <div className="col-8 col-md-9 col-lg-9 g-pl-0">

                        <div className="row g-pl-15 g-pt-8">
                            <span className="u-label g-rounded-3 bg-highlight g-mr-10 ">
                               {gettext(DealerStateTranslate[vehicle.dealer_state])}
                            </span>
                            <VehicleVatRegimeBadge vat_regime={vat_regime}/>
                        </div>

                        <Specifications vehicle={vehicle} fields={summaryFields} />


                        {!list_vehicle_top_feature_icons &&
                        <div className="g-mt-10 g-hidden-xs-down">
                            <Top9FeatureText features={top_features_text} count={features_count} />
                        </div>
                        }



                        <div className="g-mt-5">
                            <Expectation {...vehicle} />
                        </div>

                    </div>


                </div>

                {description_plus && description_plus !== "" &&
                <div
                    className="row g-ml-2 g-ml-10--md g-ml-10--lg g-ml-10--xl g-mr-2 g-mr-10--md g-mr-10--lg g-mr-10--xl"
                    style={{marginBottom: (description && description !== "") ? 5 : 10,}}>
                    <div className="col-12 g-pa-0 g-ma-0">
                        <VehicleDescriptionLabel description={description_plus}/>
                    </div>
                </div>}


                {description && description !== "" &&
                <div
                    className="row g-ml-10 g-ml-10--lg g-ml-10--xl g-mr-10--md g-mr-10--lg g-mr-10--xl g-mb-10"
                    style={{marginTop: (description_plus && description_plus !== "") ? 5 : 0,}}>
                    <div className="col-12 g-pa-0 g-ma-0">
                        <VehicleDescriptionLabel description={description} important/>
                    </div>
                </div>}

                {main_company_name &&
                <div className="col-md-12 g-pa-0 g-ma-0 g-hidden-md-up g-pl-2 g-pr-10 d-flex justify-content-between"
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span className="g-pa-5 g-color-gray-dark-v4 text-wrap"> <i
                                className="icon-location-pin"/> {main_company_name}</span>
                </div>}


            </article>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        vehicle: selectors.getVehicleById(state, ownProps.id),
    }
};

export default compose(
    withConfig,
    connect(mapStateToProps)
)(VehicleItem)
