import {createSelector} from 'reselect';
import {includes} from 'lodash';

export const getLoading = (state) => state.listvehicles_favorites.loading;
export const getError = (state) => state.listvehicles_favorites.error;
export const getListVehicleId = (state, listVehicleId) => listVehicleId;
export const getFavoriteVehicleIds = (state) => state.listvehicles_favorites.vehicleIds;
export const getFavoritesCount = (state) => getFavoriteVehicleIds(state).length;

export const makeGetListVehicleFavorite = () => {
    return listVehicleFavorite;
};
export const listVehicleFavorite = createSelector(
    [getListVehicleId, getFavoriteVehicleIds],
    (id, vehicleIds) => {
        return includes(vehicleIds, id)
    }
);

export const getVehicleById = (state, listVehicleId) => state.entities.vehicle[listVehicleId];







