import {RSAA} from 'redux-api-middleware';
import {types} from './types';
import {get} from 'lodash';
export const fetchOffer = (offerId) => {
    return (dispatch) => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/offers/${offerId}/`,
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: types.FETCH_REQUEST,
                        payload: {
                            offerId,
                        }
                    },
                    {
                        type: types.FETCH_SUCCESS,
                        payload: (action, state, res) => onSuccess(dispatch, res, offerId),
                    },
                    {
                        type: types.FETCH_FAILURE,
                        payload: (action, state, res) => onFailure(dispatch, res, offerId),

                    }
                ]
            }
        });
    };
};

const onSuccess = (dispatch, res, offerId) => {
    return res.json().then(result => {
        return {
            id: get(result, 'id'),
            name: get(result, 'name'),
            contact: get(result, 'seller_email'),
            date: get(result, 'time_stamp_sent'),
        };
    });
};

const onFailure = (dispatch, res, offerId) => {
    return {
        offerId,
        message: res.statusText,
    };
};

export const detailCleanup = () => {
    return {
        type: types.CLEANUP,
    }
};


