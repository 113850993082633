import React from 'react';
import {connect} from 'react-redux';
import * as selectors from './selectors';
import {fetchOffers} from './actions';
import {LoadingIndicator} from '../../../core/index';
import moment from 'moment';
import {map} from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {gettext} from '../../../../translations';

class OffersTable extends React.Component {

    componentDidMount() {
        const {fetchOffers} = this.props;
        fetchOffers();
    }

    render() {
        const {loading, error, data} = this.props;

        if(loading || data === null || data.length === 0 || error){
            return null;
        }

        if (loading) {
            return (
                <div id="custom-bootstrap-table-container"
                     style={{width: '100%', paddingTop: 130, border: '1px solid #eee'}}>
                    <LoadingIndicator loading={loading}/>
                    <div className="text-center g-mt-10">{gettext("Loading offers")}</div>
                </div>
            )
        }

        if (error) {
            return (
                <div id="custom-bootstrap-table-container"
                     style={{width: '100%', paddingTop: 130, border: '1px solid #eee'}}>
                    <div className="text-center">{error}</div>
                </div>
            )
        }

        if (data === null || data.length === 0) {
            return (
                <div id="custom-bootstrap-table-container"
                     style={{width: '100%', paddingTop: 130, border: '1px solid #eee'}}>
                    <div className="text-center">{gettext('You have no offers yet')}</div>
                </div>
            )
        }


        function offerFormatter(cell, row) {
            return (
                <div className="row">
                   <div className="col-9">
                       <div>
                           {(row.name !== null && row.name !== '') ? <span className="g-color-primary g-font-weight-600">{row.name}</span> : <span
                               className="font-italic g-color-primary g-font-weight-600"> {`<${gettext("Offer with no name")}>`}</span>}
                       </div>
                       <div>
                           {row.contact}
                       </div>
                   </div>

                    <div className="col-3" style={{textAlign: 'right'}}>
                        <div>
                            <span>NR-{cell}</span>
                        </div>
                        <div>
                            {`${row.count} ${gettext('vehicles')}`}
                        </div>
                    </div>
                </div>
            )
        }



        const columns = [
            {
                dataField: 'id',
                text: 'Name',
                formatter: offerFormatter,
                headerStyle: (colum, colIndex) => {
                    return {'display': 'none'};
                },

            },
        ];

        let tableData = map(data, (d) => ({
            id: d.id,
            name: d.name,
            count: d.vehicle_count,
            contact: d.seller_email,
            time: moment(d.time_stamp_sent).format('DD/MM/YYYY hh:mm'),
        }));


        const options = {
            sizePerPageList: [],
            sizePerPage: 5,
            pageStartIndex: 1,
            paginationSize: 3,
            paginationShowsTotal: false,
            paginationPosition: 'bottom',
            hideSizePerPage: true,
            alwaysShowAllBtns: true,
            withFirstAndLast: false,
            //  defaultSortName: 'average',
            //  defaultSortOrder: 'desc'
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectColumn: true,
            style: {cursor: 'pointer'},
            onSelect: (rowKey, checked, rowIndex) => {
                if (checked) {
                    this.props.onRowClick(rowKey);
                }
            },
        };

        return (
            <div id="custom-bootstrap-table-container">
                <h5 className="g-font-weight-600">{gettext('Offertes')}</h5>
                <BootstrapTable keyField='id'
                                data={ tableData }
                                columns={columns}
                                pagination={ paginationFactory(options) }
                                selectRow={ selectRow }
                                tableHeaderClass={"col-hidden"}
                                hover
                                condensed
                                bordered={ false }

                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        data: selectors.getData(state),
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
    }
};

export default connect(mapStateToProps, {fetchOffers})(OffersTable);

