import * as validators from './helpers/input-validators';
import * as FormFields from './components/web/forms/FormFields';

import {getFieldData, getFieldLabel} from './components/web/vehicle/Specifications';
export {default as SearchInput} from './components/web/search-input/SearchInput';
export {default as SearchableList} from './components/web/SearchableList';
export {LoadingContainer} from './components/web/LoadingContainer';
export {LoadingIndicator} from './components/web/LoadingIndicator';
export {CircleIcon} from './components/web/CircleIcon';
export {InfoContainer} from './components/web/InfoContainer';
export {LoadingButton} from './components/web/LoadingButton';
export {ContinueLabel} from './components/web/ContinueLabel';
export {default as WheelNotOutSide} from './components/web/WheelNotOutSide';

export {default as VehicleDamageBadge} from './components/web/vehicle/VehicleDamageBadge';
export {default as VehicleDescriptionLabel} from './components/web/vehicle/VehicleDescriptionLabel';
export {default as VehiclePictures} from './components/web/vehicle/VehiclePictures';
export {default as VehicleVatRegimeBadge} from './components/web/vehicle/VehicleVatRegimeBadge';

export {default as Specifications} from './components/web/vehicle/Specifications';
export {default as Top16FeatureIcons} from './components/web/vehicle/Top16FeatureIcons';
export {default as Expectation} from './components/web/vehicle/Expectation';
export {default as Top9FeatureText} from './components/web/vehicle/Top9FeatureText';

export {default as NotSupported} from './components/web/not-supported/NotSupported';
export {default as ComboMenu} from './components/web/combomenu/ComboMenu';
export {default as ErrorBoundary} from './components/web/application/ErrorBoundary';

export {default as Template} from './components/web/application/Template';

export {default as VehicleState} from './components/web/vehicle-state/VehicleState';

export {default as Rating} from './components/web/rating/Rating';
export {default as GDPR} from './components/web/GDPR';

export {validators};
export {FormFields}

export const SpecificationHelpers = {
    getFieldData,
    getFieldLabel,
};

