import React, {Component} from 'react';
import moment from 'moment-timezone'
import {gettext} from'../../../translations';
import {parseDateTZ} from "../../../helpers/functions";

class ListCountDown extends Component {
    constructor(props) {
        super(props);

        this.state = {time: moment()};
    }

    componentDidMount() {
        this.setInterval(1000);
    }

    setInterval(interval) {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.setState({time: moment()}), interval);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {
        let {date} = this.props;
        const date_tz = parseDateTZ(date);

        const seconds = date_tz.diff(moment(), "seconds");
        const duration = moment.duration(seconds, 'seconds');

        return (
            <div
                className="u-countdown-v3 g-line-height-1_2 g-font-weight-300 g-color-black text-center text-uppercase clearfix"
                style={{display: 'flex', justifyContent: 'center'}}>
                <div className="g-mx-5" style={{float: 'left'}}>
                    <div className="g-color-normal g-font-size-16 mb-0">{String('00' + duration.days()).slice(-2)}</div>
                    <span className="g-font-size-8">{gettext('Days')}</span>
                </div>

                <div className="hidden-down align-top g-font-size-16" style={{float: 'left'}}>:</div>

                <div className="g-mx-5" style={{float: 'left'}}>
                    <div className="g-color-normal g-font-size-16 mb-0">{String('00' + duration.hours()).slice(-2)}</div>
                    <span className="g-font-size-8">{gettext('Hours')}</span>
                </div>

                <div className="hidden-down d-inline-block align-top g-font-size-16" style={{float: 'left'}}>:</div>

                <div className="g-mx-5" style={{float: 'left'}}>
                    <div className="g-color-normal g-font-size-16 mb-0">{String('00' + duration.minutes()).slice(-2)}</div>
                    <span className="g-font-size-8">{gettext('Minutes')}</span>
                </div>

                <div className="hidden-down d-inline-block align-top g-font-size-16" style={{float: 'left'}}>:</div>

                <div className="g-mx-5" style={{float: 'left'}}>
                    <div className="g-color-normal g-font-size-16 mb-0">{String('00' + duration.seconds()).slice(-2)}</div>
                    <span className="g-font-size-8">{gettext('Seconds')}</span>
                </div>
            </div>

        );
    }
}

export default ListCountDown;
