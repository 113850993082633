import React from 'react';
import {connect} from 'react-redux';
import {logout} from '../actions';
import {gettext} from '../../../translations';

const SignOutButton = ({logout}) =>
    <li className="list-inline-item row g-ma-0 g-pa-0 clickable" onClick={logout}>
        <i className="fa fa-power-off g-mr-5 g-width-25 "
           style={{alignSelf: 'center'}}/>
        <span className="g-color-primary--hover">{gettext('Signout')}</span>
    </li>

export default connect(null, {logout})(SignOutButton);
