import React from 'react';
import WizardButtons from './WizardButtons';
import {find} from 'lodash';
import Dropzone from 'react-dropzone'
import {gettext} from '../../../../translations';
import ContactInfo from './ContactInfo';
import {createGuid} from "../../../../helpers/functions";
import {keys, map} from 'lodash';

const {detect} = require('detect-browser');
const browser = detect();

class WizardStepThree extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            documents: {}
        };

        this.onHandleNext = this.onHandleNext.bind(this);
        this.onHandlePrev = this.onHandlePrev.bind(this);
    }

    handleFile(documentKey, file) {
        this.setState({
            documents: {
                ...this.state.documents,
                [documentKey]: file
            }
        });
    }

    createUniqueFileName(file) {
        let fileExt = file.name.split('.').pop();
        console.log(`ext=${fileExt}`);

        if (fileExt && fileExt !== file.name) {
            console.log(`1`);
            return `${createGuid()}.${fileExt}`;
        } else {
            console.log(`2`);
            return createGuid();
        }
    }

    blobToFile(theBlob, fileName): File {
        const b = theBlob;
        b.lastModifiedDate = new Date();

        console.log(fileName);
        b.name = fileName;

        console.log(`bname=${b.name}`);
        return b;
    }

    onHandleNext() {
        const doc_keys = keys(this.state.documents);
        const renamed_arr = map(doc_keys, (k) => {
                switch (browser && browser.name) {
                    case 'edge':
                        const blb = Blob([this.state.documents[k]], {type: this.state.documents[k].type});
                        const f = this.blobToFile(blb, this.createUniqueFileName(this.state.documents[k]));
                        return {[k]: f};
                    default:
                        return {
                            [k]: new File([this.state.documents[k]], this.createUniqueFileName(this.state.documents[k]), {type: this.state.documents[k].type})
                        }
                }
            }
        );

        const renamed = Object.assign({}, ...renamed_arr);

        this.props.onNext(renamed);
    }

    onHandlePrev() {
        this.props.onPrev();
    }

    render() {
        const {registration, documentTypes, step, totalSteps} = this.props;

        return (
            <div className="g-pa-0 g-ma-0 g-mb-20">
                <div className="row g-bg-white  g-pa-0 g-ma-0 g-pt-20 g-pb-0 text-normal">


                    <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-lg-up g-pb-10 g-pa-0 g-ma-0 g-pl-5 gpl-10--sm g-pb-10">
                        <h5 className="g-color-primary g-mb-0 g-pb-0">{step}/{totalSteps} {gettext('Legal documents')}</h5>
                        <h6 className="g-pt-2">{gettext('Transparency above everything.')}</h6>

                        <p className="g-pt-5">{gettext('For Autralis, transparency is the highest good.')}</p>
                        <div className="text-light-gr">
                            {gettext('That is why we need the following (company) documents:')}
                            <ul>
                                <li>{gettext('Your identity card')}</li>
                                <li>{gettext('An empty invoice')}</li>
                                <li>{gettext('Statutes')}</li>
                                <li>{gettext('A picture of the dealer location')}</li>
                            </ul>
                        </div>

                        <p className="g-pt-10">{gettext('You may hide sensitive information on images. All information is processed confidentially.')}</p>

                        <ContactInfo/>

                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-md-down">
                        <h5 className="g-color-primary">{gettext('Transparency above everything.')}</h5>
                        <p className="g-pt-10">{gettext('For Autralis, transparency is the highest good.')}</p>
                        <div className="g-pt-10 text-light-gr">
                            {gettext('That is why we need the following (company) documents:')}
                            <ul>
                                <li>{gettext('Your identity card')}</li>
                                <li>{gettext('An empty invoice')}</li>
                                <li>{gettext('Statutes')}</li>
                                <li>{gettext('A picture of the dealer location')}</li>
                            </ul>
                        </div>

                        <p className="g-pt-10 g-pb-10">{gettext('You may hide sensitive information on images. All information is processed confidentially.')}</p>

                        <ContactInfo/>
                    </div>


                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0">

                        <div className="row" style={{justifyContent: 'flex-end'}}>

                            {documentTypes.map((docType) => {

                                    const document = find(registration.documents, function (doc) {
                                        return doc.name === `document_${docType.id}`
                                    });

                                    return (
                                        <Dropzone key={docType.id} style={{border: 'none', backgroundColor: '#f4f4f4'}}
                                                  className='col-5 g-ma-10' multiple={false} minSize={200}
                                                  onDrop={(files) => this.handleFile(docType.id, files[0])}>
                                            {({isDragActive, isDragReject, acceptedFiles, rejectedFiles}) => {

                                                const fileToUpload = (acceptedFiles.length > 0) ? acceptedFiles[0] : null;
                                                const fileRejected = (rejectedFiles.length > 0) ? rejectedFiles[0] : null;
                                                console.log(fileRejected);

                                                return (
                                                    <div className="g-pa-0 g-ma-0 clickable">
                                                        <div className="g-pa-0 g-ma-0 g-pt-20 text-center ">
                                                            <i className={`${docType.icon} g-font-size-80`}
                                                               style={{color: '#888888'}}></i>
                                                        </div>
                                                        <div className="g-pa-0 g-ma-0 g-pt-7 g-pb-15 text-center">
                                                            {gettext(docType.description)}
                                                        </div>

                                                        <div className="row text-center" style={{
                                                            backgroundColor: (document === undefined) ? (fileRejected) ? 'orange' : '#888888' : '#008e3b',
                                                            color: 'white',
                                                            height: 35,
                                                            paddingTop: 6,
                                                            paddingLeft: 6
                                                        }}>
                                                            <div>
                                                                {document !== undefined &&
                                                                <div>{gettext('Allready uploaded')}</div>}
                                                                {fileToUpload && <div>{fileToUpload.name} </div>}
                                                                {document === undefined && !fileToUpload && !fileRejected &&
                                                                <div>{gettext('Not uploaded')}</div>}
                                                                {fileRejected && <div>

                                                                    File rejected
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Dropzone>
                                    )
                                }
                            )}
                        </div>

                        <div className="row justify-content-end text-light-gr g-pr-10 g-font-size-12 font-italic">
                            {gettext("Click the icons to add documents from your computer files.")}
                        </div>

                    </div>
                </div>


                <div className="row g-pa-0 g-ma-0 g-bg-white">
                    <div className="col-12 g-pa-0 g-ma-0">
                        <WizardButtons currentStep={step}
                                       onNext={this.onHandleNext}
                                       onPrevious={this.onHandlePrev}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default WizardStepThree;
