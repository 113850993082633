import React, {Component} from 'react';
import VehicleStateContainer from '../components/listvehiclestate/_web/VehicleState';

class Vehicle extends Component {
    render() {
        const {vehicleId} = this.props.match.params;
        const {search} = this.props.location;
        const uniqueId = `PUBLIC_${vehicleId}`;

        return (
            <div>
                <VehicleStateContainer vehicleId={uniqueId} search={search}/>
            </div>
        )
    }
}

export default Vehicle
