import React, {Component} from 'react';
import {connect} from 'react-redux';
import ProfileForm, {profileFields} from './components/forms/ProfileForm';
import {submit} from 'redux-form'
import {LoadingIndicator} from '../../core';
import {fetchData, submitProfile} from '../actions';
import * as selectors from '../selectors';
import {gettext} from '../../../translations';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.formSubmit = this.formSubmit.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
    }

    componentDidMount() {
        this.props.fetchData();
    }

    formSubmit() {
        this.props.submit('Profile');
    }


    updateProfile(values) {
        const {profile, submitProfile} = this.props;
        submitProfile(profile.id, values);
    }


    render() {
        const {profile, countries, loading, error, hasAllData} = this.props;

        if (!hasAllData) {
            return  <div className="g-ma-0 g-mt-70"><LoadingIndicator loading={loading}/> </div>;
        }

        if ((!loading && profile === null)) {
            return <div> {gettext('No profile found.')}</div>;
        }



        return (
            <div className="g-ma-0 g-mt-70">
                <div
                    className="row sticky bg-normal g-pa-0 g-ma-0 justify-content-between g-bg-white g-pa-5 g-mb-10  bottom-shadow"
                    style={{zIndex: 50}}>

                    <h5 className="mb-2 color-highlight g-font-weight-800 g-pt-5">{gettext('Profile')}</h5>

                    <div className="row g-pa-0 g-ma-0 g-mr-5">

                        <div onClick={this.formSubmit}
                             className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6">
                            <i className=" fa fa-save g-mr-10"></i>{gettext('Save')}
                        </div>
                    </div>
                </div>

                <LoadingIndicator loading={loading}/>

                {error !== "" &&
                <div className="row g-pa-0 g-ma-0 container-important g-pa-5 g-mb-10 text-center g-bg-white">
                    {error}
                </div>}


                <ProfileForm initialValues={{
                    [profileFields.PERSONAL_TITLE]: profile.title,
                    [profileFields.PERSONAL_FIRST_NAME]: profile.first_name,
                    [profileFields.PERSONAL_LAST_NAME]: profile.last_name,
                    [profileFields.PERSONAL_PHONE]: profile.telephone,
                    [profileFields.PERSONAL_MOBILE]: profile.mobile,
                    [profileFields.PERSONAL_MAIL]: profile.email,

                    [profileFields.COMPANY_NAME]: profile.company.name,
                    [profileFields.COMPANY_VAT]: profile.company.vat_number,
                    [profileFields.COMPANY_ADDRESS]: profile.company.address.address,
                    [profileFields.COMPANY_COUNTRY]: profile.company.address.country_code,
                    [profileFields.COMPANY_POSTAL]: profile.company.address.post_code,
                    [profileFields.COMPANY_CITY]: profile.company.address.city,
                    [profileFields.COMPANY_MAIL]: profile.company.logistics_email,


                    [profileFields.SETTINGS_CAR_BY_CAR_NOTIFICATION]: profile.car_by_car_notification,
                    [profileFields.SETTINGS_CAR_BY_CAR_MAIL]: profile.car_by_car_mail,
                    [profileFields.SETTINGS_DAILY_DIGEST_MAIL]: profile.daily_digest,
                    [profileFields.SETTINGS_OFFERS_MAIL]: profile.offers_mail,
                    [profileFields.SETTINGS_WON_MAIL]: profile.won_mail,

                }}
                             required={true}
                             countries={countries}
                             onSubmit={this.updateProfile}
                />


            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const profile = selectors.getProfile(state);
    return {
        profile,
        countries: selectors.getCountries(state),
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
        hasAllData: selectors.hasAllData(state),
    };
};


export default connect(mapStateToProps, {fetchData, submitProfile, submit})(Profile);







