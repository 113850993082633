import React, {Component} from 'react';
import _ from 'lodash';
import {withConfig} from '../../../../../config';
import {gettext} from '../../../../../translations';
import PicturesGrid from './components/PicturesGrid';
import Checks from './components/Checks';
import Features from './components/Features';
import Damages from './components/Damages';
import Specifications from './components/Specifications';
import Summary from './components/Summary';
import Tyres from './components/Tyres';
import classNames from 'classnames';

class VehicleState extends Component {

    constructor(props) {
        super(props);

        this.onDamagePictureClick = this.onDamagePictureClick.bind(this);
    }

    onDamagePictureClick(picture) {
        const {vehicle, toggleSwiperVisibility, config} = this.props;
        const {vehicle_state_picture_type_order} = config;

        const sortedPictures = _.flatten(_.values(_.map(vehicle_state_picture_type_order, function (id) {
            return _.filter(vehicle.pictures, {'typeId': id});
        })));

        const pictures = _.concat(sortedPictures, _.flatten(_.map(vehicle.damages, 'pictures')));

        toggleSwiperVisibility(true, pictures, _.findIndex(pictures, {'src': picture.src}));
    }

    render() {
        const {vehicle, disabled,  showStickyHeader,  config, toggleSwiperVisibility, stickyTop, listVehicleId} = this.props;
        const {vehicle_state_summary_fields, vehicle_state_summary_provisional_fields, vehicle_state_summary_provisional_fields_reserved, vehicle_state_checks, vehicle_state_tyres, vehicle_state_damages, vehicle_state_options} = config;

        const summaryFields = (vehicle.provisional_sale && !vehicle.expected_disclose) ? (vehicle.dealer_state && vehicle.dealer_state === "RESERVED") ? vehicle_state_summary_provisional_fields_reserved: vehicle_state_summary_provisional_fields : vehicle_state_summary_fields;

        return (
            <div>

                {showStickyHeader &&
                <div className={classNames({
                    'sticky': false,
                    'item-disabled': disabled,
                })} style={{top: (stickyTop) ? stickyTop : 0}}>
                    <Summary vehicle={vehicle} listVehicleId={listVehicleId} config={config}/>
                </div>
                }

                <div className="clearfix">
                    <PicturesGrid vehicle={vehicle}
                                  onPictureClick={(index, pictures) => toggleSwiperVisibility(true, pictures, index)}
                    />
                </div>

                <div className="g-bg-white g-pa-5 g-pa-10--md g-pl-0 g-pr-0 g-mb-10">
                    <Specifications vehicle={vehicle}
                                    fields={summaryFields}/>
                </div>

                {vehicle_state_checks && vehicle.checks && vehicle.checks.length > 0 &&
                <div className="g-bg-white g-pa-5 g-pa-10--md g-mb-10">
                    <h5 className="h5 color-highlight g-mb- g-pb-5">
                        {gettext('Checks')}
                    </h5>
                    <Checks vehicle={vehicle}/>
                </div>}

                {vehicle_state_options && ((vehicle.features && vehicle.features.length > 0) || (vehicle.featuresText && vehicle.featuresText.length > 0)) &&
                <div className="g-bg-white g-pa-10 g-mb-10">
                    <h5 className="h5 color-highlight g-mb- g-pb-5">
                        {gettext('Features')}
                    </h5>
                    <Features vehicle={vehicle}/>
                </div>}

                {vehicle_state_tyres && vehicle.tyres && _.keys(vehicle.tyres).length > 0 && ((vehicle.tyres["summer"] !== undefined && vehicle.tyres["summer"].length > 0)  || (vehicle.tyres["summer"] !== undefined && vehicle.tyres["winter"].length > 0) )  &&
                <div className="g-bg-white g-pa-10 g-mb-10">
                    <h5 className="h5 color-highlight g-mb- g-pb-5">
                        {gettext('Tyres')}
                    </h5>
                    <Tyres vehicle={vehicle}/>
                </div>}

                {vehicle_state_damages &&
                <Damages vehicle={vehicle} onPictureClick={this.onDamagePictureClick}/>}

            </div>
        );
    }
}

VehicleState.defaultProps = {
    showStickyHeader: true,
    disabled: false,
};

export default withConfig(VehicleState)
