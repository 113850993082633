export const INITIAL_FILTERDATA = {
    companies: [],
    models: [],
    fuels: [],
    gearboxes: [],
    states: [],
    ranges: {
        age_min: 0,
        age_max: 1000,
        km_min: 0,
        km_max: 1000000,
        offeredDt_min: null,
        offeredDt_max: null,
    },
    basketOnly: false,
    interestWithNoBid: false,
};

export const INITIAL_FILTERCRITERIA = {
    makes: [],
    companies: [],
    fuels: [],
    gearboxes: [],
    states: [],
    km: {},
    age: {},
    price: {},
    basketOnly: false,
    interestWithNoBid: false,
    favorites: false,
    offeredDt_min: null,
    offeredDt_max: null,
    list: null,
    expectationDt_min: null,
    expectationDt_max: null,
};

export const INITIAL_STATE = {
    criteria: INITIAL_FILTERCRITERIA,
    data: INITIAL_FILTERDATA,
    loading: false,
    error: ''
};
