import React from 'react';
import {gettext} from '../../../translations';

const FiltersHeader = ({onCreateNew}) =>
    <div className="row g-pa-0 g-ma-0 justify-content-between g-bg-white g-pa-5 g-mb-10  bottom-shadow">

        <h5 className="mb-2 color-highlight g-font-weight-800 g-pt-5">{gettext('All filters')}</h5>

        <div onClick={onCreateNew}
             className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6 g-mr-10">
            <i className=" fa fa-plus g-mr-10"></i>{gettext('New')}
        </div>
    </div>

export default FiltersHeader;


