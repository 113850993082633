import {RSAA} from 'redux-api-middleware';
import {warningTypes} from './types';

export const fetchWarnings = () => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/content/9/`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': true,
                },
                types: [
                    {
                        type: warningTypes.FETCH_REQUEST,
                    },
                    {
                        type: warningTypes.FETCH_SUCCESS,
                        payload: (action, state, res) => onFetchSuccess(dispatch, res),
                    },
                    {
                        type: warningTypes.FETCH_FAILURE,

                    }
                ]
            }
        });
    };
};

const onFetchSuccess = (dispatch, res) => {
    return res.json().then(data => {

        let warnings = [];

        if (data.content && Array.isArray(data.content)) {
            warnings = data.content
        }

        return {
            warnings
        }

    });
};

