import React, {Component} from 'react';
import {Template} from '../components/core/index';
import Lists from '../components/lists/_web/Lists';
import {goToList, goToHistory} from './navigation/routes';
import {gettext} from '../translations/index';
import {Stats} from '../components/statistics/index';

import {Warnings} from '../components/warnings'
import {ApprovalCheck} from '../components/approval-check';

const CORR = 240;

class DashBoard extends Component {
    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);

        window.addEventListener('resize', this.handleResize, true);

        this.state = {
            height: window.innerHeight - CORR,
            minApprovalsRequired: 1
        };
    }

    handleResize() {
        this.setState({
            height: window.innerHeight - CORR,
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, true);
    }

    render() {
        return (
            <Template>
                <div style={{position: 'relative'}} className="row bg-normal g-ma-0 g-pa-0 g-mb-200 g-mt-70">
                    <ApprovalCheck/>

                    <Warnings/>

                    <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 g-ma-0 g-pl-5 g-pr-5 g-mb-20 g-mb-0--sm">
                        <Lists onListSelect={(listId) => goToList(listId)}
                               onNoData={() => {
                                   // if (this.state.minApprovalsRequired !== 5) {
                                   //     this.setState({minApprovalsRequired: 5})
                                   // }
                               }}/>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 g-ma-0 g-pl-5 g-pr-5 ">
                        <div className="bg-normal clickable" style={{zIndex: 50}} onClick={() => goToHistory()}>
                            <div className="g-bg-white g-pa-10 g-mb-10  bottom-shadow">
                                <div className="justify-content-between align-items-center d-flex g-mb-0">
                                    <div className="d-flex text-uppercase">
                                        {gettext('History')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="g-bg-white" style={{height: this.state.height, width: '100%', overflow: 'auto'}}>*/}

                        <Stats/>


                    </div>
                </div>
            </Template>
        )
    }
}

export default DashBoard;

