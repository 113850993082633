import React from 'react';
import {ClipLoader} from 'react-spinners';

export const LoadingButton = ({loading, text}) => {
    return (
        <button type="submit"
                disabled={loading}
                className="btn btn-block u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 text-uppercase rounded-0 g-px-25 g-py-15">

            <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="g-mr-10">
                    <ClipLoader
                        color="white"
                        loading={loading}
                        size={16}
                        style={{marginRight: 20}}

                    />
                </div>
                {text}
            </div>

        </button>
    )
}


