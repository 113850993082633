import React from 'react';
import {connect} from 'react-redux';
import InviteForm from './InviteForm';
import {submit} from 'redux-form'
import {gettext} from '../../../translations';
import {createColleague} from '../actions';
import {LoadingIndicator} from '../../core';
import * as selectors from '../selectors';
import {login} from '../../../_web/navigation/routes';
import { SubmissionError } from 'redux-form';

class Invite extends React.Component {
    render() {
        const invite = {
            company_key: this.props.inviteId,
            title: "",
            first_name: "",
            last_name: "",
            email: "",
            telephone: "",
            mobile: ""
        };

        const {submit, loading, error} = this.props;

        if (loading) {
            return <LoadingIndicator loading={loading}/>
        }


        return (
            <div className="g-pa-0 g-ma-0 g-mb-20">

                <h2 className="mb-4 g-color-primary">{gettext('Register')}</h2>

                <div className="row g-bg-white  g-pa-0 g-ma-0 g-pb-0 g-mb-5">

                    <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-lg-up g-pb-10 g-pa-0 g-ma-0 g-pb-10">
                        <h6 className="g-pt-2">{gettext('Welcome! A colleague has invited you.')}</h6>
                        <h6 className="g-pt-2">{gettext('You can now complete the registration.')}</h6>
                        <p className="g-pt-5 g-ma-0">{gettext("Enter the company key. With this we can link your account to the right company.")} </p>
                        <p className="g-pt-5 g-ma-0 g-pb-10">{gettext("Then enter your personal details. With correct data we can offer you the support you expect from us.")} </p>


                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-pa-0 g-ma-0 g-hidden-md-down">
                        <h5 className="g-pt-2">{gettext('Welcome! A colleague has invited you.')}</h5>
                        <h5 className="g-pt-2">{gettext('You can now complete the registration.')}</h5>
                        <p className="g-pt-10 g-pb-10">{gettext("Enter the company key. With this we can link your account to the right company.")} </p>
                        <p className="g-pt-10 g-pb-10">{gettext("Then enter your personal details. With correct data we can offer you the support you expect from us.")} </p>


                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0">
                        <InviteForm initialValues={invite}
                                    required={true}
                                    onSubmit={(values) => {
                                        return this.props.createColleague(values).then((result) => {
                                            if (result.payload && result.payload.status === 'Ok') {
                                                login();
                                            }
                                            else {
                                                const {payload} = result;
                                                if(payload && payload.serverValidationErrors !== null && payload.serverValidationErrors.length > 0){
                                                    throw new SubmissionError(Object.assign({}, ...payload.serverValidationErrors))
                                                }
                                            }
                                        });
                                    }}
                        />

                        {error !== "" &&
                        <div className="row g-pa-0 g-ma-0 container-important g-pa-5 ">
                            {error}
                        </div>}

                    </div>
                </div>

                <div className="row g-pa-0 g-ma-0 g-bg-white">
                    <div className="col-12 g-pa-0 g-ma-0">
                        <div className="row bg-white g-ma-0 g-pt-20 g-pb-20 g-pr-15 justify-content-end">
                            <button type="button"
                                    onClick={() => submit('Invite-PersonalInfo')}
                                    className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-5 g-py-5 g-width-90 g-ml-10">
                                {gettext('Register')}<i
                                className="fa fa-chevron-right g-font-size-12 g-valign-middle g-ml-10"></i>
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state)
    };
};


export default connect(mapStateToProps, {submit, createColleague})(Invite);

