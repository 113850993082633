import React from 'react';
import {Tooltip} from 'reactstrap';
import {capitalize} from 'lodash';

class HeaderWithToolTip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
        };

        this.onClick = this.onClick.bind(this);
        this.hide = this.hide.bind(this);
        this.toggle = this.toggle.bind(this);

    }

    onClick(e) {
        e.stopPropagation();
        this.setState({
            tooltipOpen: true,
        });
    }

    hide() {
        if (this.state.tooltipOpen) {
            this.setState({
                tooltipOpen: false,
            });
        }
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });

    }

    getHeaderText(text, maxLength){
        let tempText = text;
        if(tempText.includes(' ')){
            tempText = tempText.split(' ')[1];
        }
        return capitalize(tempText.substring(0, maxLength));

    }

    render() {
        const {text, index, maxLength} = this.props;
        const uniqueId = `header_${index}`;

        return (
            <div>
                <span id={uniqueId}>{`${this.getHeaderText(text, maxLength)}.`} </span>
                <Tooltip target={uniqueId}
                         isOpen={this.state.tooltipOpen}
                         placement={this.props.toolTipLocation}
                         toggle={this.toggle}
                         delay={{show: 50, hide: 0}}
                >
                    {text}
                </Tooltip>
            </div>
        )
    }
}

HeaderWithToolTip.defaultProps = {
  maxLength: 3
};

export default HeaderWithToolTip;
