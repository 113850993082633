import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {updateCriteria} from '../actions';
import * as criteriaHelpers from '../functions/criteria';
import * as listVehiclesFilterSelectors from '../selectors';
import FilterSummaryTag from './FilterSummaryTag';
import moment from 'moment';
import {gettext} from '../../../translations';

class FilterSummary extends Component {
    setCriteria(criteria) {
        const {listId, updateCriteria} = this.props;
        updateCriteria(listId, criteria).then(() => this.props.onCriteriaChanged());
    }

    onDeleteMakeModel(mm) {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeMakeModel(criteria, mm));
    }

    onDeleteDealer(dealer) {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeCompany(criteria, dealer));
    }

    onDeleteState(state) {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeState(criteria, state));
    }

    onDeleteFuel(fuel) {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeFuel(criteria, fuel));
    }

    onDeleteGearbox(gearbox) {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeGearbox(criteria, gearbox));
    }

    onDeleteKm() {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeKm(criteria));
    }

    onDeleteAge() {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeAge(criteria));
    }


    onDeleteOfferedDt() {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeOfferedDt(criteria));
    }

    onDeleteExpectationdDt() {
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeExpectationDt(criteria));
    }

    onDeleteList(){
        const {criteria} = this.props;
        this.setCriteria(criteriaHelpers.removeList(criteria));
    }

    render() {
        const {makeModels, criteria, data,} = this.props;

        return (<div className="filter-summary-tags-list">

                {criteria.list !== null &&
                <FilterSummaryTag key={`list-filter-summary-tag`}
                                  text={criteria.list.name}
                                  onClick={() => this.onDeleteList()}
                />
                }

                {criteria.companies.map((id, i) =>
                    <FilterSummaryTag key={`dealer-filter-summary-tag-${i}`}
                                      text={_.get(_.find(data.companies, {id}), 'name')}
                                      onClick={() => this.onDeleteDealer(id)}
                    />
                )}

                {makeModels.map((mm, i) =>
                    <FilterSummaryTag key={`mm-filter-summary-tag-${i}`}
                                      text={`${_.capitalize(mm.make)} ${_.capitalize(mm.model)}`}
                                      onClick={() => this.onDeleteMakeModel(mm)}
                    />
                )}

                {criteria.fuels.map((key, i) =>
                    <FilterSummaryTag key={`fuel-filter-summary-tag-${i}`}
                                      text={_.get(_.find(data.fuels, {key}), 'name')}
                                      onClick={() => this.onDeleteFuel(key)}
                    />
                )}

                {criteria.gearboxes.map((key, i) =>
                    <FilterSummaryTag key={`gearbox-filter-summary-tag-${i}`}
                                      text={_.get(_.find(data.gearboxes, {key}), 'name')}
                                      onClick={() => this.onDeleteGearbox(key)}
                    />
                )}

                {criteria.km && criteria.km.min && criteria.km.max &&
                <FilterSummaryTag key={`km-filter-summary-tag`}
                                  text={`${criteria.km.min} - ${criteria.km.max} km`}
                                  onClick={() => this.onDeleteKm()}
                />
                }

                {criteria.age && criteria.age.min && criteria.age.max &&
                <FilterSummaryTag key={`age-filter-summary-tag`}
                                  text={`${criteria.age.min} - ${criteria.age.max} months`}
                                  onClick={() => this.onDeleteAge()}
                />
                }

                {criteria.states.map((id, i) =>
                    <FilterSummaryTag key={`state-filter-summary-tag-${i}`}
                                      text={_.get(_.find(data.states, {code: id}), 'name')}
                                      onClick={() => this.onDeleteState(id)}
                    />
                )}

                {criteria.offeredDt_min && criteria.offeredDt_max &&
                <FilterSummaryTag key={`offeredDt-filter-summary-tag`}
                                  text={`${gettext('Offered')}: ${moment(criteria.offeredDt_min).format('DD/MM/YYYY')} - ${moment(criteria.offeredDt_max).format('DD/MM/YYYY')}`}
                                  onClick={() => this.onDeleteOfferedDt()}
                />
                }

                {criteria.expectationDt_min && criteria.expectationDt_max &&
                <FilterSummaryTag key={`expectationDt-filter-summary-tag`}
                                  text={`${gettext('expectation')}: ${moment(criteria.expectationDt_min).format('DD/MM/YYYY')} - ${moment(criteria.expectationDt_max).format('DD/MM/YYYY')}`}
                                  onClick={() => this.onDeleteExpectationdDt()}
                />
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {listId} = ownProps;

    return {
        makeModels: _.map(listVehiclesFilterSelectors.getCriteria(state, listId).makes, (mm) => ({
            make: mm.make,
            model: mm.model
        })),
        criteria: listVehiclesFilterSelectors.getCriteriaCopy(state, listId),
        data: listVehiclesFilterSelectors.getData(state, listId),
    }
};

export default connect(mapStateToProps, {updateCriteria})(FilterSummary);
