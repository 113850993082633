import React from 'react';
import {compose} from 'redux';
import {createBrowserHistory} from 'history';
import {LocalizedContainer} from '../components/localization/'
import PictureSwiper from '../components/picture-swiper/_web/PictureSwiper';
import {NotificationsHandler} from '../components/push_notifications'
import {withConfig} from '../config';
import {isAuthenticated} from '../components/session';
import {connect} from 'react-redux';
import PrivateRoute from './navigation/PrivateRoute';
import Login from './Login';
import {Faq} from '../components/FAQ'
import {ToastContainer} from 'react-toastify';
import DashBoard from './Dashboard';
import List from './List';
import Offer from './Offer';
import History from './History';
import ListVehicle from './ListVehicle';
import VehicleState from './VehicleState';
import MyProfile from './Profile';
import Filters from './Filters';
import Register from './Registration';
import RegistrationWizard from './RegistrationWizard';
import {PageNotFound} from '../components/404';
import {UserNotAllowed} from '../components/403';
import ForgotPassword from './ForgotPassword';
import TermsAndConditions from './TermsAndConditions';
import Privacy from './Privacy';
import Activate from './Activate';
import Invite from './Invite';
import Unsubscribe from './Unsubscribe';
import Frontend from './Frontend';
import {Router, Route, Switch} from 'react-router-dom';
import {createConfig} from '../config';
import ReactGA from 'react-ga';
import {GDPR} from '../components/core';
import firebase from 'firebase/app';
import socketIOClient from "socket.io-client";
import {entityActions} from "../entities/actions";
import {combineIds} from "../helpers/functions";
import {vehicleBasketFetch} from "../components/listvehiclestate/actions";
import _ from "lodash";

createConfig();

export const history = createBrowserHistory();


class WebApp extends React.Component {
    constructor(props) {
        super(props);

        this.subscribeToSocketIo = this.subscribeToSocketIo.bind(this);
    }

    subscribeToSocketIo() {
        if (this.props.config.socket_io_url && this.props.config.socket_io_url !== "") {
            this.socket = socketIOClient(this.props.config.socket_io_url);
            this.socket.on("autralis_sales_message", (jsonData) => {
                const data = JSON.parse(jsonData);

                switch (data.methodName) {
                    case "SalesVehicleOfferUpdated":
                        let result = {
                            vehicle: {
                                [combineIds(data.listId, data.vehicleId)]: {
                                    price: data.price,
                                    vehicle_state: data.state,
                                    vehicle_stop: data.stop,
                                    last_server_update: Date.now(),
                                    reserve_price_met: _.get(data, 'reserve_met') || null,
                                    reserve_price_almost_met: _.get(data, 'reserve_almost_met') || null,
                                    live_auction_hidden: false,
                                }
                            }
                        };
                        this.props.dispatch(vehicleBasketFetch(data.listId, data.vehicleId));
                        this.props.dispatch(entityActions.updateEntities(result));
                        break;
                    default:
                        break;
                }
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.authenticated && nextProps.authenticated) {
            this.subscribeToSocketIo();
        } else if (this.props.authenticated && !nextProps.authenticated) {
            if (this.socket) {
                this.socket.disconnect()
            }
        }
    }


    componentDidMount() {
        if (this.props.config.analytics_id !== null) {
            ReactGA.initialize(this.props.config.analytics_id);
            history.listen(location => ReactGA.pageview(location.pathname + location.search));

            if (this.props.authenticated) {
                this.subscribeToSocketIo();
            }
        }
    }


    render() {
        const {authenticated, config} = this.props;
        const {app_push_notifications} = config;

        return (
            <LocalizedContainer defaultLocale={this.props.locale}>
                <Router history={history}>
                    <Switch>
                        <Route exact path="/registration" component={Register}/>
                        <Route exact path="/activate/:registrationId/:uniqueId" component={Activate}/>
                        <Route exact path="/invite/:inviteId" component={Invite}/>

                        <Route exact path="/forgot-password/:uniqueId?" component={ForgotPassword}/>
                        <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
                        <Route exact path="/privacy" component={Privacy}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/vehicle/:vehicleId" component={VehicleState}/>

                        {config.faq &&
                        <Route exact path="/faq" component={Faq}/>}

                        <PrivateRoute exact path="/unsubscribe" component={Unsubscribe}/>
                        <PrivateRoute exact path="/registration-wizard" component={RegistrationWizard}/>
                        <PrivateRoute exact path="/403" component={UserNotAllowed}/>
                        <PrivateRoute exact path="/list/:listId" component={List}/>
                        <PrivateRoute exact path="/offer/:offerId" component={Offer}/>
                        <PrivateRoute exact path="/list-vehicle/:listVehicleId" component={ListVehicle}/>
                        <PrivateRoute exact path="/state/:listVehicleId" component={ListVehicle}/>
                        <PrivateRoute exact path="/history" component={History}/>
                        <PrivateRoute exact path="/profile" component={MyProfile}/>
                        <PrivateRoute exact path="/filters" component={Filters}/>
                        <PrivateRoute exact path="/dashboard" component={DashBoard}/>
                        <Route exact path="/" component={Frontend}/>


                        <Route render={() => <PageNotFound/>}/>
                    </Switch>
                </Router>

                {firebase.messaging.isSupported() && app_push_notifications && authenticated &&
                <NotificationsHandler authenticated={authenticated}/>}

                <ToastContainer progressClassName="notifications-progress-bar"/>

                <PictureSwiper/>

                <GDPR/>
            </LocalizedContainer>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        authenticated: isAuthenticated(state),
    };
};


export default compose(
    withConfig,
    connect(mapStateToProps),
)(WebApp)


