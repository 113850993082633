import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as selectors from '../selectors';
import {nextListVehicleId, prevListVehicleId} from '../functions';
import {getListIdFromListVehicleId} from '../../../helpers/functions'
import {gettext} from '../../../translations';

class NextPrevButtons extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(listVehicleId) {
        this.props.onClick(listVehicleId);
    }

    render() {
        const {prevVehicleId, nextVehicleId} = this.props;

        let prevDisabled = prevVehicleId === null || typeof prevVehicleId === 'undefined';
        let nextDisabled = nextVehicleId === null || typeof nextVehicleId === 'undefined';

        if (!prevDisabled || !nextDisabled) {
            return (
                <div className="row bg-white g-ma-0 p-pa-0">

                    <button disabled={prevDisabled}
                            onClick={() => this.onClick(prevVehicleId)}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-width-100">
                        <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"/>
                        <span className="g-hidden-xs-down">
                            {gettext('Previous')}
                        </span>
                    </button>

                    <button disabled={nextDisabled}
                            onClick={() => this.onClick(nextVehicleId)}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-width-100 g-ml-10">
                        <span className="g-hidden-xs-down">
                            {gettext('Next')}
                        </span>
                        <i className="fa fa-chevron-right g-font-size-12 g-valign-middle g-ml-10"/>
                    </button>
                </div>
            );
        }
        else {
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    const {listVehicleId} = ownProps;
    const listId = getListIdFromListVehicleId(listVehicleId);
    const listVehicleIds = selectors.getVehicleIds(state, listId);

    return {
        nextVehicleId: nextListVehicleId(listVehicleIds, listVehicleId),
        prevVehicleId: prevListVehicleId(listVehicleIds, listVehicleId),
    };
};


export default connect(mapStateToProps)(NextPrevButtons);


