import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Template} from '../components/core/index';
import {ListVehicleState} from '../components/listvehiclestate/index';
import {fetchList} from '../components/lists/actions';
import {listVehicleBasketFetch} from '../components/listvehiclestate/actions';
import {listVehiclesInterestFetch} from '../components/listvehicles-no-interest/actions';
import {listVehiclesFavoriteFetch} from '../components/listvehicles-favorite/actions';
import {dashboard, backToList} from './navigation/routes';
import {BidControl} from '../components/bidding/index';
import {listVehicleFetch} from '../entities/listvehicle/actions';
import {getVehicleIdFromListVehicleId, getListIdFromListVehicleId} from '../helpers/functions';
import {listCleanupByListVehicleId} from '../components/lists/actions';
import {withRouter} from 'react-router-dom';
import {getListById} from '../components/lists/index';
import {PrintButtons} from '../components/print';
import {compose} from "redux";
import {withConfig} from "../config/index.native";

class ListVehicle extends Component {
    constructor(props) {
        super(props);

        this.state = {closing: false};
        this.handleBack = this.handleBack.bind(this);
    }

    componentWillUnmount() {
        if (this.state.closing && this.props.history.action === 'POP') {
            const {listVehicleId, listCleanupByListVehicleId} = this.props;
            listCleanupByListVehicleId(listVehicleId);
        }
    }

    componentDidMount() {
        const {listVehicleId, config} = this.props;
        this.props.fetchList(getListIdFromListVehicleId(listVehicleId));
        this.props.listVehicleBasketFetch(listVehicleId);

        if(config.vehicle_interest) {
            this.props.listVehiclesInterestFetch(getListIdFromListVehicleId(listVehicleId));
        }
        debugger;

        if(config.vehicle_favorite) {
            this.props.listVehiclesFavoriteFetch(getListIdFromListVehicleId(listVehicleId));
        }

        this.props.listVehicleFetch(getListIdFromListVehicleId(listVehicleId), getVehicleIdFromListVehicleId(listVehicleId));
    }

    handleBack() {
        const {listVehicleId, listCleanupByListVehicleId} = this.props;
        this.setState({closing: true});

        listCleanupByListVehicleId(listVehicleId).then(() => {
                if (this.props.list && this.props.list.state === 'OPEN') {
                    backToList(this.props.list.id);
                } else {
                    dashboard();
                }

            }
        );
    }

    render() {
        const {listVehicleId} = this.props;

        return (
            <Template>

                <div className="bg-normal g-ma-0 g-mt-70 g-mb-200 g-pl-5 g-pr-5">

                    <div
                        className="sticky sticky-md-down bg-white row g-pa-5 g-ma-0 justify-content-between g-brd-bottom g-brd-10 brd-bg g-brd-primary">


                        <button onClick={this.handleBack}
                                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                            <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"></i>
                            <span
                                className="g-hidden-xs-down">{(this.props.list && this.props.list.state === 'OPEN') ? 'Back to list' : 'Back to dashboard'}</span>
                        </button>


                        <div className="row g-pa-0 g-ma-0" style={{minWidth: 150}}>

                            <div className="g-pr-5">
                                <PrintButtons listVehicleId={listVehicleId}/>
                            </div>

                            <BidControl listVehicleId={listVehicleId}/>
                        </div>


                    </div>

                    <ListVehicleState listVehicleId={listVehicleId} containerId={listVehicleId} stickyTop={120}/>
                </div>
            </Template>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {listVehicleId} = ownProps.match.params;
    const listId = getListIdFromListVehicleId(listVehicleId);

    return {
        listVehicleId,
        list: getListById(state, listId),
    };
};


export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps, {
        listVehicleFetch,
        fetchList,
        listVehicleBasketFetch,
        listVehiclesInterestFetch,
        listVehiclesFavoriteFetch,
        listCleanupByListVehicleId
    }),
)(ListVehicle)
