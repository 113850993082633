import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import * as selectors from '../selectors';
import CircleWithLabel from './CircleWithLabel';
import ListInfo from './ListInfo';
import CountDownLabel from './CountDownLabel';
import {download, formatDateTZ} from '../../../helpers/functions';
import {withConfig} from '../../../config';
import {updateCriteriaInterestWithNoBid, updateCriteriaFavorites} from '../../listvehicles-filter/index';
import {ListTypes} from "../../../constants";
import {gettext} from "../../../translations";
import {sortBy} from "lodash";
import moment from "moment";

class ListItem extends React.Component {
    constructor(props) {
        super(props);

        this.showListDetails = this.showListDetails.bind(this);
        this.onPdfClick = this.onPdfClick.bind(this);
        this.onCounterClick = this.onCounterClick.bind(this);
        this.renderListBadge = this.renderListBadge.bind(this);
    }

    showListDetails() {
        this.props.onSelectList(this.props.list.id);
    };

    onPdfClick(e) {
        e.stopPropagation();
        const {listId} = this.props;
        download(`sales/portal/downloadList/${listId}`, true);
    }

    onCounterClick(code) {
        if (code === 'OFFERED') {
            const {listId, updateCriteriaInterestWithNoBid} = this.props;
            updateCriteriaInterestWithNoBid(listId, true);
        } else if (code === 'FAVOURITES') {
            const {listId, updateCriteriaFavorites} = this.props;
            updateCriteriaFavorites(listId, true);
        }

    }

    renderListBadge(list) {
        const {config} = this.props;
        switch (list.type) {
            case ListTypes.FIXED:
                return (
                    <div className="g-pa-5" style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>

                        <h3 className="g-font-weight-600 color-highlight g-font-size-16 ">
                            <span>{list.name}</span>

                            {config.list_download_pdf &&
                            <i className="fa fa-file-pdf-o g-ml-10 g-pt-3" style={{fontSize: 16}}
                               onClick={this.onPdfClick}/>}
                        </h3>

                        <h3 className="g-font-weight-600 color-highlight g-font-size-16">
                            <div className="u-label g-rounded-3 badge g-ml-10 g-px-10 g-py-5 g-text-white g-color-white" style={{backgroundColor: '#e29300'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}><i className="fa fa-handshake-o g-mr-10 g-font-size-18 g-color-white"/> {gettext("Fixed price")}</div>
                            </div>
                        </h3>

                    </div>

                );
            case ListTypes.TENDER:
                return (
                    <div className="g-pa-5" style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>

                        <h3 className="g-font-weight-600 color-highlight g-font-size-16 ">
                            <span>{list.name}</span>

                            {config.list_download_pdf &&
                            <i className="fa fa-file-pdf-o g-ml-10 g-pt-3" style={{fontSize: 16}}
                               onClick={this.onPdfClick}/>}
                        </h3>

                        <h3 className="g-font-weight-600 color-highlight g-font-size-16">
                            <div className="u-label g-rounded-3 badge g-ml-10 g-px-10 g-py-5 g-text-white g-color-white" style={{backgroundColor: '#e2cb00'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}><i className="fa fa-gavel g-mr-10 g-font-size-18 g-color-white"/> {gettext("Blind bidding")}</div>
                            </div>
                        </h3>

                    </div>
                );
            case ListTypes.LIVE_AUCTION:
                return (
                    <div className="g-pa-5" style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>

                        <h3 className="g-font-weight-600 color-highlight g-font-size-16 ">
                            <span>{list.name}</span>

                            {config.list_download_pdf &&
                            <i className="fa fa-file-pdf-o g-ml-10 g-pt-3" style={{fontSize: 16}}
                               onClick={this.onPdfClick}/>}
                        </h3>

                        <h3 className="g-font-weight-600 color-highlight g-font-size-16">
                            <div className="u-label g-rounded-3 badge g-ml-10 g-px-10 g-py-5 g-text-white g-color-white" style={{backgroundColor: '#e25a00'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}><i className="fa fa-gavel g-mr-10 g-font-size-18 g-color-white"/> {gettext("Live Auction")}</div>
                            </div>
                        </h3>

                    </div>
                );
        }
    }


    render() {
        const {list} = this.props;
        const {id, stop, first_auction_vehicle_stop_time, counters, list_info} = list;
        const sorted_counters = sortBy(counters, ['count'], ['asc'])
        const showAuctionStartTimer = first_auction_vehicle_stop_time && moment(stop).diff(moment()) > 0

        return (

            <div className="item item--hover g-mb-5 g-bg-white clickable g-color-primary" onClick={() => this.props.onListSelect(id)}>
                {this.renderListBadge(list)}

                <div className="row g-pa-0 g-ma-0">
                    <div className="col-7 g-pa-5 g-ma-0">
                        {list_info &&
                        list_info.map((info, index) =>
                            <ListInfo {...info} key={index}/>
                        )}
                    </div>

                    <div className="col-5 g-pa-0 g-ma-0 g-pt-5 g-pr-5 g-pr-15--sm pull-right"
                         style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        {counters &&
                        sorted_counters.map((counter, index) =>
                            <CircleWithLabel size={60}
                                             text={counter.count}
                                             title={counter.name}
                                             color={counter.color}
                                             key={index}
                                             code={counter.key}
                                             onClick={this.onCounterClick}
                            />
                        )}
                    </div>
                </div>

                {showAuctionStartTimer &&
                <div className="row g-pa-0 g-ma-0 g-pl-5 g-pr-5 g-pr-15--sm  g-pb-5 justify-content-between">
                    <div>
                        <i className="fa fa-hourglass-start g-mr-7" style={{alignSelf: 'center'}}></i>
                        {formatDateTZ(first_auction_vehicle_stop_time, 'DD/MM/YYYY HH:mm')}
                    </div>
                    <CountDownLabel date={first_auction_vehicle_stop_time} showStarted={true}/>
                </div>
                }
                {stop &&
                <div className="row g-pa-0 g-ma-0 g-pl-5 g-pr-5 g-pr-15--sm  g-pb-5 justify-content-between">


                    {/*<div>*/}
                    {/*<span className="g-font-weight-400 color-orange g-font-size-14"> {(list.type === "FIXED") ? 'Vaste prijszetting' : 'Veiling'} </span>*/}
                    {/*</div>*/}

                    <div>
                        <i className="fa fa-hourglass-end g-mr-7" style={{alignSelf: 'center'}}></i>
                        {formatDateTZ(stop, 'DD/MM/YYYY HH:mm')}
                    </div>

                    <CountDownLabel date={stop}/>
                </div>
                }


            </div>


        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        list: selectors.listById(state, ownProps.listId),
    }
};

export default compose(
    withConfig,
    connect(mapStateToProps, {updateCriteriaInterestWithNoBid, updateCriteriaFavorites})
)(ListItem)
