import React from 'react';
import Template from './Template';
import {InfoContainer} from '../InfoContainer';
import {RavenStatic as Raven} from "raven-js";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({hasError: true});
        // You can also log the error to an error reporting service
        try {
            Raven.captureException(error);
        } catch (ex) {
        }


    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Template>
                    <div className="g-pt-80 g-bg-white text-center">
                        <InfoContainer container={true}
                                       text={<h4 className="g-color-primary">Something went wrong.</h4>}
                                       icon='fa fa-wrench'/>
                        <h4 className="g-color-primary">Please try again</h4>
                    </div>
                </Template>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;