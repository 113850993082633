import React, {Component} from 'react';
import {Template, InfoContainer} from '../components/core/index';
import {Registration} from '../components/registration-form/index';
import {gettext} from '../translations/index';
import * as navigation from './navigation/routes';
import {withConfig} from '../config/index';

class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {registrationComplete: false};
    }

    renderRegistrationForm() {

        return (
            <Template>
                <div className="g-bg-white">
                    <section className="g-py-70 g-py-100--sm">
                        <div className="row justify-content-center">
                            <div className="col-sm-10 col-md-9 col-lg-6 ">
                                <div
                                    className="g-brd-around g-brd-gray-light-v4 rounded  g-py-40--sm g-px-5 g-px-30--sm g-bg-white">
                                    <div className="g-max-width-645 text-center mx-auto g-mb-20">
                                        <h2 className="mb-4 g-color-primary">{gettext('Register')}</h2>
                                        <p>
                                            <span>{gettext('Register your company')}</span><br/>
                                            <span>{gettext('and get access to thousands of vehicles.')}</span>
                                        </p>
                                    </div>

                                    {/*<div className="text-center">*/}
                                    {/*<p className="g-color-gray-dark-v5 g-font-size-13 mb-0">{gettext('Do you already have an account?')}*/}
                                    {/*<a className="g-font-weight-600 g-color-primary clickable"*/}
                                    {/*onClick={navigation.login}> {gettext('Sign up')}</a>*/}
                                    {/*</p>*/}
                                    {/*</div>*/}

                                    <Registration
                                        onRegistrationCompleted={() => this.setState({registrationComplete: true})}/>

                                    <footer className="text-center">
                                        <p className="g-color-gray-dark-v5 g-font-size-13 mb-0">{gettext('Do you already have an account?')}
                                            <a className="g-font-weight-600 g-color-primary clickable"
                                               onClick={navigation.login}> {gettext('Sign in')}</a>
                                        </p>
                                    </footer>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </Template>
        )
    }


    renderRegistrationComplete() {
        const {registration_steps} = this.props.config;

        const text = (registration_steps.includes(6) && registration_steps.includes(7)) ? gettext('Then you can provide your personal and company data and set your preferences regarding dealers.') : gettext('Then you can provide your personal and company data.');

        return (
            <Template>
                <div className="g-mt-80">
                    <div>
                        <div className="row g-pa-0 g-ma-0 g-bg-white g-mb-10 g-pt-10 g-pb-10 g-hidden-sm-down">
                            <div className="col-12">
                                <ul className="row list-inline u-info-v9-1 g-ma-0 g-pa-0">
                                    {registration_steps.includes(1) &&
                                    <li className="col list-inline-item ">
                                        <div className="u-block-hover text-center ">
                                            <div className="g-mb-10">
                                                <span
                                                    className={`u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-bg-primary g-color-white g-font-size-9 rounded-circle`}>
                                                    <i className="fa fa-check"></i>
                                                </span>
                                            </div>

                                            <h3 className="g-color-primary g-font-weight-600 g-font-size-17 mb-3">
                                                {gettext("E-mail confirmation")}
                                            </h3>
                                        </div>
                                    </li>}
                                    {registration_steps.includes(2) &&
                                    <li className="col list-inline-item ">
                                        <div className="u-block-hover text-center ">
                                            <div className="g-mb-10">
                                                <span
                                                    className={`u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-bg-white g-color-gray-light-v1 g-font-size-9 rounded-circle`}>
                                                    <i className="fa fa-check"></i>
                                                </span>
                                            </div>

                                            <h3 className="g-color-primary g-font-weight-600 g-font-size-17 mb-3">
                                                {gettext("Personal info")}
                                            </h3>
                                        </div>
                                    </li>}
                                    {registration_steps.includes(3) &&
                                    <li className="col list-inline-item ">
                                        <div className="u-block-hover text-center ">
                                            <div className="g-mb-10">
                                                <span
                                                    className={`u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-bg-white g-color-gray-light-v1 g-font-size-9 rounded-circle`}>
                                                    <i className="fa fa-check"></i>
                                                </span>
                                            </div>

                                            <h3 className="g-color-primary g-font-weight-600 g-font-size-17 mb-3">
                                                {gettext("Company info")}
                                            </h3>
                                        </div>
                                    </li>}
                                    {registration_steps.includes(4) &&
                                    <li className="col list-inline-item ">
                                        <div className="u-block-hover text-center ">
                                            <div className="g-mb-10">
                                                <span
                                                    className={`u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-bg-white g-color-gray-light-v1 g-font-size-9 rounded-circle`}>
                                                    <i className="fa fa-check"></i>
                                                </span>
                                            </div>

                                            <h3 className="g-color-primary g-font-weight-600 g-font-size-17 mb-3">
                                                {gettext("Legal documents")}
                                            </h3>
                                        </div>
                                    </li>}
                                    {registration_steps.includes(5) &&
                                    <li className="col list-inline-item ">
                                        <div className="u-block-hover text-center ">
                                            <div className="g-mb-10">
                                                <span
                                                    className={`u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-bg-white g-color-gray-light-v1 g-font-size-9 rounded-circle`}>
                                                    <i className="fa fa-check"></i>
                                                </span>
                                            </div>

                                            <h3 className="g-color-primary g-font-weight-600 g-font-size-17 mb-3">
                                                {gettext("Preferences")}
                                            </h3>
                                        </div>
                                    </li>}
                                    {registration_steps.includes(6) &&
                                    <li className="col list-inline-item ">
                                        <div className="u-block-hover text-center ">
                                            <div className="g-mb-10">
                                                <span
                                                    className={`u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-bg-white g-color-gray-light-v1 g-font-size-9 rounded-circle`}>
                                                    <i className="fa fa-check"></i>
                                                </span>
                                            </div>

                                            <h3 className="g-color-primary g-font-weight-600 g-font-size-17 mb-3">
                                                {gettext("Filters")}
                                            </h3>
                                        </div>
                                    </li>}
                                    {registration_steps.includes(7) &&
                                    <li className="col list-inline-item ">
                                        <div className="u-block-hover text-center ">
                                            <div className="g-mb-10">
                                                <span
                                                    className={`u-icon-v2 u-shadow-v22 g-width-25 g-height-25 g-bg-white g-color-gray-light-v1 g-font-size-9 rounded-circle`}>
                                                    <i className="fa fa-check"></i>
                                                </span>
                                            </div>

                                            <h3 className="g-color-primary g-font-weight-600 g-font-size-17 mb-3">
                                                {gettext("Requests")}
                                            </h3>
                                        </div>
                                    </li>}
                                </ul>
                            </div>
                        </div>

                        <div className="g-pa-0 g-ma-0 g-mb-20">
                            <div className="row g-bg-white  g-pa-0 g-ma-0 g-pt-20 g-pb-20">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-lg-up g-pb-10">
                                    <h5 className="g-color-primary g-mb-0 g-pb-0">{gettext('E-mail confirmation')}</h5>
                                    <p className="g-pt-8-5">{gettext('Once you confirm your e-mail address, you can complete the registration.')}</p>
                                    <p>{text}</p>

                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-md-down">
                                    <h5 className="g-color-primary g-mb-0 g-pb-0">{gettext('E-mail confirmation')}</h5>
                                    <p className="g-pt-8-5">{gettext('Once you confirm your e-mail address, you can complete the registration.')}</p>
                                    <p>{text}</p>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0 text-center">

                                    <InfoContainer icon="fa fa-envelope-open"
                                                   text={<h5
                                                       className="mb-2 color-highlight g-font-weight-800">{gettext('You will receive a confirmation e-mail shortly.')}</h5>}
                                                   smallText={<span className="text-light-gr font-size-12">
                                                                {gettext("Confirm your registration by clicking on the link in the mail.")}<br/>
                                                       {gettext("Didn’t receive an e-mail? Check your spam inbox.")}
                                                              </span>}
                                                   container={true}/>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Template>
        )
    }

    render() {
        if (this.state.registrationComplete) {
            return this.renderRegistrationComplete()
        }

        return this.renderRegistrationForm()
    }

}

export default withConfig(Register);

