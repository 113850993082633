import React from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import Companies from './Companies';
import Makes from './Makes';
import Vehicle from './Vehicle';
import {updateName} from '../functions/filter';
import {gettext} from '../../../translations';

//import Oldtimer from '../../../assets/bodytypes/oldtimer.svg'
//<img src={Oldtimer}/>

class FilterForm extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.filter && nextProps.filter && this.props.filter.id !== nextProps.filter.id) {
            this.setState({activeTab: '1'});
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const {filter, allCompanies, allMakes, onChange} = this.props;
        if (!filter) return null;

        return (
            <div>
                <div className="g-mb-2 g-color-primary g-font-weight-600 g-pt-10">{gettext('Filter name')}</div>
                <input type="text"
                       placeholder={gettext("name")}
                       className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--hover rounded g-py-8 g-px-8"
                       value={filter.name}
                       onChange={(e) => onChange(updateName(filter, e.target.value))}
                />

                <Nav tabs className="g-pt-30">
                    <NavItem className="clickable">
                        <NavLink
                            className={classnames({active: this.state.activeTab === '1'})}
                            onClick={() => {
                                this.toggle('1');
                            }}
                        >
                            {gettext('Dealers')}
                        </NavLink>
                    </NavItem>
                    <NavItem className="clickable">
                        <NavLink
                            className={classnames({active: this.state.activeTab === '2'})}
                            onClick={() => {
                                this.toggle('2');
                            }}
                        >
                            {gettext('Makes')}
                        </NavLink>
                    </NavItem>
                    <NavItem className="clickable">
                        <NavLink
                            className={classnames({active: this.state.activeTab === '3'})}
                            onClick={() => {
                                this.toggle('3');
                            }}
                        >
                            {gettext('Vehicle')}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <p className="g-pt-5 g-pb-0 g-mb-0"><span className="font-weight-bold">! {gettext("Please note")}:</span></p>
                        <p className="g-pt-2">{gettext("The dealers will receive an automatic e-mail in which we inform them of your registration. The dealers must activate your company within their network. If this does not happen after a while, please contact the dealer in person.")}</p>

                        <Companies filter={filter}
                                   companies={allCompanies}
                                   onChange={onChange}
                        />

                    </TabPane>
                    <TabPane tabId="2">
                        <Makes filter={filter}
                               makes={allMakes}
                               onChange={onChange}
                        />
                    </TabPane>
                    <TabPane tabId="3">
                        <Vehicle filter={filter}
                                 onChange={onChange}
                        />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}


export default FilterForm;
