import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withConfig} from '../../../config';
import {gettext} from '../../../translations';
import {PropagateLoader} from 'react-spinners';
import {listVehicleStateFetch, listVehicleStateClosed} from '../actions';
import {toggleSwiperVisibility} from '../../picture-swiper/actions';
import * as selectors from '../selectors';
import * as interestSelectors from '../../listvehicles-no-interest/selectors';
import style from '../../../styles';
import {InfoContainer, VehicleState} from '../../core';

class ListVehicleState extends Component {

    constructor(props) {
        super(props);

        this.loadVehicleState = this.loadVehicleState.bind(this);

    }

    componentDidMount() {
        const {listVehicleId} = this.props;
        this.loadVehicleState(listVehicleId);
    }

    componentWillReceiveProps(nextProps) {
        this.loadVehicleState(nextProps.listVehicleId);
    }

    loadVehicleState(listVehicleId) {
        const {sendListInformationByRequest} = this.props;

        if (listVehicleId !== 0) {
            this.props.listVehicleStateFetch(listVehicleId, sendListInformationByRequest);
        }
    }


    render() {
        const {listVehicleId, vehicle, stickyTop, toggleSwiperVisibility, showStickyHeader, disabled, loading,  hasData,  error} = this.props;

        if (error !== '') {
            return <InfoContainer text={error} icon='fa fa-wrench'/>
        }

        if ((loading || listVehicleId === 0 || !vehicle || !hasData))
            return (
                <div className="g-pa-10 g-mb-10 text-center g-min-height-250">

                    {(loading || !vehicle) &&
                    <div className="g-relative-centered--x g-relative-centered--y">
                        <PropagateLoader
                            color={style.highlightColor}
                            loading={this.props.loading}
                        />
                    </div>
                    }

                    {listVehicleId === 0 &&
                    <div>{gettext('No vehicle selected')}</div>}
                </div>
            );

        return (
            <VehicleState vehicle={vehicle}
                          listVehicleId={listVehicleId}
                          stickyTop={stickyTop}
                          showStickyHeader={showStickyHeader}
                          toggleSwiperVisibility={toggleSwiperVisibility}
                          disabled={disabled}
            />
        );
    }
}

ListVehicleState.defaultProps = {
    showStickyHeader: true,
    sendListInformationByRequest: false,
};

const mapStateToProps = (state, ownProps) => {
    const {listVehicleId} = ownProps;

    return {
        requestedListVehicleId: listVehicleId,
        vehicle: selectors.getVehicleById(state, listVehicleId),
        disabled: interestSelectors.listVehicleDisabledByInterest(state, listVehicleId),
        loading: selectors.getLoading(state, listVehicleId),
        error: selectors.getError(state, listVehicleId),
        hasData: selectors.hasData(state, listVehicleId),
    };
};

export default compose(
    withConfig,
    connect(mapStateToProps, {
        listVehicleStateFetch,
        listVehicleStateClosed,
        toggleSwiperVisibility
    }),
)(ListVehicleState);
