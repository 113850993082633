import React from 'react';
import {gettext} from '../../../../../translations/index'
import {withConfig} from '../../../../../config';
import classNames from 'classnames';

const VehicleVatRegimeBadge = ({vat_regime, config}) => {
    const {vehicle_vat_regime} = config;
    const accent = vehicle_vat_regime === !vat_regime;
    const text = (vat_regime) ? gettext('VAT NON Refundable') : gettext('VAT Refundable');

    var spanClass = classNames({
        'u-label': true,
        'g-rounded-3': true,
        'badge': true,
        'badge-normal': !accent,
        'vat-highlight': accent,
        'g-mr-10': true,
        'g-mb-5': true,
    });

    return (
        <span className={spanClass}>
                {text}
        </span>

    )
}

export default withConfig(VehicleVatRegimeBadge);




