import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as listVehiclesFilterSelectors from '../selectors';
import * as criteriaHelpers from '../functions/criteria';
import {fetchListVehiclesFilter, updateCriteria, resetCriteria} from '../actions';
import _ from 'lodash';
import moment from 'moment';
import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import FilterItem from './FilterItem';
import {numberFormat} from '../../../helpers/functions';
import style from '../../../styles';
import {LoadingIndicator} from '../../core';
import {gettext} from '../../../translations';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import * as selectors from '../selectors';
import {sortBy} from 'lodash';
import FilterDropDown from './FilterDropDown';
import {withConfig} from '../../../config';
import {compose} from 'redux';

class Filter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            age_min: props.data.age.min_selected,
            age_max: props.data.age.max_selected,
            km_min: props.data.km.min_selected,
            km_max: props.data.km.max_selected,
        }

        this.offeredDtpicker = null;
        this.expectationDtpicker = null;

        this.setOfferedDtPickerRef = element => {
            this.offeredDtpicker = element;
        };

        this.setExpectationDtPickerRef = element => {
            this.expectationDtpicker = element;
        };

        this.handleOfferedCaretClick = this.handleOfferedCaretClick.bind(this);
        this.handleExpectationCaretClick = this.handleExpectationCaretClick.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.data.age.min_selected !== nextProps.data.age.min_selected || this.props.data.age.max_selected !== nextProps.data.age.max_selected) {
            this.setState({
                age_min: nextProps.data.age.min_selected,
                age_max: nextProps.data.age.max_selected,
                km_min: nextProps.data.km.min_selected,
                km_max: nextProps.data.km.max_selected,
            });
        }
    }

    componentDidMount() {
        this.props.fetchListVehiclesFilter(this.props.listId);
    }


    setCriteria(criteria) {
        this.props.updateCriteria(this.props.listId, criteria).then(() => this.props.onCriteriaChanged());
    }

    handleCompany(company) {
        if (company.active) {
            this.setCriteria(criteriaHelpers.removeCompany(this.props.criteria, company.id));
        }
        else {
            this.setCriteria(criteriaHelpers.addCompany(this.props.criteria, company.id));
        }
    }

    handleMake(make) {
        if (make.active) {
            this.setCriteria(criteriaHelpers.removeMake(this.props.criteria, make.id));
        }
        else {
            this.setCriteria(criteriaHelpers.addMake(this.props.criteria, make.id));
        }
    }

    handleMakeFromModel(make) {
        if (criteriaHelpers.containsMake(this.props.criteria, make)) {
            this.setCriteria(criteriaHelpers.removeMake(this.props.criteria, make));
        }
        else {
            this.setCriteria(criteriaHelpers.addMake(this.props.criteria, make));
        }
    }

    handleMakeModel(makeModel) {
        if(criteriaHelpers.containsMake(this.props.criteria, makeModel.make)){
            this.setCriteria(criteriaHelpers.removeMake(this.props.criteria, makeModel.make));
        }

        if (makeModel.active) {
            this.setCriteria(criteriaHelpers.removeMakeModel(this.props.criteria, makeModel));
        }
        else {
            this.setCriteria(criteriaHelpers.addMakeModel(this.props.criteria, makeModel));
        }
    }

    handleMakeModelClose(make){
        this.setCriteria(criteriaHelpers.removeAllMakeModels(this.props.criteria, make));
    }

    handleFuel(fuelType) {
        if (fuelType.active) {
            this.setCriteria(criteriaHelpers.removeFuel(this.props.criteria, fuelType.id));
        }
        else {
            this.setCriteria(criteriaHelpers.addFuel(this.props.criteria, fuelType.id));
        }
    }

    handleGearbox(gearbox) {
        if (gearbox.active) {
            this.setCriteria(criteriaHelpers.removeGearbox(this.props.criteria, gearbox.id));
        }
        else {
            this.setCriteria(criteriaHelpers.addGearbox(this.props.criteria, gearbox.id));
        }
    }

    handleKm(value) {
        if (this.props.loading) return;
        const {km} = this.props.data;

        if (km.min_selected !== value[0] || km.max_selected !== value[1]) {
            this.setCriteria(criteriaHelpers.updateKm(this.props.criteria, {min: value[0], max: value[1]}));
        }
    }

    handleAgeState(value) {
        if (this.props.loading) return;
        this.setState({age_min: value[0], age_max: value[1]});
    }

    handleKmState(value) {
        if (this.props.loading) return;
        this.setState({km_min: value[0], km_max: value[1]});
    }

    handleState(state) {
        if (state.active) {
            this.setCriteria(criteriaHelpers.removeState(this.props.criteria, state.id));
        }
        else {
            this.setCriteria(criteriaHelpers.addState(this.props.criteria, state.id));
        }
    }

    handleOfferedDt(startDate, endDate) {
        this.setCriteria(criteriaHelpers.updateOfferedDt(this.props.criteria, startDate, endDate));
    }

    handleExpectationDt(startDate, endDate) {
        this.setCriteria(criteriaHelpers.updateExpectationDt(this.props.criteria, startDate, endDate));
    }

    handleAge(value) {
        if (this.props.loading) return;
        const {age} = this.props.data;

        if (age.min_selected !== value[0] || age.max_selected !== value[1]) {
            this.setCriteria(criteriaHelpers.updateAge(this.props.criteria, {min: value[0], max: value[1]}));
        }
    }

    handleOfferedCaretClick() {
        this.offeredDtpicker.click();
    }


    handleExpectationCaretClick() {
        this.expectationDtpicker.click();
    }


    render() {
        const {
            companies,
            makes,
            modelsByMake,
            fuels,
            gearboxes,
            km,
            age,
            states,
            offeredDt_min,
            offeredDt_max,
            offered_range,
            expectationDt_min,
            expectationDt_max,
            expectation_range,
        } = this.props.data;
        const {showCounters, config} = this.props;
        const {list_vehicle_filter_make_models} = config;

        if (this.props.loading) return <div className="g-bg-white g-pa-10 g-mb-10 text-center h-100"><LoadingIndicator
            loading={this.props.loading}/></div>;

        return (
            <div className="order-md-1">

                {states && states.length > 0 &&

                <div className="bg-white g-pr-20 g-pl-20 g-pt-10">
                    <h6 className="mb-2 color-highlight g-font-weight-800">{gettext('States')}</h6>
                    <ul className="list-unstyled">
                        {sortBy(states, 'index').map((state, i) =>
                            <FilterItem key={`state-${i}`}
                                        onClick={() => this.handleState(state)} item={state}
                                        showCounters={showCounters}
                            />
                        )}
                    </ul>

                </div>
                }

                {states && states.length > 0 &&
                <div className="bg-white g-pr-20 g-pl-20 g-pt-10 g-mb-15">
                    <h6 className="mb-2 color-highlight g-font-weight-800">{gettext('Dates')}</h6>
                    <div className="d-flex align-items-center g-pb-10">
                        <DateRangePicker alwaysShowCalendars={true}
                                         showCustomRangeLabel={true}
                                         startDate={offeredDt_min}
                                         endDate={offeredDt_max}
                                         onApply={(event, picker) => this.handleOfferedDt(picker.startDate, picker.endDate)}
                                         onCancel={() => this.handleOfferedDt('', '')}
                                         ranges={selectors.defaultRanges}
                                         maxDate={moment()}
                                         opens="left">

                            <div ref={this.setOfferedDtPickerRef}
                                 className=" g-color-gray-dark-v4 g-color-primary--hover clickable">
                                {(offered_range) ? offered_range : gettext("Offered")}
                            </div>

                        </DateRangePicker>

                        <div
                            className="d-flex justify-content-end align-items-end clickable g-color-primary--hover"
                            style={{flex: 1}} onClick={this.handleOfferedCaretClick}>
                            <i className="fa fa-caret-down g-color-gray-dark-v4 g-font-size-18 g-mr-10 g-color-primary--hover clickable"/>
                        </div>
                    </div>
                    <div className="d-flex align-items-center g-pb-10">
                        <DateRangePicker alwaysShowCalendars={true}
                                         showCustomRangeLabel={true}
                                         startDate={expectationDt_min}
                                         endDate={expectationDt_max}
                                         onApply={(event, picker) => this.handleExpectationDt(picker.startDate, picker.endDate)}
                                         onCancel={() => this.handleExpectationDt('', '')}
                                         ranges={selectors.defaultRanges}
                                         opens="left">

                            <div ref={this.setExpectationDtPickerRef}
                                 className=" g-color-gray-dark-v4 g-color-primary--hover clickable">
                                {(expectation_range) ? expectation_range : gettext("Delivery")}
                            </div>

                        </DateRangePicker>

                        <div
                            className="d-flex justify-content-end align-items-end clickable g-color-primary--hover"
                            style={{flex: 1}} onClick={this.handleExpectationCaretClick}>
                            <i className="fa fa-caret-down g-color-gray-dark-v4 g-font-size-18 g-mr-10 g-color-primary--hover clickable"/>
                        </div>
                    </div>
                </div>}


                <div className="bg-white g-pr-20 g-pl-20 g-pt-10">

                    <div className="g-pb-20">
                        <h6 className="mb-2 color-highlight g-font-weight-800">
                            {numberFormat(this.state.km_min)} - {numberFormat(this.state.km_max)} km
                        </h6>
                        <Range min={km.min}
                               max={km.max}
                               value={[this.state.km_min, this.state.km_max]}
                               step={1000}
                               allowCross={false}
                               onChange={(value) => this.handleKmState(value)}
                               onAfterChange={(value) => this.handleKm(value)}
                               trackStyle={[{backgroundColor: style.highlightColor}]}
                               handleStyle={[{borderColor: style.highlightColor}, {borderColor: style.highlightColor}]}
                        />
                    </div>


                    <div className="g-pb-20">
                        <h6 className="mb-2 color-highlight g-font-weight-800">
                            {this.state.age_min} - {this.state.age_max} {gettext('months')}
                        </h6>

                        <Range min={age.min}
                               max={age.max}
                               value={[this.state.age_min, this.state.age_max]}
                               step={1}
                               allowCross={false}
                               onChange={(value) => this.handleAgeState(value)}
                               onAfterChange={(value) => this.handleAge(value)}
                               trackStyle={[{backgroundColor: style.highlightColor}]}
                               handleStyle={[{borderColor: style.highlightColor}, {borderColor: style.highlightColor}]}
                        />
                    </div>

                    {companies.length > 0 &&
                    <h6 className="mb-2 color-highlight g-font-weight-800">{gettext('Dealer')}</h6>}
                    {companies.length > 0 &&
                    <ul className="list-unstyled">
                        {companies.map((company, i) =>
                            <FilterItem key={`dealer-${i}`}
                                        onClick={() => this.handleCompany(company)}
                                        item={company}
                                        showCounters={showCounters}
                            />
                        )}
                    </ul>}

                    <h6 className="mb-2 color-highlight g-font-weight-800">{gettext('Fuel')}</h6>
                    <ul className="list-unstyled">
                        {fuels.map((fuel, i) =>
                            <FilterItem key={`fuel-${i}`}
                                        onClick={() => this.handleFuel(fuel)}
                                        item={fuel}
                                        showCounters={showCounters}
                            />
                        )}
                    </ul>

                    <h6 className="mb-2 color-highlight g-font-weight-800">{gettext('Transmission')}</h6>
                    <ul className="list-unstyled">
                        {gearboxes.map((gearbox, i) =>
                            <FilterItem key={`gearbox-${i}`}
                                        onClick={() => this.handleGearbox(gearbox)}
                                        item={gearbox}
                                        showCounters={showCounters}
                            />
                        )}
                    </ul>


                    {!list_vehicle_filter_make_models &&
                    <h6 className="mb-2 color-highlight g-font-weight-800">{gettext('Make')}</h6>}
                    {!list_vehicle_filter_make_models &&
                    <ul className="list-unstyled">
                        {makes.map((make, i) =>
                            <FilterItem key={`make-${i}`}
                                        onClick={() => this.handleMake(make)}
                                        item={make}
                                        showCounters={showCounters}
                            />
                        )}
                    </ul>}
                </div>


                {list_vehicle_filter_make_models && _.keys(modelsByMake).map((make, i) =>
                        <FilterDropDown title={make}
                                        key={`make-models-container-${i}`}
                                        items={modelsByMake[make]}
                                        onClick={(makeModel) => this.handleMakeModel(makeModel)}
                                        onOpen={(makeModel) => this.handleMakeFromModel(makeModel)}
                                        onClose={(make) => this.handleMakeModelClose(make)}
                                        showCounters={showCounters}
                                        makeActive={criteriaHelpers.containsMake(this.props.criteria, make)}

                        />
                )}
            </div>)
    }
}

Filter.defaultProps = {
    showCounters: true
};


const mapStateToProps = (state, ownProps) => {
    const {listId} = ownProps;
    const data = listVehiclesFilterSelectors.getData(state, listId,);
    const currentCriteria = listVehiclesFilterSelectors.getCriteria(state, listId);

    const makes = _.sortBy(_.uniqBy(_.map(data.models, (model) => ({
        id: model.make,
        name: model.make,
        active: (_.findIndex(currentCriteria.makes, function (mm) {
            return mm.make === model.make
        }) > -1),
        count: _.sumBy(_.filter(data.models, (m) => m.make === model.make), 'count'),
    })), 'name'), 'name');

    const modelsByMake = _.groupBy(_.uniqBy(_.map(data.models, (vehicle) => ({
        make: vehicle.make,
        model: vehicle.model,
        name: vehicle.model,
        active: (_.findIndex(currentCriteria.makes, function (mm) {
            return mm.make === vehicle.make && mm.model === vehicle.model
        }) > -1),
        count: vehicle.count,
    })), 'model'), 'make');

    const companies = _.map(data.companies, (company) => ({
        id: company.id,
        name: company.name,
        active: _.includes(currentCriteria.companies, company.id),
        count: company.count,
    }));

    const fuels = _.sortBy(_.map(data.fuels, (fuel) => ({
        id: fuel.key,
        name: fuel.name,
        active: _.includes(currentCriteria.fuels, fuel.key),
        count: fuel.count,
    })), 'name');

    const gearboxes = _.sortBy(_.map(data.gearboxes, (gearbox) => ({
        id: gearbox.key,
        name: gearbox.name,
        active: _.includes(currentCriteria.gearboxes, gearbox.key),
        count: gearbox.count,
    })), 'name');

    const km = {
        min: (Math.round(data.ranges.km_min / 1000) * 1000) || 0,
        max: (Math.ceil(data.ranges.km_max / 1000) * 1000) || 1000000,
        min_selected: currentCriteria.km.min || ((Math.round(data.ranges.km_min / 1000) * 1000) || 0),
        max_selected: currentCriteria.km.max || ((Math.ceil(data.ranges.km_max / 1000) * 1000) || 1000000),
    };

    const age = {
        min: (data.ranges.age_max) ? moment().diff(data.ranges.age_max, 'months') : 0,
        max: (data.ranges.age_min) ? moment().diff(data.ranges.age_min, 'months') : 1000,
        min_selected: currentCriteria.age.min || (data.ranges.age_max) ? (moment().diff(data.ranges.age_max, 'months')) : 0,
        max_selected: currentCriteria.age.max || (data.ranges.age_min) ? (moment().diff(data.ranges.age_min, 'months')) : 1000,
    };

    const states = _.sortBy(_.map(data.states, (state) => ({
        id: state.code,
        name: state.name,
        active: _.includes(currentCriteria.states, state.code),
        count: state.count,
        index: state.index,
    })), 'name');

    const basketOnly = false;

    let offeredDt_min = moment();
    let offeredDt_max = moment();
    let offered_range = "";

    if (currentCriteria.offeredDt_min && currentCriteria.offeredDt_min !== "") {
        offeredDt_min = moment(currentCriteria.offeredDt_min);
    }

    if (currentCriteria.offeredDt_max && currentCriteria.offeredDt_max !== "") {
        offeredDt_max = moment(currentCriteria.offeredDt_max);
    }

    if (currentCriteria.offeredDt_min && currentCriteria.offeredDt_min !== "" && currentCriteria.offeredDt_max && currentCriteria.offeredDt_max !== "") {
        offered_range = moment(currentCriteria.offeredDt_min).format('DD/MM/YYYY') + ' - ' + moment(currentCriteria.offeredDt_max).format('DD/MM/YYYY');
        if (currentCriteria.offeredDt_min === currentCriteria.offeredDt_max) {
            offered_range = moment(currentCriteria.offeredDt_min).format('DD/MM/YYYY');
        }
    }


    let expectationDt_min = moment();
    let expectationDt_max = moment();
    let expectation_range = "";

    if (currentCriteria.expectationDt_min && currentCriteria.expectationDt_min !== "") {
        expectationDt_min = moment(currentCriteria.expectationDt_min);
    }

    if (currentCriteria.expectationDt_max && currentCriteria.expectationDt_max !== "") {
        expectationDt_max = moment(currentCriteria.expectationDt_max);
    }

    if (currentCriteria.expectationDt_min && currentCriteria.expectationDt_min !== "" && currentCriteria.expectationDt_max && currentCriteria.expectationDt_max !== "") {
        expectation_range = moment(currentCriteria.expectationDt_min).format('DD/MM/YYYY') + ' - ' + moment(currentCriteria.expectationDt_max).format('DD/MM/YYYY');
        if (currentCriteria.expectationDt_min === currentCriteria.expectationDt_max) {
            expectation_range = moment(currentCriteria.expectationDt_min).format('DD/MM/YYYY');
        }
    }

    return {
        loading: listVehiclesFilterSelectors.getLoading(state, listId),
        criteria: listVehiclesFilterSelectors.getCriteriaCopy(state, listId),
        data: {
            makes,
            modelsByMake,
            companies,
            fuels,
            gearboxes,
            km,
            age,
            states,
            basketOnly,
            offeredDt_min,
            offeredDt_max,
            offered_range,
            expectationDt_min,
            expectationDt_max,
            expectation_range,
        }
    }
};

export default compose(
    withConfig,
    connect(mapStateToProps, {fetchListVehiclesFilter, updateCriteria, resetCriteria})
)(Filter)
