import {RSAA} from 'redux-api-middleware';
import {listVehicleFavoriteTypes} from './types';
import {combineIds} from '../../helpers/functions';

export const listVehiclesFavoriteFetch = (listId) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/favourite/?listId=${listId}`,
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: listVehicleFavoriteTypes.FETCH_REQUEST,
                        payload: {
                            listId
                        }
                    },
                    {
                        type: listVehicleFavoriteTypes.FETCH_SUCCESS,
                        payload: (action, state, res) => onListVehiclesFavoriteFetchSuccess(dispatch, res, listId),
                    },
                    {
                        type: listVehicleFavoriteTypes.FETCH_FAILURE

                    }
                ]
            }
        });
    };
};

const onListVehiclesFavoriteFetchSuccess = (dispatch, res, listId) => {
    return res.json().then(data => {
        return {
            vehicleIds: data.map((obj) => combineIds(obj.list_id, obj.vehicle_id)),
        };
    });
};


export const listVehicleFavorite = (listId, vehicleId) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/favourite/',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    list_id: listId,
                    vehicle_id: vehicleId,
                }),
                types: [
                    {
                        type: listVehicleFavoriteTypes.POST_REQUEST,
                    },
                    {
                        type: listVehicleFavoriteTypes.POST_SUCCESS,
                        payload: (action, state, res) => onListVehicleFavoriteSuccess(dispatch, res, listId, vehicleId),
                    },
                    {
                        type: listVehicleFavoriteTypes.POST_FAILURE,
                    }
                ]
            }
        });
    }
};

export const listVehicleFavoriteDelete = (listId, vehicleId) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/favourite/',
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    list_id: listId,
                    vehicle_id: vehicleId,
                }),
                types: [
                    {
                        type: listVehicleFavoriteTypes.POST_REQUEST,
                    },
                    {
                        type: listVehicleFavoriteTypes.POST_SUCCESS,
                        payload: (action, state, res) => onListVehicleFavoriteDeleteSuccess(dispatch, res, listId, vehicleId),
                    },
                    {
                        type: listVehicleFavoriteTypes.POST_FAILURE,
                    }
                ]
            }
        });
    }
};

const onListVehicleFavoriteSuccess = (dispatch, res, listId, vehicleId) => {
    return res.json().then(data => {
        if (data.result_code === 'Ok') {
            dispatch({
                type: listVehicleFavoriteTypes.ADD,
                payload: {
                    vehicleId: combineIds(listId, vehicleId),
                }
            });
        }
    });
};


const onListVehicleFavoriteDeleteSuccess = (dispatch, res, listId, vehicleId) => {
    return res.json().then(data => {
        if (data.result_code === 'Ok') {

            dispatch({
                type: listVehicleFavoriteTypes.REMOVE,
                payload: {
                    vehicleId: combineIds(listId, vehicleId),
                }
            });
        }
    });
};


export const listVehicleFavoriteErrorConfirm = () => ({
    type: listVehicleFavoriteTypes.ERROR_CLEAR,
});


