import {listVehicleStateTypes} from './types';
import {omit} from 'lodash';

const listVehicleStateByKey = (state = {}, action) => {
    switch (action.type) {
        case listVehicleStateTypes.FETCH_REQUEST:
        case listVehicleStateTypes.FETCH_SUCCESS:
        case listVehicleStateTypes.FETCH_FAILURE:
            return {
                ...state,
                [action.payload.listVehicleId]: listVehicleState(state[action.payload.listVehicleId], action),
            };
        case listVehicleStateTypes.CLEANUP:
            return omit(state, action.payload.invalidListVehicleIds);
        default:
            return state;
    }
};

const listVehicleState = (state = {}, action) => {
    switch (action.type) {
        case listVehicleStateTypes.FETCH_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: '',
            };
        case listVehicleStateTypes.FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.payload.receivedAt,
                error: '',
            };
        case listVehicleStateTypes.FETCH_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.message,
            };
        default:
            return state;
    }
};

export default listVehicleStateByKey;



