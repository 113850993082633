import React from 'react';
import {gettext} from '../../../../translations';

const WizardButtons = ({currentStep, onNext, onPrevious}) =>
    <div className="row bg-white g-ma-0 g-pt-20 g-pb-20 g-pr-15 justify-content-end">

        {(currentStep > 2) &&
        <button onClick={onPrevious}
                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-5 g-py-5 g-width-90">
            <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"></i>{gettext('Previous')}
        </button>}

        <button type="button"
                onClick={onNext}
                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-5 g-py-5 g-width-90 g-ml-10">
            {gettext('Next')}<i className="fa fa-chevron-right g-font-size-12 g-valign-middle g-ml-10"></i>
        </button>
    </div>;


export default WizardButtons;
