import React from 'react';
import {connect} from 'react-redux';
import * as selectors from './selectors';
import {fetchStatistics} from './actions';
import {LoadingIndicator} from '../core/index';
import {updateCriteriaStates} from '../listvehicles-filter/index';
import NoStats from './NoStats';

class Statistics extends React.Component {
    componentDidMount() {
        const {fetchStatistics} = this.props;
        fetchStatistics();
    }

    render() {
        const {loading, error, data} = this.props;

        if (loading) {
            return <LoadingIndicator loading={loading}/>
        }

        if (error) {
            return <div>{error}</div>
        }

        if (data === null) {
            return (
                <NoStats />
            )
        }

        switch (data.modus) {
            case 'BASE':
                return React.createElement(require('./base/BaseStatistics').default, {data});
            case 'EXPERT':
                return React.createElement(require('./expert/ExpertStatistics').default, {data});
            default:
                return React.createElement(require('./expert/ExpertStatistics').default, {data});
        }
    }
}

const mapStateToProps = (state) => {

    return {
        data: selectors.getData(state),
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
    }
};

export default connect(mapStateToProps, {fetchStatistics, updateCriteriaStates})(Statistics);
