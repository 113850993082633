import React from 'react';

const WizardHeader = ({number, title, currentPage}) => {
        let colors = '';
        let textColor= 'g-color-primary';
        let icon = "fa fa-pencil";

        if (currentPage === number) {
            colors = 'g-bg-primary g-color-white ';
            icon = 'fa fa-pencil';
        }
        else if (currentPage > number) {
            colors = 'g-bg-green g-color-white ';
            icon = 'fa fa-check';
            textColor='g-color-green';
        }
        else {
            colors = 'g-bg-white g-color-gray-light-v1';
        }

        return (
            <li className="col list-inline-item ">
                <div className="u-block-hover text-center ">
                    <div className="g-mb-10">
                <span
                    className={`u-icon-v2 u-shadow-v22 g-width-25 g-height-25 ${colors} g-font-size-9 rounded-circle`}>

                    <i className={icon}></i>
                </span>
                    </div>
                    {/*    <h3 className="g-color-primary g-font-weight-600 g-font-size-17 g-mb-0 g-pb-0">
                     0{number}.
                     </h3>*/}
                    <h3 className={`${textColor} g-font-weight-600 g-font-size-15 g-font-size-16--md g-font-size-16--lg g-font-size-17--xl mb-3`}>
                        {title}
                    </h3>
                </div>
            </li>
        )
    }
;


export default WizardHeader;
