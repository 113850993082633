import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getLocale} from '../../localization'
import {gettext} from '../../../translations';
import CollapsePanel from './CollapsePanel';
import Video from "./Video";
import Template from "../../core/components/web/application/Template";


const types = {
    SUBSCRIBE: 'subscribe',
    PASSWORD: 'password',
    HOMEPAGE: 'homepage',
    VEHICLE_DETAILS: 'vehicle_details',
    HISTORY: 'history',
    PROFILE_AND_FILTERS: 'profile_and_filters',
    EYE_AND_SORT: 'eye_and_sort',
};

const videos = [
    {
        type: types.SUBSCRIBE,
        title: gettext('How do I register?'),
        urls: {
            nl: 'https://player.vimeo.com/video/318705754',
            fr: 'https://player.vimeo.com/video/318595618',
            en: 'https://player.vimeo.com/video/320151532',
        }
    },
    {
        type: types.PASSWORD,
        title: gettext('How can I change my password?'),
        urls: {
            nl: 'https://player.vimeo.com/video/318723531',
            fr: 'https://player.vimeo.com/video/318721331',
            en: 'https://player.vimeo.com/video/320155289',
        }
    },
    {
        type: types.HOMEPAGE,
        title: gettext('What is the dashboard?'),
        urls: {
            nl: 'https://player.vimeo.com/video/318943356',
            fr: 'https://player.vimeo.com/video/318729366',
            en: 'https://player.vimeo.com/video/320164137',
        }
    },
    {
        type: types.VEHICLE_DETAILS,
        title: gettext('How can I make offers on the cars?'),
        urls: {
            nl: 'https://player.vimeo.com/video/318965635',
            fr: 'https://player.vimeo.com/video/318963319',
            en: 'https://player.vimeo.com/video/320151828',
        }
    },
    {
        type: types.HISTORY,
        title: gettext('Where can I find an overview of the cars I bought?'),
        urls: {
            nl: 'https://player.vimeo.com/video/318957880',
            fr: 'https://player.vimeo.com/video/318955639',
            en: 'https://player.vimeo.com/video/320153612',
        }
    },
    {
        type: types.PROFILE_AND_FILTERS,
        title: gettext('Where can I change my personal information and filters?'),
        urls: {
            nl: 'https://player.vimeo.com/video/318721152',
            fr: 'https://player.vimeo.com/video/318708311',
            en: 'https://player.vimeo.com/video/320156617',
        }
    },
    {
        type: types.EYE_AND_SORT,
        title: gettext('How can I filter/sort the vehicles?'),
        urls: {
            nl: 'https://player.vimeo.com/video/320167197',
            fr: 'https://player.vimeo.com/video/318967491',
            en: 'https://player.vimeo.com/video/320167544',
        }
    }


];

class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = {current: ""}
    }

    render() {
        const {locale} = this.props;


        return (
            <Template>
                <div className="row bg-white text-center g-ma-0 g-pa-0 g-mt-70 g-mb-200">

                    <div className="row g-pa-0 g-ma-0 w-100 g-ml-50">
                        <h1 className="h2 g-color-primary g-pt-20 g-mb-10">{gettext('How can we help? ')}</h1>
                    </div>
                    <div className='row g-pa-0 g-ma-0 w-100 g-ml-50'>
                        <p>{gettext('Do you have a question? Check out our instructional videos.')} </p>
                    </div>


                    {videos.map((video, key) =>
                        <React.Fragment key={key}>
                            <CollapsePanel title={gettext(video.title)}

                                           collapsed={(this.state.current !== video.type)}
                                           onClick={() => this.setState({current: (video.type !== this.state.current) ? video.type : ''})}>
                                <Video video={video} locale={locale}/>
                            </CollapsePanel>
                        </React.Fragment>
                    )}

                    <div className="w-100 g-ml-10 g-ml-50--md g-mr-10 g-mr-50--md g-mb-20">
                        <div
                            className='row w-100 g-pa-0 g-ma-0 g-pa-5 g-pl-5 g-pl-25--md g-pr-5 g-pr-25--md text-light-gr g-font-size-14 g-font-size-16--md align-items-center justify-content-between'
                            style={{backgroundColor: '#edf8f5', height: '60px'}}
                        >
                            <span> {gettext('Other question? Mail ')} <span className="g-text-underline">support@usedcars.center</span></span>
                        </div>
                    </div>
                </div>

            </ Template>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: getLocale(state)
    };
};

export default connect(mapStateToProps)(Faq);

