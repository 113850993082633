import React from 'react';
import {connect} from 'react-redux';
import {submit} from 'redux-form'
import {gettext} from '../../../translations';
import {InfoContainer, LoadingIndicator} from '../../core';
import * as selectors from '../selectors';
import SettingsForm from './SettingsForm';
import {settingsFields} from './SettingsForm';
import Confirm from './Confirm';
import {fetchSettings, saveSettings} from '../actions';
import {keys} from 'lodash';
import {dashboard} from '../../../_web/navigation/routes'

const states = {
    INPUT: 'INPUT',
    OVERVIEW: 'OVERVIEW',
    RESULT: 'RESULT',
};

class Unsubscribe extends React.Component {
    constructor(props) {
        super(props);

        this.state = {state: states.INPUT, data: null};

        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
        const {fetchSettings} = this.props;
        fetchSettings();
    }

    onSubmitForm(data) {
        this.setState({state: states.OVERVIEW, data: data});
    }

    onConfirm() {
        const {saveSettings, mail_settings} = this.props;
        const {data} = this.state;

        saveSettings(mail_settings.id, {
            car_by_car_mail: data.car_by_car_mail,
            offers_mail: data.offers_mail,
            won_mail: data.won_mail,
            no_mail: data.no_mail,
        }).then((result) => {
            if (result.payload.success) {
                this.setState({state: states.RESULT})
            }
        });
    }

    render() {
        const {submit, mail_settings, loading, error} = this.props;

        return (
            <div className="g-pa-0 g-ma-0 g-mb-20">

                {this.state.state !== states.RESULT &&
                <h2 className="mb-4 g-color-primary">{gettext('Unsubscribe')}</h2>
                }

                <div className="row g-bg-white  g-pa-0 g-ma-0 g-pb-0 g-mb-5">

                    {this.state.state !== states.RESULT &&
                    <React.Fragment>
                        <div
                            className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-lg-up g-pb-10 g-pa-0 g-ma-0 g-pb-10">
                            <h6 className="g-pt-2">{gettext('Let’s keep in touch')}</h6>
                            <p className="g-pt-5 g-pb-0 g-ma-0">{gettext("Help us improve your experience with UsedCars.center.")} </p>
                            <p className="g-pt-0 g-ma-0 g-mb-10 g-mb-0--md">{gettext("Please adjust your email preferences and we will adjust our communication accordingly.")} </p>
                            <h5 className="g-pt-5 g-ma-0 g-hidden-md-down">{gettext("Still want to unsubscribe entirely?")} </h5>
                            <p className="g-pt-5 g-ma-0 g-hidden-md-down">{gettext("We are sad to see you go but if you change your mind, you can always change your settings under ‘profile’.")} </p>

                            {this.state.state === states.INPUT &&
                            <p className="g-pt-5 g-ma-0 g-hidden-md-down">{gettext("Swipe the last slider to unsubscribe.")} </p>}

                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-pa-0 g-ma-0 g-hidden-md-down">
                            <h5 className="g-pt-2">{gettext('Let’s keep in touch')}</h5>
                            <p className="g-pt-10 g-pb-0">{gettext("Help us improve your experience with UsedCars.center.")} </p>
                            <p className="g-pt-0 g-pb-0">{gettext("Please adjust your email preferences and we will adjust our communication accordingly.")} </p>
                            <h5 className="g-pt-10">{gettext("Still want to unsubscribe entirely?")} </h5>
                            <p className="g-pt-10 g-pb-10">{gettext("We are sad to see you go but if you change your mind, you can always change your settings under ‘profile’.")} </p>

                            {this.state.state === states.INPUT &&
                            <p className="g-pt-10 g-pb-10">{gettext("Swipe the last slider to unsubscribe.")} </p>}

                        </div>
                    </React.Fragment>}


                    {this.state.state === states.INPUT &&
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0">

                        {(loading || keys(mail_settings).length === 0) &&
                        <LoadingIndicator loading={loading}/>
                        }

                        {(!loading && keys(mail_settings).length > 0) &&
                        <React.Fragment>
                            <SettingsForm initialValues={{
                                [settingsFields.CAR_BY_CAR_MAIL]: mail_settings.car_by_car_mail,
                                [settingsFields.OFFERS_MAIL]: mail_settings.offers_mail,
                                [settingsFields.WON_MAIL]: mail_settings.won_mail,
                                [settingsFields.NO_MAIL]: mail_settings.no_mail,
                            }}
                                          onSubmit={this.onSubmitForm}
                            />

                            <div className="row g-pa-0 g-ma-0 g-bg-white">
                                <div className="col-12 g-pa-0 g-ma-0">
                                    <div
                                        className="row bg-white g-ma-0 g-pt-20 g-pb-20 g-pr-15 justify-content-end">
                                        <button type="button"
                                                onClick={() => submit('unsubscribe-settings')}
                                                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-5 g-py-5 g-width-90 g-ml-10">
                                            {gettext('Next')}<i
                                            className="fa fa-chevron-right g-font-size-12 g-valign-middle g-ml-10"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </React.Fragment>}

                    </div>


                    }

                    {this.state.state === states.OVERVIEW &&
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0">

                        {loading &&
                        <LoadingIndicator loading={loading}/>
                        }

                        {!loading &&
                        <React.Fragment>
                            <Confirm data={this.state.data}/>
                            <div className="row g-pa-0 g-ma-0 g-bg-white">
                                <div className="col-12 g-pa-0 g-ma-0">
                                    <div
                                        className="row bg-white g-ma-0 g-pt-20 g-pb-20 g-pr-15 justify-content-end">
                                        <button type="button"
                                                onClick={() => {
                                                    this.setState({state: states.INPUT})
                                                }}
                                                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-5 g-py-5 g-width-90 g-ml-10">

                                            <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"/> {gettext('Back')}
                                        </button>
                                        <button type="button"
                                                onClick={this.onConfirm}
                                                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-5 g-py-5 g-width-90 g-ml-10">
                                            {gettext('Confirm')}
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </React.Fragment>}

                    </div>
                    }

                    {this.state.state === states.RESULT &&
                    <div className="col-12 g-ma-0 text-center">
                        <InfoContainer icon="fa fa-check" text={<h3>{gettext('Confirmed.')}</h3>}
                                       container={true}/>

                        <p className='text-center'>{gettext('Your email preferences have been changed.')}</p>

                        <button onClick={() => dashboard()}
                                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-mt-10">
                            {gettext('Dashboard')}
                        </button>
                    </div>
                    }

                    {error !== null &&
                    <div className="row g-pa-0 g-ma-0 container-important g-pa-5 ">
                        {error.message}
                    </div>}


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mail_settings: selectors.getMailSettings(state),
        loading: selectors.getLoading(state),
        error: selectors.getError(state)
    };
};


export default connect(mapStateToProps, {submit, fetchSettings, saveSettings})(Unsubscribe);

