import React from 'react';
import {Template} from '../components/core/index';
import Profile from '../components/profile/_web/Profile';

const MyProfile = () => (
    <Template>
        <Profile />
    </Template>
);

export default MyProfile;

