import React from 'react';
import CountUp from 'react-countup';
import {map, sortBy, filter, find} from 'lodash';

class States extends React.Component {

    render() {
        const {data, onClick} = this.props;

        const states = sortBy(filter(data, (state) => state.index <= 4), 'index');
        const stateLabelLeft = find(data, (state) => state.index === 5);
        const stateLabelRight = find(data, (state) => state.index === 6);

        return (
            <div>
                <div className="btn-group justified-content text-center g-pa-10 g-pb-0 g-color-white--hover">
                    {map(states, (state, index) =>

                        <label key={index}
                               className="stats-item u-check g-pl-0 g-color-white--hover g-bg-primary--hover g-min-width-150"
                               onClick={() => onClick(state.code)}>
                            <div
                                className="btn btn-md btn-block u-btn-outline-lightgray rounded-0 g-color-white--hover g-bg-primary--hover">
                                <div className="g-font-size-22 g-font-weight-700 g-color-primary ">
                                    <CountUp start={0}
                                             end={state.count}/>
                                </div>
                                <div>{state.name}</div>
                            </div>
                        </label>
                    )}

                </div>

                {((stateLabelLeft && stateLabelLeft.count > 0) || (stateLabelRight && stateLabelRight.count > 0)) &&
                <div className="row states-overflow-border justify-content-between">
                    {stateLabelLeft && stateLabelLeft.count > 0 &&
                    <div className="col g-text-underline clickable g-color-primary"
                         onClick={() => onClick(stateLabelLeft.code)}>
                        {`${stateLabelLeft.count} ${stateLabelLeft.name}`}
                    </div>}

                    {stateLabelRight && stateLabelRight.count > 0 &&
                    <div className="col g-text-underline clickable g-color-primary" style={{textAlign: (stateLabelLeft && stateLabelLeft.count > 0) ? 'right' : 'left'}}
                         onClick={() => onClick(stateLabelRight.code)}>
                        {`${stateLabelRight.count} ${stateLabelRight.name}`}
                    </div>}
                </div>}


            </div>
        )
    }
}


export default States

