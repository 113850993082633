import {historyAndStateTypes} from './types';
import {basketTypes} from '../basket/types';

export const reducer = (state = {selectedId: 0, vehicleStateVisible: false}, action) => {
    switch (action.type) {
        case historyAndStateTypes.VEHICLE_SELECT:
            return {
                ...state,
                selectedId: action.listVehicleId,
                vehicleStateVisible: action.stateVisible,
            };

        case historyAndStateTypes.TOGGLE_STATE:
            return {
                ...state,
                vehicleStateVisible: !state.vehicleStateVisible,
            };
        case basketTypes.FETCH_SUCCESS:
            if(!action.type === "ALL") return;

            const {data, offset} = action.payload;
            return {
                ...state,
                selectedId: data.length > 0 ? offset === 0 ? data[0] : state.selectedId : 0,
            };
        case historyAndStateTypes.CLEANUP:
            return {
                selectedId: 0,
                vehicleStateVisible: false,
            };
        default:
            return state;
    }
};

export default reducer;