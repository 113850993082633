import React, {Component} from 'react';
import WarningModal from './WarningModal';

export default class Warning extends Component {

    constructor(props) {
        super(props);

        this.state = {visible: false}
    }

    render() {

        const {title, content} = this.props;
        return (
            <div className="row g-pa-10 g-ma-0 clickable"
                 style={{fontSize: 20, color: "#5c4813", backgroundColor: "#f9ebc5", borderBottomWidth: 2, borderBottomStyle: 'solid', borderBottomColor: "#f4ca64"}}
                 onClick={() => this.setState({visible: true})}>


                <div className="d-flex justify-content-center align-items-center g-mr-15"
                     style={{
                         width: 30,
                         height: 30,
                         borderRadius: 15,
                         backgroundColor: '#f4ca64',
                         alignItems: 'center',
                         justifyContent: 'center'
                     }}>

                    {!content || content === "" &&
                    <i className="fa fa-info" style={{fontSize: 22}}></i>}

                    {content && content !== "" &&
                    <img src={require('../../../assets/svg/bullseye-pointer-light.svg')}
                         className=""
                         width={20} height={20}/>}
                </div>


                {title}

                {/*<img src={require('../../../assets/svg/bullseye-pointer-light.svg')}*/}
                {/*     className="g-ml-12 g-mt-4"*/}
                {/*     width={24} height={24}/>*/}


                <WarningModal visible={this.state.visible}
                              data={content}
                              onCancel={() => this.setState({visible: false})}/>

            </div>
        )
    }
}

