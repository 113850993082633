import React from 'react';
import {connect} from 'react-redux';
import * as selectors from './selectors';
import {fetchListHistory} from './actions';
import {LoadingIndicator} from '../../../core/index';
import HeaderWithToolTip from './HeaderWithToolTip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import {map, sortBy} from 'lodash';
import {gettext} from '../../../../translations';

class HistoryTable extends React.Component {

    componentDidMount() {
        const {fetchListHistory} = this.props;
        fetchListHistory();
    }


    render() {
        const {loading, error, data} = this.props;

       if (loading) {
            return (
                <div id="custom-bootstrap-table-container" style={{width: '100%', paddingTop: 130, border: '1px solid #eee'}}>
                    <LoadingIndicator loading={loading}/>
                    <div className="text-center g-mt-10">{gettext('Loading history data')}</div>
                </div>
            );
        }

        if (error) {
            return (
                <div id="custom-bootstrap-table-container"
                     style={{width: '100%', paddingTop: 130, border: '1px solid #eee'}}>
                    <div className="text-center">{error}</div>
                </div>
            )
        }

        if (data === null || data.length === 0) {
            return (
                <div id="custom-bootstrap-table-container"
                     style={{width: '100%', paddingTop: 130, border: '1px solid #eee'}}>
                    <div className="text-center">{gettext('Currently no history available for you')}</div>
                </div>
            )
        }

        function headerFormatter(column, columnIndex) {
            return <HeaderWithToolTip text={column.text} index={columnIndex}/>
        }

        function ListFormatter(cell, row) {
            return (
                <span>
                    <span className="g-color-primary g-font-weight-600">{cell}</span>
                    <span className="g-font-size-11">  ({row.date}) </span>
                </span>
            )
        }

        const columns = [
            {
                dataField: 'name',
                text: gettext('List'),
                headerStyle: (colum, colIndex) => {
                    return {'width': '100px'};
                },
                formatter: ListFormatter,
            }];

        const stateColumns = map(sortBy(data[0].states, 'index'), (state) => ({
            dataField: state.code,
            text: state.name,
            headerFormatter: headerFormatter,
            align: 'center',
            // events: {
            //     onClick: (e, column, columnIndex, row, rowIndex) => {
            //         window.alert('clicked');
            //     },
            // },
            headerStyle: (colum, colIndex) => {
                return {'width': '20px'};
            }
        }));

        let tableData = map(data, (d) => {
            return d.states.reduce((acc, i) => ({...acc, [i.code]: (i.count > 0) ? i.count : ''}), {
                id: d.id,
                name: d.name,
                date: `${moment(d.start).format('DD/MM')} - ${moment(d.stop).format('DD/MM')}`,
                states: d.states,
            });
        });

        const options = {
            sizePerPageList: [],
            sizePerPage: 5,
            pageStartIndex: 1,
            paginationSize: 3,
            paginationShowsTotal: false,
            paginationPosition: 'bottom',
            hideSizePerPage: true,
            alwaysShowAllBtns: true,
            withFirstAndLast: false,
            //  defaultSortName: 'average',
            //  defaultSortOrder: 'desc'
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectColumn: true,
            onSelect: (rowKey, checked, rowIndex) => {
                if (checked) {
                    this.props.onRowClick(rowKey);
                }
            },
        };

        return (
            <div id="custom-bootstrap-table-container">
                <BootstrapTable keyField='id'
                                data={ tableData }
                                columns={[...columns, ...stateColumns]}
                                pagination={ paginationFactory(options) }
                                selectRow={ selectRow }

                                hover
                                condensed

                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        data: selectors.getData(state),
        loading: selectors.getLoading(state),
        error: selectors.getError(state),
    }
};

export default connect(mapStateToProps, {fetchListHistory})(HistoryTable);
