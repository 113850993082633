import {basketTypes} from './types'
import {omit} from 'lodash';

const INITIAL_STATE = {
    loading: false,
    error: '',
    items: [],
    limit: 0,
    offset: 0,
    more: false,
    searchTerm: '',
    totalCount: 0,
};

function basket(state = INITIAL_STATE, action) {
    switch (action.type) {

        case basketTypes.FETCH_REQUEST:
            return Object.assign({}, state, {
                loading: true,
                error: '',
                searchTerm: action.payload.searchTerm,
                offset: action.payload.offset,
            });
        case basketTypes.FETCH_SUCCESS:           
            const {limit, offset, data, totalCount} = action.payload;

            const items =  (offset === 0) ? data : [...state.items, ...data];

            return Object.assign({}, state, {
                loading: false,
                limit,
                offset,
                items,
                error: '',
                more: totalCount > items.length,
                totalCount,
            });
        case basketTypes.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
                more: false,
            };
        default:
            return state
    }
}


export const basketByGroups = (state = {}, action) => {
    switch (action.type) {
        case basketTypes.FETCH_REQUEST:
        case basketTypes.FETCH_SUCCESS:
        case basketTypes.FETCH_FAILURE:
            return Object.assign({}, state, {
                [action.payload.key]: basket(state[action.payload.key], action)
            });
        case basketTypes.CLEANUP:
                return omit(state, action.payload.invalidKeys);
        default:
            return state
    }
}
