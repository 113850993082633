import React from 'react';
import {VehicleVatRegimeBadge, VehicleDamageBadge, VehicleDescriptionLabel, Expectation} from '../../../../index';
import SpecificationsTop3 from './SpecificationsTop3';
import {withConfig} from '../../../../../../config/index';
import {PrintButtons} from "../../../../../print";

const Summary = ({vehicle, listVehicleId, config}) => {
    const {make, model, version, main_company_name, seller_first_name, seller_last_name, provisional_sale, expected_disclose, damages, vat_regime, description, description_plus, list_number} = vehicle;
    const {vehicle_state_expectation, vehicle_state_damage_counter, vehicle_print} = config;

    return (
        <div className="g-bg-white g-pa-10 g-mb-10  bottom-shadow">

            <div className="row g-ma-0 g-pa-0">
                <div className={`col-9 col-md-9 col-lg-9 col-xl-9 g-pa-0 g-ma-0 text-wrap`}>
                    <h5 className="h5 text-uppercase color-highlight g-pa-0 g-ma-0 g-mr-10 text-wrap">
                        {(list_number && list_number > 0) ? '#' + String('000' + list_number).slice(-3) : ""} {make} {model}
                    </h5>
                </div>
                <div className="col-3 text-right g-pa-0 g-ma-0 g-pl-10 justify-content-end">
                    {vehicle_print &&
                    <div className="g-mr-5 g-hidden-xs-down">
                        <PrintButtons listVehicleId={listVehicleId}/>
                    </div>}
                </div>
            </div>


            <span
                className="g-color-gray-dark-v5 g-font-style-normal text-uppercase g-font-size-12">{version}</span>

            <SpecificationsTop3 vehicle={vehicle}
                                fields={(provisional_sale && !expected_disclose) ? ['EXPECTED_FROM', 'EXPECTED_KM_FROM_TO', 'POWER_KW_PK'] : ['KM', 'FIRST_REGISTRATION', 'POWER_KW_PK']}
            />

            {main_company_name && seller_first_name && seller_last_name &&
            <div>{main_company_name} - {seller_first_name} {seller_last_name}  </div>}

            {vehicle_state_expectation &&
            <Expectation {...vehicle} />}


            <div className="row g-ma-0 g-pa-0">

                {vehicle.list_index && vehicle.list_index > 0 &&
                <span className="u-label g-rounded-3 badge badge-normal g-mr-10">
                               #{String('000' + vehicle.list_index).slice(-3)}
                </span>}


                <div className="g-ma-0 g-pa-0">
                    <VehicleVatRegimeBadge vat_regime={vat_regime}/>
                </div>
                {vehicle_state_damage_counter &&
                <div className="g-ma-0 g-pa-0 g-ml-5">
                    <VehicleDamageBadge damages={damages}/>
                </div>}

                {vehicle.list_short_name &&
                <span className="u-label g-rounded-3 badge badge-normal">
                               {vehicle.list_short_name}
                </span>}
            </div>

            <div className="row g-ma-0 g-pa-0 g-mt-5 ">
                <div className="col-12 g-pa-0 g-ma-0">
                    <VehicleDescriptionLabel description={description_plus}/>
                </div>
            </div>
            <div className="row g-ma-0 g-pa-0 g-mt-5 ">
                <div className="col-12 g-pa-0 g-ma-0">
                    <VehicleDescriptionLabel description={description} important/>
                </div>
            </div>
        </div>
    );
};

export default withConfig(Summary);

