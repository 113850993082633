import _ from 'lodash';
import moment from 'moment';


export function filter(state, vehicles) {
    let filterCriteria = state.listvehicles_filter.criteria;

    if (filterCriteria.makes.length > 0 && filterCriteria.makes[0].make !== "") {
        vehicles = filterMakeModel(vehicles, filterCriteria);
    }

    if (filterCriteria.companies.length > 0) {
        vehicles = filterCompanies(vehicles, filterCriteria);
    }

    if (filterCriteria.fuels.length > 0) {
        vehicles = filterFuels(vehicles, filterCriteria);
    }

    if (filterCriteria.gearboxes.length > 0) {
        vehicles = filterGearboxes(vehicles, filterCriteria);
    }

    if (filterCriteria.age.min !== undefined || filterCriteria.age.max !== undefined) {
        vehicles = filterAge(vehicles, filterCriteria);
    }

    if (filterCriteria.km.min !== undefined || filterCriteria.km.max !== undefined) {
        vehicles = filterKm(vehicles, filterCriteria);
    }

    if (filterCriteria.basketOnly) {
        vehicles = filterBasketOnly(vehicles);
    }

    if (filterCriteria.interestWithNoBid) {
        vehicles = filterInterstWithNoBid(vehicles)
    }

    return vehicles;
}

function filterMakeModel(vehicles, filterCriteria) {
    let result = [];

    _.each(vehicles, (vehicle) => {
        _.each(filterCriteria.makes, (filter) => {

            if (filter.make === vehicle.make) {

                if (filter.models.length > 0) {
                    _.each(filter.models, (model) => {

                        if (model === vehicle.model) {
                            if (result.indexOf(vehicle) < 0) {
                                result.push(vehicle);
                            }
                        }
                    });
                } else {
                    if (result.indexOf(vehicle) < 0) {
                        result.push(vehicle);
                    }
                }
            }
        });
    });
    return result;
}

function filterFuels(vehicles, filterCriteria) {
    let result = [];

    _.each(vehicles, (vehicle) => {
        _.each(filterCriteria.fuels, (fuel) => {
            if (vehicle.fuel === fuel) {
                result.push(vehicle);
            }
        });
    });

    return result;
}

function filterGearboxes(vehicles, filterCriteria) {
    let result = [];

    _.each(vehicles, (vehicle) => {
        _.each(filterCriteria.gearboxes, (gearbox) => {
            if (vehicle.gearbox === gearbox) {
                result.push(vehicle);
            }
        });
    });

    return result;
}

function filterCompanies(vehicles, filterCriteria) {
    let result = [];

    _.each(vehicles, (vehicle) => {
        _.each(filterCriteria.companies, (company_id) => {
            if (vehicle.main_company_id === company_id) {
                result.push(vehicle);
            }
        });
    });

    return result;
}

function filterAge(vehicles, filterCriteria) {
    let result = [];

    _.each(vehicles, (vehicle) => {
        let months = moment().diff(moment(vehicle.first_registration), 'months');
        if (months >= filterCriteria.age.min && months <= filterCriteria.age.max) {
            result.push(vehicle);
        }
    });

    return result;
}

function filterKm(vehicles, filterCriteria) {
    let result = [];

    _.each(vehicles, (vehicle) => {
        if (vehicle.kilometers >= filterCriteria.km.min && vehicle.kilometers <= filterCriteria.km.max) {
            result.push(vehicle);
        }
    });

    return result;
}

export function hasActiveCriteria(state, id) {

    if (!state.listvehicles_filter[id]) return false;
    if (!state.listvehicles_filter[id].criteria) return false;

    let filterCriteria = state.listvehicles_filter[id].criteria;

    if ((filterCriteria.makes.length > 0 && filterCriteria.makes[0].make !== "") ||
        (filterCriteria.fuels.length > 0) ||
        (filterCriteria.gearboxes.length > 0) ||
        (filterCriteria.companies.length > 0) ||
        (filterCriteria.states !== undefined && filterCriteria.states.length > 0) ||
        (filterCriteria.age.min !== undefined || filterCriteria.age.max !== undefined) ||
        (filterCriteria.km.min !== undefined || filterCriteria.km.max !== undefined) ||
        ((filterCriteria.offeredDt_min !== null && filterCriteria.offeredDt_min !== undefined) || (filterCriteria.offeredDt_max !== undefined && filterCriteria.offeredDt_max !== null)) ||
        (filterCriteria.basketOnly === true)) {

        return true;
    }

    return false;
}

export function hasFilterData(state, id) {

    if (!state.listvehicles_filter[id]) return false;

    let filterData = state.state.listvehicles_filter[id].data;

    if ((filterData.companies.length > 0) ||
        (filterData.models.length > 0) ||
        (filterData.fuels.length > 0) ||
        (filterData.gearboxes.length > 0)) {
        return true;
    }
    return false;
}


function filterBasketOnly(vehicles) {
    let result = [];

    _.each(vehicles, (vehicle) => {
        if (vehicle.basket_state === 'INITIAL' || vehicle.basket_state === 'TRANSMITTED') {
            result.push(vehicle);
        }

    });

    return result;
}

function filterInterstWithNoBid(vehicles) {
    let result = [];
    _.each(vehicles, (vehicle) => {
        if ((typeof vehicle.basket_price === 'undefined' || vehicle.basket_price <= 0) && (typeof vehicle.interest === 'undefined' || vehicle.interest)) {
            result.push(vehicle);
        }
    });
    return result;


}

