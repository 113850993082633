import React, {Component} from 'react';
import {connect} from 'react-redux';
import {LoadingContainer, LoadingIndicator, WheelNotOutSide, InfoContainer} from '../../core';
import HistoryVehicleItem from './HistoryVehicleItem';
import {ListVehicleState} from '../../../components/listvehiclestate';
import {historyVehicleSelect} from '../actions';
import {withRouter} from 'react-router-dom';
import {dashboard, goBack} from '../../../_web/navigation/routes';
import {gettext} from '../../../translations';
import InfiniteScroll from 'react-infinite-scroller';
import * as basketSelectors from '../../basket/selectors';
import {basketFetchWebTest, basketFetchMore} from '../../basket/actions';
import {Filter, FilterSummary, FilterButton, ClearFilters, hasActiveCriteria} from '../../listvehicles-filter/';
import {SortDropDown, SortDropDownIcon, getHistorySorters} from '../../listvehicles-sort';
import {compose} from 'redux';
import {withConfig} from '../../../config';
import ExcelDownloadByFilter from './ExcelDownloadByFilter';
import {Scrollbars} from 'react-custom-scrollbars';

const CORR = 95;
const xxl = 1600;
const small = 992;

class HistoryVehicles extends Component {
    constructor() {
        super();


        this.state = {
            height: window.innerHeight - CORR,
            width: window.innerWidth,
            detailsVisible: window.innerWidth >= xxl,
            small: window.innerWidth <= small,
            closing: false,
            clicked: false,
            filterModalVisible: false,
        };

        this.onListVehicleClick = this.onListVehicleClick.bind(this);
        this.onShowState = this.onShowState.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.gooBack = this.gooBack.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    handleResize() {
        this.setState({
            height: window.innerHeight - CORR,
            width: window.innerWidth,
            detailsVisible: window.innerWidth >= xxl,
            small: window.innerWidth <= small,
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.handleResize, true);
    }


    handleScroll() {
        if (this.state.detailsVisible) {
            const footerOffset = document.getElementById('footer').offsetTop;
            const currentOffset = window.pageYOffset + window.innerHeight;

            if (currentOffset > footerOffset) {
                this.setState({
                    height: ((window.innerHeight - CORR) - (currentOffset - footerOffset)),
                });
            } else {
                const height = window.innerHeight - CORR;
                if (this.state.height !== height) {
                    this.setState({
                        height: height,
                    });
                }
            }
        }
    }


    onListVehicleClick(listVehicleId) {
        if (!this.state.detailsVisible) {
            this.setState({clicked: true}, () => this.props.historyVehicleSelect(listVehicleId, true));

        }
        else {
            this.props.historyVehicleSelect(listVehicleId, false);
        }
    }

    onShowState(listVehicleId) {
        this.props.historyVehicleSelect(listVehicleId, true);
    }

    loadMore() {
        this.props.basketFetchMore('ALL');
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.handleResize, true);

    }


    gooBack() {
        const {location,} = this.props;

        //this.props.resetFilterCriteria(listId);

        this.setState({closing: true});

        if (location.state && location.state.routed) {
            goBack();
        }
        else {
            dashboard();
        }

    }


    render() {
        const {vehicleIds, loading, selectedVehicleId, offset, totalCount, more, hasActiveFilter, config} = this.props;

        if (this.state.closing) return null;

        if (totalCount === '0' && !loading && !hasActiveFilter) {
            return (
                <div className="g-mt-100">
                    <InfoContainer icon="fa fa-gavel"
                                   text={gettext("Currently no vehicles with biddings to show") + "..."}/>
                </div>
            )
        }

        return (
            <div className="row bg-normal g-ma-0 g-mt-70 g-mb-200">

                <div className="g-hidden-sm-down col-md-3 col-lg-3 col-xl-3 col-xxl-2 g-ma-0 g-pl-5 g-pr-5">


                    <div className="sticky" style={{height: this.state.height}}>
                        <Scrollbars autoHide style={{height: this.state.height}}>
                            <WheelNotOutSide>
                                <React.Fragment>
                                    <div className="bg-white g-pt-10 g-pb-10 g-mb-10">

                                        <div className="text-center g-mt-10">
                                            <button onClick={() => this.gooBack()}
                                                    className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                                                <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"></i> {gettext('Back')}
                                            </button>

                                            {config.history_download_excel &&
                                            <ExcelDownloadByFilter />}
                                        </div>
                                    </div>

                                    <Filter listId={-1}
                                            onCriteriaChanged={() => this.props.basketFetchWebTest('ALL')}
                                            showCounters={false}
                                    />
                                </React.Fragment>
                            </WheelNotOutSide>
                        </Scrollbars>
                    </div>

                </div>

                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-5 g-ma-0 g-pl-5 g-pr-5">

                    <div className="sticky sticky-md-down bg-normal" style={{zIndex: 50}}>

                        <div className="g-bg-white g-pa-10 g-mb-10  bottom-shadow">

                            <div className="justify-content-between align-items-center d-flex g-mb-0">

                                <div className="g-hidden-md-up">
                                    <button onClick={this.gooBack}
                                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                                        <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"/>
                                        <span>
                                {gettext('Back')}
                            </span>
                                    </button>
                                </div>

                                <div className="row g-pa-0 g-ma-0">
                                    {totalCount &&
                                    <div className="g-pa-3 g-pt-5 text-uppercase g-hidden-sm-down">
                                        {totalCount} {gettext('results')}
                                    </div>}
                                    {totalCount &&
                                    <div className="g-pa-3 g-pt-5 text-uppercase g-hidden-md-up g-font-size-15">
                                        #{totalCount}
                                    </div>}
                                    <div className="g-pa-3 g-pt-5 g-hidden-sm-down">
                                        <ClearFilters listId={-1}
                                                      onClearFilters={() => this.props.basketFetchWebTest('ALL')}/>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-inline-block btn-group g-line-height-1_2 g-mr-15 g-hidden-md-up">
                                        <FilterButton listId={-1}
                                                      onCriteriaChanged={() => this.props.basketFetchWebTest('ALL')}
                                                      onClearFilters={() => this.props.basketFetchWebTest('ALL')}
                                        />
                                    </div>
                                    <div className="d-inline-block btn-group g-line-height-1_2 g-hidden-md-down">
                                        <SortDropDown listId={-1}
                                                      sorters={getHistorySorters()}
                                                      defaultSorterId={2}
                                                      onSortChanged={() => this.props.basketFetchWebTest('ALL')}/>
                                    </div>
                                    <div className="d-inline-block btn-group g-line-height-1_2 g-hidden-lg-up">
                                        <SortDropDownIcon listId={-1}
                                                          sorters={getHistorySorters()}
                                                          defaultSorterId={2}
                                                          onSortChanged={() => this.props.basketFetchWebTest('ALL')}/>
                                    </div>
                                </div>
                            </div>
                            <div className="g-hidden-sm-down">
                                <FilterSummary listId={-1}
                                               onCriteriaChanged={() => this.props.basketFetchWebTest('ALL')}/>
                            </div>
                        </div>

                        <LoadingIndicator loading={loading}/>
                    </div>


                    <LoadingContainer loading={loading && vehicleIds.length > 0 && offset === 0 }>
                        <WheelNotOutSide>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.loadMore}
                                hasMore={more}
                                useWindow={true}
                                threshold={800}
                                loader={<LoadingIndicator key="loader" loading={(vehicleIds.length > 0)}/>}
                            >
                                {vehicleIds.map((id) =>
                                    <HistoryVehicleItem key={id}
                                                        listVehicleId={id}
                                                        onClick={this.onListVehicleClick}
                                                        selected={selectedVehicleId === id}
                                    />
                                )}
                            </InfiniteScroll>
                        </WheelNotOutSide>
                    </LoadingContainer>
                </div>

                <div className="hide-xl-down col-xxl-5 g-ma-0 g-pl-5 g-pr-5">

                    {this.state.detailsVisible &&
                    <div className="sticky"
                         style={{height: this.state.height, overflow: 'auto'}}>
                        <Scrollbars autoHide style={{height: this.state.height}}>
                            <WheelNotOutSide>
                                <ListVehicleState containerId={selectedVehicleId}
                                                  listVehicleId={selectedVehicleId}
                                                  showStickyHeader={false}
                                />
                            </WheelNotOutSide>
                        </Scrollbars>
                    </div>
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        vehicleIds: basketSelectors.getIds(state, 'ALL'),
        loading: basketSelectors.getLoading(state, 'ALL'),
        offset: basketSelectors.getOffset(state, 'ALL'),
        more: basketSelectors.getMore(state, 'ALL'),
        totalCount: basketSelectors.getTotalCount(state, 'ALL'),
        hasActiveFilter: hasActiveCriteria(state, -1),
    };
};

export default compose(
    withRouter,
    withConfig,
    connect(mapStateToProps, {
        historyVehicleSelect,
        basketFetchWebTest,
        basketFetchMore,
    }),
)(HistoryVehicles)



