import React, {Component} from 'react';
import {connect} from 'react-redux';
import FiltersHeader from './FiltersHeader';
import FilterDetailsHeader from './FilterDetailsHeader';
import {LoadingContainer, LoadingIndicator} from '../../core';
import FiltersList from './FiltersList';
import FilterForm from './FilterForm';
import FilterModal from './FilterModal';
import {
    filtersFetch,
    filtersCompaniesFetch,
    filtersMakesFetch,
    selectFilter,
    updateSelectedFilterData,
    createFilter,
    updateFilter,
    deleteFilter
} from '../actions';
import * as selectors from '../selectors';
import {createNewFilter} from '../functions/filter';

class FiltersConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {modalVisible: false};

        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCreateNew = this.handleCreateNew.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.props.filtersFetch();
        this.props.filtersCompaniesFetch();
        this.props.filtersMakesFetch();
    }

    componentWillUnmount() {
        const {selectFilter} = this.props;
        selectFilter(null)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedFilter === null && nextProps.filtersCount > 0) {
            this.props.selectFilter(nextProps.filters[Object.keys(nextProps.filters)[0]])
        }
    }

    handleCreateNew() {
        const {makes} = this.props;
        this.handleSelect(createNewFilter(makes))
    }

    handleSelect(filter) {
        const {selectFilter} = this.props;

        if (window.innerWidth < 992) {
            this.setState({modalVisible: true});
        }

        selectFilter(filter)
    }

    toggleModal() {
        this.setState({modalVisible: !this.state.modalVisible});
    }


    handleSave() {
        const {selectedFilter, createFilter, updateFilter} = this.props;

        if (selectedFilter) {
            if (selectedFilter.id === -1) {
                createFilter(selectedFilter);
            } else {
                updateFilter(selectedFilter);
            }
        }

    }

    handleDelete() {
        const {selectedFilter, deleteFilter} = this.props;

        if (selectedFilter) {
            deleteFilter(selectedFilter);
        }

        if (this.state.modalVisible) {
            this.toggleModal();
        }
    }

    handleInput(filter) {
        const {updateSelectedFilterData} = this.props;
        updateSelectedFilterData(filter);
    }

    render() {
        const {filters, selectedFilter, makes, companies, filtersLoading, filterLoading} = this.props;
        return (
            <div className="row g-ma-0 g-mt-70">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 g-ma-0 g-pl-5 g-pr-5">

                    <div className="sticky bg-normal" style={{zIndex: 50}}>
                        <FiltersHeader onCreateNew={this.handleCreateNew}/>
                        <LoadingIndicator loading={filtersLoading}/>
                    </div>
                    <FiltersList filters={filters}
                                 selectedFilter={selectedFilter}
                                 onSelectFilter={this.handleSelect}
                                 allMakesCount={makes.length}
                                 allCompaniesCount={companies.length}
                    />
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 g-hidden-md-down">
                    <div className="sticky bg-normal" style={{zIndex: 50}}>
                        <FilterDetailsHeader saveEnabled={(selectedFilter !== undefined && selectedFilter !== null)}
                                             deleteEnabled={(selectedFilter !== undefined && selectedFilter !== null && selectedFilter.id > 0)}
                                             onSave={this.handleSave}
                                             onDelete={this.handleDelete}
                        />
                        <LoadingIndicator loading={filterLoading}/>
                    </div>

                    <div className="g-bg-white g-pa-0 g-ma-0  g-pa-10">
                        <LoadingContainer loading={filterLoading}>
                            <FilterForm filter={selectedFilter}
                                        allCompanies={companies}
                                        allMakes={makes}
                                        onChange={this.handleInput}
                            />
                        </LoadingContainer>
                    </div>
                </div>


                {this.state.modalVisible &&
                <FilterModal {...this.props}
                             visible={this.state.modalVisible}
                             onChange={this.handleInput}
                             onCancel={() => {
                                 this.setState({modalVisible: false})
                             }}
                             onSave={this.handleSave}
                             onDelete={this.handleDelete}
                />}


            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        filters: selectors.getFilters(state),
        selectedFilter: selectors.getSelectedFilter(state),
        filtersLoading: selectors.getLoading(state),
        filterLoading: selectors.getSelectedFilterLoading(state),
        filtersCount: selectors.getFiltersCount(state),

        makes: selectors.getMakes(state),
        companies: selectors.getCompanies(state),
    };
};

export default connect(mapStateToProps, {
    filtersFetch,
    filtersCompaniesFetch,
    filtersMakesFetch,
    selectFilter,
    updateSelectedFilterData,
    createFilter,
    updateFilter,
    deleteFilter,
})(FiltersConfiguration);

