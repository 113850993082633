import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toggleSwiperVisibility} from '../actions';
import * as selectors from '../selectors';
import {PhotoSwipe} from 'react-photoswipe';
// For swiper options ==> http://photoswipe.com/documentation/options.html

class PictureSwiper extends Component {
    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
    };

    onClose() {
        this.props.toggleSwiperVisibility(false);
    }

    render() {
        const {visible, pictures, index} = this.props;
        return (

                <PhotoSwipe isOpen={visible}
                            items={pictures}
                            onClose={this.onClose}
                            options={{
                                index: index,
                                closeOnScroll: false,
                                clickToCloseNonZoomable: false,
                                history: false,
                                tapToClose: false,
                                closeElClasses: ['caption', 'zoom-wrap', 'ui', 'top-bar'],
                                shareButtons: [
                                    {id:'download', label:'Download image', url:'{{raw_image_url}}', download:true}
                                ],
                            }}
                />

        );
    }
}

const mapStateToProps = (state) => {
    return {
        visible: selectors.getVisible(state),
        pictures: selectors.getPictures(state),
        index: selectors.getIndex(state),
    };
};

export default connect(mapStateToProps, {toggleSwiperVisibility})(PictureSwiper);


