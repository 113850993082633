import React, {Component} from 'react';
import {Template} from '../components/core/index';
import {isAuthenticated} from '../components/session/'
import {loginByCode} from '../components/authentication';
import {connect} from 'react-redux';
import {getIsAuthenticating, getError} from '../components/authentication'
import {LoadingIndicator} from '../components/core';
import {Redirect} from 'react-router-dom';

class LoginByCode extends Component {
    constructor(props){
        super(props);
        this.state = { redirectToReferrer: false };
    }

    componentDidMount() {
        const {code} = this.props;
        this.props.loginByCode(code);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authenticated) {
            this.setState({redirectToReferrer: true})
        }
    }

    render() {
        const {authenticating, error} = this.props;
        let { from } = (this.props.location && this.props.location.state) || { from: { pathname: "/dashboard" } };
        let { redirectToReferrer } = this.state;

        if (redirectToReferrer) return <Redirect to={from} />;


        return (
            <Template>
                <div className="g-mt-80">
                    <LoadingIndicator loading={authenticating}/>
                    {error && <span className="text-danger">ERROR: {error}</span>}
                </div>
            </Template>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authenticating: getIsAuthenticating(state),
        authenticated: isAuthenticated(state),
        error: getError(state),
    };
};


export default connect(mapStateToProps, {loginByCode})(LoginByCode);

