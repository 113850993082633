import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateCriteria} from '../actions';
import * as listVehiclesFilterSelectors from '../../listvehicles-filter/selectors';
import * as criteriaHelpers from '../functions/criteria';
import {gettext} from '../../../translations';

class EyeButton extends Component {

    render(){
        const {listId, criteria, updateCriteria, onInterestWithNoBidChanged, showText} = this.props;
        return (
            <div className="g-px-10 g-py-5 clickable"
                 style={{minHeight: 28,backgroundColor: criteria.interestWithNoBid ? '#96db79' : '#f2f2f2', color: criteria.interestWithNoBid ? 'white' : 'black'}}
                 onClick={() => {
                     updateCriteria(listId, criteriaHelpers.toggleInterestWithNoBid(criteria)).then(() => onInterestWithNoBidChanged());
                 }}>
                <i className="fa fa-eye-slash g-font-size-16 clickable g-mr-5" style={{color: criteria.interestWithNoBid ? 'white' : 'black'}}/> {(showText) ? gettext('All') : "" }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        criteria: listVehiclesFilterSelectors.getCriteriaCopy(state, ownProps.listId),
    }
};

EyeButton.defaultProps = {
    showText: true,
};

export default connect(mapStateToProps, {updateCriteria})(EyeButton);



