import React from 'react';

const ConfirmHeader = ({cancelBtnText, nextBtnText, title, onCancel, onNext}) => (
    <div className="sticky sticky-md-down col-12 g-pa-0 g-ma-0 bg-normal" style={{zIndex: 50}}>

        <div
            className="row g-pa-0 g-ma-0 justify-content-between g-bg-white g-pa-5 g-mb-10  bottom-shadow g-hidden-xs-down">

            <div onClick={onCancel}
                 className="g-color-white g-bg-red g-bg-red--hover clickable g-font-weight-500 g-px-15 g-py-6 g-mr-10">
                <i className="fa fa-chevron-left g-mr-10"></i>{cancelBtnText}
            </div>

            <h6 className="mb-2 color-highlight g-font-weight-800 g-pt-5">
                {title}
            </h6>

            <div onClick={onNext}
                 className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6">
                {nextBtnText}<i className="fa fa-chevron-right g-ml-10"></i>
            </div>

        </div>


        <div
            className="row g-pa-0 g-ma-0 justify-content-center g-bg-white g-pa-5 g-mb-10  bottom-shadow g-hidden-sm-up">

            <h6 className="col-12 g-pa-0 g-ma-0 mb-2 color-highlight g-font-weight-800 g-pt-5 text-center">
                {title}
            </h6>

            <div className="row g-pa-0 g-ma-0 justify-content-between g-pt-5">

                <div onClick={onCancel}
                     className="g-color-white g-bg-red g-bg-red--hover clickable g-font-weight-500 g-px-15 g-py-6 g-mr-10">
                    <i className="fa fa-chevron-left g-mr-10"></i>{cancelBtnText}
                </div>

                <div onClick={onNext}
                     className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6">
                    {nextBtnText}<i className="fa fa-chevron-right g-ml-10"></i>
                </div>
            </div>

        </div>

    </div>
);


export default ConfirmHeader;


