import {includes} from 'lodash'
import {gettext} from "../translations";


export const DealerStates = {
    HISTORY: 'HISTORY',
    DELIVERY: 'DELIVERY',
    DELIVERED: 'DELIVERED',
    ORDERED: 'ORDERED',
    RESERVED: 'RESERVED',
    OFFERED: 'OFFERED',
    HISTORY_REFUSED: 'HISTORY_REFUSED',

    ACCEPTED: 'ACCEPTED',
    REFUSED: 'REFUSED',
    HIGHEST: 'HIGHEST',
};

export const DealerStateTranslate = {
    [DealerStates.HISTORY]: gettext('History', true),
    [DealerStates.DELIVERY]: gettext('Deliverable', true),
    [DealerStates.DELIVERED]: gettext('DELIVERED', true),
    [DealerStates.ORDERED]: gettext('Ordered', true),
    [DealerStates.RESERVED]: gettext('Reserved', true),
    [DealerStates.OFFERED]: gettext('Offered', true),
    [DealerStates.HISTORY_REFUSED]: gettext('Refused', true),

    [DealerStates.ACCEPTED]: gettext('Accepted', true),
    [DealerStates.REFUSED]: gettext('Refused', true),
    [DealerStates.HIGHEST]: gettext('Highest', true),
};

class HistoryStates {

    constructor() {
        this.states = DealerStates;
    }

    soldStates() {
        return [this.states.DELIVERY, this.states.DELIVERED, this.states.ORDERED, this.states.RESERVED, this.states.ACCEPTED, this.states.HIGHEST]
    }

    pendingStates() {
        return [this.states.OFFERED]
    }

    lostStates() {
        return [this.states.HISTORY_REFUSED, this.states.REFUSED]
    }

    historyStates() {
        return [this.states.HISTORY, this.states.HISTORY_REFUSED]
    }

    isSold(state) {
        return includes(this.soldStates(), state)
    }

    isPending(state) {
        return includes(this.pendingStates(), state)
    }

    isLost(state) {
        return includes(this.lostStates(), state)
    }

    isHistory(state) {
        return includes(this.historyStates(), state)
    }
}


const HistoryStatesHelper = new HistoryStates();

export {HistoryStatesHelper};




