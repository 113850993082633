import React from 'react';
import VehicleInfo from './VehicleInfo';
import {gettext} from '../../../translations';
import {formatPrice} from '../../../helpers/functions';
import NumberFormat from 'react-number-format';
import {Link} from 'react-router-dom';
import {withConfig} from '../../../config';
import {Rating} from '../../../components/core';

class BidConfirm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {comment: props.comment, price: props.price, quality: 0};
    }

    render() {
        const {listVehicleId, price, fixedPrice, config, vehicle} = this.props;
        const {vehicle_bidding_cart, vehicle_vat_regime} = config;

        const placeholder = (vehicle_vat_regime) ? gettext("Incl VAT") : (vehicle.vat_regime) ? gettext("Incl VAT") : gettext("Excl VAT");


        return (
            <div className="g-pa-2" style={{maxWidth: 600}}>


                <VehicleInfo listVehicleId={listVehicleId}/>

                <div className="row g-pa-0 g-ma-0 g-pt-20">
                    <div className="col g-pa-0 g-ma-0 g-pb-10">
                        <h6 className="color-highlight g-font-weight-800">{gettext('Your bid')}:</h6>
                        <NumberFormat value={this.state.price}
                                      disabled={fixedPrice}
                                      getInputRef={(el) => this.priceInput = el}
                                      placeholder={placeholder}
                                      thousandSeparator={' '} prefix={'€ '}
                                      onValueChange={(values) => this.setState({price: values.value})}
                                      allowNegative={false}
                                      onSelect={() => {
                                          this.setState({inputSelected: true})
                                      }}
                                      onBlur={() => this.setState({inputSelected: false})}
                                      onKeyPress={(e) => {
                                          // if (e.key === 'Enter') {
                                          //     this.showBidConfirmModal()
                                          // }
                                      }}
                                      className="slick-slide g-brd-none g-brd-bottom g-brd-gray-light-v1 g-brd-primary--active g-brd-primary--focus g-brd-primary--hover"
                                      onFocus={() => (this.priceInput) ? this.priceInput.setSelectionRange(0, this.priceInput.value.length) : null}
                                      autoFocus={!(price > 0)}
                                      style={{
                                          border: 'none',
                                          outline: 'none',
                                          outlineOffset: 0,
                                          paddingRight: 10,
                                      }}
                        />

                    </div>
                    <div className="col g-pa-0 g-ma-0 g-pb-10">
                        <h6 className="color-highlight g-font-weight-800">{gettext('Inspection quality')}:</h6>
                        <Rating rating={this.state.quality} onChange={(rate) => {
                            this.setState({quality: rate})
                        }}/>
                        <p>{gettext("We value your feedback")}</p>
                    </div>
                </div>

                {!vehicle_bidding_cart &&
                <div className="row g-pa-0 g-ma-0">
                    <div className="col g-pa-0 g-ma-0">
                        <h6 className="color-highlight g-font-weight-800">{gettext('Your comment')}:</h6>
                        <textarea placeholder={gettext("Add your comment here")}
                                  style={{width: '100%'}}
                                  value={this.state.comment}
                                  onChange={(e) => this.setState({comment: e.target.value})}
                                  rows={2}
                                  autoFocus={(price > 0)}
                                  className="slick-slide g-brd-none g-brd-bottom g-brd-gray-light-v1 g-brd-primary--active g-brd-primary--focus g-brd-primary--hover"/>
                    </div>
                </div>}

                {!vehicle_bidding_cart &&
                <div
                    className="row g-pa-0 g-ma-0 g-mt-20 g-mb-20 g-pa-10 justify-content-center text-center bg-normal flex-column">

                    <span>{gettext('Do you confirm to post an offer of')}{" "}
                        <span className="g-font-weight-800">
                                {formatPrice(this.state.price)}{" "}
                            </span>{gettext('for this car?')}
                        </span>

                    <span>
                            {gettext('This offer is subject to the')}{" "}
                        <span className="g-text-underline">
                                <Link to="../terms-and-conditions" target="_blank">
                                     {gettext("general sales conditions")}.
                                </Link>
                            </span>
                        </span>
                </div>}


                <div className="row justify-content-end g-pa-0 g-ma-0">
                    <button onClick={(e) => {
                        e.stopPropagation();
                        this.props.onConfirm(this.state.price, this.state.comment, this.state.quality)
                    }}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-500 g-font-size-14 rounded-0 g-pa-8">
                        {gettext('I Confirm')}
                    </button>

                    <button onClick={(e) => {
                        e.stopPropagation();
                        this.props.onCancel()
                    }}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-500 g-font-size-14 rounded-0 g-pa-8 g-ml-8">
                        {gettext('Cancel')}
                    </button>
                </div>
            </div>
        )
    }
}

export default withConfig(BidConfirm);





