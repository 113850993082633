import React from 'react';
import {Template} from '../components/core/index';
import {InviteForm} from '../components/colleagues';


const Invite = ({match}) =>
    <Template>
        <div className="g-bg-white g-mt-20">
            <section className="container g-py-100 ">
                <InviteForm inviteId={match.params.inviteId} />
            </section>
        </div>
    </Template>;

export default Invite ;



