import _ from 'lodash';
import {INITIAL_FILTERCRITERIA} from '../state';

export const addCompany = (criteria, company) => {
    if (!_.includes(criteria.companies, company)) {
        criteria.companies.push(company);
    }
    return criteria;
};

export const setCompanies = (criteria, companies) => {
    criteria.companies = companies;
    return criteria;
};


export const removeCompany = (criteria, company) => {
    if (_.includes(criteria.companies, company)) {
        criteria.companies = _.pull(criteria.companies, company);
    }
    return criteria;
};

export const addMake = (criteria, make) => {
    if ((_.findIndex(criteria.makes, (mm) => {
            return mm.make === make
        })) <= -1) {

        criteria.makes.push({make: make, model: null});
    }
    return criteria;
};

export const addMakeModel = (criteria, makeModel) => {
    if ((_.findIndex(criteria.makes, (mm) => {
            return mm.make === makeModel.make && mm.model === makeModel.model
        })) <= -1) {

        criteria.makes.push({make: makeModel.make, model: makeModel.model});
    }
    return criteria;
};

export const removeMake = (criteria, make) => {
    const index = (_.findIndex(criteria.makes, (mm) => {
        return mm.make === make && (mm.model === "" || mm.model === null || mm.model === undefined)
    }));

    if (index > -1) {
        criteria.makes.splice(index, 1);
    }
    return criteria;
};


export const removeMakeModel = (criteria, makeModel) => {
    const index = (_.findIndex(criteria.makes, (mm) => {
        return mm.make === makeModel.make && mm.model === makeModel.model
    }));

    if (index > -1) {
        criteria.makes.splice(index, 1);
    }
    return criteria;
};

export const removeAllMakeModels = (criteria, make) => {
    criteria.makes = _.filter(criteria.makes, (mm) => mm.make !== make);
    return criteria;
};


export const updateMakeByIndex = (criteria, index, make) => {
    criteria.makes[index] = {make: make, models: []};
    return criteria;
};

export const updateModelsByMakeIndex = (criteria, index, models) => {
    criteria.makes[index].models = models;
    return criteria;
};


export const addFuel = (criteria, fuel) => {
    if (!_.includes(criteria.fuels, fuel)) {
        criteria.fuels.push(fuel);
    }
    return criteria;
};

export const setFuels = (criteria, fuels) => {
    criteria.fuels = fuels;
    return criteria;
};

export const removeFuel = (criteria, fuel) => {
    if (_.includes(criteria.fuels, fuel)) {
        criteria.fuels = _.pull(criteria.fuels, fuel);
    }
    return criteria;
};

export const addGearbox = (criteria, gearbox) => {
    if (!_.includes(criteria.gearboxes, gearbox)) {
        criteria.gearboxes.push(gearbox);
    }
    return criteria;
};

export const setGearboxes = (criteria, gearboxes) => {
    criteria.gearboxes = gearboxes;
    return criteria;
};

export const removeGearbox = (criteria, gearbox) => {
    if (_.includes(criteria.gearboxes, gearbox)) {
        criteria.gearboxes = _.pull(criteria.gearboxes, gearbox);
    }
    return criteria;
};

export const addState = (criteria, state) => {
    if (!_.includes(criteria.states, state)) {
        criteria.states.push(state);
    }
    return criteria;
};

export const removeState = (criteria, state) => {
    if (_.includes(criteria.states, state)) {
        criteria.states = _.pull(criteria.states, state);
    }
    return criteria;
};

export const toggleInterestWithNoBid = (criteria) => {
    criteria.interestWithNoBid = !criteria.interestWithNoBid;
    return criteria;
};

export const toggleFavorites = (criteria) => {
    criteria.favorites = !criteria.favorites;
    return criteria;
};


export const updateAge = (criteria, age) => {
    criteria.age.min = age.min;
    criteria.age.max = age.max;
    return criteria;
};

export const removeAge = (criteria) => {
    criteria.age = {};
    return criteria;
};

export const updateKm = (criteria, km) => {
    criteria.km.min = km.min;
    criteria.km.max = km.max;
    return criteria;
};

export const removeKm = (criteria,) => {
    criteria.km = {};
    return criteria;
};

export const removeOfferedDt = (criteria,) => {
    criteria.offeredDt_min = null;
    criteria.offeredDt_max = null;
    return criteria;
};

export const removeExpectationDt = (criteria,) => {
    criteria.expectationDt_min = null;
    criteria.expecationdDt_max = null;
    return criteria;
};

export const updatePrice = (criteria, price) => {
    criteria.price.min = price.min;
    criteria.price.max = price.max;
    return criteria;
};

export const updateOfferedDt = (criteria, minDt, maxDt) => {
    criteria.offeredDt_min = minDt.toString();
    criteria.offeredDt_max = maxDt.toString();
    return criteria;
};

export const updateExpectationDt = (criteria, minDt, maxDt) => {
    criteria.expectationDt_min = minDt.toString();
    criteria.expectationDt_max = maxDt.toString();
    return criteria;
};

export const removeList = (criteria,) => {
    criteria.list = null;
    return criteria;
};

export const reset = (criteria, initial = null) => {
    /* Don't reset interestWithNoBid, if enabled -> enabled for whole session */
    let result = Object.assign({}, INITIAL_FILTERCRITERIA, {interestWithNoBid: criteria.interestWithNoBid});

    if (initial) {
        result = Object.assign({}, result, initial);
    }

    return result;
};

export const containsMake = (criteria, make) => {
    return _.findIndex(criteria.makes, function (mm) {
            return mm.make === make && (mm.model === "" || mm.model === null || mm.model === undefined)
        }) > -1;
};
