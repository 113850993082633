import React from 'react';
import {Field, reduxForm} from 'redux-form'
import {FormFields, validators} from '../../../../core';

const {Multiline} = FormFields;

const dealerPresentationFields = {
    PRESENTATION_TEXT: 'presentation_text',
};

const mapDataToFields = (data) => {
    return ({
        [dealerPresentationFields.PRESENTATION_TEXT]: data.presentation_text,
    })
};


const DealerPresentationForm = ({initialValues, handleSubmit, required}) =>
    <form initialvalues={mapDataToFields(initialValues)}
          onSubmit={handleSubmit}>

            <Field name={dealerPresentationFields.PRESENTATION_TEXT}
                   component={Multiline}
                   showLabel={false}
                   type="text"
                   rows={20}
                   validate={[validators.required]}
                //   placeholder={gettext("This text will be used in mail comunicaton to the dealers")}
            />
    </form>;


export default reduxForm({
    form: 'Registration-DealerPresentation',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
})(DealerPresentationForm)
