import React from 'react';
import {connect} from 'react-redux';
import {goToVehicleDetails} from '../../../_web/navigation/routes';
import {vehiclesFetch, vehicleSearchCleanUp} from '../actions';
import * as selectors from '../selectors';
import Autosuggest from 'react-autosuggest';
import {gettext} from '../../../translations';
import moment from 'moment';
import numeral from 'numeral';
import {numberFormat} from '../../../helpers/functions';
import format from 'string-format';
import {Expectation} from '../../core';
import {config} from '../../../config';
import {DealerStateTranslate} from '../../../helpers/utils';

const getSuggestionValue = suggestion => "";


class VehicleSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            prevValue: '',
        };

        this.onChange = this.onChange.bind(this);
        this.goToVehicle = this.goToVehicle.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }

    onChange = (event, {newValue, method}) => {

        this.setState({
            value: newValue
        });

    };

    onSuggestionsFetchRequested = ({value}) => {
        const trimmed = value.trim().replace(" ", "");
        if (trimmed.length >= 6 && this.state.prevValue !== trimmed) {
            this.props.vehiclesFetch(trimmed).then(() => this.setState({prevValue: trimmed}))
        }
    };

    onSuggestionSelected(event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) {
        this.goToVehicle(suggestion);
        this.props.vehicleSearchCleanUp()

    }

    onSuggestionsClearRequested = () => {
        this.props.vehicleSearchCleanUp();
    };

    renderSuggestion = (suggestion, {isHighlighted}) => {
        const className = isHighlighted ? 'highlight' : null;
        const {make, model, vin, picture_url, first_registration, km, provisional_sale, expected_date, expected_km, expected_km_to, expected_disclose, main_company_name, list_name, dealer_state} = suggestion;
        const {vehicle_first_registration_format, list_vehicle_expectation} = config;

        return (
            <div className={className} style={{minWidth: 400}}>


                <div className="row g-pa-0">

                    <div className="col-md-12">

                        <div className="row g-pa-0 g-ma-0 g-pos-rel g-nowrap">

                            <div className="col-12 g-pa-0 g-ma-0 text-wrap">
                                <h5 className="g-font-size-14 d-inline-block text-uppercase color-highlight g-pa-0 g-ma-0 g-mr-10 text-wrap">
                                    {make} {model}
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div>
                            {picture_url &&
                            <img src={picture_url}
                                 alt={make + ' ' + model}
                                 className="img-fluid" style={{width: '100%', height: '100%'}}/>}

                            {!picture_url &&
                            <img src={require("../../../img/nopic.jpeg")}
                                 alt={make + ' ' + model}
                                 className="img-fluid"/>}

                        </div>

                        {dealer_state &&
                        <div
                            className="bg-highlight color-highlight-invert g-font-size-14 g-font-weight-600 text-center">
                            {gettext(DealerStateTranslate[dealer_state])}
                        </div>}


                        {/*<div*/}
                        {/*className="bg-highlight color-highlight-invert g-font-size-12 g-font-weight-600 text-center">*/}
                        {/*{basket_state}*/}
                        {/*</div>*/}
                    </div>

                    {provisional_sale === expected_disclose &&
                    <div className="col-7 g-pl-0">
                        <div className="text-wrap">{list_name} </div>
                        <div className="text-wrap">{main_company_name} </div>
                        <div
                            className="text-wrap">{moment(first_registration).format(vehicle_first_registration_format)}
                            - {format(gettext('{0} km'), numberFormat(km))}</div>
                        <div>{vin}</div>
                        {list_vehicle_expectation &&
                        <div><Expectation {...suggestion}/></div>}
                    </div>}

                    {provisional_sale && !expected_disclose &&
                    <div className="col-7 g-pl-0">
                        <div className="text-wrap">{list_name} </div>
                        <div className="text-wrap">{main_company_name} </div>
                        <div
                            className="text-wrap">{format(gettext('from {0}'), moment(expected_date).format('MM/YYYY'))}
                            - {format(gettext('{0} to {1} km'), numeral(expected_km).format('0,0'), numeral(expected_km_to).format('0,0'))}</div>
                        <div>{vin}</div>
                        <div><Expectation {...suggestion}/></div>
                    </div>}


                </div>


            </div>


        )
    };

    renderInputComponent = (inputProps) => {
        return (
            <div style={{width: 250}}>
                <span className="g-color-text g-pos-abs g-top-5 g-left-10">
                    <i className="fa fa-search"></i>
                </span>

                <input  {...inputProps}
                        style={{borderRadius: 0}}
                        className="form-control g-color-text g-pl-35 g-py-6"
                />

            </div>


        )
    };


    goToVehicle(suggestion) {
        const {list_id, vehicle_id} = suggestion;

        goToVehicleDetails(`${list_id}_${vehicle_id}`);

    }


    render() {
        const {vehicles, loading} = this.props;
        //const suggestions = vehicles.map((vehicle) => ({name: vehicle.make + ' ' + vehicle.model}));

        const inputProps = {
            placeholder: gettext('Search VIN') + ' ...',
            value: this.state.value,
            onChange: this.onChange,
            autoFocus: this.props.autoFocusInput,
        };


        let suggestions = [];


        if (vehicles.length > 0 && this.state.value.length >= 6 && !loading) {
            suggestions = vehicles;
        }


        return (
            <div>
                {/* <div className="status">
                 <strong>Loading:</strong> {loading ? 'yes' : 'nope'}
                 </div>*/}
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderInputComponent={this.renderInputComponent}
                    highlightFirstSuggestion={true}
                    focusInputOnSuggestionClick={false}
                    inputProps={inputProps}/>
            </div>
        );
    }
}

VehicleSearch.defaultProps = {
    autoFocusInput: false,
};

const mapStateToProps = (state) => {
    return {
        loading: selectors.getLoading(state),
        vehicles: selectors.getVehicles(state),
    }
};

export default connect(mapStateToProps, {vehiclesFetch, vehicleSearchCleanUp})(VehicleSearch);
