import React from 'react';
import {gettext} from '../../../../../translations/index'
import classNames from 'classnames';

const VehicleReserveAlmostMetBadge = ({reserve_price_almost_met, reserve_price_met}) => {

    var spanClass = classNames({
        'u-label': true,
        'g-rounded-3': true,
        'badge': true,
        'g-color-white': true,
        'g-mr-10': true,
        'g-mb-5': true,
    });

    if (!reserve_price_almost_met || reserve_price_met) {
        return null;
    }


    return (
        <span className={spanClass} style={{backgroundColor: 'orange'}}>
            {gettext("Reserve almost price met")}
        </span>

    )
};

export default VehicleReserveAlmostMetBadge;
