import React, {Component} from 'react';
import {connect} from 'react-redux';
import {basketFetchWebTest} from '../../basket/actions';
import HistoryVehicles from './HistoryVehicles';
import HistoryState from './HistoryState';
import * as selectors from '../selectors';
import {Template} from '../../core';
import {historyCleanUp} from '../actions';

class HistoryAndState extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        const {basketFetchWebTest} = this.props;
        basketFetchWebTest('ALL');
    }

    componentWillUpdate() {
        if (!this.props.vehicleStateVisible) {
            this.scrollOffset = window.scrollY;
        }
    }

    componentDidUpdate() {
        if (!this.props.vehicleStateVisible && this.scrollOffset) {
            window.scrollTo(0, this.scrollOffset);
        }
    }

    componentWillUnmount() {
       this.props.historyCleanUp();
    }


    render() {
        const {selectedVehicleId, vehicleStateVisible} = this.props;

        if (!vehicleStateVisible) {
            return (
                <Template allowFluid={true}>
                    <HistoryVehicles selectedVehicleId={selectedVehicleId} />
                </Template>
            );
        }
        else {
            return (
                <Template allowFluid={false}>
                    <HistoryState listVehicleId={selectedVehicleId}/>
                </Template>
            );
        }
    }
}


const mapStateToProps = (state) => {
    return {
        selectedVehicleId: selectors.getSelectedVehicleId(state),
        vehicleStateVisible: selectors.getVehicleStateVisible(state),
    };
};

export default connect(mapStateToProps, {basketFetchWebTest, historyCleanUp})(HistoryAndState);

