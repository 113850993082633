import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as selectors from '../selectors';
import ListCountDown from './ListCountDown'
import {gettext} from '../../../translations';
import moment from 'moment';

class ListDetailInfo extends Component {

    render() {
        const {list} = this.props;

        if(!list) return null;

        return (
            <div>
                <h5 className="text-center text-uppercase color-highlight g-mb-5 g-pb-0">{list.name}</h5>

                {(list.stop && list.state === 'OPEN' && list.type !== 'FIXED' && list.type !== 'AUCTION' && moment(list.stop) > moment()) &&
                <ListCountDown date={list.stop}/>}

                {((list.state === 'CLOSED') || (moment(list.stop) < moment())) &&
                <h5 className="text-center text-uppercase color-highlight g-mb-5 g-pb-0">{gettext('Closed')}</h5>}
            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        list: selectors.listById(state, ownProps.listId),
    }
};

export default connect(mapStateToProps)(ListDetailInfo);
