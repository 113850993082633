export const listsTypes = {
    LISTS_FETCH_REQUEST: 'LISTS_FETCH_REQUEST',
    LISTS_FETCH_SUCCESS: 'LISTS_FETCH_SUCCESS',
    LISTS_FETCH_FAILURE: 'LISTS_FETCH_FAILURE',

    LIST_FETCH_REQUEST: 'LIST_FETCH_REQUEST',
    LIST_FETCH_SUCCESS: 'LIST_FETCH_SUCCESS',
    LIST_FETCH_FAILURE: 'LIST_FETCH_FAILURE',

};


