import React, {Component} from 'react';
import withData from '../withData';
import {formatPrice} from '../../../helpers/functions';
import {gettext} from '../../../translations';
import {BasketStatesTranslate} from '../../../constants';
import BiddingFixedTender from "./BiddingFixedTender";
import BiddingAuction from "./BiddingAuction";

class Bidding extends Component {

    render() {
        const {isAuction, biddingAllowed, price, offered, basket_state, basket_state_visible} = this.props;


        if (!isAuction) {
            // Tender en fixed
            if (biddingAllowed) {
                return <BiddingFixedTender {...this.props} />
            } else if (!basket_state_visible) {
                const text = (offered) ? formatPrice(price) : gettext('Closed');
                return (
                    <div
                        className="bg-highlight color-highlight-invert g-min-width-65 g-font-size-14 g-font-weight-600 d-flex align-items-center justify-content-center ">
                        {text}
                    </div>
                );
            } else {
                const text = (offered) ? formatPrice(price) : gettext('Closed');
                return (
                    <div className="row g-pa-0 g-ma-0">
                        {basket_state && basket_state_visible &&
                        <span
                            className="u-label g-rounded-3 bg-highlight g-mr-10 d-flex align-items-center justify-content-center ">
                               {gettext(BasketStatesTranslate[basket_state])}
                            </span>}
                        <div
                            className="bg-highlight color-highlight-invert g-min-width-65 g-font-size-14 g-font-weight-600 d-flex align-items-center justify-content-center ">
                            {text}
                        </div>
                    </div>

                );
            }
        } else {
            // Auction veiling
            return <BiddingAuction {...this.props} />
        }

    }
}


export default withData(Bidding);

