import React from 'react';
import {gettext} from '../../../../translations';
import {InfoContainer} from '../../../core';

class WizardCompleted extends React.Component {

    render() {

        const {onComplete} = this.props;

        return (
            <div
                className="row g-bg-white d-flex flex-column justify-content-center align-items-center g-pa-0 g-ma-0 g-mb-20">
                <InfoContainer icon="fa fa-check" text={<h3>{gettext('Congratulations!')}</h3>}
                               container={true}/>

                <p>{gettext('Registration completed.')}</p>



                <div className="g-pb-20">
                    <button onClick={onComplete}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-mt-10 g-py-5">
                        {gettext('Start')}
                    </button>

                </div>
            </div>
        )
    }

}

export default WizardCompleted;
