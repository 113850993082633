import React, {Component} from 'react';
import './SearchInput.css';

class Input extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isEmpty: this.props.value !== ''
        };

        this.onClick = this.onClick.bind(this);
        this.clear = this.clear.bind(this);

        const date = Date.now();

        this.timeoutsDates = {
            onChange: date,
            onKeyUp: date,
            onKeyDown: date,
            onKeyPress: date
        };

        this.timeouts = {};
    }

    render() {

        let clearButton = false;

        if (this.props.clearButton && this.state.isEmpty) {
            clearButton = <div className='react-simple-input-clear clickable' onClick={ () => {
                this.clear()
            } }>&times;</div>
        }

        return (
            <div className={ 'react-simple-input-container ' + this.props.classNameContainer }>
                <span className="g-color-text g-pos-abs g-top-5 g-left-10">
                    <i className="fa fa-search"></i>
                </span>

                <input
                    type='text'
                    value={ this.props.value }
                    className={ 'react-simple-input ' + this.props.className }
                    placeholder={ this.props.placeholder }
                    ref='input'

                    onChange={ (e) => {
                        this.setState({isEmpty: e.target.value !== ''});
                        this.props.onChange(e.target.value );
                    } }
                    onKeyDown={ (e) => {
                        this.delayedEvent(e, 'onKeyDown');
                    } }
                    onKeyUp={ (e) => {
                        this.delayedEvent(e, 'onKeyUp');
                    } }
                    onKeyPress={ (e) => {
                        if (e.which === 13 /* Enter */) {
                            e.preventDefault();
                        }else {
                            this.delayedEvent(e, 'onKeyPress');
                        }


                    } }
                    onClick={ this.onClick }
                />
                { clearButton }
            </div>
        );
    }

    onClick(e) {
        e.persist();

        if (this.props.selectOnClick) this.refs.input.select();

        this.props.onClick(e);
    }

    delayedEvent(e, type) {
        e.persist();

        const self = this;
        const now = Date.now();
        const timeout = this.props.eventsTimeouts[type] || 0;

        if (now - this.timeoutsDates[type] < timeout) {
            clearTimeout(this.timeouts[type]);
        }

        this.timeoutsDates[type] = now;

        self.setState({isEmpty: e.currentTarget.value !== ''});

        this.timeouts[type] = setTimeout(function () {
            self.props[type](e);
        }, timeout);
    }

    clear() {
        this.refs.input.value = '';
        const event = new Event('input', {bubbles: true});
        this.refs.input.dispatchEvent(event);
        this.props.onChange('');
    }
}

Input.defaultProps = {
    className: '',
    classNameContainer: '',
    defaultValue: '',
    placeholder: '',
    clearButton: true,
    selectOnClick: true,
    eventsTimeouts: {},
    onChange: () => {
    },
    onKeyDown: () => {
    },
    onKeyUp: () => {
    },
    onKeyPress: () => {
    },
    onClick: () => {
    }
};

export default Input;