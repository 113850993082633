import React from 'react';
import Modal from 'react-modal';
import BidConfirm from './BidConfirm';
import BidProcessing from './BidProcessing';

export default class BidConfirmModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {processingVisible: false}
    }

    componentWillMount() {
        Modal.setAppElement('#app');
    }


    render() {
        return (
            <Modal
                isOpen={this.props.visible}
                onRequestClose={this.props.onCancel}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                onClick={(e) => e.stopPropagation()}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 500,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: (window.innerWidth > 800) ? 600 : 0,
                        maxWidth: window.innerWidth -10,

                    }
                }}
            >

                <div className="d-flex justify-content-end clickable" onClick={(e) => {
                    e.stopPropagation();
                    this.props.onCancel()
                }}>
                    <i className="fa fa-close g-font-size-20"/>
                </div>


                <BidConfirm listVehicleId={this.props.listVehicleId}
                            price={this.props.price}
                            fixedPrice={this.props.fixedPrice}
                            comment={this.props.comment}
                            vehicle={this.props.vehicle}
                            onConfirm={(price, comment, quality) => {
                                this.setState({processingVisible: true}, this.props.onConfirm(price, comment, quality))
                            }}
                            onCancel={this.props.onCancel}
                />

                {this.state.processingVisible &&
                <BidProcessing onClose={() => {
                    this.props.onCancel()
                }}
                               onReview={(e) => {
                                   e.stopPropagation();
                                   this.setState({processingVisible: false})
                               }}
                />}


            </Modal>

        );
    }
}



