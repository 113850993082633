import {statisticTypes} from './types';
import {combineReducers} from 'redux';
import {baseReducer} from './base'

const coreReducer = (state = {loading: false, error: '', data: null}, action) => {
    switch (action.type) {
        case statisticTypes.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case statisticTypes.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                data: action.payload,
            };
        case statisticTypes.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
            };
        default:
            return state;
    }
};



export const statistics = combineReducers({
    core: coreReducer,
    base: baseReducer
});
