import React, {Component} from 'react';
import WizardHeader from './WizardHeader';
import {LoadingIndicator} from '../../../core';
import {filter} from 'lodash';
class Wizard extends Component {


    render() {
        const {children: pages, step, loading, error} = this.props; //all wizard pages

        let selected;

        React.Children.forEach(pages, (page, index) => {
            if (index + 1 === step) {
                selected = page;
            }
        });

        return (
            <div>

                <div className="row g-pa-0 g-ma-0 g-bg-white g-mb-10 g-pt-10 g-pb-10 g-hidden-md-down">
                    <div className="col-12">
                        <ul className="row list-inline u-info-v9-1 g-ma-0 g-pa-0">
                            {React.Children.map(filter(pages, (p) => p !== false), (page, index) =>
                                <WizardHeader key={index + 1}
                                              number={index + 1}
                                              title={page.props.title}
                                              currentPage={step}
                                />
                            )}
                        </ul>
                    </div>
                </div>

                <LoadingIndicator loading={loading}/>

                {error !== '' && <div> {error} </div>}

                    {
                        React.cloneElement(
                            selected,
                            {
                                step: step,
                                totalSteps: React.Children.count(this.props.children),
                                required: true,
                            }
                        )
                    }



            </div>)
    }


}

export default Wizard;