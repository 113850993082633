import React from 'react';
import {connect} from 'react-redux';
import ResetPasswordForm from './ResetPasswordForm';
import {resetPassword} from '../actions';
import {getLoading, getError} from '../selectors';

class ResetPassword extends React.Component {

    onHandleSubmit(data) {
        const {resetPassword, uniqueId} = this.props;
        resetPassword(uniqueId, data.password)
    }

    render() {
        const {error, loading} = this.props;
        return (
            <div>
                <ResetPasswordForm loading={loading}
                                 onSubmit={(data) => this.onHandleSubmit(data)}
                />

                {error !== null &&
                <div className="row g-pa-0 g-ma-0 container-important g-pa-5 g-mb-10 text-center">
                    {error.message}
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: getLoading(state),
        error: getError(state),
    };
};

export default connect(mapStateToProps, {resetPassword})(ResetPassword);
