import React from 'react';
import {gettext} from '../../../translations';

const FilterDetailsHeader = ({onSave, onDelete, onClose, saveEnabled, deleteEnabled}) =>
    <div className="row g-pa-0 g-ma-0 justify-content-between g-bg-white g-pa-5 g-mb-10  bottom-shadow">

        <h5 className="mb-2 color-highlight g-font-weight-800 g-pt-5">{gettext('Filter details')}</h5>

        <div className="row g-pa-0 g-ma-0 g-mr-5">
            {deleteEnabled &&
            <div onClick={onDelete}
                 disabled={!deleteEnabled}
                 className="g-color-white g-bg-red g-bg-red--hover clickable g-font-weight-500 g-px-15 g-py-6 g-mr-10">
                <i className=" fa fa-remove g-mr-10"></i>{gettext('Delete')}
            </div>}

            {saveEnabled &&
            <div onClick={onSave}
                 disabled={!saveEnabled}
                 className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6">
                <i className=" fa fa-save g-mr-10"></i>{gettext('Save')}
            </div>}
            <div className="clickable g-mr-10 g-hidden-md-up g-ml-10"  onClick={onClose}>
                <i className="fa fa-close g-font-size-30 g-color-primary"                           />
            </div>
        </div>
    </div>

export default FilterDetailsHeader;


