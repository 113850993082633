import React from 'react';
import Modal from 'react-modal';
import {LoadingIndicator} from "./LoadingIndicator";
import Iframe from "react-iframe";
import moment from "moment-timezone";
import {withConfig} from "../../../../config/index.native";

class TeaserBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading: true, modalVisible: false};
    }


    componentWillMount() {
        Modal.setAppElement('#app');
    }

    render() {
        return null;
    }


    render() {
        const {containerClass} = this.props;
        const {teaser_banner} = this.props.config;

        const now = moment();
        const expires = moment("2020-05-05T00:00:00");
        const bannerExpired = now > expires;

        if (!teaser_banner || bannerExpired) {
            return null;
        }


        const {loading, modalVisible} = this.state;


        return (
            <div className={containerClass}>
                <img className="img-fluid" src={require("../../../../assets/temp/auction.png")} alt='Live auction banner' style={{marginBottom: 5}}
                     onClick={() => this.setState({modalVisible: true})}/>

                {/*<Modal*/}
                {/*    isOpen={modalVisible}*/}
                {/*    onRequestClose={() => this.setState({modalVisible: false})}*/}
                {/*    shouldCloseOnEsc={true}*/}
                {/*    shouldCloseOnOverlayClick={true}*/}
                {/*    onClick={(e) => e.stopPropagation()}*/}
                {/*    style={{*/}
                {/*        overlay: {*/}
                {/*            position: 'fixed',*/}
                {/*            top: 0,*/}
                {/*            left: 0,*/}
                {/*            right: 0,*/}
                {/*            bottom: 0,*/}
                {/*            backgroundColor: 'rgba(0, 0, 0, 0.75)',*/}
                {/*            zIndex: 500,*/}
                {/*        },*/}
                {/*        content: {*/}
                {/*            top: '50%',*/}
                {/*            left: '50%',*/}
                {/*            right: 'auto',*/}
                {/*            bottom: 'auto',*/}
                {/*            marginRight: '-50%',*/}
                {/*            transform: 'translate(-50%, -50%)',*/}
                {/*            minWidth: (window.innerWidth > 800) ? 600 : 0,*/}
                {/*            maxWidth: window.innerWidth - 10,*/}

                {/*        }*/}
                {/*    }}*/}
                {/*>*/}

                {/*    <React.Fragment>*/}

                {/*        <div className="d-flex justify-content-end clickable" onClick={(e) => {*/}
                {/*            e.stopPropagation();*/}
                {/*            this.setState({modalVisible: false})*/}
                {/*        }}>*/}
                {/*            <i className="fa fa-close g-font-size-22 g-mb-2"/>*/}
                {/*        </div>*/}


                {/*        <div className='w-100 justify-content-center' style={{position: 'relative', display:'flex', alignItems:'center'}}>*/}

                {/*            <div className='row g-pa-0 g-ma-0 w-100 justify-content-center g-pt-10' style={{position: 'absolute'}}>*/}
                {/*                <LoadingIndicator loading={loading}/>*/}
                {/*            </div>*/}

                {/*            <Iframe url="https://player.vimeo.com/video/394248807"*/}
                {/*                    width={(window.innerWidth > 800) ? "700px" : "440px"}*/}
                {/*                    height={(window.innerWidth > 800) ? "400px" : "260px"}*/}
                {/*                    id="Action video"*/}
                {/*                    display="initial"*/}
                {/*                    position="relative"*/}
                {/*                    allowFullScreen*/}
                {/*                    onLoad={() => {*/}
                {/*                        this.setState({loading: false})*/}
                {/*                    }}/>*/}
                {/*        </div>*/}
                {/*    </React.Fragment>*/}


                {/*</Modal>*/}
            </div>

        );
    }
}

export default withConfig(TeaserBanner);



