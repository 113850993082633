import React, {Component} from "react";
import {HistoryStatesHelper, DealerStateTranslate} from '../../../helpers/utils'
import {formatPrice} from "../../../helpers/functions";
import {gettext} from "../../../translations";


class DealerState extends Component {
    render() {
        const {basket_price, dealer_state} = this.props;

        if (HistoryStatesHelper.isSold(dealer_state)) {
            return (
                <div className="g-min-width-65 g-font-size-14 g-font-weight-600 align-items-center justify-content-center g-pt-2 g-pb-2"
                     style={{backgroundColor: '#48bb78', color: 'white', minHeight: 30}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', fontSize: 11}}>{gettext(DealerStateTranslate[dealer_state])}</div>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}> {formatPrice(basket_price)}</div>
                </div>
            )
        } else if (HistoryStatesHelper.isPending(dealer_state)) {
            return (
                <div className="g-min-width-65 g-font-size-14 g-font-weight-600 align-items-center justify-content-center g-pt-2 g-pb-2"
                     style={{backgroundColor: 'lightgrey', color: 'darkgrey', minHeight: 30}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', fontSize: 11}}>{gettext(DealerStateTranslate[dealer_state])}</div>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}> {formatPrice(basket_price)}</div>
                </div>
            )
        } else if (HistoryStatesHelper.isHistory(dealer_state)) {
            return (
                <div className="g-min-width-65 g-font-size-14 g-font-weight-600 align-items-center justify-content-center g-pt-2 g-pb-2"
                     style={{backgroundColor: 'lightgrey', color: 'black', minHeight: 30}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', fontSize: 11}}>{gettext(DealerStateTranslate[dealer_state])}</div>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}> {formatPrice(basket_price)}</div>
                </div>
            )

        } else if (HistoryStatesHelper.isLost(dealer_state)) {
            return (
                <div className="g-min-width-65 g-font-size-14 g-font-weight-600 align-items-center justify-content-center g-pt-2 g-pb-2"
                     style={{backgroundColor: '#c53030', color: 'white', minHeight: 30}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', fontSize: 11}}>{gettext(DealerStateTranslate[dealer_state])}</div>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}> {formatPrice(basket_price)}</div>
                </div>
            )
        } else {
            return (
                <div className="g-min-width-65 g-font-size-14 g-font-weight-600 align-items-center justify-content-center g-pt-2 g-pb-2"
                     style={{backgroundColor: 'lightgrey', color: 'white', minHeight: 30}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', fontSize: 11}}>{gettext(DealerStateTranslate[dealer_state])}</div>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}> {formatPrice(basket_price)}</div>
                </div>
            )
        }


    }
}

export default DealerState;
