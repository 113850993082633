import React from 'react';
import _ from 'lodash';
import {SearchInput} from '../../index';
import {gettext} from '../../../../translations';

class SearchableList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            selected: []
        };

        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleRemoveAll = this.handleRemoveAll.bind(this);
    }


    handleSelectAll() {
        const {data} = this.props;
        const {searchTerm} = this.state;

        this.props.onSelectAll(this.filter(data, searchTerm).map((item) => item.id));
        this.setState({searchTerm: ''})
    }

    handleRemoveAll() {
        const {data} = this.props;
        const {searchTerm} = this.state;

        this.props.onDeSelectAll(this.filter(data, searchTerm).map((item) => item.id));
        this.setState({searchTerm: ''})
    }

    filter(data, searchTerm) {
        if (searchTerm === "") return data;

        let result = [];
        const term = searchTerm.toLowerCase();

        _.each(data, (item) => {
            _.each(item.searchTerms, (searchField) => {
                if (searchField.toLowerCase().startsWith(term)) {
                    result.push(item);
                    return false;
                }
            })

        });
        return result;
    }

    render() {
        const {data} = this.props;


        const filteredData = this.filter(data, this.state.searchTerm);


        return (
            <div>

                <div className="row g-pa-0 g-ma-0 g-pt-8 g-pb-8">

                    <form className="g-pos-rel g-min-width-180--sm g-min-width-315--xl  g-mr-5">
                        <SearchInput {...this.props}
                                     className="form-control w-100 g-color-text g-font-size-13 g-pl-35 g-py-6"
                                     value={this.state.searchTerm}
                                     onChange={(value) => this.setState({searchTerm: value})}
                        />
                    </form>


                    <div onClick={() => this.handleSelectAll()}
                         className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6 g-mr-5">
                        <i className=" fa fa-check g-mr-10 "></i>{(window.innerWidth <= 576) ? gettext('All') : gettext('Select all')}
                    </div>

                    <div onClick={() => this.handleRemoveAll()}
                         className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6">
                        <i className=" fa fa-remove g-mr-10"></i>{(window.innerWidth <= 576) ? gettext('All') : gettext('Select none')}
                    </div>

                </div>

                {filteredData && filteredData.length === 0 &&
                    <div className="row g-pa-0 g-ma-0">
                <span>{gettext('No results found.')} ... </span>
                    </div>
                }


                <div className="row">
                    {filteredData.map((item) =>
                        this.props.renderItem(item)
                    )}
                </div>
            </div>
        )
    }
}


export default SearchableList;
