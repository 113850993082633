import React from 'react';
import {connect} from 'react-redux';
import CookieConsent, {Cookies} from "react-cookie-consent";
import {compose} from "redux";
import {withConfig} from "../../../../config";
import {gettext} from "../../../../translations";
import {getLocale} from "../../../localization";
import {goToPrivacy} from "../../../../_web/navigation/routes";

class GDPR extends React.Component {
    render() {
        const {config} = this.props;
        const gdpr_cookie = `${config.name}-gdpr-cookie`;

        if (Cookies.get(gdpr_cookie) !== undefined) {
            return null;
        }

        return (
            <CookieConsent
                debug={false}
                location="bottom"
                buttonText={gettext('Accept cookies')}
                cookieName={gdpr_cookie}
                style={{background: "#2B373B"}}
                buttonStyle={{backgroundColor: "white", color: 'black', fontSize: "13px", margin: 30}}
                contentStyle={{margin: 30}}
                expires={150}
            >
                {gettext("This website uses cookies. We use cookies to make our website work at the best, improving your online experience.")}<br/>
                {gettext(" By continuing to navigate on this site, you agree to the use of cookies.")} <span> </span>
                <span className='g-text-underline clickable' onClick={goToPrivacy}>{gettext("Learn more")}</span>

            </CookieConsent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: getLocale(state),
    };
};

export default compose(
    withConfig,
    connect(mapStateToProps),
)(GDPR)

