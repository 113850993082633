import {RSAA} from 'redux-api-middleware';
import {types} from './types';
import _ from 'lodash';

export const vehiclesFetch = (searchTerm) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/vehicles/?term=${searchTerm}`,
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: types.FETCH_REQUEST,
                    },
                    {
                        type: types.FETCH_SUCCESS,
                        payload: (action, state, res) => onSuccess(dispatch, res),
                    },
                    {
                        type: types.FETCH_FAILURE,
                        payload: (action, state, res) => {
                            return {
                                message: `${res.status} - ${res.statusText}`,
                            }
                        }
                    }
                ]
            }
        });
    };
};


const onSuccess = (dispatch, res,) => {
    return res.json().then(data => {
            if (data.result_code && data.result_code === 'NOk') {
                return {
                    vehicles: []
                }
            }

            return {
                vehicles: data.map((data) => ({
                    list_id: _.get(data, 'list_id'),
                    vehicle_id: _.get(data, 'vehicle_id'),
                    make: _.get(data, 'model'),
                    model: _.get(data, 'make'),
                    vin: _.get(data, 'vin'),
                    version: _.get(data, 'version'),
                    basket_state: _.get(data, 'state'),
                    dealer_state: _.get(data, 'dealer_state'),
                    thumbnail_url: _.get(data, 'thumbnail_url'),
                    picture_url: _.get(data, 'picture_url'),
                    main_company_name: _.get(data, 'main_company_name'),
                    km: _.get(data, 'kilometers'),
                    first_registration: _.get(data, 'first_registration'),
                    list_name: _.get(data, 'list_name'),
                    expected_date: _.get(data, 'expected_date'),
                    expected_km: _.get(data, 'expected_km'),
                    expected_km_to: _.get(data, 'expected_km_to'),
                    provisional_sale: _.get(data, 'provisional_sale') || false,
                    expected_disclose: _.get(data, 'expected_disclose') || false,
                })),
            };
        }
    );
};

export const vehicleSearchCleanUp = (invalidListVehicleIds) => {
    return (dispatch) => {
        return dispatch({
            type: types.CLEANUP,
        });
    }
};
