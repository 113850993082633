import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {openAdminUrl, getVehicleIdFromListVehicleId} from '../../../helpers/functions';
import {getLocale} from '../../localization';
import {withConfig} from '../../../config';

class PrintButtons extends Component {
    constructor(props) {
        super(props);

        this.onPrint = this.onPrint.bind(this);
        this.onPrint2 = this.onPrint2.bind(this);
    }


    onPrint2(e) {
        e.stopPropagation();
        const {listVehicleId, locale} = this.props;
        const vehicle_id = getVehicleIdFromListVehicleId(listVehicleId);
        openAdminUrl(`sales/offer/assessment2/${vehicle_id}/0/${locale}`);
    }

    onPrint(e) {
        e.stopPropagation();
        const {listVehicleId, locale} = this.props;
        const vehicle_id = getVehicleIdFromListVehicleId(listVehicleId);
        openAdminUrl(`sales/offer/assessment/${vehicle_id}/0/${locale}`);
    }

    render() {
        const {config} = this.props;
        const {vehicle_print} = config;

        if(!vehicle_print) return null;

        return (
            <React.Fragment>
                <i className="fa fa fa-globe g-ml-10 clickable" style={{fontSize: 16, color: '#016f92'}}
                   onClick={this.onPrint2}/>

                <i className="fa fa fa-print g-ml-10 clickable g-mr-5" style={{fontSize: 16, color: '#016f92'}}
                   onClick={this.onPrint}/>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        locale: getLocale(state)
    };
};

export default compose(
    withConfig,
    connect(mapStateToProps),
)(PrintButtons)








