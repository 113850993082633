import {connect} from 'react-redux';
import React, {Component} from 'react';
import * as selectors from '../selectors';
import {PropagateLoader} from 'react-spinners';
import style from '../../../styles';
import {gettext} from '../../../translations';
import {CircleIcon} from '../../core';
import {toggleConfirm} from '../actions'

class Result extends Component {

    componentWillReceiveProps(nextProps) {
        if (!this.props.accepted && nextProps.accepted) {
         this.props.toggleConfirm(false);
        }
    }

    renderProcessing() {
        return (
            <div className="col justify-content-center">
                <div className="row justify-content-center text-center g-mt-40">
                    <CircleIcon icon="fa fa-gavel" size={200}/>
                </div>

                <div className="row justify-content-center text-center g-mt-40">
                    <PropagateLoader loading={this.props.loading}
                                     color={style.highlightColor}
                    />
                </div>

                <div className="g-mt-40 text-center">{gettext('Processing biddings')}</div>
                <div className="text-center">{gettext('Please wait') + '...'}</div>
            </div>
        );
    }

    renderError() {
        const {message} = this.props;
        return (
            <div className="col justify-content-center">
                <div className="row justify-content-center text-center g-mt-40">
                    <CircleIcon icon="fa fa-bolt" size={200}/>
                    <div className="g-width-100vw g-mt-40 text-center">
                        {(message !== "") ? message : gettext('Something went wrong. Please try again')}
                    </div>
                </div>

                <div className="row justify-content-center g-pa-0 g-ma-0 g-mt-20 g-mr-10">
                    <button onClick={() => this.props.toggleConfirm(false)}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-500 g-font-size-14 rounded-0 g-pa-8">
                        {gettext('Try again')}
                    </button>
                </div>
            </div>

        )
    }

    render() {
        const {loading, accepted} = this.props;

        return (
            <div className="row bg-normal g-ma-0 g-mt-70 g-mb-200">
                {loading && this.renderProcessing() }
                {!loading && !accepted && this.renderError()}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        loading: selectors.getLoading(state),
        accepted: selectors.getAccepted(state),
        message: selectors.getMessage(state),
    };
};
export default connect(mapStateToProps, {toggleConfirm})(Result);

