import React from 'react';
import _ from 'lodash';
import {withConfig} from '../../../../../../config/index';

const PicturesGrid = ({vehicle, config, onPictureClick}) => {

    const {vehicle_state_picture_type_order} = config;

    const sortedPictures = _.flatten(_.values(_.map(vehicle_state_picture_type_order, function (id) {
        return _.filter(vehicle.pictures, {'typeId': id});
    })));

    const vehiclePictureCount = (sortedPictures.length > 0 && (sortedPictures.length - 5 > 0)) ? sortedPictures.length - 5 : 0;
    const pictures = _.concat(sortedPictures, _.flatten(_.map(vehicle.damages, 'pictures')));

    if (pictures.length < 5) {
        return null;
    }

    return (
        <div>
            <div id="test">
                <div className="upper-image-container g-mr-3 g-mb-3 g-pos-rel"
                     onClick={() => onPictureClick(0, pictures)}>
                    <img src={pictures[0].src}
                         alt={pictures[0].description}
                    />

                    {vehicle.list_number !== undefined && vehicle.list_number >= 0 &&
                    <div className="g-pos-abs" style={{
                        display: 'flex',
                        top: 10,
                        left: 10,
                        height: 20,
                        backgroundColor: 'rgba(0,0,0,0.3)',
                        borderRadius: 5,
                        width: 35,
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 15,
                        color: 'white',
                        fontWeight: 600
                    }}>
                        {String('000' + vehicle.list_number).slice(-3)}
                    </div>}
                </div>
                <div className="upper-image-container g-ml-3 g-mb-3" onClick={() => onPictureClick(1, pictures)}>
                    <img src={pictures[1].src}
                         alt={pictures[1].description}
                    />
                </div>

                <div className="lower-image-container g-mr-3 g-mt-3" onClick={() => onPictureClick(2, pictures)}>
                    <img src={pictures[2].src}
                         alt={pictures[2].description}
                    />
                </div>
                <div className="lower-image-container g-ml-3 g-mr-3 g-mt-3" onClick={() => onPictureClick(3, pictures)}>
                    <img src={pictures[3].src}
                         alt={pictures[3].description}
                    />
                </div>

                <div className="lower-image-container image-container-overlay g-ml-3 g-mt-3"
                     onClick={() => onPictureClick(4, pictures)}>
                    {vehiclePictureCount > 0 &&
                    <div className="div-overlay">
                        <div>
                            + {vehiclePictureCount}
                        </div>
                    </div>}
                    <img src={pictures[4].src}
                         alt={pictures[4].description}
                    />
                </div>
            </div>
        </div>
    )
};


export default withConfig(PicturesGrid);




