import React from 'react';
import {connect} from 'react-redux';
import {listVehicleInterest, listVehicleInterestDelete} from '../actions';
import * as selectors from '../selectors';
import {getListIdFromListVehicleId, getVehicleIdFromListVehicleId} from '../../../helpers/functions';

const InterestThumb = ({listId, vehicleId, interest, listVehicleInterest, listVehicleInterestDelete, visible, onClick}) => {
    // const icon = (interest) ? 'fa fa-thumbs-down' : 'fa fa-thumbs-up';
    const icon = 'fa fa-thumbs-down';

    return (
        <div className="text-center g-valign-middle " onClick={(e) => {
            (interest) ? listVehicleInterest(listId, vehicleId) : listVehicleInterestDelete(listId, vehicleId);
            onClick(e);
        }}>
            <i className={`${icon} g-font-size-20 ${(interest) ? 'color-btn-icon' : 'g-color-primary'} g-color-primary--hover`}/>
        </div>
    )
};

const makeMapStateToProps = () => {
    const getListVehicleInterest = selectors.makeGetListVehicleInterest();
    const getListVehicleInterestVisible = selectors.makeGetListVehicleInterestVisible();


    const mapStateToProps = (state, ownProps) => {
        let {listVehicleId} = ownProps;
        return {
            listId: getListIdFromListVehicleId(listVehicleId),
            vehicleId: getVehicleIdFromListVehicleId(listVehicleId),
            listVehicleId: listVehicleId,
            interest: getListVehicleInterest(state, listVehicleId),
            visible: getListVehicleInterestVisible(state, listVehicleId),
        }
    };
    return mapStateToProps
};

export default connect(makeMapStateToProps, {listVehicleInterest, listVehicleInterestDelete})(InterestThumb);




