import React from 'react';
import {connect} from 'react-redux';
import AuthenticationForm from './AuthenticationForm';
import {withRouter} from 'react-router-dom'
import {history} from '../../../_web/App';
import {gettext} from "../../../translations";
import {login, requestActivationMail} from '../actions';
import {getLoading, getError} from '../selectors';
import {isAuthenticated} from '../../../components/session/index';
import format from "string-format";
import cogoToast from "cogo-toast";


class Authentication extends React.Component {
    constructor(props) {
        super(props);
        if (props.authenticated && props.location.state !== undefined && props.location.state.routed && props.history.action === "POP") {
            history.goBack();
        } else if ((props.authenticated && props.history.action === "PUSH") || (props.authenticated && props.location.state === undefined)) {
            this.redirect();
        }

        this.resendActivationMail = this.resendActivationMail.bind(this);
    }

    onHandleSubmit(data) {
        const {login} = this.props;
        login(data.username, data.password).then((result) => {
            if (result && result.type === "AUTH_FAILURE" && result.payload.code === 412) {
                this.setState({email: data.username});
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authenticated) {
            this.redirect();
        }
    }

    redirect() {
        history.push({
            pathname: '/dashboard',
            state: (this.props.location.state) ? this.props.location.state : {routed: true}
        });
    }

    resendActivationMail() {
        const {requestActivationMail} = this.props;
        const {email} = this.state;
        requestActivationMail(email).then((result) => {
            if (result.success) {
                const message = format(gettext("A new confirmation e-mail has been sent to {0}."), email);
                cogoToast.success(message, {hideAfter: 5});
            }
            else {
                cogoToast.success(result.message, {hideAfter: 5});
            }
        });
    }

    render() {
        const {authenticating, error, loading} = this.props;

        return (
            <div>
                <AuthenticationForm submitting={authenticating}
                                    loading={loading}
                                    onSubmit={(data) => this.onHandleSubmit(data)}
                />

                {error !== null &&
                <div className="row g-pa-0 g-ma-0 container-important g-pa-5 g-mb-10 text-center">
                    <div className="row g-pa-0 g-ma-0 w-100">{error.message}</div>

                    {error.code === 412 &&
                    <div className='row g-pa-0 g-ma-0'>
                        <div>
                            {gettext('Didn’t receive a confirmation mail? ')} <span
                            className="g-text-underline clickable"
                            onClick={this.resendActivationMail}>{gettext('Resend')}.</span>
                        </div>

                    </div>}
                </div>}
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        authenticated: isAuthenticated(state),
        error: getError(state),
        loading: getLoading(state),
    };
};


export default withRouter(connect(mapStateToProps, {login, requestActivationMail})(Authentication));
