import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Template} from '../../../core/index';
import {fetchOfferVehicles, vehiclesCleanup} from '../actions';
import Vehicles from './Vehicles'
import VehicleState from './VehicleState';
import {listCleanupByListId} from '../../../lists/actions';


//import Listvehicles from './Listvehicles';
//import ListvehicleState from './ListvehicleState';
import * as selectors from '../selectors';


class OfferVehiclesAndState extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        const {offerId, fetchOfferVehicles} = this.props;
        fetchOfferVehicles(offerId);
    }

    componentWillUpdate() {
        if (!this.props.vehicleStateVisible) {
            this.scrollOffset = window.scrollY;
        }
    }

    componentDidUpdate() {
        if (!this.props.vehicleStateVisible && this.scrollOffset) {
            window.scrollTo(0, this.scrollOffset);
        }
    }

    componentWillUnmount() {
        const {vehiclesCleanup} = this.props;
        vehiclesCleanup();
    }

    render() {
        const {offerId, selectedId, vehicleStateVisible} = this.props;

        if (vehicleStateVisible) {
            return (
                <Template allowFluid={false}>
                    <VehicleState id={selectedId}/>
                </Template>
            );
        }
        else {
            return (
                <Template allowFluid={true}>
                    <Vehicles offerId={offerId}/>
                </Template>
            );
        }
    }
}


const mapStateToProps = (state) => {
    return {
        selectedId: selectors.getSelectedId(state),
        vehicleStateVisible: selectors.getVehicleStateVisible(state),
    };
};

export default connect(mapStateToProps, {
    fetchOfferVehicles,
    listCleanupByListId,
    vehiclesCleanup
})(OfferVehiclesAndState);

