import React from 'react';
import {gettext} from '../../../../translations';
import {withConfig} from '../../../../config';

const ContactInfo = ({config}) => {
    const {registration_contact_info, registration_contact_info_mail, registration_contact_info_tel} = config;

    if (registration_contact_info) {
        return (
            <React.Fragment>
                <h6 className="g-color-primary g-mb-0 g-pb-0">{gettext('Need help?')}</h6>
                <p>
                    <div>{gettext("Contact us")}</div>
                    <div>{registration_contact_info_tel}</div>
                    <div>{registration_contact_info_mail}</div>
                </p>
            </React.Fragment>);
    }
    else {
        return null;
    }
};

export default withConfig(ContactInfo);
