import React from 'react';
import {connect} from 'react-redux';
import {compose} from "redux";
import {getListById} from '../lists';
import {getVehicleById} from '../listvehicles';
import {listVehicleBid, listVehicleBidDelete} from './actions';
import {withConfig} from '../../config';
import {get} from 'lodash';
import {ListTypes, BasketStates, ListVehicleStates} from '../../constants';
import {getListIdFromListVehicleId, isExpired} from '../../helpers/functions';

const defaultValue = 0;

function withData(WrappedComponent) {
    return class extends React.Component {

        render() {
            const {list, vehicle} = this.props;

            if (!list || !vehicle) return null;

            const vehicle_price = Math.round(get(vehicle, 'price')) || defaultValue;
            const basket_price = Math.round(get(vehicle, 'basket_price')) || defaultValue;


            const vehicle_state = get(vehicle, 'vehicle_state');
            const basket_state = get(vehicle, 'basket_state');
            const list_type = get(list, 'type');
            const list_stop = get(list, 'stop');
            const vehicle_stop = get(vehicle, 'vehicle_stop');

            const price = list_type === ListTypes.FIXED ? vehicle_price : basket_price;
            const offered = list_type === ListTypes.FIXED ? (price === basket_price && (basket_state === BasketStates.TRANSMITTED || basket_state === BasketStates.BOUGHT)) : (basket_price > 0 && basket_state !== BasketStates.INITIAL);
            const biddingExpired = isExpired(list_stop, vehicle_stop);
            const biddingAllowed = ((vehicle_state === ListVehicleStates.INITIAL) && (basket_state !== BasketStates.BOUGHT) && (!biddingExpired));
            const fixedPrice = (list_type === ListTypes.FIXED);
            const comment = vehicle && vehicle.comment !== undefined ? vehicle.comment : "";
            const inBasket = price === basket_price && basket_state === BasketStates.INITIAL;

            // specific data for auction
            const isAuction = (list_type === ListTypes.LIVE_AUCTION);
            const highest = get(vehicle, 'highest');
            const hasBasketPrice = get(vehicle, 'basket_price') !== undefined;
            const highestBidder = ListTypes.LIVE_AUCTION && hasBasketPrice && (vehicle_price === basket_price) && highest;
            const maximumPrice = Math.round(get(vehicle, 'basket_maximum_price')) || defaultValue;
            const reserveMet = get(vehicle, 'reserve_price_met') || false;
            const reserveAlmostMet = get(vehicle, 'reserve_price_almost_met') || false;

            return <WrappedComponent listId={list.id}
                                     vehicleId={vehicle.vehicle_id}
                                     price={price}
                                     fixedPrice={fixedPrice}
                                     offered={offered}
                                     comment={comment}
                                     biddingAllowed={biddingAllowed && list.id > 0}
                                     basket_state={vehicle.basket_state}
                                     inBasket={inBasket}
                                     isAuction={isAuction}
                                     highestBidder={highestBidder}
                                     maximumPrice={maximumPrice}
                                     vehiclePrice={vehicle_price}
                                     vehicleStop={vehicle_stop}
                                     basketPrice={basket_price}
                                     reserveMet={reserveMet}
                                     reserveAlmostMet={reserveAlmostMet}
                                     {...this.props}
            />;
        }
    };
}


const mapStateToProps = (state, ownProps) => {
    const {listVehicleId} = ownProps;

    return {
        list: getListById(state, getListIdFromListVehicleId(listVehicleId)),
        vehicle: getVehicleById(state, listVehicleId),
    }
};

const composedWithData = compose(
    withConfig,
    connect(mapStateToProps, {listVehicleBid, listVehicleBidDelete}),
    withData,
);

export default composedWithData;


