import React from 'react';
import {Template} from '../components/core/index';
import {Content, LegalTypes} from '../components/legal/index';

const Privacy = ({match}) =>
    <Template>
        <div className="g-bg-white g-pt-80">
          <Content id={LegalTypes.PRIVACY}/>
        </div>
    </Template>;

export default Privacy;



