import {history} from '../App';


const pushToHistory = (path) => {
    history.push({
        pathname: path,
        state: {routed: true}
    });
};

const replaceHistory = (path) => {
    history.push({
        pathname: path,
        state: {routed: false}
    });
};

export const login = () => {
    pushToHistory(`/login`);
};

export const register = () => {
    pushToHistory(`/register`);
};

export const forgot_password = () => {
    pushToHistory(`/forgot-password`);
};


export const registration_wizard = () => {
    pushToHistory(`/registration-wizard`);
};

export const dashboard = () => {
    pushToHistory(`/dashboard`);
};

export const profile = () => {
    pushToHistory(`/profile`);
};
export const filters = () => {
    pushToHistory(`/filters`);
};


export const goToList = (listId) => {
    pushToHistory(`/list/${listId}`);
};

export const backToList = (listId) => {
    replaceHistory(`/list/${listId}`);
};

export const goToVehicleDetails = (listVehicleId) => {
    pushToHistory(`/list-vehicle/${listVehicleId}`);
};

export const goToOffer = (offerId) => {
    pushToHistory(`/offer/${offerId}`);
};


export const goToRegistration = () => {
    pushToHistory(`/registration`);
};

export const goToForgotPassword = () => {
    pushToHistory(`/forgot-password`);
};

export const goToHistory = () => {
    pushToHistory(`/history`);
};

export const goToTerms = () => {
    pushToHistory(`/terms-and-conditions`);
};

export const goToPrivacy = () => {
    pushToHistory(`/privacy`);
};

export const goBack = () => {
    history.goBack();
};

export const goToLanding = () => {
    pushToHistory(`/`);
};

export const faq = () => {
    pushToHistory(`/faq`);
};

export const reloadCurrentPage = () => {
    history.replace({path: window.location.origin});
    /* added for bugfix culture change on login - when ?culture=xx in url .. */
    history.go(0);
};

