import {createSelector} from 'reselect';
import _ from 'lodash';
import moment from 'moment';

import {getCriteria} from '../basket-filter/selectors';


export const getLoading = (state, status) => (state.basket[status]) ? state.basket[status].loading : false;
export const getError = (state, status) => (state.basket[status]) ? state.basket[status].error : '';
export const getIds = (state, status) => (state.basket[status]) ? state.basket[status].items : [];
export const getOffset = (state, status) => (state.basket[status]) ? state.basket[status].offset : 0;
export const getLimit = (state, status) => (state.basket[status]) ? state.basket[status].limit : 10;
export const getMore = (state, status) => (state.basket[status]) ? state.basket[status].more : false;
export const getTotalCount = (state, status) => (state.basket[status]) ? state.basket[status].totalCount : null;
export const getSearchTerm = (state, status) => (state.basket[status]) ? state.basket[status].searchTerm : '';
export const getVehicles = (state, status) => getVehiclesByIds(allVehicles(state), getIds(state, status));


export const getVehiclesCount = (state, status) => getIds(state, status).length;
export const getInitialLoading = (state, status) => (state.basket[status]) ? getLoading(state, state) && getOffset(state, state) === 0 : false;

export const allVehicles = (state) => state.entities.vehicle;
export const getVehiclesByIds = (vehicles, ids) => {
    return _.map(_.intersection(ids, _.keys(vehicles)), (id) => {
        return vehicles[id];
    });
};



export const makeMapStateToProps = () => createSelector(
    getIds, getLoading, getError, getOffset, getLimit, getMore, getSearchTerm,
    (ids, loading, error, offset, limit, more, searchTerm) => {
        return {
            vehicleIds: ids,
            loading: loading,
            error: error,
            offset: offset,
            limit: limit,
            more: more,
            searchTerm: searchTerm
        }
    }
);

export const getVehicle = (state, listVehicleId) => allVehicles(state)[listVehicleId];


export const makeTimeLineMapStateToProps = () => createSelector(
    getVehicles, getLoading, getError, getOffset, getLimit, getMore, getCriteria,
    (vehicles, loading, error, offset, limit, more, criteria) => {

        const sortedVehicles = _.sortBy(vehicles, function (o) {
            return new moment(o.offer_date).format('YYYYMMDDHHMMSS');
        }).reverse();

        const vehiclesByDate = _.groupBy(sortedVehicles, function (vehicle) {
            return moment(vehicle.offer_date).format('DD/MM/YYYY-HH:mm')
        });

        return {
            vehiclesByDate,
            counter: vehicles.length,
            loading: loading,
            error: error,
            offset: offset,
            limit: limit,
            more: more,
            searchTerm: criteria.searchTerm,
            state: criteria.state,
        }
    }
);