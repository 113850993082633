import React from 'react';
import {connect} from 'react-redux';
import States from './States'
import {HistoryTable} from './history/';
import {OffersTable} from './offers';
import {updateCriteriaStates, updateCriteriaList} from '../../listvehicles-filter/index';
import {goToHistory, goToOffer} from '../../../_web/navigation/routes';


class BaseStatistics extends React.Component {

    constructor(props) {
        super(props);

        this.onStateClick = this.onStateClick.bind(this);
        this.onHistoryRowClick = this.onHistoryRowClick.bind(this);
        this.onOfferRowClick = this.onOfferRowClick.bind(this);
    }

    onStateClick(state) {
        const {updateCriteriaStates} = this.props;

         updateCriteriaStates(-1, [state]);
        goToHistory();
    }

    onHistoryRowClick(data) {
        const {updateCriteriaList} = this.props;
        //const states = map(filter(data.states, (d) => d.count > 0), 'code');

        updateCriteriaList(-1, data.id, data.name);
       // updateCriteriaStates(-1, states);
        goToHistory();
    }

    onOfferRowClick(data){
        goToOffer(data.id);
    }

    render() {
        const {data} = this.props;

        return (
            <div id="statistics-container" className="g-pa-2 g-pa-10--sm g-bg-white">

                <States data={data.states} onClick={this.onStateClick}/>

                <div className="row g-pa-2 g-pa-10--sm g-ma-0">
                    <OffersTable onRowClick={this.onOfferRowClick} />
                </div>

                <div className="row g-pa-2 g-pa-10--sm g-ma-0">
                    <HistoryTable onRowClick={this.onHistoryRowClick}/>
                </div>

            </div>
        )
    }
}

export default connect(null, {updateCriteriaStates, updateCriteriaList})(BaseStatistics);
