import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withConfig} from '../../../config';
import {gettext} from '../../../translations';
import {PropagateLoader} from 'react-spinners';
import {vehicleStateFetch, listVehicleStateClosed} from '../actions';
import {toggleSwiperVisibility} from '../../picture-swiper/actions';
import * as selectors from '../selectors';
import style from '../../../styles';

import {InfoContainer, VehicleState} from '../../core';

class VehicleStateContainer extends Component {

    constructor(props) {
        super(props);

        this.loadVehicleState = this.loadVehicleState.bind(this);

    }

    componentDidMount() {
        const {vehicleId, search} = this.props;
        this.loadVehicleState(vehicleId, search);
    }

    loadVehicleState(listVehicleId, search) {
        if (listVehicleId !== 0) {
            this.props.vehicleStateFetch(listVehicleId, search);
        }
    }


    render() {
        const {listVehicleId, vehicle, stickyTop, toggleSwiperVisibility, loading, hasData, error} = this.props;

        if (error !== '') {
            return <InfoContainer text={error} icon='fa fa-wrench'/>
        }

        if ((loading || listVehicleId === 0 || !vehicle || !hasData))
            return (
                <div className="g-pa-10 g-mb-10 text-center g-min-height-250">

                    {(loading || !vehicle) &&
                    <div className="g-relative-centered--x g-relative-centered--y">
                        <PropagateLoader
                            color={style.highlightColor}
                            loading={this.props.loading}
                        />
                    </div>
                    }

                    {listVehicleId === 0 &&
                    <div>{gettext('No vehicle selected')}</div>}
                </div>
            );

        return (
            <div className="content container-semiboxed">
                <VehicleState vehicle={vehicle}
                              stickyTop={stickyTop}
                              showStickyHeader={true}
                              toggleSwiperVisibility={toggleSwiperVisibility}
                              disabled={false}
                />
            </div>
        );

    }
}


const mapStateToProps = (state, ownProps) => {
    const {vehicleId} = ownProps;

    return {
        vehicle: selectors.getVehicleById(state, vehicleId),
        loading: selectors.getLoading(state, vehicleId),
        error: selectors.getError(state, vehicleId),
        hasData: selectors.hasData(state, vehicleId),
    };
};

export default compose(
    withConfig,
    connect(mapStateToProps, {
        vehicleStateFetch,
        listVehicleStateClosed,
        toggleSwiperVisibility
    }),
)(VehicleStateContainer);
