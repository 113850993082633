import React, {Component} from 'react';
import {Template} from '../components/core/index';
import {FiltersConfiguration} from '../components/filters/index';

class Filters extends Component {

    render() {
        return (
            <Template>
                <FiltersConfiguration />
            </Template>
        )
    }
}

export default Filters;


