import _ from 'lodash';

export const mapVehicleState = (data) => {
    return {
        vehicle_id: _.get(data, 'vehicle_id'),
        vin: _.get(data, 'vin'),
        thumbnail_url: _.get(data, 'thumbnail_url') || '',
        make: _.get(data, 'make'),
        model: _.get(data, 'model'),
        version: _.get(data, 'version'),
        kilometers: _.get(data, 'kilometers'),
        first_registration: _.get(data, 'first_registration'),
        color_interior: _.get(data, 'color_interior'),
        color_exterior: _.get(data, 'color_exterior'),
        emission_norm: _.get(data, 'emission_norm'),
        power_kw: _.get(data, 'power_kw'),
        engine_cc: _.get(data, 'engine_cc'),
        doors: _.get(data, 'doors'),
        seats: _.get(data, 'seats'),
        fuel: _.get(data, 'fuel'),
        gearbox: _.get(data, 'gearbox'),
        emission_co2: _.get(data, 'emission_co2'),
        vat_regime: _.get(data, 'vat_regime'),
        featureGroups: mapFeatureGroups(_.get(data, 'features2')),
        features: mapFeatures(_.get(data, 'features2')),
        featuresText: (_.get(data, 'features_text')) ? _.get(data, 'features_text').items : [],
        damages: mapDamages(_.get(data, 'damages')),
        pictures: mapPictures(_.get(data, 'pictures')),
        description: _.get(data, 'description'),
        description_plus: _.get(data, 'description_plus'),
        checks: _.map(_.get(data, 'checks'), mapChecks),
        tyres: _.get(data, 'tyres'),
    }
};

export const mapPictures =  (data) => {
    return _.map(data, (picture) => {
        return {
            src: picture.url,
            w: 1200,
            h: 900,
            title: picture.name,
            typeId: picture.typeId,
        }
    });
};

export const mapDamages = (data) => {
    return _.map(data, (damage) => {
        return {
            location: damage.locationText,
            type: damage.typeText,
            note: damage.note ? damage.note : '',
            pictures: damage.pictures.map((p) => {
                return {
                    src: p,
                    w: 1200,
                    h: 900,
                    title: damage.locationText + ' ' + damage.typeText,
                }
            }),
        }
    })
};


export const mapChecks = (data) => {
    return {
        name: _.get(data, 'name'),
        value: _.get(data, 'value'),
        priority: _.get(data, 'priority'),
        typeName: _.get(data, 'typeName'),
        groupName: _.get(data.checkGroup, 'name'),

    }
};

const mapFeatureGroups = (data) => {
    return _.uniqBy(_.map(data, (feature) => feature.group), 'id');
};

const mapFeatures = (data) => {
    return _.map(data, (feature) => ({
        id: feature.id,
        icon: (feature.icon) ? feature.icon : 'fa fa-check',
        group: (feature.group) ? feature.group.id : 0,
        name: feature.name,
    }));
};





