import React from 'react';
import Modal from 'react-modal';

export default class WarningModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {processingVisible: false}
    }

    componentWillMount() {
        Modal.setAppElement('#app');
    }


    render() {
        const {data} = this.props;
        return (
            <Modal
                isOpen={this.props.visible}
                onRequestClose={this.props.onCancel}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                onClick={(e) => e.stopPropagation()}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 500,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: (window.innerWidth > 800) ? 600 : 0,
                        maxWidth: window.innerWidth -10,

                    }
                }}
            >

                <div className="d-flex justify-content-end clickable" onClick={(e) => {
                    e.stopPropagation();
                    this.props.onCancel()
                }}>
                    <i className="fa fa-close g-font-size-20"/>
                </div>



                <div className="g-pa-10  g-mb-20" dangerouslySetInnerHTML={{__html: decodeURIComponent(data)}}/>

            </Modal>

        );
    }
}



