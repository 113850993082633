import {RSAA} from 'redux-api-middleware';
import {listVehicleCartTypes} from './types'
import {listVehiclesBasketFetch} from '../listvehicles/actions';

export const toggleConfirm = (visible) => {
    return (dispatch) => {
        return dispatch({
            type: listVehicleCartTypes.TOGGLE_CONFIRM,
            payload: {
                visible
            }
        });


    }
};

export const confirmBiddings = (listId, vehicleIds) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/basket/0/confirm/',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    list_id: listId,
                    vehicle_ids: vehicleIds,
                }),
                types: [
                    {
                        type: listVehicleCartTypes.POST_REQUEST,
                    },
                    {
                        type: listVehicleCartTypes.POST_SUCCESS,
                        payload: (action, state, res) => onSuccess(dispatch, res, listId),
                    },
                    {
                        type: listVehicleCartTypes.POST_FAILURE,
                        payload: (action, state, res) => onFailure(dispatch, res),
                    }
                ]
            }
        });
    }
};

const onSuccess = (dispatch, res, listId) => {
    return res.json().then(data => {

        if (data.result_code) {
            dispatch(listVehiclesBasketFetch(listId));
        }

        return {
            accepted: data.result_code === 'Ok',
            message: data.result_message,
        };
    });
};

const onFailure = (dispatch, res) => {
    return {
        message: res.statusText,
    };
};
