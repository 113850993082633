import React from 'react';
import {PropagateLoader} from 'react-spinners';
import style from '../../../../styles';

export const LoadingIndicator = ({loading, text}) => {
    if (!loading) return null;
    return (
        <div className="row justify-content-center text-center g-height-30 g-pt-5" style={{zIndex: 3}}>
            <PropagateLoader
                color={style.highlightColor}
                loading={loading}
            />
        </div>
    )
}


