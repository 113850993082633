import React, {Component} from 'react';
import {slice, intersection} from 'lodash';
import style from '../../../../../styles';
import {Tooltip} from 'reactstrap';
import {equipments} from '../../../../listvehicles/equipments';
import {gettext} from '../../../../../translations/index';

class EquipmentItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
        };

        this.onClick = this.onClick.bind(this);
        this.hide = this.hide.bind(this);
        this.toggle = this.toggle.bind(this);

    }

    onClick(e) {
        e.stopPropagation();
        this.setState({
            tooltipOpen: true,
        });
    }

    hide() {
        if (this.state.tooltipOpen) {
            this.setState({
                tooltipOpen: false,
            });
        }
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });

    }

    render() {
        const {equipment, id, available} = this.props;
        const uniqueId = `equipment_${id}`;

        return (
            <div className="col d-flex align-content-end justify-content-center" style={{width: 45}}>

                <i id={uniqueId}
                   onClick={this.onClick}
                   className={equipment.icon}
                   style={{fontSize: 20, color: (available) ? style.highlightColor : '#d6d6d6', textAlign: 'center'}}
                />

                <Tooltip target={uniqueId}
                         isOpen={this.state.tooltipOpen}
                         placement={this.props.toolTipLocation}
                         toggle={this.hide}>
                    {gettext(equipment.title)}
                </Tooltip>


            </div>
        )
    }
}

EquipmentItem.props = {
    toolTipLocation: 'top',
}


class Equipments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(id) {
        this.setState({
            tooltipOpen: id,
        });
    }


    shouldComponentUpdate() {
        return false;
    }

    render() {
        const {listVehicleId, vehicle_features} = this.props;

        return (
            <div>

                <div className="row g-mt-10">
                    {slice(equipments, 0, 8).map((equipment, i) =>
                        <EquipmentItem key={`${listVehicleId}_${equipment.id}`}
                                       id={`${listVehicleId}_${equipment.id}`}
                                       equipment={equipment}
                                       available={intersection(vehicle_features, equipment.ids).length > 0 }
                        />
                    )}
                </div>

                <div className="row g-mt-10 g-mb-5">
                    {slice(equipments, 8, 17).map((equipment, i) =>
                        <EquipmentItem key={`${listVehicleId}_${equipment.id}`}
                                       id={`${listVehicleId}_${equipment.id}`}
                                       equipment={equipment}
                                       toolTipLocation="bottom"
                                       available={intersection(vehicle_features, equipment.ids).length > 0 }
                        />
                    )}
                </div>

            </div>
        )
    }
}
export default Equipments;
