import {RSAA} from 'redux-api-middleware';
import {listVehicleStateTypes} from './types';
import {entityActions} from '../../entities/actions';
import {mapVehicleState} from './mappers';
import {getVehicleIdFromListVehicleId, getListIdFromListVehicleId, combineIds} from '../../helpers/functions';
import {mapKeys, map} from 'lodash';
import _ from 'lodash';

const shouldFetchListVehicleState = (state, listVehicleId) => {
    const vehicleState = state.vehiclestate[listVehicleId];

    if (vehicleState) {
        return false;
    }

    return true;
};

export const listVehicleStateFetch = (listVehicleId, sendListInformation = false) => {
    const vehicleId = getVehicleIdFromListVehicleId(listVehicleId);
    let listId = 0;

    return (dispatch, getState) => {
        if (shouldFetchListVehicleState(getState(), listVehicleId)) {
            if (sendListInformation) {
                listId = getListIdFromListVehicleId(listVehicleId)
                console.log(getListIdFromListVehicleId(listVehicleId));
            }
            return dispatch({
                [RSAA]: {
                    endpoint: (!sendListInformation) ? `sales/api/v2/states/${vehicleId}/` : `sales/api/v2/states/${vehicleId}/?listId=${listId}`,
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                    types: [
                        {
                            type: listVehicleStateTypes.FETCH_REQUEST,
                            payload: {
                                listVehicleId
                            }
                        },
                        {
                            type: listVehicleStateTypes.FETCH_SUCCESS,
                            payload: (action, state, res) => onListVehicleStateFetchSuccess(dispatch, res, listVehicleId),
                        },
                        {
                            type: listVehicleStateTypes.FETCH_FAILURE,
                            payload: (action, state, res) => onListVehicleStateFetchError(dispatch, res, listVehicleId),
                        }
                    ]
                }
            });
        }
    };
};

export const vehicleStateFetch = (id, search) => {
    const vehicleId = getVehicleIdFromListVehicleId(id);

    return (dispatch) => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/frontpage-state/${vehicleId}/${search}`,
                method: 'GET',
                headers: {'Content-Type': 'application/json', 'Authorization': false,},

                types: [
                    {
                        type: listVehicleStateTypes.FETCH_REQUEST,
                        payload: {
                            vehicleId: id
                        }
                    },
                    {
                        type: listVehicleStateTypes.FETCH_SUCCESS,
                        payload: (action, state, res) => onListVehicleStateFetchSuccess(dispatch, res, id),
                    },
                    {
                        type: listVehicleStateTypes.FETCH_FAILURE,
                        payload: (action, state, res) => onListVehicleStateFetchError(dispatch, res, id),
                    }
                ]
            }
        });

    };
};

const onListVehicleStateFetchSuccess = (dispatch, res, listVehicleId) => {
    return res.json().then(data => {
        let result = {
            vehicle: {
                [listVehicleId]: mapVehicleState(data)
            }
        };
        dispatch(entityActions.updateEntities(result));

        return {
            listVehicleId: listVehicleId,
            receivedAt: Date.now()
        };
    });
};

const onListVehicleStateFetchError = (dispatch, res, listVehicleId) => {
    return {
        listVehicleId,
        message: res.statusText,
    };
};

export const listVehicleStateClosed = (listVehicleId) => {
    return {
        type: listVehicleStateTypes.CLOSED,
        listVehicleId,
    }
};

export const listVehicleBasketFetch = (listVehicleId) => {
    const listId = getListIdFromListVehicleId(listVehicleId);
    const vehicleId = getVehicleIdFromListVehicleId(listVehicleId);
    return basketFetchV1(listId, vehicleId, `sales/api/v2/basket/?listId=${listId}`)
};

export const vehicleBasketFetch = (listId, vehicleId) => {
    return basketFetchV1(listId, vehicleId, `sales/api/v2/basket/?vehicleId=${vehicleId}`)
};

const basketFetchV1 = (listId, vehicleId, endpoint) => {
    const listVehicleId = combineIds(listId,vehicleId);
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: endpoint,
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: listVehicleStateTypes.FETCH_BASKET_REQUEST,
                        payload: {
                            listId,
                        }
                    },
                    {
                        type: listVehicleStateTypes.FETCH_BASKET_SUCCESS,
                        payload: (action, state, res) => onlistVehicleBasketFetchSuccess(dispatch, res, listVehicleId),
                    },
                    {
                        type: listVehicleStateTypes.FETCH_BASKET_FAILURE

                    }
                ]
            }
        });
    };
};

const onlistVehicleBasketFetchSuccess = (dispatch, res, listVehicleId) => {
    return res.json().then(result => {
        let vehicles = mapKeys(map(_.filter(result, (vehicle) => listVehicleId === `${vehicle.list_id}_${vehicle.vehicle_id}`), (data) => ({
            list_vehicle_id: `${_.get(data, 'list_id')}_${_.get(data, 'vehicle_id')}`,
            basket_state: _.get(data, 'state'),
            dealer_state: _.get(data, 'dealer_state'),
            basket_price: _.get(data, 'price'),
            comment: _.get(data, 'comment') || "",
            basket_maximum_price: _.get(data, 'maximum_price') || 0,
            highest: _.get(data, 'highest') || false,
        })), function (value) {
            return value.list_vehicle_id;
        });


        if (_.keys(vehicles).length > 0) {
            dispatch(entityActions.updateEntities({
                vehicle: vehicles,
            }));
        }
    });
};

export const listVehiclesStateCleanUp = (invalidListVehicleIds) => {
    return (dispatch) => {
        return dispatch({
            type: listVehicleStateTypes.CLEANUP,
            payload: {
                invalidListVehicleIds,
            }
        });
    }
};

