import React from 'react';
import {connect} from 'react-redux';
import * as selectors from '../selectors';
import {gettext} from '../../../translations';
import {toggleConfirm} from '../actions';

const ConfirmButton = ({count, toggleConfirm}) => (
    <button onClick={() => toggleConfirm(true)}
            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-5 g-py-5"
           style={{height: 42, width: '100%',}}
            disabled={count === 0}
    >

        <div className="row g-pa-0 g-ma-0">
            <div className="col-10 g-pa-0 g-ma-0">
                <i className="fa fa-shopping-basket g-font-size-16 g-valign-middle g-mr-10"/>
                <span className="text-center g-pt-5 g-valign-middle">
                    <span className="g-hidden-md-down">{gettext('Your basket')}:</span> {count} {gettext('vehicles')}
                </span>
            </div>

            <div className="col-2 g-pa-0 g-ma-0">
                <i className="fa fa-chevron-right g-font-size-20 g-valign-middle"/>
            </div>
        </div>

    </button>
);

const mapStateToProps = (state, ownProps) => {
    return {
        count: selectors.getBiddingsToConfirmCount(state, ownProps.listId),
    }
};

export default connect(mapStateToProps, {toggleConfirm})(ConfirmButton);







