import {gettext} from "../../../translations";
import {ClearFilters, FilterButton, FilterSummary} from "../../listvehicles-filter";
import Favorite from "../../listvehicles-filter/_web/Favorite";
import EyeButton from "../../listvehicles-filter/_web/EyeButton";
import {LoadingIndicator} from "../../core";
import React, {Component} from "react";

class LiveAuctionListVehiclesHeader extends Component {
    render() {

        const {listId, loading, onBack, onFilterChanged} = this.props;

        return (
            <div className="sticky sticky-md-down bg-normal" style={{zIndex: 50}}>
                <div className="g-bg-white g-pa-5 g-mb-10  bottom-shadow">

                    <div className="justify-content-between align-items-center d-flex g-mb-0">

                        <div className="g-hidden-md-up">
                            <button onClick={onBack}
                                    className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                                <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"/>
                                <span>
                                {gettext('Back')}
                            </span>
                            </button>
                        </div>

                        <div className="row g-pa-0 g-ma-0">
                            {/*<div className="g-pa-3 g-pt-5 text-uppercase g-hidden-sm-down">*/}
                            {/*    {count} {gettext('vehicles')}*/}
                            {/*</div>*/}
                            <div className="g-pa-3 g-pt-5 text-uppercase g-hidden-sm-down">
                               {gettext('Live auction')}
                            </div>
                            <div className="g-pa-3 g-pt-5 g-hidden-sm-down">
                                <ClearFilters listId={listId}
                                              onClearFilters={onFilterChanged}/>
                            </div>
                        </div>

                        <div>
                            <div className="d-inline-block btn-group g-line-height-1_2 g-mr-10">
                                <Favorite listId={listId}
                                          onFavoriteChanged={onFilterChanged}/>

                            </div>

                            <div className="d-inline-block btn-group g-line-height-1_2">
                                <EyeButton listId={listId}
                                           onInterestWithNoBidChanged={onFilterChanged}/>
                            </div>


                            <div className="d-inline-block btn-group g-line-height-1_2 g-mr-15 g-hidden-md-up">
                                <FilterButton listId={listId}
                                              onCriteriaChanged={onFilterChanged}
                                              onClearFilters={onFilterChanged}/>
                            </div>

                        </div>
                    </div>
                    <div className="g-hidden-sm-down">
                        <FilterSummary listId={listId}
                                       onCriteriaChanged={onFilterChanged}/>
                    </div>
                </div>


                <LoadingIndicator loading={loading}/>
            </div>
        )
    }
}

export default LiveAuctionListVehiclesHeader;
