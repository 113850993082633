import {combineReducers} from 'redux';
import {entities} from './entities/reducer';
import {listVehiclesById,} from './components/listvehicles/reducer';
import {reducer as listVehicleStateReducer} from './components/listvehiclestate';
import {reducer as listVehiclesAndStateReducer} from './components/listvehicles-and-state';
import {reducer as historyAndStateReducer} from './components/history';
import {reducer as offerReducer} from './components/offer';
import {listVehicleInterest} from './components/listvehicles-no-interest/reducer';
import {listVehicleFavorites} from './components/listvehicles-favorite/reducer';
import {bidding} from './components/bidding/reducer';
import {basketByGroups} from './components/basket/reducer';
import {filterByListId} from './components/listvehicles-filter/reducer';
import {sorterById as listVehiclesSorter} from './components/listvehicles-sort/reducer';
import {swiper} from './components/picture-swiper/reducer';

import {currentListsReducer} from './components/lists/reducer'
import {reducer as filtersReducer} from './components/filters'
import {reducer as sessionReducer, initial_state as session_initial} from './components/session';
import {reducer as registrationWizardReducer} from './components/registration-wizard';
import {reducer as profileReducer} from './components/profile';
import {reducer as registrationActivationReducer} from './components/registration-activation';
import {reducer as registrationFormReducer} from './components/registration-form';
import {reducer as contentReducer} from './components/legal';
import {reducer as statisticsReducer} from './components/statistics';
import {reducer as formReducer} from 'redux-form';
import {searchReducer} from './components/vehicle-search/reducer';
import {reducer as localizationReducer} from './components/localization';
import {reducer as listCartReducer} from './components/cart';
import {reducer as colleaguesReducer} from './components/colleagues';
import {reducer as approvalCheckReducer} from './components/approval-check';
import {reducer as warningsReducer} from './components/warnings';
import {reducer as unsubscribeReduer} from './components/unsubscribe';
//import {reducer as versionReducer} from './components/version'
import {reducer as uiReducer} from './components/ui';
import {keys, each, includes} from 'lodash';


const appReducer = combineReducers({
    entities,
    currentLists: currentListsReducer,
    // lists: listById,
    listvehicles: listVehiclesById,
    listvehicles_filter: filterByListId,

    listvehicles_sort: listVehiclesSorter,
    vehiclestate: listVehicleStateReducer,


    history_and_state: historyAndStateReducer,
    listvehicles_and_state: listVehiclesAndStateReducer,
    bidding,
    listvehicles_no_interest: listVehicleInterest,
    listvehicles_favorites: listVehicleFavorites,
    basket: basketByGroups,

    picture_swiper: swiper,

    form: formReducer,


    registration_wizard: registrationWizardReducer,
    registration_activation: registrationActivationReducer,
    registration_form: registrationFormReducer,
    filters: filtersReducer,
    session: sessionReducer,

    vehicles_search: searchReducer,
    localization: localizationReducer,
    profile: profileReducer,
    content: contentReducer,
    statistics: statisticsReducer,
    list_cart: listCartReducer,
    offer: offerReducer,
    colleagues: colleaguesReducer,
    warnings: warningsReducer,
    ui: uiReducer,
    approval_check: approvalCheckReducer,
    unsubscribe: unsubscribeReduer,
    // version: versionReducer,
});


export const types = {
    CLEAR_STATE: 'CLEAR_STATE',
};

export const rootReducer = (state, action) => {
    if (action.type === "CLEAR_STATE") {
        return appReducer({
            localization: state.localization,
        }, action)
    } else if (action.type === "persist/REHYDRATE") {
        if (action.payload && action.payload.session) {
            const currentSessionKeys = keys(action.payload.session);
            const requiredSessionKeys = keys(session_initial);

            let invalid = false;
            each(requiredSessionKeys, (key) => {
               if(!includes(currentSessionKeys, key)){
                   invalid = true;
               }
            });

            if(invalid) {
                action.payload.session = session_initial;
            }
        }
        return appReducer(state, action)
    }
    return appReducer(state, action)

};


export default rootReducer;
