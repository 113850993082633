import React from 'react';
import {gettext} from '../../../translations';
import {Template} from '../../core';
import {login} from '../../../_web/navigation/routes';

export const PageNotFound = () =>
    <Template headerVisible={false} footerVisible={false}>
        <div className="g-bg-white text-center g-mt-20 g-pb-20 g-pt-20">

            <img src={require("../../../img/logo_web.png")} alt="Brand"
                 style={{marginLeft: 10}}
                 className="img-fluid"
            />

            <h1 className="g-pt-20 g-pb-20">404</h1>

            <p>
                <span className="g-font-size-20 g-mb-10"> {gettext('The page you are looking for could not be found.')}<br /></span>
                {gettext('Make sure the address is correct and that the page hasn\'t moved.')}
            </p>


            <button onClick={() => login()}
                className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-mt-15">
                <span>{gettext('Login')}</span>
                <i className="fa fa-chevron-right g-font-size-12 g-valign-middle g-ml-10 "/>
            </button>
        </div>
    </Template>