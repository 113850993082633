import React from 'react';
import {take} from 'lodash';

const FeatureText = ({features, count}) => {

    const itemsToShow = features.length >= 9 ? 8 : 9;

    return (
        <div>
            <div className="row g-color-gray-dark-v5 g-font-style-normal g-font-size-12">
                {take(features, itemsToShow).map((feature, i_feature) =>
                    <div className="col-4 text-wrap" key={`featuretext_${i_feature}`}>
                        {feature}
                    </div>
                )}

                {features.length >= 9 &&
                <div className="col-4 text-wrap g-color-primary">
                    {count - itemsToShow} more >>
                </div>}
            </div>
        </div>
    )
};


export default FeatureText;




