const commonStyle = {
    defaultColor: '#757575',
    highlightColor: '#009acb',
    highlightDeleteColor: '#009acb',
    highlightUnderlayColor: '#00ccfe',
    highlightInvertColor: '#fff',

    borderHairlineColor: '#F7F6F5',
    backgroundColor: '#fff',
    borderColor: '#E4E4E4',
    underlayColor: '#E4E4E4',

    badgeColor: 'lightgrey',
    badgeTextColor: '#757575',

    smallTextSize: 12,
    regularTextSize: 14,
    bigTextSize: 16,

    headerColor: "#fff",
    headerIconSize: 22,
    headerTextSize: 16,


    iconSize: 20,
    iconColor: '#d1cfcf'
};

const style = commonStyle;

export default style;

