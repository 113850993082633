import {RSAA} from 'redux-api-middleware';
import {config} from '../config';
import {startsWith} from 'lodash';

export const authMiddleware = ({getState, dispatch}) => next => action => {

    // Add token to header
    if (action[RSAA]) {

        const state = getState();

        /* only for mobile app,  website by nginx /sales/ */
        const endpoint = action[RSAA].endpoint;
        if (!startsWith(action[RSAA].endpoint, 'http')) {
            action[RSAA].endpoint = `${config.base_url}/${endpoint}`;
        }

        if (!action[RSAA].headers.hasOwnProperty('Authorization') || action[RSAA].headers.Authorization) {
            action[RSAA].headers = {
                ...action[RSAA].headers,
                Authorization: 'Token ' + state.session.token,
            };
        }

        // always set accept language, also for not authorized requests (bugfix for registration languages ru, sk, .. -> sends empty mail)
        action[RSAA].headers = {
            ...action[RSAA].headers,
            'Accept-Language': state.localization.locale,
        };
    }
    return next(action)
};

