import React from 'react';
import FilterItem from './FilterItem';
import classNames from 'classnames';

class FilterDropDown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {panelOpen: false};
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleHeaderClick() {
        const {onOpen, onClose, title} = this.props;

        this.setState({panelOpen: !this.state.panelOpen}, (!this.state.panelOpen) ? onOpen(title) : onClose(title));
    }

    handleItemClick(item) {
        this.props.onClick(item)
    }

    render() {
        const {title, items, makeActive} = this.props;
        const paddingBottom = (this.state.panelOpen) ? 'g-pb-0' : 'g-pb-10';

        return (
            <div className={`bg-white g-pr-20 g-pl-20 g-pt-10 g-mb-15 ${paddingBottom}`}>
                <div className="d-flex align-items-center" onClick={this.handleHeaderClick}>

                    <div className="clickable text-wrap" style={{float: 'left', width: '80%'}}>
                        <h6 className="g-pa-0 g-ma-0 color-highlight g-font-weight-800">{title}</h6>
                    </div>


                    {makeActive &&
                    <div className="justify-content-end" style={{display: 'flex', flex: 1}}>
                    <span className={classNames({
                        'g-font-size-12 float-right': true,
                        'filter-item-check': true,
                        'filter-item-counter-active': true,
                        'text-center': true
                    })}>
              <i className='fa fa-check g-font-size-12 color-white'/>
            </span>
                    </div>
                    }

                    {!makeActive &&
                    <div
                        className="d-flex justify-content-end align-items-end clickable g-color-primary--hover"
                        style={{flex: 1}}>
                        <i className="fa fa-caret-down g-color-gray-dark-v4 g-font-size-18 g-mr-10 g-color-primary--hover clickable"/>
                    </div>}
                </div>

                {this.state.panelOpen &&
                <ul className="list-unstyled">
                    {items.map((item, i) =>
                        <FilterItem key={`make-${title}-model-${item.model}`}
                                    onClick={this.handleItemClick}
                                    item={item}
                                    showCounters={this.props.showCounters}
                        />
                    )}
                </ul>
                }
            </div>
        )
    }
}

export default FilterDropDown;

