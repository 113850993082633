import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ListVehicleState} from '../../../components/listvehiclestate';
import {toggleVehicleState} from '../actions';
import {listCleanupByListId} from '../../lists/actions';
import {historyVehiclesAndStateCleanUp} from '../actions';
import {gettext} from '../../../translations';
import {formatPrice} from '../../../helpers/functions';
import * as selectors from '../selectors';
import {PrintButtons} from '../../print';

class HistoryState extends Component {
    constructor(props) {
        super(props);

        this.state = {closing: false};

        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        if (!this.state.closing) {
            //const {listVehicleId, listCleanupByListId, listVehiclesAndStateCleanUp} = this.props;
            //listCleanupByListId(getListIdFromListVehicleId(listVehicleId));
            historyVehiclesAndStateCleanUp();
        }
    }

    handleBack() {
        this.setState({closing: true}, () => this.props.toggleVehicleState());
    }


    render() {
        const {listVehicleId, vehicle,} = this.props;

        return (

            <div className="bg-normal g-ma-0 g-mt-70 g-mb-200 g-pl-5 g-pr-5">

                <div
                    className="sticky sticky-md-down bg-white row g-pa-5 g-ma-0 justify-content-between g-brd-bottom g-brd-10 brd-bg g-brd-primary "
                    style={{zIndex: 50}}>

                    <button onClick={this.handleBack}
                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                        <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"/>
                        <span className="g-hidden-xs-down">
                            {gettext('Back to list')}
                        </span>
                        <span className="g-hidden-sm-up">
                            {gettext('Back')}
                        </span>
                    </button>

                    <div className="row">

                        <div className="g-mr-5">
                            <PrintButtons listVehicleId={vehicle.list_vehicle_id}/>
                        </div>

                        <div className="g-width-200 g-pt-5">

                            <div
                                className="bg-highlight color-highlight-invert g-font-size-14 g-font-weight-600 text-center">
                                {formatPrice(vehicle.basket_price)}
                            </div>
                        </div>
                    </div>






                </div>

                <ListVehicleState listVehicleId={listVehicleId} stickyTop={120}/>
            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        vehicle: selectors.getVehicleById(state, ownProps.listVehicleId)
    };
};

export default connect(mapStateToProps, {
    toggleVehicleState,
    listCleanupByListId,
    historyVehiclesAndStateCleanUp
})(HistoryState);

