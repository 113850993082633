import React, {Component} from 'react';
import InterestThumb from "../../listvehicles-no-interest/_web/InterestThumb";
import {Tooltip} from "reactstrap";
import NumberFormat from "react-number-format";
import {formatPrice} from "../../../helpers/functions";
import BidConfirmModal from "./BidConfirmModal";
import style from "../../../styles";
import {gettext} from "../../../translations";

class BiddingFixedTender extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bidConfirmModalVisible: false,
            price: this.getPrice(props.price),
            inputSelected: false,
            tooltipOpen: false,
            priceUnconfirmed: false,
        };

        this.onConfirm = this.onConfirm.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.processBid = this.processBid.bind(this);
        this.showBidConfirmModal = this.showBidConfirmModal.bind(this);
        this.toggle = this.toggle.bind(this);

    }


    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.listVehicleId !== nextProps.listVehicleId) {
            this.setState({bidConfirmModalVisible: false, price: this.getPrice(nextProps.price), inputSelected: false});
        }

        if (this.props.listVehicleId === nextProps.listVehicleId && this.props.price !== nextProps.price) {
            this.setState({price: this.getPrice(nextProps.price)})
        }
    }

    getPrice(price) {
        return (price > 0) ? price : '';
    }

    onConfirm(price, comment, quality) {
        this.props.listVehicleBid(this.props.listId, this.props.vehicleId, price, comment, quality);

        /*  this.setState({
         bidConfirmModalVisible: false,
         inputSelected: false,
         });*/
    }

    onDelete() {
        this.props.listVehicleBidDelete(this.props.listId, this.props.vehicleId);
    }

    onCancel() {
        this.setState({
            bidConfirmModalVisible: false,
            price: this.getPrice(this.props.price)
        });
    }


    processBid() {
        /* vehicle_bidding_cart: TRUE => user can confirm al bids in 1 time (VDFIN) */
        /* vehicle_bidding_cart: FALSE => confirm each vehicle by modal and input comments (DIETEREN */
        const {vehicle_bidding_cart} = this.props.config;

        if (vehicle_bidding_cart) {
            this.priceInput.blur();
            this.divElement.focus();
            this.onConfirm(this.state.price, '');
        } else {
            this.showBidConfirmModal()
        }
    }

    showBidConfirmModal(e) {
        if (this.state.price && this.state.price > 0) {
            this.setState({bidConfirmModalVisible: true, inputSelected: false});
        }
    }


    render() {
        const {listVehicleId, biddingAllowed, price, fixedPrice, offered, comment, inBasket, basket_state, config, vehicle} = this.props;
        const {vehicle_bidding_cart, vehicle_bidding_delete, vehicle_interest, vehicle_vat_regime} = config;
        if (biddingAllowed === undefined) return null;

        const {inputSelected} = this.state;

        const containerBorderColor = (offered) ? style.highlightColor : 'lightgrey';
        const bgColor = (offered && !inputSelected && this.state.price === price) ? style.highlightColor : style.highlightInvertColor;
        const textColor = (offered && !inputSelected && this.state.price === price) ? style.highlightInvertColor : (basket_state === 'INITIAL' && inBasket) ? style.highlightColor : '#888';
        const brdColor = (offered && !inputSelected && this.state.price === price) ? style.highlightInvertColor : 'lightgrey';
        //const confirmIcon = (offered) ? 'fa fa-pencil' : 'fa fa-check';
        const confirmIcon = 'fa fa-check';

        let priceColumnCount = ((price <= 0 && vehicle_interest) || (price > 0 && comment && comment !== "" && !vehicle_bidding_delete) || (price > 0 && vehicle_bidding_delete)) ? 8 : 10;
        const hoverClass = (offered && !inputSelected && this.state.price === price) ? 'g-color-black--hover' : 'g-color-primary--hover';


        /*               |   VDFIN (FALSE)   | DIETEREN (TRUE)   -> client vat_regime setting*/
        /*  veh_vat_reg  |-----------------------------
        /*    TRUE       |      INCL         |      INCL  */
        /*    FALSE      |      EXCL         |      INCL  */
        const placeholder = (vehicle_vat_regime) ? gettext("Incl VAT") : (vehicle.vat_regime) ? gettext("Incl VAT") : gettext("Excl VAT");

        return (
            <div style={{flex: 1}}>
                <div className="row g-pa-0 g-ma-0 g-brd-1 g-brd-style-solid g-hidden-xs-down"
                     style={{borderColor: containerBorderColor, backgroundColor: bgColor, height: 35}}>


                    {vehicle_interest && price <= 0 &&
                    <div className="col-2 g-ma-0 g-pa-0 g-mt-3 g-mb-3"
                         style={{
                             backgroundColor: bgColor,
                             borderRightColor: brdColor,
                             borderRightWidth: 1,
                             borderRightStyle: 'solid',
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center'

                         }}>
                        <InterestThumb listVehicleId={listVehicleId} onClick={(e) => e.stopPropagation()}/>
                    </div>}


                    {comment && comment !== "" && !vehicle_bidding_delete &&
                    <div className="col-2 g-ma-0 g-pa-0 text-center g-mt-3 g-mb-3 clickable"
                         onClick={(e) => e.stopPropagation()}
                         id="TooltipExample"
                         style={{
                             backgroundColor: bgColor, borderRightColor: brdColor,
                             borderRightWidth: 1,
                             borderRightStyle: 'solid',
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center'
                         }}>
                        <div>
                            <i className="fa fa-commenting g-font-size-14" style={{color: textColor}}></i>
                            <div>
                                <Tooltip placement="top" isOpen={this.state.tooltipOpen} target="TooltipExample"
                                         toggle={this.toggle}>
                                    {comment}
                                </Tooltip>
                            </div>
                        </div>
                    </div>}


                    {vehicle_bidding_delete && price > 0 &&
                    <div className="col-2 g-ma-0 g-pa-0 text-center g-mt-3 g-mb-3 clickable"
                         onClick={(e) => {
                             e.stopPropagation();
                             this.onDelete();
                         }}
                         style={{
                             backgroundColor: bgColor,
                             borderRightColor: brdColor,
                             borderRightWidth: 1,
                             borderRightStyle: 'solid',
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center'
                         }}>
                        <i className={`fa fa-remove g-font-size-16 color-btn-icon ${hoverClass}`}
                           style={{color: textColor}}></i>
                    </div>}


                    <div className={`col-${priceColumnCount} g-pa-0 g-ma-0 g-valign-middle`}>
                        <NumberFormat value={this.state.price}
                                      disabled={fixedPrice}
                                      getInputRef={(el) => this.priceInput = el}
                                      placeholder={placeholder}
                                      thousandSeparator={' '}
                                      prefix={'€ '}
                                      onValueChange={(values) => this.setState({price: values.value})}
                                      allowNegative={false}
                                      onSelect={(e) => {
                                          if (!this.state.inputSelected) {
                                              this.setState({inputSelected: true});
                                          }

                                          if (this.props.onFocus) {
                                              this.props.onFocus()
                                          }
                                      }}
                                      onBlur={() => {
                                          if (this.state.inputSelected) {
                                              this.setState({inputSelected: false})
                                          }

                                          /* fix when leaves the control, but not clicked to confirm the bid ==> reset to initial price */

                                          if (this.state.price !== this.props.price) {
                                              setTimeout(() => {
                                                  if (!this.state.bidConfirmModalVisible && !vehicle_bidding_cart) {
                                                      //this.setState({price: this.getPrice(this.props.price)});
                                                      this.setState({priceUnconfirmed: true});
                                                  }
                                              }, 200);
                                          }

                                          if (this.props.onBlur) {
                                              this.props.onBlur()
                                          }
                                      }
                                      }
                                      onKeyPress={(e) => {
                                          if (e.key === 'Enter') {
                                              this.divElement.focus();
                                              this.processBid()
                                          }
                                      }}
                                      onFocus={this.props.onFocus}
                                      style={{
                                          border: 'none',
                                          backgroundColor: bgColor,
                                          color: (this.state.priceUnconfirmed) ? 'red' : textColor,
                                          outline: 'none',
                                          outlineOffset: 0,
                                          textAlign: 'right',
                                          minWidth: '100%',
                                          maxWidth: '100%',
                                          height: '100%',
                                          paddingRight: 10,
                                      }}
                        />
                    </div>
                    <div ref={divElement => this.divElement = divElement}
                         className="col-2 g-ma-0 g-pa-0 g-mt-3 g-mb-3 text-center clickable "
                         onClick={(e) => {
                             e.stopPropagation();
                             this.processBid();
                         }
                         }
                         style={{
                             backgroundColor: bgColor,
                             borderLeftColor: brdColor,
                             borderLeftWidth: 1,
                             borderLeftStyle: 'solid',
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center'
                         }}>

                        <i className={`${confirmIcon} g-font-size-16 color-btn-icon ${hoverClass}`}
                           style={{color: textColor}}></i>
                    </div>
                </div>
                <div className="row g-pa-0 g-ma-0 g-brd-1 g-brd-style-solid g-hidden-sm-up"
                     style={{borderColor: containerBorderColor, backgroundColor: bgColor, height: 35}}>


                    {!offered && !inBasket &&
                    <div
                        className="col-3 g-ma-0 g-pa-0 text-center d-flex justify-content-center align-items-center"
                        style={{backgroundColor: 'lightgrey'}}>
                        <i className="fa fa-euro g-font-size-16 color-btn-icon g-color-white"/>
                    </div>}

                    {vehicle_bidding_delete && inBasket &&
                    <div className="col-3 g-ma-0 g-pa-0 g-pl-5 g-pr-5 text-center g-mt-3 g-mb-3 clickable"
                         onClick={(e) => {
                             e.stopPropagation();
                             this.onDelete();
                         }}
                         style={{
                             backgroundColor: bgColor, borderRightColor: brdColor,
                             borderRightWidth: 1,
                             borderRightStyle: 'solid',
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center'
                         }}>
                        <i className={`fa fa-remove g-font-size-16 color-btn-icon ${hoverClass}`}
                           style={{color: textColor}}></i>
                    </div>}


                    <div
                        className={`${(offered && !vehicle_bidding_delete) ? 'col-12' : 'col-9'} g-pa-0 g-ma-0 g-valign-middle`}
                        style={{
                            backgroundColor: bgColor,
                            color: textColor,
                            textAlign: 'right',
                            fontSize: 14,
                            paddingTop: 10,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!this.state.bidConfirmModalVisible) {
                                this.setState({bidConfirmModalVisible: true});
                            }
                        }}
                    >
                        <div className="g-pt-5 g-pr-4">
                            {
                                (this.state.price) ? formatPrice(this.state.price) : placeholder
                            }
                        </div>

                    </div>

                </div>

                {this.state.bidConfirmModalVisible &&
                <BidConfirmModal visible={this.state.bidConfirmModalVisible}
                                 listVehicleId={listVehicleId}
                                 price={this.state.price}
                                 fixedPrice={fixedPrice}
                                 comment={comment}
                                 vehicle={vehicle}
                                 onConfirm={this.onConfirm}
                                 onCancel={this.onCancel}


                />}

            </div>
        )
    }
}

BiddingFixedTender.defaultProps = {
    basket_state_visible: false,
};

export default BiddingFixedTender;
