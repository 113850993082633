import React, {Component} from 'react';
import classNames from 'classnames';
import format from 'string-format';
import Flash from "react-reveal/Flash";
import {parseDateTZ} from "../../../helpers/functions";
import {ServerDate} from "../../../scripts/ServerDate";

class ListVehicleCountDown extends Component {
    constructor(props) {
        super(props);
        const now = ServerDate.now();
        const date = parseDateTZ(this.props.date);

        this.state = {
            countDown: this.getTime(date.diff(now, 'seconds', false)),
            flash: 0,
            expiring: 0,
    }
        ;

        this.startInterval = this.startInterval.bind(this);
        this.runEverySecond = this.runEverySecond.bind(this);
    }

    startInterval(interval, date) {
        this.interval = setInterval(() => this.runEverySecond(), interval);
    }

    runEverySecond() {

        const now = ServerDate.now();
        const date = parseDateTZ(this.props.date);

        if (date >= now) {
            const countDown = this.getTime(date.diff(now, 'seconds', false));

            let flash = this.state.flash;
            const secondsLeft = (countDown.days === 0 && countDown.hours === 0 && countDown.minutes === 0 && countDown.seconds < 30) ? countDown.seconds : null;

            if (secondsLeft && (secondsLeft % 2) === 1) {
                flash += 1;
            }

            if (this.state.expiring > 0) {
                this.props.extended()
            }

            this.setState({countDown: countDown, flash: flash, expiring: 0});

        } else {
            if (this.state.expiring === 0) {
                this.props.onExpiring();
            } else if (this.state.expiring === 4) {
                clearInterval(this.interval);
                this.props.onExpire()
            }

            this.setState({expiring: this.state.expiring + 1});
        }


        // setTimeout(
        //     function () {
        //
        //     }.bind(this), 4000);
        //}
    }

    componentDidMount() {
        if (this.props.date !== null) {
            this.startInterval(1000);
        }

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getTime(totalSeconds) {
        let days = 0;
        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        if (totalSeconds > 0) {
            //a day contains 60 * 60 * 24 = 86400 seconds
            //an hour contains 60 * 60 = 3600 seconds
            //a minut contains 60 seconds
            //the amount of seconds we have left
            let leftover = totalSeconds;

            //how many full days fits in the amount of leftover seconds
            days = Math.floor(leftover / 86400);

            //how many seconds are left
            leftover = leftover - (days * 86400);

            //how many full hours fits in the amount of leftover seconds
            hours = Math.floor(leftover / 3600);

            //how many seconds are left
            leftover = leftover - (hours * 3600);

            //how many minutes fits in the amount of leftover seconds
            minutes = Math.floor(leftover / 60);

            //how many seconds are left
            seconds = leftover - (minutes * 60);
        }

        return {
            days,
            hours,
            minutes,
            seconds,
        }
    }


    render() {
        const {countDown} = this.state;

        const accent = countDown.days === 0 && countDown.hours === 0 && countDown.minutes === 0 && countDown.seconds < 30;
        const text = format('{0}d {1} : {2} : {3} ', String('00' + countDown.days).slice(-2), String('00' + countDown.hours).slice(-2), String('00' + countDown.minutes).slice(-2), String('00' + countDown.seconds).slice(-2));
        const visible = countDown.days > 0 || countDown.hours > 0 || countDown.minutes > 0 || countDown.seconds > 0;


        var spanClass = classNames({
            'u-label': true,
            'g-rounded-3': true,
            'badge': true,
            'counter-badge-normal': !accent,
            'counter-badge-highlight': accent,
            'g-color-black': accent,
        });

        if (!visible) {
            return null
        }

        return (
            <Flash spy={this.state.flash}>
                <span className={spanClass} style={{width: 100, minWidth: 100}}>
                    {text}
                </span>
            </Flash>

        )
    }
}

export default ListVehicleCountDown;
