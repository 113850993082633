import React from 'react';
import * as selectors from '../selectors';
import {connect} from 'react-redux';
import {toggleConfirm, confirmBiddings} from '../actions';
import CartVehicles from './CartVehicles';
import CartTermsConditions from './CartTermsConditions';
import Result from './Result';
import {map} from 'lodash';

class Confirm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            vehicles: [],
        };

        this.onSelectionEnd = this.onSelectionEnd.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentWillUnmount() {
        const {toggleConfirm} = this.props;
        toggleConfirm(false);
    }

    onConfirm() {
        const {vehicles} = this.state;
        const {listId, confirmBiddings} = this.props;
        confirmBiddings(listId, map(vehicles, 'vehicle_id').join());
        this.setState({step: 3});
    }

    onSelectionEnd(vehicles) {
        this.setState({vehicles: vehicles, step: 2})
    }

    render() {
        const {listId, toggleConfirm} = this.props;
        const {step} = this.state;


        switch (step) {
            case 1:
                return (
                    <CartVehicles listId={listId}
                                  onSelectionEnd={this.onSelectionEnd}
                                  onCancel={() => toggleConfirm(false)}
                    />
                );
            case 2:
                return <CartTermsConditions
                    onConfirm={this.onConfirm}
                    onCancel={() => toggleConfirm(false)}
                />;
            case 3:
                return <Result />;
            default:
                return (
                    <CartVehicles listId={listId}
                                  onSelectionEnd={this.onSelectionEnd}
                                  onCancel={() => toggleConfirm(false)}
                    />
                );
        }
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        vehicles: selectors.getVehiclesToConfirm(state, ownProps.listId),
        loading: selectors.getLoading(state),
    };
};


export default connect(mapStateToProps, {toggleConfirm, confirmBiddings})(Confirm);








