import {RSAA} from "redux-api-middleware";
import {approval_check_types} from "./types";
import {filter} from 'lodash';

export const approvalCheck = () => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/companies-for-company/',
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                types: [
                    {
                        type: approval_check_types.FETCH_REQUEST,
                    },
                    {
                        type: approval_check_types.FETCH_SUCCESS,
                        payload: (action, state, res) => onApprovalCheckSuccess(dispatch, res),
                    },
                    {
                        type: approval_check_types.FETCH_FAILURE,
                        payload: (action, state, res) => onApprovalCheckFailure(dispatch, res),
                    }
                ]
            }
        });
    }
};

const onApprovalCheckSuccess = (dispatch, res) => {
    return res.json().then(data => {
       const approved = filter(data, (item) => item.state === "APPROVED");

        return {
            approvalCount: approved.length,
        }
    });
};

const onApprovalCheckFailure = (dispatch, res) => {
    return res.json().then(data => {
        return {
            message: data
        };
    });
};

