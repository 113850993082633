import {listVehicleBidTypes} from './types'

export const bidding = (state = {loading: false, accepted: false, message: '', error: ''}, action) => {
    switch (action.type) {
        case listVehicleBidTypes.POST_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                accepted: false,
                message: ''
            };
        case listVehicleBidTypes.POST_SUCCESS:
            return {
                ...state,
                loading: false,
                accepted: action.payload.accepted,
                message: action.payload.message
            };
        case listVehicleBidTypes.POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
                message: ''
            };
        default:
            return state
    }
}

