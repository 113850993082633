import React from 'react';
import WizardButtons from './WizardButtons';
import {SearchableList} from '../../../../components/core/index';
import {includes} from 'lodash';
import {gettext} from '../../../../translations';
import ContactInfo from'./ContactInfo';

class WizardStepFive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMakes: (props.filter !== null) ? props.filter.makes : props.filterMakes.map((make) => make.id),
        };

        this.renderItem = this.renderItem.bind(this);
        this.addMakes = this.addMakes.bind(this);
        this.removeMakes = this.removeMakes.bind(this);
    }

    addRemoveMake(id) {
        if (includes(this.state.selectedMakes, id)) {
            this.removeMakes([id]);
        }
        else {
            this.addMakes([id]);
        }
    }

    removeMakes(makes) {
        this.setState({
            selectedMakes: this.state.selectedMakes.filter(function (make_id) {
                return !includes(makes, make_id)
            })
        });
    }

    addMakes(makes) {
        this.setState(prevState => ({
            selectedMakes: [...prevState.selectedMakes, ...makes]
        }));
    }

    renderItem = ({id, data}) => (
        <div key={id} className="col-4 g-mt-5 g-mb-5">

            <label className="form-check-inline u-check g-pl-25">
                <input type="checkbox"
                       checked={includes(this.state.selectedMakes, id)}
                       onChange={() => this.addRemoveMake(id)}
                       className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                />

                <div className="u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                    <i className="fa" data-check-icon=""></i>
                </div>
                {data.name}
            </label>
        </div>
    );


    render() {
        const {step, totalSteps, filterMakes, onNext, onPrev} = this.props;
        const data = filterMakes.map((make) => ({id: make.id, searchTerms: [make.name], data: make}));

        return (
            <div className="g-pa-0 g-ma-0 g-mb-20">
                <div className="row g-bg-white  g-pa-0 g-ma-0 g-pt-20 g-pb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-lg-up g-pb-10 g-pa-0 g-ma-0 g-pl-5 gpl-10--sm g-pb-10">
                        <h5 className="g-color-primary g-mb-0 g-pb-0">{step}/{totalSteps} {gettext('Filters')}</h5>
                        <p className="g-pt-2">{gettext('For which brands do you want to receive prize requests?')}</p>

                        <ContactInfo />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-md-down">
                        <h5 className="g-color-primary">{gettext('Filters')}</h5>
                        <p className="g-pt-10 g-pb-10">{gettext('For which brands do you want to receive prize requests?')}</p>

                        <ContactInfo />
                    </div>


                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0">
                        <div style={{height: 500, overflow: 'auto'}}>
                            <SearchableList data={data}
                                            renderItem={this.renderItem}
                                            onSelectAll={this.addMakes}
                                            onDeSelectAll={this.removeMakes}
                                            placeholder={gettext("Makes") + ' ...'}
                            />
                        </div>

                        <div className="row g-pa-0 g-ma-0 g-bg-white">
                            <div className="col-12 g-pa-0 g-ma-0 box-shadow">
                                <WizardButtons currentStep={step}
                                               onNext={() => onNext(this.state.selectedMakes)}
                                               onPrevious={() => onPrev()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WizardStepFive;
