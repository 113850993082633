import React from 'react';
import {Field, reduxForm} from 'redux-form'
import {FormFields, validators} from '../../../components/core/';
import {gettext} from '../../../translations';

const {TextField, PhoneNumber, Combo} = FormFields;

const inviteFields = {
    COMPANY_KEY: 'company_key',
    TITLE: 'title',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    MAIL: 'email',
    PHONE: 'telephone',
    MOBILE: 'mobile',
    PASSWORD: 'password',
    PASSWORD_CONFIRM: 'password_confirm'
};

const mapDataToFields = (data) => {
    return ({
        [inviteFields.COMPANY_KEY]: data.company_key,
        [inviteFields.TITLE]: data.title,
        [inviteFields.FIRST_NAME]: data.first_name,
        [inviteFields.LAST_NAME]: data.last_name,
        [inviteFields.MAIL]: data.email,
        [inviteFields.PHONE]: data.telephone,
        [inviteFields.MOBILE]: data.mobile,
    })
};


const validate = (values, props) => {

    const {required} = props;
    const errors = {};

    if (!required)
        return errors;

    const phoneError = validators.required(values.telephone) || validators.minLength10(values.telephone);
    const mobileError = validators.required(values.mobile) || validators.minLength10(values.mobile);

    if (phoneError !== undefined && mobileError !== undefined) {

        errors.telephone = gettext('Telephone or mobile is required');
        errors.mobile = gettext('Telephone or mobile is required');
    }
    return errors;
};


const InviteForm = ({initialValues, handleSubmit, required}) => {
    return (
        <form initialvalues={mapDataToFields(initialValues)}
              onSubmit={handleSubmit}>

            <Field
                name={inviteFields.COMPANY_KEY}
                component={TextField}
                disabled={initialValues.company_key !== null && initialValues.company_key !== undefined && initialValues.company_key.length === 16 }
                type="text"
                label={gettext("Company key")}
                validate={[validators.required]}
                additionalInfo={gettext("This is your unique company identification generated by the Used Cars Center. Find the company key in the Used Cars Center e-mail invitation.")}

            />

            <div style={{height: 20}}></div>

            <Field name={inviteFields.TITLE}
                   component={Combo}
                   label={gettext("Salutation")}
                   validate={[validators.required]}
                   placeholder={gettext("Salutation")}
                   options={[
                       {value: 'Mr', label: gettext('Mr')},
                       {value: 'Mrs', label: gettext('Mrs')},
                       {value: 'Miss', label: gettext('Miss')}
                   ]}
            />

            <Field
                name={inviteFields.FIRST_NAME}
                component={TextField}
                type="text"
                label={gettext("First name")}
                validate={[validators.required]}
            />

            <Field
                name={inviteFields.LAST_NAME}
                component={TextField}
                type="text"
                label={gettext("Lastname")}
                validate={[validators.required]}
            />

            <Field
                name={inviteFields.MAIL}
                component={TextField}
                type="email"
                label={gettext("E-mail address")}
                validate={[validators.required, validators.email]}
            />

            <Field
                name={inviteFields.PHONE}
                component={PhoneNumber}
                type="text"
                label={gettext("Telephone")}
                example={`${gettext("E.g.")} 09 360 00 00`}
            />

            <Field
                name={inviteFields.MOBILE}
                component={PhoneNumber}
                type="text"
                label={gettext("Mobile")}
                example={`${gettext("E.g.")} 0494 11 22 33`}
            />

            <div style={{height: 20}}></div>

            <Field name={inviteFields.PASSWORD}
                   type="password"
                   component={TextField}
                   label={gettext("Password")}
                   placeholder="********"
                   validate={[validators.required, validators.minLength6, validators.hasNumber, validators.hasLowerCase, validators.hasUpperCase]}
            />

            <Field name={inviteFields.PASSWORD_CONFIRM}
                   type="password"
                   component={TextField}
                   label={gettext("Password (confirm)")}
                   placeholder="********"
                   validate={[validators.required, validators.minValue6, validators.hasNumber, validators.hasLowerCase, validators.hasUpperCase]}
            />

        </ form >
    )
}



export default reduxForm({
    form: 'Invite-PersonalInfo',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(InviteForm)

