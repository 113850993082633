import React from 'react';
import {connect} from 'react-redux';
import {getLocale, setLocale, getBrowserLocale} from '../';
import {setFunctionsLocale} from '../../../helpers/functions';

const defaultLocales = ["en", 'fr', 'nl', 'de'];
class LocalizedContainer extends React.Component {

    parseLocale(locale) {
        if (defaultLocales.includes(locale)) {
            return locale;
        }
        else if (defaultLocales.includes(getBrowserLocale())) {
            return getBrowserLocale();
        }
        else {
            return "en";
        }
    }

    constructor(props) {
        super(props);

        let locale = "en";

        if (props.defaultLocale && props.defaultLocale !== "") {
            locale = props.defaultLocale;
        } else if (props.locale === undefined || props.locale === "") {
            locale = getBrowserLocale();
        } else if (props.locale !== undefined && props.locale !==  "") {
            locale = props.locale; //bugfix for temp stored not supported languages (pl, ... )
        }

        props.setLocale(this.parseLocale(locale));
        setFunctionsLocale(this.parseLocale(locale));
    }



    render() {
        return (
            this.props.children
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locale: getLocale(state),
    };
};


export default connect(mapStateToProps, {setLocale})(LocalizedContainer);