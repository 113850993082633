import React, {Component} from "react";
import {gettext} from "../../../translations";

class BadgeWithCopy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltip: false,
            copy: false,
            minWidth: 140,

        };

        this.vinRef = React.createRef();

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
    }

    onMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();
        const {text} = this.props;
        try {
            navigator.clipboard.writeText(text);
        } catch (ex) {
        }


        this.setState({
            copy: true,
            tooltip: false,
            minWidth: this.vinRef.current.getBoundingClientRect().width,
        });
    };

    onMouseUp(e) {
        e.preventDefault();
        e.stopPropagation();

        const {copy} = this.state;

        if (copy) {

            setTimeout(
                function () {
                    this.setState({
                        copy: false,
                        tooltip: false,

                    });
                }
                    .bind(this),
                500
            );

        }
    };


    render() {
        const {text, uniqueId} = this.props;
        const {copy, minWidth} = this.state;

        return (
            <div key={`id_${uniqueId}`}>
                <div id={uniqueId}
                     ref={this.vinRef}
                     className="u-label g-rounded-3 badge badge-normal g-mb-5"
                     onMouseDown={this.onMouseDown}
                     onMouseUp={this.onMouseUp}
                     onMouseLeave={this.onMouseUp}
                     onClick={(e) => e.stopPropagation()}
                     style={{
                         minWidth: minWidth,
                         backgroundColor: (copy) ? '#2fcc70' : '',
                         color: (copy) ? 'white' : 'black'
                     }}

                >
                    {(copy) ? gettext("Copied") : text}
                </div>


                {/*<Tooltip placement="top" isOpen={this.state.tooltip} target={uniqueId} toggle={() => { debugger;*/}
                {/*    this.setState({tooltip: (copy) ? false : !this.state.tooltip})*/}
                {/*}}>*/}
                {/*    {gettext("Click to copy")}*/}
                {/*</Tooltip>*/}
            </div>

        )
    }

}


export default BadgeWithCopy;
