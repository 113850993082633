import React from 'react';
import {gettext} from '../../translations/index';

const NoStats = () =>
    <div className="g-pa-0 g-ma-0 justify-content-center g-min-height-250">

        <div className="row justify-content-center g-mt-50">
            <i className="fa fa-line-chart g-font-size-180 g-color-primary"/>
        </div>

        <div className="row justify-content-center g-mt-20">
            <h5 className="mb-2 color-highlight g-font-weight-800 g-pt-5"> {gettext('Currently no statistics available.')}</h5>
        </div>

        <div className="row justify-content-center g-pl-20 g-pr-20 g-pb-20 g-pt-5 text-center">
            {gettext('As soon as you start participating to the auctions, your statistics will be visible here.')}
        </div>

    </div>

export default NoStats;