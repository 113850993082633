import React from 'react';
import {CircleIcon} from './CircleIcon';

export const InfoContainer = ({icon, text, container = false, fontSize, smallText}) =>
    <div className={`row ${(container) ? 'bg-white' : 'bg-normal'} g-ma-0 g-mb-200 text-center`}>
        <div className="col-12 g-ma-0 g-pl-5 g-pr-5 text-center">
            <div className="row g-mt-20 justify-content-center">
                <CircleIcon icon={icon} size={200}/>
            </div>
        </div>
        <div className="col-12 g-ma-0 g-pl-5 g-pr-5 text-center g-mt-20">
            {text}
        </div>
        {smallText &&
        <div className="col-12 g-ma-0 g-pl-5 g-pr-5 text-center g-mt-5">
            {smallText}
        </div>}
    </div>




