import React, {Component} from 'react';
import {formatPrice} from "../../../helpers/functions";
import {gettext} from "../../../translations";
import Flash from 'react-reveal/Flash';

class BiddingAuction extends Component {

    render() {

        const {vehiclePrice, basketPrice, highestBidder, biddingAllowed, reserveMet} = this.props;

        if (biddingAllowed) {
            const bgColor = (highestBidder) ? '#48bb78' : ((basketPrice > 0) && !highestBidder) ? '#c53030' : 'lightgrey';
            const textColor = (highestBidder) ? 'white' : ((basketPrice > 0) && !highestBidder) ? 'white' : '#6C6C6C';
            const text = (vehiclePrice > 0) ? gettext("Actual bid") : gettext('Place your bid');

            return (
                <BiddingItem bgColor={bgColor} textColor={textColor} text={text} price={vehiclePrice}/>
            )
        } else if (!biddingAllowed && highestBidder) {

            if(reserveMet === null || reserveMet === true){
                return (
                    <BiddingItem bgColor='#48bb78' textColor='white' text={gettext("Won")} price={vehiclePrice}/>
                )
            }
            else if (!reserveMet){
                return (
                    <BiddingItem bgColor='#c9c938' textColor='white' text={gettext("Reserved")} price={vehiclePrice}/>
                )
            }

            return (
                <BiddingItem bgColor='#48bb78' textColor='white' text={gettext("Won")} price={vehiclePrice}/>
            )
        } else if (!biddingAllowed && !highestBidder) {
            return (
                <BiddingItem bgColor='lightgrey' textColor='#6C6C6C' text={gettext("Closed")} price={vehiclePrice}/>
            )
        }

    }
}


const BiddingItem = ({bgColor, textColor, text, price}) => {
    return (
        <div className="g-min-width-65 g-font-size-14 g-font-weight-600 align-items-center justify-content-center g-pt-2 g-pb-2"
             style={{backgroundColor: bgColor, color: textColor, minHeight: 30}}>
            <Flash spy={price}>
                <React.Fragment>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', fontSize: 11}}>{text}</div>
                    {(price > 0) &&
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>{formatPrice(price)}</div>}
                </React.Fragment>
            </Flash>
        </div>
    )
};

export default BiddingAuction;
