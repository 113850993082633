import {sortTypes} from './types';
import {omit} from 'lodash';

const INITIAL_STATE = {
    activeSorter: null,
    modalVisible: false,
};

const sort = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case sortTypes.SORT_UPDATE:
            return {
                ...state,
                activeSorter: action.payload.sorter
            };
        case sortTypes.SORT_TOGGLE:
            return {
                ...state,
                modalVisible: !state.modalVisible
            };
        default:
            return state;
    }
};


export const sorterById = (state = {}, action) => {
    switch (action.type) {
        case sortTypes.SORT_UPDATE:
        case sortTypes.SORT_TOGGLE:
            return {
                ...state,
                [action.payload.id]: sort(state[action.payload.id], action),
            };
        case sortTypes.CLEANUP:
            return omit(state, action.payload.invalidIds);
        default:
            return state;
    }
};
