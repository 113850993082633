import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import * as selectors from '../selectors';
import moment from 'moment';
import {selectListVehicle} from '../actions';
import classNames from 'classnames';
import {BidControl, BiddingAuctionRowBlock} from '../../bidding';
import {
    VehicleVatRegimeBadge,
    VehicleDescriptionLabel,
    VehicleDamageBadge,
    Top16FeatureIcons,
    Expectation,
    Specifications,
    Top9FeatureText
} from '../../core';
import * as interestSelectors from '../../listvehicles-no-interest/selectors';
import InterestLabelButton from '../../listvehicles-no-interest/_web/InterestLabelButton';
import {withConfig} from '../../../config';
import ListVehicleCountDown from './ListVehicleCountDown'
import Catalog from './Catalog';
import {PrintButtons} from '../../print';
import FavoriteButton from "../../listvehicles-favorite/_web/FavoriteButton";
import VehicleReservePriceBadge from "../../core/components/web/vehicle/VehicleReservePriceBadge";
import VehicleReservePriceAlmostMetBadge from "../../core/components/web/vehicle/VehicleReservePriceAlmostMetBadge";
import {entityActions} from "../../../entities/actions";
import {gettext} from "../../../translations";


class ListVehicleItem extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);

        this.state = {
            biddingInputSelected: false,
            startHide: false,
            hidden: props.hidden,
            expiring: false,
        }

    }

    onClick() {
        const {vehicle, onClick} = this.props;
        const {list_vehicle_id} = vehicle;

        onClick(list_vehicle_id, this.state.biddingInputSelected);
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.hidden === false && nextProps.hidden === true) {
            this.setState({startHide: true, expiring: false});

            setTimeout(
                function () {
                    this.setState({hidden: true, startHide: false});
                }.bind(this), 2000);
        } else if (this.props.hidden && nextProps.hidden === false) {
            this.setState({hidden: false});
        }

    }

    render() {
        const {vehicle, disabled, selected, config} = this.props;

        if (this.state.hidden) {
            return null;
        }

        const {
            list_vehicle_id,
            picture_url, make,
            model,
            version,
            main_company_name,
            list_number,
            list_date,
            vat_regime,
            description,
            description_plus,
            top_features,
            top_features_text,
            damage_count,
            vehicle_stop,
            flexible_stop,
            features_count,
            catalog,
            provisional_sale,
            expected_disclose,
            location_name,
            reserve_price_met,
            reserve_price_almost_met,
        } = vehicle;

        const {
            vehicle_interest,
            list_vehicle_date_online,
            list_vehicle_summary_fields,
            list_vehicle_summary_provisional_fields,
            list_vehicle_top_feature_icons,
            list_vehicle_expectation,
            list_vehicle_damage_counter,
            vehicle_print,
            vehicle_favorite
        } = config;


        const summaryFields = (vehicle.provisional_sale && !vehicle.expected_disclose) ? list_vehicle_summary_provisional_fields : list_vehicle_summary_fields;

        const titleCol = (flexible_stop || vehicle_stop) ? 6 : 12;
        const versionCol = (vehicle_print && flexible_stop) ? 9 : 12;

        return (
            <article className={classNames({
                'item item--hover': true,
                'item-disabled': disabled,
                'item-active': selected,
                'g-pr-5': true,
            })} onClick={this.onClick} style={{position: 'relative'}}>

                <div className="row g-pa-0 g-pa-5 g-pa-10--sm g-pa-10--md g-pa-10--lg g-pa-a-10--xl g-pb-5">

                    <div className="col-md-12">

                        <div className="row g-pa-0 g-ma-0 g-pos-rel g-nowrap">

                            <div className={`col-${titleCol} col-md-6 col-lg-6 col-xl-6 g-pa-0 g-ma-0 text-wrap`}>
                                <h3 className="h5 text-uppercase color-highlight g-pa-0 g-ma-0 g-mr-10 text-wrap">
                                    #{String('000' + list_number).slice(-3)} {make} {model}
                                </h3>
                            </div>


                            <div className="col-6 text-right g-pa-0 g-ma-0 g-pl-10 ">

                                {((provisional_sale && expected_disclose && location_name !== "") || (!provisional_sale && location_name !== null && location_name !== "")) &&
                                <span className="g-pa-5 g-color-gray-dark-v4 g-hidden-sm-down"> <i
                                    className="icon-map-pin"/> {location_name}</span>
                                }

                                {main_company_name &&
                                <span className="g-pa-5 g-color-gray-dark-v4 g-hidden-sm-down"> <i
                                    className="icon-map-pin"/> {main_company_name}</span>}

                                {list_vehicle_date_online &&
                                <span className="g-pa-5 g-ml-10 g-color-gray-dark-v4 g-hidden-sm-down"> <i
                                    className="icon-upload"/> {moment(list_date).format('DD/MM/YYYY HH:MM')}</span>}

                                {vehicle_print &&
                                <div className="g-pt-2 g-hidden-sm-down">
                                    <PrintButtons listVehicleId={list_vehicle_id}/>
                                </div>
                                }

                                {!vehicle_print && flexible_stop &&
                                <ListVehicleCountDown date={vehicle_stop}
                                                      onExpire={() => {
                                                          let data = {
                                                              vehicle: {
                                                                  [this.props.listVehicleId]: {
                                                                      live_auction_hidden: true,
                                                                  }
                                                              }
                                                          };
                                                          this.props.updateVehicle(data);
                                                      }}
                                                      onExpiring={() => {
                                                          this.setState({expiring: true})
                                                      }}
                                                      extended={() => {
                                                          this.setState({expiring: false})
                                                      }}
                                />}
                            </div>
                        </div>


                        <div className="row g-pa-0 g-ma-0 g-pos-rel g-nowrap">

                            <div className={`col-${versionCol} g-ma-0 g-pa-0 text-wrap`}>
                                <div
                                    className="g-color-gray-dark-v5 g-font-style-normal text-uppercase g-font-size-12 g-mb-5 text-wrap">
                                    {version}
                                </div>
                            </div>


                            <div className="col-3 text-right g-pa-0 g-ma-0 g-pl-10">
                                {flexible_stop && vehicle_print &&
                                <ListVehicleCountDown date={vehicle_stop}
                                                      onExpire={() => {
                                                          let data = {
                                                              vehicle: {
                                                                  [this.props.listVehicleId]: {
                                                                      live_auction_hidden: true,
                                                                  }
                                                              }
                                                          };
                                                          this.props.updateVehicle(data);
                                                      }}
                                                      onExpiring={() => {
                                                          this.setState({expiring: true})
                                                      }}
                                                      extended={() => {
                                                          this.setState({expiring: false})
                                                      }}
                                />}
                            </div>

                        </div>


                    </div>

                    <div className="col-4 col-md-3 col-lg-3 g-pr-5 g-pr-10--sm">

                        <div className="" style={{position: 'relative'}}>
                            {picture_url &&
                            <img src={picture_url}
                                 alt={make + ' ' + model}
                                 className="img-fluid"/>}

                            {!picture_url &&
                            <img src={require("../../../img/nopic.jpeg")}
                                 alt={make + ' ' + model}
                                 className="img-fluid"/>}

                            {vehicle_favorite &&
                            <div style={{position: 'absolute', top: 5, left: 5}}>
                                <FavoriteButton listVehicleId={list_vehicle_id} onClick={(e) => e.stopPropagation()}/>
                            </div>}

                        </div>

                        <BidControl listVehicleId={list_vehicle_id}
                                    onFocus={() => this.setState({biddingInputSelected: true})}
                                    onBlur={() => this.setState({biddingInputSelected: false})}
                        />


                    </div>

                    <div className="col-8 col-md-9 col-lg-9 g-pl-0">

                        <div className="row g-pl-15">

                            {reserve_price_met && reserve_price_met !== null && reserve_price_met === true &&
                            <div className="g-mr-5">
                                <VehicleReservePriceBadge reserve_price_met={reserve_price_met}/>
                            </div>}


                            {reserve_price_almost_met && reserve_price_almost_met !== null && reserve_price_almost_met === true &&
                            <div className="g-mr-5">
                                <VehicleReservePriceAlmostMetBadge reserve_price_almost_met={reserve_price_almost_met} reserve_price_met={reserve_price_met}/>
                            </div>}


                            <div className="g-mr-5">
                                <VehicleVatRegimeBadge vat_regime={vat_regime}/>
                            </div>

                            {list_vehicle_damage_counter &&
                            <VehicleDamageBadge damages={damage_count}/>}

                        </div>

                        <Specifications vehicle={vehicle} fields={summaryFields}/>
                        <Catalog catalog={catalog}/>

                        {list_vehicle_top_feature_icons &&
                        <div className="g-hidden-xs-down">
                            <Top16FeatureIcons listVehicleId={list_vehicle_id} vehicle_features={top_features}/>
                        </div>}

                        {!list_vehicle_top_feature_icons &&
                        <div className="g-mt-10 g-hidden-xs-down">
                            <Top9FeatureText features={top_features_text} count={features_count}/>
                        </div>
                        }

                        {list_vehicle_expectation &&
                        <div className="g-mt-5">
                            <Expectation {...vehicle} />
                        </div>}

                    </div>


                </div>

                {description_plus && description_plus !== "" &&
                <div
                    className="row g-ml-2 g-ml-10--md g-ml-10--lg g-ml-10--xl g-mr-2 g-mr-10--md g-mr-10--lg g-mr-10--xl"
                    style={{marginBottom: (description && description !== "") ? 5 : 10,}}>
                    <div className="col-12 g-pa-0 g-ma-0">
                        <VehicleDescriptionLabel description={description_plus}/>
                    </div>
                </div>}


                {description && description !== "" &&
                <div
                    className="row g-ml-2 g-ml-10--lg g-ml-10--xl g-mr-2 g-mr-10--md g-mr-10--lg g-mr-10--xl g-mb-10"
                    style={{marginTop: (description_plus && description_plus !== "") ? 5 : 0,}}>
                    <div className="col-12 g-pa-0 g-ma-0">
                        <VehicleDescriptionLabel description={description} important/>
                    </div>
                </div>}

                {(main_company_name || vehicle_interest) &&
                <div className="col-md-12 g-pa-0 g-ma-0 g-hidden-md-up g-pl-2 g-pr-10 d-flex justify-content-between"
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                    {main_company_name &&
                    <span className="g-pa-5 g-color-gray-dark-v4 text-wrap"> <i
                        className="icon-map-pin"/> {main_company_name}</span>}
                    <span className="g-pa-5 g-ml-10 g-color-gray-dark-v4 text-wrap"> <i
                        className="icon-upload"/> {moment(list_date).format('DD/MM/YYYY HH:MM')}</span>

                    {vehicle_interest &&
                    <div className="text-wrap">
                        <InterestLabelButton listVehicleId={list_vehicle_id}/>
                    </div>}

                </div>}


                <BiddingAuctionRowBlock listVehicleId={list_vehicle_id}
                                        onFocus={() => this.setState({biddingInputSelected: true})}
                                        onBlur={() => this.setState({biddingInputSelected: false})}
                />

                {this.state.expiring && !this.props.loading &&
                <div className="row g-pa-0 g-ma-0 justify-content-center g-pos-abs g-width-100 loadingContainerOverlay " style={{alignItems: 'center', cursor: 'default'}}>
                    <span className="g-font-size-33 g-font-weight-600"> {gettext("This auction is ending")}</span>
                </div>}

                {this.state.startHide && !this.props.loading &&
                <div className="row g-pa-0 g-ma-0 justify-content-center g-pos-abs g-width-100 loadingContainerOverlay " style={{alignItems: 'center', cursor: 'default'}}>
                    <span className="g-font-size-33 g-font-weight-600"> {gettext("This auction has ended")}</span>
                </div>}


            </article>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        vehicle: selectors.getVehicleById(state, ownProps.listVehicleId),
        disabled: interestSelectors.listVehicleDisabledByInterest(state, ownProps.listVehicleId),
        hidden: selectors.getIsHidden(state, ownProps.listVehicleId)
    }
};


export default compose(
    withConfig,
    connect(mapStateToProps, {selectListVehicle, updateVehicle: entityActions.updateEntities}),
)(ListVehicleItem)

