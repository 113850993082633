import React from 'react';
import {connect} from 'react-redux';
import PersonalInfoForm from './forms/PersonalInfoForm';
import WizardButtons from './WizardButtons';
import {submit} from 'redux-form'
import {gettext} from '../../../../translations';
import ContactInfo from'./ContactInfo';

class WizardStepOne extends React.Component {

    render() {
        const {required, step, totalSteps, submit, onNext, registration} = this.props;
        return (
            <div className="g-pa-0 g-ma-0 g-mb-20">
                <div className="row g-bg-white  g-pa-0 g-ma-0 g-pt-20 g-pb-0">
                    <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-lg-up g-pa-0 g-ma-0 g-pl-5 gpl-10--sm g-pb-10">
                        <h5 className="g-color-primary g-mb-0 g-pb-0">{step}/{totalSteps} {gettext('Personal info')}</h5>
                        <h6 className="g-pt-2">{gettext('How can we reach you the best?')}</h6>
                        <p className="g-pt-8-5">{gettext('With correct information we can offer you the support you expect from us.')}</p>

                        <ContactInfo />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 g-hidden-md-down">
                        <h5 className="g-color-primary">{gettext('How can we reach you the best?')}</h5>
                        <p className="g-pt-10 g-pb-10">{gettext('With correct information we can offer you the support you expect from us.')}</p>

                        <ContactInfo />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 g-ma-0">

                        <PersonalInfoForm initialValues={registration}
                                          required={required}
                                          onSubmit={(values) => onNext(values)}/>


                    </div>
                </div>

                <div className="row g-pa-0 g-ma-0 g-bg-white">
                    <div className="col-12 g-pa-0 g-ma-0">
                        <WizardButtons currentStep={step}
                                       onNext={() => submit('Registration-PersonalInfo')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, {submit})(WizardStepOne);

