import React from 'react';
import {connect} from 'react-redux';
import RegistrationForm from './RegistrationForm';
import {register} from '../actions';
import * as selectors from '../selectors';
import {LoadingIndicator} from '../../core';
import {gettext} from "../../../translations";
import {login, forgot_password} from '../../../_web/navigation/routes';

const RegistrationContainer = ({register, loading, error, onRegistrationCompleted}) =>
    <div>
        <LoadingIndicator loading={loading}/>


        {error !== null &&
        <div className="row g-pa-0 g-ma-0 container-important g-pa-5 g-mb-10">
            <div className="row g-pa-0 g-ma-0 w-100">{error.message}</div>

            {error.code === 409 &&
            <div className='row g-pa-0 g-ma-0'>
                <div>
                   <span className="g-text-underline clickable" onClick={() => login()}>{gettext('Click here')}</span> {gettext('to login')}.<br />
                    {gettext('Forgot password? ')} <span className="g-text-underline clickable" onClick={() => forgot_password()}>{gettext('Reset password')}.</span>
                </div>

            </div>}
        </div>}

        <RegistrationForm onSubmit={(values) => {
            register({
                step: 1,
                company_name: values.company_name,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                password: values.password,
                confirm_password: values.password_confirm
            }).then((result) => {
                if (!result.error) {
                    if (result.payload.registration_id > 0) {
                        onRegistrationCompleted();
                    }
                }
            });
        }}/>

    </div>;

const mapStateToProps = (state) => {
    return {
        loading: selectors.getLoading(state),
        error: selectors.getError(state)
    };
};


export default connect(mapStateToProps, {register})(RegistrationContainer);
