import React from 'react';
import {connect} from 'react-redux';
import {gettext} from '../../../translations';
import {lockColleague, unlockColleague} from '../actions';
import * as selectors from '../selectors';

class ColleagueItem extends React.Component {
    constructor(props) {
        super(props);

        this.handleLock = this.handleLock.bind(this);
    }

    handleLock(colleague) {
        const {lockColleague, unlockColleague} = this.props;
        if (colleague.state !== "LOCKED_APPROVED") {
            lockColleague(colleague);
        }
        else {
            unlockColleague(colleague)
        }
    }

    render() {
        const {colleague} = this.props;
        let color = "#999";
        if (colleague.state === "LOCKED_APPROVED") {
            color = 'red';
        }

        return (
            <figure className="row g-brd-around g-brd-gray-light-v4 g-rounded-4 g-pa-0 g-ma-0 g-pa-15">
                <div className="col-9 g-pa-0 g-ma-0 d-flex justify-content-start">


                    <div className="g-width-60 g-height-35 g-mr-15">
                        <i className="fa fa-user-circle-o g-font-size-50 g-valign-middle"
                           style={{color: color}}/>

                        {colleague.state === 'LOCKED_APPROVED' &&
                        <div>
                            {/*<span className="u-label g-rounded-3 badge damage-highlight">*/}
                            {/*{gettext('Locked')}*/}
                            {/*</span>*/}
                        </div>}
                    </div>

                    <div className="d-block">
                        <div className="g-mb-5">
                            <h4 className="h5 g-mb-0">
                                {colleague.state === 'LOCKED_APPROVED' &&
                                <i className="fa fa-lock g-font-size-20 g-valign-middle g-mr-5"
                                   style={{color: '#999'}}/>}
                                {colleague.first_name} {colleague.last_name}
                            </h4>
                        </div>
                        <em className="d-block g-color-gray-dark-v5 g-font-style-normal g-font-size-13 g-mb-2">{(colleague.email) ? colleague.email : <br />}</em>
                    </div>

                </div>
                <div className="col-3 g-pa-0 g-ma-0 text-right">
                    <a className="u-link-v5 g-brd-around g-brd-gray-light-v3 g-color-black g-color-primary--hover g-font-size-12 rounded g-px-10 g-py-5 clickable"
                       onClick={() => this.handleLock(colleague)}> {(colleague.state === 'LOCKED_APPROVED') ? gettext('Unlock') : gettext('Lock')}</a>
                </div>

            </figure>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: selectors.getLockLoading(state, ownProps.colleague.id),

    };
};

export default connect(mapStateToProps, {lockColleague, unlockColleague})(ColleagueItem);
