import React from 'react';
import {updateAge, updateKm} from '../functions/vehicle';
import {gettext} from '../../../translations';


class Vehicle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            kmError: '',
            ageError: ''
        }

        this.handleUpdateAge = this.handleUpdateAge.bind(this);
        this.handleUpdateKm = this.handleUpdateKm.bind(this);
    }

    handleUpdateKm(from, to) {
        if ((to >= from && to !== "") || (to === 0 || from.length > to.length)) {
            const {onChange} = this.props;
            onChange(updateKm(this.props.filter, from, to));
            this.setState({kmError: ''})
        } else {
            this.setState({ageError: gettext('Max km must be greater then min km')})
        }
    }

    handleUpdateAge(from, to) {
        if ((to >= from && to !== "") || (to === 0 || from.length > to.length)) {
            const {onChange} = this.props;
            onChange(updateAge(this.props.filter, from, to));
            this.setState({ageError: ''})
        } else {
            this.setState({kmError: gettext('Max age must be greater then min age')})

        }
    }

    render() {
        const {filter} = this.props;

        return (
            <div className="g-pt-20">
                <h6 className="g-mb-2">{gettext('Age (months)')}</h6>
                <div className="row g-pa-0 g-ma-0 g-pt-8 g-pb-8">

                    <div className="g-width-150">
                        <input type="text"
                               placeholder={gettext("from")}
                               value={(filter.age_min === 0) ? "" : filter.age_min}
                               onChange={(e) => this.handleUpdateAge(e.target.value, filter.age_max)}
                               className="form-control rounded g-py-8 g-px-8"
                        />
                    </div>

                    <i className="fa fa-arrow-right g-font-size-20 g-pa-6-8"></i>

                    <div className="g-width-150">
                        <input type="text"
                               placeholder={gettext("to")}
                               value={(filter.age_max === 0 ? "" : filter.age_max)}
                               onChange={(e) => this.handleUpdateAge(filter.age_min, e.target.value)}
                               className="form-control rounded g-py-8 g-px-8"
                        />
                    </div>
                </div>
                {this.state.ageError &&
                <div className="g-color-red g-font-size-12 font-italic">{this.state.ageError}</div>}


                <h6 className="g-mb-2 g-mt-20">{gettext('Km')}</h6>
                <div className="row g-pa-0 g-ma-0 g-pt-8 g-pb-8">

                    <div className="g-width-150">
                        <input type="text"
                               placeholder={gettext("from")}
                               value={(filter.km_min === 0) ? "" : filter.km_min}
                               onChange={(e) => this.handleUpdateKm(e.target.value, filter.km_max)}
                               className="form-control rounded g-py-8 g-px-8"
                        />
                    </div>

                    <i className="fa fa-arrow-right g-font-size-20 g-pa-6-8"></i>

                    <div className="g-width-150">
                        <input type="text"
                               placeholder={gettext("to")}
                               value={(filter.km_max) === 0 ? "" : filter.km_max}
                               onChange={(e) => this.handleUpdateKm(filter.km_min, e.target.value)}
                               className="form-control rounded g-py-8 g-px-8"
                        />
                    </div>

                </div>
                {this.state.kmError &&
                <div className="g-color-red g-font-size-12 font-italic">{this.state.kmError}</div>}

            </div>
        )
    }
}

export default Vehicle;
