import React from 'react';
import {SpecificationHelpers} from '../../../../index';
import format from 'string-format';

const SpecificationsTop3 = ({vehicle, fields}) => (
    <div>
        {format('{0} - {1} - {2}',  SpecificationHelpers.getFieldData(fields[0], vehicle),  SpecificationHelpers.getFieldData(fields[1], vehicle), SpecificationHelpers.getFieldData(fields[2], vehicle))}
    </div>
);

export default SpecificationsTop3;











