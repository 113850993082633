import {createErrorMessageSelector, createLoadingSelector} from "../ui";
import {UNSUBSCRIBE, UNSUBSCRIBE_SETTINGS} from "./types";


const loadingSelector = createLoadingSelector([UNSUBSCRIBE, UNSUBSCRIBE_SETTINGS]);
const errorSelector = createErrorMessageSelector([UNSUBSCRIBE, UNSUBSCRIBE_SETTINGS]);

export const getLoading = (state) => {
    return loadingSelector(state)
};

export const getError = (state) => {
    return errorSelector(state)
};

export const getMailSettings = (state) => {
    return state.unsubscribe;
};

