import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {ListVehicleState} from '../../../components/listvehiclestate';
import NextPrevButtons from '../../../components/listvehicles-nextprev/_web/NextPrevButtons';
import {BidControl} from '../../../components/bidding';
import {listVehicleSelect, toggleListVehicleState} from '../actions';
import {listCleanupByListId} from '../../lists/actions';
import {getListIdFromListVehicleId} from '../../../helpers/functions';
import {listVehiclesAndStateCleanUp} from '../actions';
import {gettext} from '../../../translations';
import InterestLabelButton from '../../listvehicles-no-interest/_web/InterestLabelButton';
import {withConfig} from '../../../config';
import BiddingAuctionRowBlock from "../../bidding/_web/BiddingAuctionRowBlock";

class ListVehicleStateContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {closing: false};

        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        if (!this.state.closing) {
            const {listVehicleId, listCleanupByListId, listVehiclesAndStateCleanUp} = this.props;
            listCleanupByListId(getListIdFromListVehicleId(listVehicleId));
            listVehiclesAndStateCleanUp();
        }
    }

    handleBack() {
        this.setState({closing: true}, () => this.props.toggleListVehicleState());
    }


    render() {
        const {listVehicleId, config, sendListInformationByRequest} = this.props;
        const {vehicle_interest} = config;

        return (

            <div className="bg-normal g-ma-0 g-mt-70 g-mb-200 g-pl-5 g-pr-5">

                <div className="sticky sticky-md-down bg-white g-pa-5 g-ma-0 justify-content-between align-items-center g-brd-bottom g-brd-10 brd-bg g-brd-primary  "
                     style={{zIndex: 50}}>

                    <div className="g-pa-0 g-ma-0" style={{display: 'flex', flex: 1, justifyContent: 'space-between', width: '100%'}}>
                        <div>
                            <button onClick={this.handleBack}
                                    className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5 g-pt-5">
                                <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"/>
                                <span className="g-hidden-xs-down">
                            {gettext('Back to list')}
                        </span>
                                <span className="g-hidden-sm-up">
                            {gettext('Back')}
                        </span>
                            </button>
                        </div>

                        <div>
                            <NextPrevButtons listVehicleId={listVehicleId}
                                             onClick={(listVehicleId) => this.props.listVehicleSelect(listVehicleId, true)}/>
                        </div>

                        <div className="row g-pa-0 g-ma-0">

                            {/*<div className="g-mr-5 g-hidden-xs-down">*/}
                            {/*    <PrintButtons listVehicleId={listVehicleId}/>*/}
                            {/*</div>*/}

                            <div className="g-width-200 g-hidden-xs-down">
                                <BidControl listVehicleId={listVehicleId}/>
                            </div>
                        </div>
                    </div>


                </div>


                <div
                    className="sticky sticky-md-down bg-white row g-pa-0 g-ma-0 g-brd-bottom g-brd-10 brd-bg g-brd-primary justify-content-center g-brd-bottom g-hidden-sm-up align-items-center align-counten-center"
                    style={{zIndex: 50, top: 40}}>

                    <div className="g-width-200  g-pb-5 g-mr-10">
                        <BidControl listVehicleId={listVehicleId}/>
                    </div>

                    {vehicle_interest &&
                    <InterestLabelButton listVehicleId={listVehicleId}/>}

                </div>

                <div
                    className="sticky sticky-md-down bg-white row g-pa-0 g-ma-0 g-brd-bottom g-brd-10 brd-bg g-brd-primary justify-content-center g-brd-bottom align-items-center g-pa-2"
                    style={{zIndex: 50, top: 40}}>

                    <BiddingAuctionRowBlock listVehicleId={listVehicleId}/>

                </div>


                <ListVehicleState listVehicleId={listVehicleId} stickyTop={124} sendListInformationByRequest={sendListInformationByRequest}/>

            </div>

        )
    }
}

const mapStateToProps = (state) => {

    return {

        // listVehicleId: listVehiclesSelectors.getSelectedListVehicleId(state),
    };
};

export default compose(
    withConfig,
    connect(mapStateToProps, {
        toggleListVehicleState,
        listVehicleSelect,
        listCleanupByListId,
        listVehiclesAndStateCleanUp
    }),
)(ListVehicleStateContainer)

