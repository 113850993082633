import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import * as selectors from '../selectors';
import moment from 'moment';
import classNames from 'classnames';
import {withConfig} from '../../../config';
import {PrintButtons} from '../../print';
import UUID from 'uuid';
import {VehicleVatRegimeBadge, Expectation, VehicleDescriptionLabel, Specifications, Top9FeatureText} from '../../core';
import BadgeWithCopy from "./BadgeWithCopy";
import DealerState from "./DealerState";


class HistoryVehicleItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vinToolTip: false,
        };

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const {vehicle, onClick} = this.props;
        const {list_vehicle_id} = vehicle;
        onClick(list_vehicle_id,);
    }

    render() {
        const {vehicle, selected, config} = this.props;
        const {
            picture_url,
            make,
            model,
            version,
            main_company_name,
            vat_regime,
            description,
            description_plus,
            offer_date,
            top_features_text,
            features_count,
            provisional_sale,
            expected_disclose,
            location_name,
        } = vehicle;

        const {
            history_vehicle_summary_fields, history_vehicle_summary_provisional_fields, history_vehicle_summary_provisional_fields_reserved, list_vehicle_top_feature_icons, history_vehicle_offer_date, history_vehicle_expectation, vehicle_print
        } = config;

        const summaryFields = (vehicle.provisional_sale && !vehicle.expected_disclose) ? (vehicle.dealer_state === "RESERVED") ? history_vehicle_summary_provisional_fields_reserved : history_vehicle_summary_provisional_fields : history_vehicle_summary_fields;


        const provisional_location = ((provisional_sale && expected_disclose && location_name !== "") || (!provisional_sale && location_name !== null && location_name !== ""));
        const titleCol = (provisional_location || vehicle_print || main_company_name || history_vehicle_offer_date) ? 6 : 12;
        const versionCol = ((main_company_name  || history_vehicle_offer_date) && provisional_location) ? 8 : 12;

        return (
            <article className={classNames({
                'item item--hover': true,
                'item-active': selected,
            })} onClick={this.onClick}>

                <div className="row g-pa-0 g-pa-5 g-pa-10--sm g-pa-10--md g-pa-10--lg g-pa-a-10--xl g-pb-5">

                    <div className="col-md-12">

                        <div className="row g-pa-0 g-ma-0 g-pos-rel g-nowrap">

                            <div className={`col-${titleCol} col-md-6 col-lg-6 col-xl-6 g-pa-0 g-ma-0 text-wrap`}>
                                <h3 className="h5 text-uppercase color-highlight g-pa-0 g-ma-0 g-mr-10 text-wrap">
                                    {make} {model}
                                </h3>
                            </div>


                            <div className="col-6 text-right g-pa-0 g-ma-0 g-pl-10 ">

                                {provisional_location &&
                                <span className="g-pa-5 g-color-gray-dark-v4 g-hidden-sm-down"> <i
                                    className="icon-map-pin"/> {location_name}</span>
                                }


                                {main_company_name && !provisional_location &&
                                <span className="g-pa-5 g-color-gray-dark-v4">
                                    <i className="icon-map-pin"/> {main_company_name}
                                </span>}

                                {history_vehicle_offer_date && !provisional_location &&
                                <span className="g-pa-5 g-ml-10 g-color-gray-dark-v4"> <i
                                    className="icon-upload"/> {moment(offer_date).format('DD/MM/YYYY HH:mm')}</span>}

                                {vehicle_print &&
                                <div className="g-pt-2">
                                    <PrintButtons listVehicleId={vehicle.list_vehicle_id}/>
                                </div>
                                }
                            </div>


                        </div>


                        <div className="row g-pa-0 g-ma-0 g-pos-rel g-nowrap">

                            <div className={`col-${versionCol} g-ma-0 g-pa-0 text-wrap`}>
                                <div
                                    className="g-color-gray-dark-v5 g-font-style-normal text-uppercase g-font-size-12 g-mb-5 text-wrap">
                                    {version}
                                </div>
                            </div>


                            <div className="col-4 text-right g-pa-0 g-ma-0 g-pl-10 ">
                                {main_company_name && provisional_location &&
                                <span className="g-pa-5 g-color-gray-dark-v4">
                                    <i className="icon-map-pin"/> {main_company_name}
                                </span>}

                                {history_vehicle_offer_date && provisional_location &&
                                <span className="g-pa-5 g-ml-10 g-color-gray-dark-v4"> <i
                                    className="icon-upload"/> {moment(offer_date).format('DD/MM/YYYY HH:mm')}</span>}

                            </div>

                        </div>


                    </div>


                    <div className="col-4 col-md-3 col-lg-3">

                        <div>
                            {picture_url &&
                            <img src={picture_url}
                                 alt={make + ' ' + model}
                                 className="img-fluid"/>}

                            {!picture_url &&
                            <img src={require("../../../img/nopic.jpeg")}
                                 alt={make + ' ' + model}
                                 className="img-fluid"/>}
                        </div>

                        <DealerState {...vehicle} />

                    </div>

                    <div className="col-8 col-md-9 col-lg-9 g-pl-0">

                        <div className="row g-pl-15">

                            <span className="u-label g-rounded-3 badge badge-normal g-mr-10 g-mb-5">
                               #{String('000' + vehicle.list_index).slice(-3)}
                           </span>

                            {/*<span className="u-label g-rounded-3 bg-highlight g-mr-10 g-mb-5">*/}
                            {/*   {gettext(DealerStateTranslate[vehicle.dealer_state])}*/}
                            {/*</span>*/}

                            <VehicleVatRegimeBadge vat_regime={vat_regime}/>

                            <span className="u-label g-rounded-3 badge badge-normal g-mr-10 g-mb-5">
                               {vehicle.list_short_name}
                           </span>

                            <BadgeWithCopy uniqueId={UUID.v4()} text={vehicle.vin}/>

                        </div>

                        <Specifications vehicle={vehicle} fields={summaryFields}/>


                        {!list_vehicle_top_feature_icons &&
                        <div className="g-mt-10 g-hidden-xs-down">
                            <Top9FeatureText features={top_features_text} count={features_count}/>
                        </div>
                        }


                        {history_vehicle_expectation &&
                        <div className="g-mt-5">
                            <Expectation {...vehicle} />
                        </div>}

                    </div>


                </div>

                {description_plus && description_plus !== "" &&
                <div
                    className="row g-ml-2 g-ml-10--md g-ml-10--lg g-ml-10--xl g-mr-2 g-mr-10--md g-mr-10--lg g-mr-10--xl"
                    style={{marginBottom: (description && description !== "") ? 5 : 10,}}>
                    <div className="col-12 g-pa-0 g-ma-0">
                        <VehicleDescriptionLabel description={description_plus}/>
                    </div>
                </div>}


                {description && description !== "" &&
                <div
                    className="row g-ml-10 g-ml-10--lg g-ml-10--xl g-mr-10--md g-mr-10--lg g-mr-10--xl g-mb-10"
                    style={{marginTop: (description_plus && description_plus !== "") ? 5 : 0,}}>
                    <div className="col-12 g-pa-0 g-ma-0">
                        <VehicleDescriptionLabel description={description} important/>
                    </div>
                </div>}

                {main_company_name &&
                <div className="col-md-12 g-pa-0 g-ma-0 g-hidden-md-up g-pl-2 g-pr-10 d-flex justify-content-between"
                     style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span className="g-pa-5 g-color-gray-dark-v4 text-wrap"> <i
                                className="icon-location-pin"/> {main_company_name}</span>
                </div>}


            </article>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        vehicle: selectors.getVehicleById(state, ownProps.listVehicleId),
    }
};

export default compose(
    withConfig,
    connect(mapStateToProps)
)(HistoryVehicleItem)
