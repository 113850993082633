import React from 'react';
import CheckValue from './CheckValue';
import _ from 'lodash';

const Checks = ({vehicle}) => {

    const checks = _.groupBy(_.orderBy(_.get(vehicle, 'checks'), [check => check.name], ['asc']), 'groupName');

    return (
        <div className="row g-pa-0 g-ma-0">
            <div className="col-xs-12 col-sm-12 col-lg-10 col-xl-10 col-xxl-10 g-pa-0 g-color-gray-dark-v4">
                {Object.keys(checks).sort().map((group, i_group) =>
                    <div className="row g-pa-0 g-ma-0" key={`group-${i_group}`}>
                        <div className="col-xs-3 col-sm-3 col-lg-4 col-xl-4 col-xxl-4 g-pa-0 g-ma-0">
                            {group}
                        </div>
                        <div className="col-xs-9 col-sm-9 col-lg-8 col-xl-8 col-xxl-8 g-pa-0 g-ma-0 g-brd-left g-brd-gray-light-v3 g-mb-20">
                            {checks[group].map((check, i_check) =>
                                <div className="row g-pa-0 g-ma-0" key={`check-${i_group}-${i_check}`}>
                                    <div className="col-8 g-pa-0 g-pl-10 g-pl-15--md g-ma-0">
                                        {_.capitalize(check.name)}
                                    </div>
                                    <div className="col-4 g-pa-0 g-ma-0 align-right">
                                        <CheckValue type={check.typeName} value={check.value}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};


export default Checks;




