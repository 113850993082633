import {RSAA} from "redux-api-middleware";
import {unsubscribe_types} from "./types";

export const fetchSettings = () => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/mailsettings/',
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                types: [
                    {
                        type: unsubscribe_types.SETTINGS_REQUEST,
                    },
                    {
                        type: unsubscribe_types.SETTINGS_SUCCESS,
                        payload: (action, state, res) => onFetchSettingsSuccess(dispatch, res),
                    },
                    {
                        type: unsubscribe_types.SETTINGS_FAILURE,
                        payload: (action, state, res) => onFetchSettingsFailure(dispatch, res),
                    }
                ]
            }
        });
    }
};


const onFetchSettingsSuccess = (dispatch, res) => {
    return res.json().then(data => {
        if (data && data[0]) {
            return {
                id: data[0].id,
                car_by_car_mail: data[0].car_by_car_mail,
                offers_mail: data[0].offers_mail,
                won_mail: data[0].won_mail,
                no_mail: data[0].no_mail,
            }
        }
    });
};

const onFetchSettingsFailure = (dispatch, res) => {
    return res.json().then(data => {
        return {
            message: data.statusText,
            code: data.status,
        };
    });
};


export const saveSettings = (id, settings) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/mailsettings/${id}/`,
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(settings),
                types: [
                    {
                        type: unsubscribe_types.SETTINGS_REQUEST,
                    },
                    {
                        type: unsubscribe_types.SETTINGS_SUCCESS,
                        payload: (action, state, res) => onSaveSettingsSuccess(dispatch, res),
                    },
                    {
                        type: unsubscribe_types.SETTINGS_FAILURE,
                        payload: (action, state, res) => onSaveSettingsFailure(dispatch, res),
                    }
                ]
            }
        });
    }
};

const onSaveSettingsSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return {
            success: data.result_code === "Ok"
        };
    });
};

const onSaveSettingsFailure = (dispatch, res) => {
    return res.json().then(data => {
        return {
            message: data.statusText,
            code: data.status,
        };
    });
};

