import {RSAA} from 'redux-api-middleware';
import {reset_pw_types} from './types';
import cogoToast from 'cogo-toast';
import {gettext} from "../../translations";


export const resetPassword = (uniqueId, password) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/profiles/1/set_password/?request=${uniqueId}&password=${password}/`,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': false,
                },
                types: [
                    {
                        type: reset_pw_types.RESET_PASSWORD_REQUEST,
                    },
                    {
                        type: reset_pw_types.RESET_PASSWORD_SUCCESS,
                        payload: (action, state, res) => onResetPasswordSuccess(dispatch, res),

                    },
                    {
                        type: reset_pw_types.RESET_PASSWORD_FAILURE,
                        payload: (action, state, res) => {
                            return {
                                success: false,
                                message: `${res.status} - ${res.statusText}`,
                            }
                        }
                    }
                ]
            }
        });
    }
};
const onResetPasswordSuccess = (dispatch, res) => {
    return res.json().then(data => {
        let success = data.result === 'Ok';

        if (success) {
            cogoToast.success(gettext("Password successfully reset"), {hideAfter: 5});
        } else {
            return dispatch({type: reset_pw_types.RESET_PASSWORD_FAILURE, payload: {message: data.result_message}});
        }

        return {
            success
        }
    });
};
