import React from 'react';
import * as selectors from "../../version/selectors";
import {connect} from "react-redux";


const ServiceWorkerActive = ({swInitialized}) => {

    const color = (swInitialized) ? 'green' : 'red';
    return (
        <React.Fragment>
            <div>{(swInitialized) ? 'sw ini' : 'sw fail'}</div>
            <div style={{backgroundColor: color, width: 20, height: 20, borderRadius: 10}}>

            </div>
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        swInitialized: selectors.isServiceWorkerInitialized(state),
    }
};

export default connect(mapStateToProps)(ServiceWorkerActive);

