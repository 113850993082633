import React from 'react';
import Modal from 'react-modal';
import collaboration from '../../../assets/svg/undraw_collaboration.svg';
import calling from '../../../assets/svg/call.svg';
import {gettext} from '../../../translations';


export default class ApprovalCheckModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {processingVisible: false}
    }

    componentWillMount() {
        Modal.setAppElement('#app');
    }


    render() {
        return (
            <Modal
                isOpen={this.props.visible}
                onRequestClose={this.props.onCancel}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                onClick={(e) => e.stopPropagation()}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 500,
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: (window.innerWidth > 800) ? 600 : 0,
                        maxWidth: window.innerWidth - 10,
                        padding: 0,

                    }
                }}
            >


                <div className="row">
                    <div className={`${(window.innerWidth > 800) ? 'col-7' : 'col-12'} text-center g-pa-20 g-pa-80--m`} style={{backgroundColor: '#e4f4f9'}}>

                        {window.innerWidth < 800 &&
                        <div className="d-flex justify-content-end clickable g-pa-5" onClick={(e) => {
                            e.stopPropagation();
                            this.props.onCancel()
                        }}>
                            <i className="fa fa-close g-font-size-20"/>
                        </div>}

                        <h4 className="g-mb-25 color-highlight g-font-weight-800">{gettext("Welcome to the Used Cars Center")}!</h4>

                        <div className="d-flex justify-content-center text-light-gr">
                            <p style={{width: 330}}>{gettext("The official car dealers need to activate your account for you to see the offers.")} </p>
                        </div>

                        <div className="row g-pa-5 g-font-size-16 g-font-size-20--md g-ma-0 g-mt-15"
                             style={{
                                 color: "#2680C2",
                                 backgroundColor: "#DCEEFB",
                                 borderWidth: 1,
                                 borderStyle: 'solid',
                                 borderColor: "#2680C2"
                             }}>


                            <div className="row g-pa-5 g-pa-10--mdd g-ma-0">

                                <div className="col-2 g-pa-0 g-ma-0 align-self-center">
                                    <img className="color-highlight" src={calling} alt="calling" style={{width: (window.innerWidth > 800) ? 45 : 30}}/>
                                </div>
                                <div className="col-10 g-pa-0 g-ma-0 g-font-size-14 g-font-size-20--md">
                                    {gettext('Contact the dealers so they can add you to their network.')}
                                </div>
                            </div>
                        </div>


                        <div className="flex flex-1 row justify-content-center">
                            <div className="g-color-white g-bg-primary g-bg-primary-dark-v1--hover clickable g-font-weight-500 g-px-15 g-py-6 g-mt-20 g-color-white"
                                 style={{maxWidth: 250}}>
                                <a href="https://admin.usedcars.center/sales/dealer/contact/"
                                   target="_blank"
                                   className="u-link-v5 g-color-primary g-font-size-16 g-font-weight-500  g-color-white clickable">
                                    {gettext('Contact suppliers')}
                                </a>
                            </div>
                        </div>
                    </div>

                    {window.innerWidth > 800 &&
                    <div className="col-5">
                        <div className="d-flex justify-content-end clickable g-pa-10" onClick={(e) => {
                            e.stopPropagation();
                            this.props.onCancel()
                        }}>
                            <i className="fa fa-close g-font-size-20"/>
                        </div>

                        <div className="g-pa-20 g-pt-10 text-center">

                            <img src={collaboration} alt="icon"
                                 style={{maxWidth: (window.innerWidth > 800) ? 300 : 150}}/>
                        </div>
                    </div>}
                </div>


            </Modal>

        );
    }
}



