import {RSAA} from 'redux-api-middleware';
import {colleagueTypes} from './types';
import {mapColleagues} from './mappers';
import cogoToast from 'cogo-toast';
import {gettext} from '../../translations';
import format from 'string-format';

export const colleaguesFetch = () => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/colleagues/',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                types: [
                    {
                        type: colleagueTypes.FETCH_REQUEST,
                    },
                    {
                        type: colleagueTypes.FETCH_SUCCESS,
                        payload: (action, state, res) => onFetchSuccess(dispatch, res),
                    },
                    {
                        type: colleagueTypes.FETCH_FAILURE,
                    }
                ]
            }
        });
    };
};

const onFetchSuccess = (dispatch, res) => {
    return res.json().then(data => {
        return mapColleagues(data);

    });
};


export const inviteColleague = (email) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/colleagues/0/invite/?email=${email}`,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                types: [
                    {
                        type: colleagueTypes.INVITE_REQUEST,
                    },
                    {
                        type: colleagueTypes.INVITE_SUCCESS,
                        payload: (action, state, res) => onInviteSuccess(dispatch, res, email),

                    },
                    {
                        type: colleagueTypes.INVITE_FAILURE,
                        payload: (action, state, res) => onInviteFailure(dispatch, res, email),

                    }
                ]
            }
        });
    }
};
const onInviteSuccess = (dispatch, res, email) => {
    return res.json().then(result => {
        cogoToast.success(format(gettext("Invite successfully send to {0}"), email), {hideAfter: 5});
    });
};

const onInviteFailure = (dispatch, res, email) => {
    return cogoToast.error(format(gettext("Error sending e-mail to {0}. Error: {1}"), email, res.statusText), {hideAfter: 5})
};


export const createColleague = (colleague) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: 'sales/api/v2/colleagues/',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': false,
                },
                body: JSON.stringify(colleague),
                types: [
                    {
                        type: colleagueTypes.CREATE_REQUEST,
                    },
                    {
                        type: colleagueTypes.CREATE_SUCCESS,
                        payload: (action, state, res) => onCreateSuccess(dispatch, res),
                    },
                    {
                        type: colleagueTypes.CREATE_FAILURE,
                        payload: (action, state, res) => onCreateFailure(dispatch, res),
                    }
                ]
            }
        });
    }
};

const onCreateSuccess = (dispatch, res) => {

    return res.json().then(result => {
        if (result.result_code === "Ok") {
            return {
                status: 'Ok',
                error: "",
            }
        } else {
            return {
                status: 'Nok',
                error: (result.result !== undefined && result.result.length > 0) ? "" : result.result_message,
                serverValidationErrors: result.result,
            }
        }
    });
};

const onCreateFailure = (dispatch, res) => {
    return {
        error: res.statusText,
    }
};


export const lockColleague = (colleague) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/colleagues/${colleague.id}/lock/`,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(colleague),
                types: [
                    {
                        type: colleagueTypes.LOCK_REQUEST,
                        payload: {
                            id: colleague.id,
                        }
                    },
                    {
                        type: colleagueTypes.LOCK_SUCCESS,
                        payload: (action, state, res) => onLockUnlockSucces(dispatch, res, colleague, 'lock'),
                    },
                    {
                        type: colleagueTypes.LOCK_FAILURE,
                        payload: (action, state, res) => onLockUnlockFailure(dispatch, res),
                    }
                ]
            }
        });
    }
};

export const unlockColleague = (colleague) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `sales/api/v2/colleagues/${colleague.id}/unlock/`,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(colleague),
                types: [
                    {
                        type: colleagueTypes.LOCK_REQUEST,
                        payload: {
                            id: colleague.id,
                        }
                    },
                    {
                        type: colleagueTypes.LOCK_SUCCESS,
                        payload: (action, state, res) => onLockUnlockSucces(dispatch, res, colleague, 'unlock'),
                    },
                    {
                        type: colleagueTypes.LOCK_FAILURE,
                        payload: (action, state, res) => onLockUnlockFailure(dispatch, res),
                    }
                ]
            }
        });
    }
};


const onLockUnlockSucces = (dispatch, res, colleague, type) => {
    return res.json().then(result => {
        if (result.result_code === "Ok") {
            dispatch(updateState(colleague.id, (type === 'lock') ? "LOCKED_APPROVED" : "APPROVED"));

            const text = (type === 'lock') ? gettext("{0} {1} is now locked") : gettext("{0} {1} is now unlocked");

            cogoToast.success(format(text, colleague.first_name, colleague.last_name), {hideAfter: 5});

            return {
                id: colleague.id
            }

        } else {
            cogoToast.error(format(gettext("Error: {0}"), res.statusText), {hideAfter: 5})
        }
    });
};

const onLockUnlockFailure = (dispatch, res) => {
    return cogoToast.error(format(gettext("Error: {0}"), res.statusText), {hideAfter: 5})
};

export const updateState = (colleagueId, state) => ({
    type: colleagueTypes.UPDATE_STATE,
    payload: {
        colleagueId,
        state,
    }
});
