import {RSAA} from 'redux-api-middleware';
import {listVehiclesFilterTypes} from './types';

/*
 const shouldFetchListVehiclesFilter = (state, listId) => {
 const filter = state.listvehicles_filter[listId];

 if (filter) {
 return false;
 }

 return true;
 };*/

export const fetchListVehiclesFilter = (listId) => {
    return (dispatch, getState) => {
        //   if (shouldFetchListVehiclesFilter(getState(), listId)) {

        let endpoint = `sales/api/v2/list-filter/${listId}/`;
        if (listId === -1) {
            endpoint = 'sales/api/v2/basket-filter/0/';
        }

        return dispatch({
                [RSAA]: {
                    endpoint,
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                    types: [
                        {
                            type: listVehiclesFilterTypes.FETCH_REQUEST,
                            payload: {
                                listId
                            }
                        },
                        {
                            type: listVehiclesFilterTypes.FETCH_SUCCESS,
                            payload: (action, state, res) => res.json().then(data => ({
                                listId,
                                data,
                                receivedAt: Date.now()
                            })),
                        },
                        {
                            type: listVehiclesFilterTypes.FETCH_FAILURE,
                            payload: {
                                listId
                            }

                        }
                    ]
                }
            }
        );
    }
    //};
};


export const updateCriteria = (listId, criteria) => {
    return (dispatch) => {
        return dispatch({
            type: listVehiclesFilterTypes.CRITERIA_UPDATE,
            payload: {
                listId,
                criteria,
            }
        });
    }
};


const updateField = (listId, fieldName, data) => {
    return {
        type: listVehiclesFilterTypes.CRITERIA_FIELD_UPDATE,
        payload: {
            listId,
            fieldName,
            data,
        }
    }
};


const updateCriteriaField = (listId, fieldName, data) => {
    return (dispatch) => {
        return dispatch(updateField(listId, fieldName, data));
    }
};

export const updateCriteriaStates = (listId, states) => {
    return updateCriteriaField(listId, 'states', states);
};

export const updateCriteriaList = (id, listId, name) => {
    return updateCriteriaField(id, 'list', {listId: listId, name: name});
};

export const updateCriteriaInterestWithNoBid = (listId, value) => {
    return updateCriteriaField(listId, 'interestWithNoBid', value);
};

export const updateCriteriaFavorites = (listId, value) => {
    return updateCriteriaField(listId, 'favorites', value);
};

export const updateCriteriaRange = (id, from, to) => {
    return (dispatch) => {
        return Promise.all([
            dispatch(updateField(id, 'expectationDt_min', from)),
            dispatch(updateField(id, 'expectationDt_max', to)),
        ]);
    }
};

export const resetCriteria = (listId) => {
    return (dispatch) => {
        return dispatch({
            type: listVehiclesFilterTypes.CRITERIA_RESET,
            payload: {
                listId,
            }
        });
    }
};

export const listVehiclesFilterCleanUp = (invalidListIds) => {
    return (dispatch) => {
        return dispatch({
            type: listVehiclesFilterTypes.CLEANUP,
            payload: {
                invalidListIds,
            }
        });
    }
};
