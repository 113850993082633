import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {apiMiddleware} from 'redux-api-middleware';
import {authMiddleware} from './middlewares/auth';
import {localizationMiddleware} from './components/localization';
/*import {stateSizeMiddleware} from './middlewares/statesize';*/
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage' // default: localStorage if _web, AsyncStorage if react-native
import rootReducer from './reducer';
//import {migrations} from './store.migrations';
import {authenticationMiddleware} from './components/authentication'
import {navigationMiddleware} from './middlewares/navigation';

const persistConfig = {
    key: 'autralis-trader',
    version: 1,
    storage: storage,
    whitelist: ['session', 'localization'],
    //blacklist: ['bidding', 'form', 'listvehicles_filter', 'listvehicles_sort', 'filters', 'registration_wizard'],
    //migrate: createMigrate(migrations)
};

export const store = createStore(
    persistReducer(persistConfig, rootReducer),
    compose(applyMiddleware(
        thunkMiddleware,
        localizationMiddleware,
        authMiddleware,
        apiMiddleware,
        authenticationMiddleware,
        navigationMiddleware,
        /*stateSizeMiddleware,*/
        ), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    ));

export const persistor = persistStore(store);
//https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975