import React, {Component} from "react";
import {connect} from 'react-redux';
import {compose} from 'redux';
import {userDeviceInfoSend} from '../actions';
import {toast} from 'react-toastify';
import firebase from 'firebase/app';
import 'firebase/messaging';
import {combineIds} from '../../../helpers/functions';
import {withConfig} from '../../../config';
import {goToVehicleDetails} from '../../../_web/navigation/routes'

class ToastItem extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const {list_id, vehicle_id} = this.props.data;
        const listVehicleId = combineIds(list_id, vehicle_id);

        goToVehicleDetails(listVehicleId);
    }

    render() {
        const {title, body} = this.props.notification;

        return (
            <div onClick={this.onClick}>
                {title} {body}
            </div>)
    }
}


class NotificationsHandler extends Component {

    constructor(props) {
        super(props);

        this.state = {token: null};

        this.onNotificationClicked = this.onNotificationClicked.bind(this);
        this.sendUDI = this.sendUDI.bind(this);
    }

    componentWillUnmount() {
        if (firebase.apps.length && this.state.token && this.state.token !== null) {
            const messaging = firebase.messaging();
            messaging.deleteToken(this.state.token);
        }
    }


    componentDidMount() {
        try {
            if(!firebase.messaging.isSupported()){
                return;
            }
            if (firebase.apps.length) {
                return;
            }
        }
        catch(ex){
            return;
        }

        const {web_fcm_messagingSenderId, web_fcm_PublicVapidKey} = this.props.config;

        var config = {
            messagingSenderId: web_fcm_messagingSenderId,
        };
        firebase.initializeApp(config);

        const messaging = firebase.messaging();

        messaging.usePublicVapidKey(web_fcm_PublicVapidKey);

        messaging.requestPermission().then(() => {
            messaging.getToken().then((token) => {
                if (token) {
                    this.setState({token});
                    this.sendUDI(token);
                    //console.log(token);
                }
            }).catch(function (err) {
            });

        }).catch(function (err) {
            console.log('Unable to get permission to notify.', err);
        });

        messaging.onTokenRefresh(function () {
            messaging.getToken().then((token) => {
                console.log('Token refreshed.');
                this.sendUDI(token);
            }).catch(function (err) {
                console.log('Unable to retrieve refreshed token ', err);
            });
        });

        messaging.onMessage(function (payload) {
            const {notification, data} = payload;

            toast(<ToastItem notification={notification} data={data}/>);
        });

    }


    onNotificationClicked(notification) {
//        const {news_id, list_id, vehicle_id} = notification;

        //      if(news_id !== null && typeof news_id !== 'undefined'){
        //const url = `${base_url}/redirect/${news_id}/`;
        //openUrl(url);
        //    }
    }

    sendUDI(token) {
        const data = {
            device_type: "FCM",
            device_token: token,
            device_os: 'web',
            app_version: 0,
        };

        if(this.props.authenticated) {
            this.props.userDeviceInfoSend(data);
        }
    }

    render() {
        return null;
    }
}

export default compose(
    withConfig,
    connect(null, {userDeviceInfoSend})
)(NotificationsHandler);
