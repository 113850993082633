import React, {Component} from 'react';
import {connect} from 'react-redux';
import Listvehicles from './Listvehicles';
import ListvehicleState from './ListvehicleState';
import * as selectors from '../selectors';
import {listVehiclesFetchAndBasket} from '../../listvehicles/actions';
import {Template} from '../../core';
import {Confirm, getConfirmVisible} from '../../cart';
import {listCleanupByListId} from '../../lists/actions';
import {listVehicleSelect, listVehiclesAndStateCleanUp} from '../actions';
import ReactGA from 'react-ga';

class ListVehiclesAndState extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        const {listId, listVehiclesFetchAndBasket} = this.props;
        listVehiclesFetchAndBasket(listId);
    }

    componentWillUpdate() {
        if (!this.props.vehicleStateVisible) {
            this.scrollOffset = window.scrollY;
        }
    }

    componentDidUpdate() {
        if (!this.props.vehicleStateVisible && this.scrollOffset) {
            window.scrollTo(0, this.scrollOffset);
        }
    }

    componentWillUnmount() {
        const {listId, listCleanupByListId, listVehiclesAndStateCleanUp} = this.props;
        listCleanupByListId(listId);
        listVehiclesAndStateCleanUp()
    }

    render() {
        const {listId, selectedListVehicleId, vehicleStateVisible, confirmVisible} = this.props;


        if (confirmVisible) {
            return (
                <Template allowFluid={false}>
                    <Confirm listId={listId}/>
                </Template>
            );
        }
        else if (vehicleStateVisible) {
            return (
                <Template allowFluid={false}>
                    <ListvehicleState listVehicleId={selectedListVehicleId}
                                      sendListInformationByRequest={true}
                    />
                </Template>
            );
        }
        else {
            return (
                <Template allowFluid={true}>
                    <Listvehicles listId={listId}
                                  sendListInformationByRequest={true}
                                  onSelectedVehicleChanged={(listVehicleId) => ReactGA.pageview(`${window.location.pathname}/${listVehicleId}`)}/>
                </Template>
            );
        }
    }
}


const mapStateToProps = (state) => {
    return {
        selectedListVehicleId: selectors.getSelectedListVehicleId(state),
        vehicleStateVisible: selectors.getVehicleStateVisible(state),
        confirmVisible: getConfirmVisible(state),
    };
};

export default connect(mapStateToProps, {listVehiclesFetchAndBasket, listVehicleSelect, listCleanupByListId, listVehiclesAndStateCleanUp})(ListVehiclesAndState);

