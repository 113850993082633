import React, {Component} from 'react';
import {connect} from 'react-redux';
import {LoadingContainer, LoadingIndicator, WheelNotOutSide} from '../../../core/index';
import VehicleItem from './VehicleItem';
import {ListVehicleState} from '../../../listvehiclestate/index';
import {withRouter} from 'react-router-dom';
import {dashboard, goBack} from '../../../../_web/navigation/routes';
import {gettext} from '../../../../translations/index';
import InfiniteScroll from 'react-infinite-scroller';
import * as selectors from '../selectors';
import {vehicleSelect} from '../actions';
import {OfferInfo} from '../../detail';

const CORR = 95;
const xxl = 1600;
const small = 992;

class Vehicles extends Component {
    constructor() {
        super();

        this.handleResize = this.handleResize.bind(this);

        window.addEventListener('resize', this.handleResize, true);

        this.state = {
            height: window.innerHeight - CORR,
            width: window.innerWidth,
            detailsVisible: window.innerWidth >= xxl,
            small: window.innerWidth <= small,
            closing: false,
            clicked: false,
        };

        this.onVehicleClick = this.onVehicleClick.bind(this);
        this.onShowState = this.onShowState.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.gooBack = this.gooBack.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleResize() {
        this.setState({
            height: window.innerHeight - CORR,
            width: window.innerWidth,
            detailsVisible: window.innerWidth >= xxl,
            small: window.innerWidth <= small,
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }


    handleScroll() {
        if (this.state.detailsVisible) {
            const footerOffset = document.getElementById('footer').offsetTop;
            const currentOffset = window.pageYOffset + window.innerHeight;

            if (currentOffset > footerOffset) {
                this.setState({
                    height: ((window.innerHeight - CORR) - (currentOffset - footerOffset)),
                });
            } else {
                const height = window.innerHeight - CORR;
                if (this.state.height !== height) {
                    this.setState({
                        height: height,
                    });
                }
            }
        }
    }


    onVehicleClick(id) {
        if (!this.state.detailsVisible) {
            this.setState({clicked: true}, () => this.props.vehicleSelect(id, true));

        }
        else {
            this.props.vehicleSelect(id, false);
        }
    }

    onShowState(listVehicleId) {
        this.props.historyVehicleSelect(listVehicleId, true);
    }

    loadMore() {
        this.props.basketFetchMore('ALL');
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, true);
        window.removeEventListener('scroll', this.handleScroll, true);

    }


    gooBack() {
        const {location,} = this.props;

        //this.props.resetFilterCriteria(listId);

        this.setState({closing: true});

        if (location.state && location.state.routed) {
            goBack();
        }
        else {
            dashboard();
        }

    }


    render() {
        const {offerId, vehicleIds, loading, selectedId,} = this.props;

        if (this.state.closing) return null;

        return (
            <div className="row bg-normal g-ma-0 g-mt-70 g-mb-200">

                <div className="g-hidden-sm-down col-md-3 col-lg-3 col-xl-3 col-xxl-2 g-ma-0 g-pl-5 g-pr-5">

                    <WheelNotOutSide>

                        <div className="sticky" style={{height: this.state.height, overflow: 'auto'}}>

                            <div className="bg-white g-pt-10 g-pb-10 g-mb-10">

                                <OfferInfo offerId={offerId} />

                                <div className="text-center g-mt-10">
                                    <button onClick={() => this.gooBack()}
                                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                                        <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"></i> {gettext('Back')}
                                    </button>
                                </div>
                            </div>



                        </div>



                    </WheelNotOutSide>

                </div>

                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-5 g-ma-0 g-pl-5 g-pr-5">

                    <div className="sticky sticky-md-down bg-normal" style={{zIndex: 50}}>

                        <div className="g-bg-white g-pa-10 g-mb-10  bottom-shadow">

                            <div className="justify-content-between align-items-center d-flex g-mb-0">

                                <div className="g-hidden-md-up">
                                    <button onClick={this.gooBack}
                                            className="btn u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 rounded-0 g-px-10 g-py-5">
                                        <i className="fa fa-chevron-left g-font-size-12 g-valign-middle g-mr-10"/>
                                        <span>
                                            {gettext('Back')}
                                        </span>
                                    </button>
                                </div>
                                <div className="row g-pa-0 g-ma-0">
                                    <div className="g-pa-3 g-pt-5 text-uppercase g-hidden-sm-down">
                                        {vehicleIds.length} {gettext('vehicles')}
                                    </div>
                                    <div className="g-pa-3 g-pt-5 text-uppercase g-hidden-md-up g-font-size-15">
                                        #{vehicleIds.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoadingIndicator loading={loading}/>
                    </div>


                    <LoadingContainer loading={loading}>
                        <WheelNotOutSide>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={() => false}
                                hasMore={false}
                                useWindow={true}
                                threshold={800}
                                loader={<LoadingIndicator key="loader" loading={(vehicleIds.length > 0)}/>}
                            >
                                {vehicleIds.map((id) =>
                                    <VehicleItem key={id}
                                                 id={id}
                                                 onClick={this.onVehicleClick}
                                                 selected={selectedId === id}
                                    />
                                )}
                            </InfiniteScroll>
                        </WheelNotOutSide>
                    </LoadingContainer>
                </div>

                <div className="hide-xl-down col-xxl-5 g-ma-0 g-pl-5 g-pr-5">

                    {this.state.detailsVisible &&
                    <WheelNotOutSide>
                        <div className="sticky"
                             style={{height: this.state.height, overflow: 'auto'}}>
                            <ListVehicleState containerId={selectedId}
                                              listVehicleId={selectedId}
                                              showStickyHeader={false}
                            />
                        </div>
                    </WheelNotOutSide>}
                </div>
            </div>
        )
    }


}


const mapStateToProps = (state) => {

    return {
        vehicleIds: selectors.getVehicleIds(state),
        selectedId: selectors.getSelectedId(state),
        loading: selectors.getLoading(state),
        error: selectors.getError(state),

    };
};

export default connect(mapStateToProps, {vehicleSelect})(withRouter(Vehicles));

