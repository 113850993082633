import  _ from 'lodash'
import {normalize} from 'normalizr';
import {listsTypes} from './types';
import {entityActions} from '../../entities/actions';
import {RSAA} from 'redux-api-middleware';
import {listScheme} from '../../helpers/normalizer';
import {mapList} from './mappers';
import moment from 'moment';
import {listVehiclesCleanUp} from '../listvehicles/actions';
import {listVehiclesStateCleanUp} from '../listvehiclestate';
import {listVehiclesFilterCleanUp} from '../listvehicles-filter/actions';
import {getListIdFromListVehicleId} from '../../helpers/functions'


const shouldFetchLists = (state) => {
    const currentLists = state.currentLists;


    if (!currentLists.lastUpdated) {
        return true;
    }
    else if (currentLists.ids.length === 0) {
        return true;
    }
    else if (currentLists.lastUpdated) {

        const startDate = moment(currentLists.lastUpdated);

        const diff = moment().diff(startDate);
        const diffDuration = moment.duration(diff);

        if (diffDuration.minutes() >= 1) {
            return true;
        } else {
            //  return false;
            return true;
        }

    }

    return false;
};

export const listsFetch = () => {
    return (dispatch, getState) => {

        if (shouldFetchLists(getState())) {
            return dispatch({
                [RSAA]: {
                    endpoint: 'sales/api/v2/lists/',
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                    types: [
                        {
                            type: listsTypes.LISTS_FETCH_REQUEST
                        },
                        {
                            type: listsTypes.LISTS_FETCH_SUCCESS,
                            payload: (action, state, res) => onSuccess(dispatch, res),
                        },
                        {
                            type: listsTypes.LISTS_FETCH_FAILURE,
                            payload: (action, state, res) => onFailure(dispatch, res),

                        }
                    ]
                }
            });
        }
    };
};

const onSuccess = (dispatch, res) => {
    return res.json().then(data => {
        data = _.filter(data, (list) => list.vehicle_count > 0);
        data = normalize(_.map(data, mapList), [listScheme]);
        dispatch(entityActions.updateEntities(data.entities));

        //return {ids: []};
        return {
            ids: data.result,
            receivedAt: Date.now(),
        };
    });
};

const onFailure = (dispatch, res) => {
    return {
        message: res.statusText,
    };
};

const shouldFetchList = (state, listId) => {
    const list = state.entities.list[listId];

    if (list) {
        return false;
    }

    return true;
};

export const fetchList = (listId) => {
    return (dispatch, getState) => {
        if (shouldFetchList(getState(), listId)) {

            return dispatch({
                [RSAA]: {
                    endpoint: `sales/api/v2/lists/${listId}/?limit=0&offset=0`,
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                    types: [
                        {
                            type: listsTypes.LIST_FETCH_REQUEST,
                            payload: {
                                listId,
                            }
                        },
                        {
                            type: listsTypes.LIST_FETCH_SUCCESS,
                            payload: (action, state, res) => onListFetchSuccess(dispatch, res, listId),
                        },
                        {
                            type: listsTypes.LIST_FETCH_FAILURE,
                            payload: {
                                listId,
                            }
                        }
                    ]
                }
            })
        } else {
            return Promise.resolve()
        }
    }
};

const onListFetchSuccess = (dispatch, res, listId,) => {

    return res.json().then(data => {

        const list = {
            id: _.get(data, 'id'),
            name: _.get(data, 'name'),
            start: _.get(data, 'start'),
            stop: _.get(data, 'stop'),
            state: _.get(data, 'state'),
            type: _.get(data, 'publicationType'),
        };

        dispatch(entityActions.updateEntities({
            list: {
                [list.id]: list
            }
        }));

        return {
            listId,
            receivedAt: Date.now(),
        };
    });
};

/*
 const timeCheck = (time, secondsOld) => {
 return moment.duration(moment().diff(time)).seconds() >= secondsOld;
 };
 */


export const listCleanupByListId = (listId) => {
    return (dispatch, getState) => {
        const state = getState();

        const vehicleIds = state.listvehicles[listId] ? state.listvehicles[listId].vehicleIds : [];

        let cleanupList = false;
        if (state.entities.list[listId] && state.entities.list[listId].stop && state.entities.list[listId].state !== 'OPEN' ) {
            cleanupList = moment(state.entities.list[listId].stop) < moment();
        }

        return Promise.all([
            dispatch(listVehiclesCleanUp([listId])),
            dispatch(listVehiclesStateCleanUp(vehicleIds)),
            dispatch(listVehiclesFilterCleanUp([listId])),
            dispatch(entityActions.clearEntities((cleanupList) ? [listId] : [], vehicleIds)),
        ]);

    }
};

export const listCleanupByListVehicleId = (listVehicleId) => {
    return (dispatch, getState) => {
        const state = getState();

        const listId = getListIdFromListVehicleId(listVehicleId);

        let cleanupList = false;
        const listIdsFromVehicles = _.map(_.keys(state.vehiclestate), (key) => key.split('_')[0]);
        if (_.includes(listIdsFromVehicles, listId)) {
            cleanupList = false;
        } else {
            if (state.entities.list[listId] && state.entities.list[listId].stop) {
                cleanupList = moment(state.entities.list[listId].stop) < moment();
            }
        }

        return Promise.all([
            dispatch(listVehiclesCleanUp([listId])),
            dispatch(listVehiclesStateCleanUp([listVehicleId])),
            dispatch(listVehiclesFilterCleanUp([listId])),
            dispatch(entityActions.clearEntities((cleanupList) ? [listId] : [], [listVehicleId])),
        ]);


    }
};

